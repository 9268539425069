import React from "react"
import {Row, Col} from "reactstrap"
import i18n from "../../i18n"

const Index = () => {
  const currentLanguage = i18n.language || 'is';

  return (
    <div className="page-content">
      <Row>
        <Col xs="12">
          <div style={{position: 'relative', paddingTop: 'max(60%,326px', height: 0, width: '80%', left: '10%'}}>
            <iframe
              title="leaf-way-i"
              allow="clipboard-write"
              sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox"
              allowFullScreen={true}
              style={{position:'absolute', border:'none', width:'100%', height:'100%', left:0, right:0, top:0, bottom:0}}
              src={
                currentLanguage === 'en' ? "https://e.issuu.com/embed.html?d=lei_in_a_laufinu-enska_20des_singlepages&u=laufid" :
                currentLanguage === 'pl' ? "https://e.issuu.com/embed.html?d=lei_in_a_laufinu-po_lska_singlepages_16_&u=laufid" :
                  "https://e.issuu.com/embed.html?d=lei_in_a_laufinu_a_n_linka_fyrir_issuu&u=laufid"
              }
            />
          </div>
        </Col>
      </Row>
    </div>
  )
};

export default Index
