import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {Check} from "@mui/icons-material";
import {Input} from "reactstrap";

import {HelperGetCurrentDate} from "../../../../helpers/main";
import {customDropdownStyles} from "../../../../helpers/dropdown";
import {editComment, getUnGoals} from "../../../../store/actions/greenStep";
import {LoaderSpinner} from "../../../../components/Common/Loader";

const GreenStep203 = (props) => {
  const { t } = useTranslation();
  const {comment, item, commentIndex, greenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const dispatch = useDispatch();
  const {unGoals, loadingUnGoal} = useSelector( state => state.greenStep );

  const [subGoal, setSubGoal] = useState('');
  const [goal, setGoal] = useState('');
  const [explanation, setExplanation] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if(unGoals.length === 0) {
      dispatch(getUnGoals());
    }
  },[]);


  useEffect(() => {
    if(props.item) {
      setGoal(item.goal);
      setSubGoal(item.subGoal);
      setExplanation(item.explanation);
    }
  }, [props.item]);


  const saveEditComment = () => {
    if(explanation.length === 0 || subGoal.length === 0) {
      setError(true);
      return
    }

    let newComment = comment;
    newComment[commentIndex] = {
      'goal': goal,
      'goalValue': goal.value,
      'subGoal': subGoal,
      'subGoalValue': subGoal.value,
      'explanation': explanation,
      "user_id": userData.user.id,
      "name": userData.user.name,
      "date": HelperGetCurrentDate(),
      "customComment": true
    };

    dispatch(editComment(greenStep.new_company_green_steps[0].id, newComment, greenStep.main_category-1, 'comment'));
    props.close();
  };

  const onChangeGoal = (value) => {
    setGoal(value);
    setSubGoal('')
  }

  if (loadingUnGoal) return (<LoaderSpinner/>);

  return (
    <div className="customGreenStep203Completed">
      <div>
        <p className="titleCopy categoryTextColor">Veljið markmið</p>
        <Select
          placeholder={t('select')}
          isSearchable={false}
          onChange={(event) => onChangeGoal(event)}
          styles={customDropdownStyles('#4433CD')}
          value={goal}
          options={unGoals.results ? unGoals.results.filter(f => f.parent_id === null).map(item => {return {label: item.goal, value: item.id}}) : []}
          className={`react-select ${error && goal.length === 0 && 'has-error'}`}
        />
      </div>

      <div>
        <p className="titleCopy categoryTextColor">Veljið undirmarkmið (valkvætt)</p>
        <Select
          placeholder={t('select')}
          isSearchable={false}
          onChange={(event) => setSubGoal(event)}
          styles={customDropdownStyles('#4433CD')}
          value={subGoal}
          options={unGoals.results && goal ? unGoals.results.filter(f => f.parent_id === goal.value).map(m => {
            return {label: m.goal, value: m.id}
          }) : []}
          className={`react-select ${error && subGoal.length === 0 && 'has-error'}`}
        />
      </div>

      <div>
        <p className="titleCopy categoryTextColor">
          Vinsamlegast bætið inn skýringu um hvernig fyrirtækið tengist heimsmarkmiðinu að ofan.
        </p>
        <div className="explanationDiv">
          <Input
            id="text"
            type="text"
            className="form-control explanationField"
            value={explanation}
            onChange={(event) => {
              setExplanation(event.target.value)
            }}
            invalid={error && explanation.length === 0}
          />
          <div className="companyCompletedIcon categoryBorderColor true" onClick={() => saveEditComment()}><Check/></div>
        </div>
       </div>

    </div>
  )
};

export default GreenStep203