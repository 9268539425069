import React from "react"
import * as ReactDOMClient from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import { Provider } from "react-redux"

import App from "./App"
import store from "./store"
import './i18n';
import "./assets/scss/theme.scss"

const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </Provider>
);