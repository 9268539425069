import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useDispatch} from "react-redux";
import {Label} from "reactstrap";
import Select from "react-select";
import {Check} from "@mui/icons-material";

import {HelperGetCurrentDate} from "../../../../helpers/main";
import {customDropdownStyles} from "../../../../helpers/dropdown";
import {editComment} from "../../../../store/actions/greenStep";

const yearOptions = () => {
  let years = [];
  for(let i=0; i<=60; i++) {
    years.push({label: 1990+i, value: 1990+i})
  }
  return years;
};

const yearOptionsTo = () => {
  let years = [];
  for(let i=0; i<=60; i++) {
    years.push({label: 2024+i, value: 2024+i})
  }
  return years;
};

const GreenStep142 = (props) => {
  const { t } = useTranslation();
  const {comment, item, commentIndex, greenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const dispatch = useDispatch();

  const [yearFrom, setYearFrom] = useState('');
  const [yearTo, setYearTo] = useState('');

  useEffect(() => {
    if(props.item) {
      setYearFrom({label: item.yearFrom, value: item.yearFrom});
      setYearTo({label: item.yearTo, value: item.yearTo});
    }
  }, [props.item]);

  const saveEditComment = () => {
    let newComment = comment;
    newComment.splice(commentIndex, 1);
    newComment.unshift({
      "comment": `Stefnan gildir frá árinu ${yearFrom.value} til ársins ${yearTo.value}.`,
      "user_id": userData.user.id,
      "name": userData.user.name,
      "yearFrom": yearFrom.value,
      "yearTo": yearTo.value,
      "date": HelperGetCurrentDate(),
      "customComment": true
    });

    dispatch(editComment(greenStep.new_company_green_steps[0].id, newComment, greenStep.main_category-1, 'comment'));
    props.close();
  };

return (
    <div className="row">
      <div className="col-4">
        <Label className="col-form-label categoryTextColor">Frá árinu</Label>
        <Select
          placeholder={t('select')}
          isSearchable={false}
          onChange={(event) => setYearFrom(event)}
          value={yearFrom}
          styles={customDropdownStyles('#FD974F')}
          options={yearOptions()}
          className={`react-select`}
        />
      </div>

      <div className="col-4">
      <Label className="col-form-label categoryTextColor">Til ársins</Label>
      <Select
        placeholder={t('select')}
        isSearchable={false}
        onChange={(event) => setYearTo(event)}
        value={yearTo}
        styles={customDropdownStyles('#FD974F')}
        options={yearOptionsTo()}
        className={`react-select`}
      />
      </div>
      <div className="col-2">
        <div className="companyCompletedIcon categoryBorderColor true" style={{marginTop: '40px'}} onClick={() => saveEditComment()}><Check/></div>
      </div>
    </div>
)
};

export default GreenStep142