import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getCompanies = (page, loadMore) => async dispatch => {
  dispatch({type: loadMore ? actionTypes.GET_MORE_COMPANY_START : actionTypes.GET_COMPANY_START});
  try {
    const res = await axios.get(page ? '/company?page='+page : '/company');
    dispatch({type: loadMore ?  actionTypes.GET_MORE_COMPANY_SUCCESS : actionTypes.GET_COMPANY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: loadMore ? actionTypes.GET_MORE_COMPANY_FAIL : actionTypes.GET_COMPANY_FAIL});
  }
};

export const getCompaniesOnly = () => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_ONLY_START});
  try {
    const res = await axios.get('/company/get_all');
    dispatch({type: actionTypes.GET_COMPANY_ONLY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_ONLY_FAIL});
  }
};

export const createUpdateCompany = (update, data, updateFrom='') => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_COMPANY_START : actionTypes.CREATE_COMPANY_START});
  try {
    const res = await axios({
      url: update ? '/company/'+data.id : '/company',
      method: update ? 'PUT' : 'POST',
      data: { ...data }
    });
    dispatch({type: update ? actionTypes.UPDATE_COMPANY_SUCCESS : actionTypes.CREATE_COMPANY_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Vistað!', style:'success'});

    if(updateFrom === 'companies') {
      dispatch(getCompanies());
    }

  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_COMPANY_FAIL : actionTypes.CREATE_COMPANY_FAIL, data: errorMessage});
  }
};

export const updateCompanyCategory = (id, categories) => async dispatch => {
  dispatch({type: actionTypes.UPDATE_CATEGORY_START});
  try {
    const res = await axios({
      url: '/company/update_category',
      method: 'POST',
      data: {
        "company_id": id,
        "company_categories": categories,
      }
    });
    dispatch({type: actionTypes.UPDATE_CATEGORY_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Flokkur uppfært!', style:'success'});
    dispatch(getCompanies());
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.UPDATE_CATEGORY_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};

export const searchCompanies = (search) => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_START});
  try {
    const res = await axios.get('/api/company/search/'+search);
    dispatch({type: actionTypes.GET_COMPANY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_FAIL});
  }
};

export const connectWith1819 = (companyId, registrationId) => async dispatch => {
  dispatch({type: actionTypes.COMPANY_CONNECT_1819_START});
  try {
    const res = await axios({
      url: '/company/connect_with_1819',
      method: 'POST',
      data: {
        "laufid_company_id": companyId,
        "registration_id": registrationId
      }
    });
    dispatch({type: actionTypes.COMPANY_CONNECT_1819_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Þú hefur tengt fyrirtæki við gagnagrunn upplýsingaveitu Laufsins.', style:'success'});
    dispatch(getCompanies());
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.COMPANY_CONNECT_1819_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: errorMessage, style:'error'});
  }
};

export const disconnectWith1819 = (companyId) => async dispatch => {
  dispatch({type: actionTypes.COMPANY_DISCONNECT_1819_START});
  try {
    const res = await axios({
      url: '/company/disconnect_with_1819',
      method: 'POST',
      data: {
        "laufid_company_id": companyId,
      }
    });
    dispatch({type: actionTypes.COMPANY_DISCONNECT_1819_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Þú hefur aftengt fyrirtæki við gagnagrunn upplýsingaveitu Laufsins. ', style:'success'});
    dispatch(getCompanies());
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.COMPANY_DISCONNECT_1819_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: errorMessage, style:'error'});
  }
};

export const companyCreateIn1819 = (kennitala) => async dispatch => {
  dispatch({type: actionTypes.COMPANY_CREATE_1819_START});
  try {
    const res = await axios({
      url: '/company/create_company_in_1819',
      method: 'POST',
      data: {
        "kennitala": kennitala
      }
    });
    dispatch({type: actionTypes.COMPANY_CREATE_1819_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Company Created!', style:'success'});
    dispatch(getCompanies());
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.COMPANY_CREATE_1819_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: errorMessage, style:'error'});
  }
};

export const disableEnableCompany = (type, data) => async dispatch => {
  dispatch({type: actionTypes.DISABLE_ENABLE_COMPANY_START});
  try {
    const res = await axios({
      url: type === 'disable' ? '/company/disable_company' : '/company/enable_company',
      method: 'POST',
      data: {
        "laufid_company_id": data.id,
        "registration_id": data.registration_id
      }
    });
    dispatch({type: actionTypes.DISABLE_ENABLE_COMPANY_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: type === 'disable' ? 'Fyrirtæki óvirkt' : 'Fyrirtæki virkt', style:'success'});
    dispatch(getCompanies());
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.DISABLE_ENABLE_COMPANY_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: errorMessage, style:'error'});
  }
};

export const deleteCompany = (data) => async dispatch => {
  try {
    await axios.delete('/company/' + data.id);
    dispatch(getCompanies());
  } catch (error) {
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};
