import React from 'react';
import {useTranslation} from 'react-i18next';
import Swal from 'sweetalert';
import {Close} from '@mui/icons-material';
import {Button, UncontrolledTooltip} from "reactstrap"

import "./index.scss"

const DeleteButton = (props) => {
  const { t } = useTranslation();

  const warningWithConfirmMessage = () => {
    Swal({
      title:t('are_you_sure'),
      icon: "warning",
      dangerMode: true,
      buttons: {confirm: t('yes'), cancel: t('no')},
    }).then((confirm) => {
      if (confirm) {
        props.action();
      } else {
        Swal.close();
      }
    });
  };

  return <React.Fragment>
    <Button color="danger" id={props.id || ''} size={props.size || 'sm'} style={props.styleBtn} className="actionButton" onClick={() => warningWithConfirmMessage()}>
      {props.msg ? props.msg : <Close className="icon" />}
      {props.tooltip && props.id && <UncontrolledTooltip placement="bottom" target={props.id}>{t('delete')}</UncontrolledTooltip>}
    </Button>
  </React.Fragment>
};

export default DeleteButton;