import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  dashboard: [],
  loadingMunicipality: false,
  municipality: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function dashboardReducer(state = initialState, action = {}) {
  switch (action.type) {
    
    case actionTypes.GET_DASHBOARD_REPORT_SUCCESS:
      return {
        ...state,
        dashboard: action.data,
        loading: false
      };
    case actionTypes.GET_DASHBOARD_REPORT_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_DASHBOARD_REPORT_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.GET_DASHBOARD_MUNICIPALITY_START:
      return  {
        ...state,
        loadingMunicipality: true
      };
    case actionTypes.GET_DASHBOARD_MUNICIPALITY_SUCCESS:
      return {
        ...state,
        municipality: action.data,
        loadingMunicipality: false
      };
    case actionTypes.GET_DASHBOARD_MUNICIPALITY_FAIL:
      return  {
        ...state,
        loadingMunicipality: false
      };
      
    default:
      return state;
  }
}
