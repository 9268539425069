import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux';
import {Edit, KeyboardArrowDown, KeyboardArrowUp, Check} from '@mui/icons-material';
import {Table, Button, Collapse, Input} from "reactstrap"

import {LoaderSpinner} from "../../components/Common/Loader";
import {getCarbonEmissions, updateCarbonEmission} from "../../store/actions/carbon";
import "./index.scss"

const Index = () => {
  const dispatch = useDispatch ();
  const store = JSON.parse(localStorage.getItem('p_user'));
  const permission = store ? store.permission : [];
  const canEdit = permission.includes('admin_pluses_edit');
  const {loadingEmissions,  emissions} = useSelector( state => state.carbon );

  const [collapse, setCollapse] = useState(['2020']);
  const [emissionsData, setEmissionsData] = useState([]);
  const [emissionsId, setEmissionsId] = useState(false);
  const [emissionsValue, setEmissionsValue] = useState("");

  useEffect(() => {
    emissions.length === 0 && dispatch(getCarbonEmissions());
  }, []);

  useEffect(() => {
    if(emissions && emissions.results) {
      const groups = emissions.results.reduce((groups, item) => {
        const date = item.year;
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(item);
        return groups;
      }, {});

      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          items: groups[date]
        };
      });

      setEmissionsData(groupArrays);
    }

  }, [emissions]);

  if (loadingEmissions) return (<LoaderSpinner/>);


  const editCategory = (data) => {
    setEmissionsId(data.id);
    setEmissionsValue(data.value)
  };

  const saveCategory = () => {
    if(emissionsId>0 && emissionsValue.length>0) {
      dispatch(updateCarbonEmission(emissionsId, emissionsValue));
      setEmissionsId(false);
      setEmissionsValue("");
    }
  };

  const handleCollapseCategory = (item) => {
    let items = [...collapse];
    let index = items.indexOf(item);
    (index === -1) ? items.push(item) : items.splice(index, 1);
    setCollapse(items);
  };

  return (
    <div className="page-content carbonAdminPage">
      <div className="row">
        {
          emissionsData.map((category, mainCategoryIndex) => {
            let isOpenAccordion = collapse.indexOf(category.date) >= 0;
            return (
              <div className="accordion" id="accordionExample" key={category.date + 'category'}>
                <div className="accordion-item">

                  <div className="accordion-button categoryTextColor categoryBorderColor" onClick={() => {handleCollapseCategory(category.date)}}>
                    {category.date} {isOpenAccordion ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                  </div>

                  <Collapse id="collapseOne" className="accordion-collapse" isOpen={isOpenAccordion}>
                    <div className="accordion-body row">
                      <div className="table-responsive">
                        <Table className="table table-striped table-hover">
                          <thead className="table-light">
                            <tr className="table-success">
                              <th>#</th>
                              <th>Umfang</th>
                              <th>Type</th>
                              <th>Treatment</th>
                              <th>Value</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {category.items.map((item, itemIndex) =>
                              <tr key={itemIndex + 'parent'}>
                                <td>{itemIndex+1}</td>
                                <td>{item.scope}</td>
                                <td>{item.type}</td>
                                <td>{item.treatment}</td>
                                <td>
                                  {emissionsId === item.id ?
                                    <Input
                                      name="other"
                                      type="text"
                                      value={emissionsValue}
                                      className="form-control"
                                      onChange={event => setEmissionsValue(event.target.value)}
                                    />
                                    : item.value
                                  }
                                </td>
                                <td>
                                  {canEdit && emissionsId === item.id ?
                                    <Button
                                      id="tooltip_carbon_save"
                                      key={itemIndex + 'save_carbon_btn'}
                                      color="warning"
                                      size="sm"
                                      style={{marginRight: '10px'}}
                                      onClick={() => saveCategory()}
                                    >
                                      <Check style={{fontSize: '12px'}}/>
                                    </Button>
                                    :
                                    <Button
                                      id="tooltip_carbon_edit"
                                      key={itemIndex + 'edit_carbon_btn'}
                                      color="success"
                                      size="sm"
                                      style={{marginRight: '10px'}}
                                      onClick={() => editCategory(item)}
                                    >
                                      <Edit style={{fontSize: '12px'}}/>
                                    </Button>
                                  }
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
};

export default Index
