import * as actionTypes from '../actionTypes';

const initialState = {
  planGoals: [],
  loadingPlanGoals: true,
  planActions: [],
  loadingPlanActions: true,
  achievements: [],

  goalsCompany: [],
  loadingGoalsCompany: true,
  loadingSave: false,
  loadingDelete: false,
  error: false,
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function companyReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_PLAN_GOALS_START:
      return  {
        ...state,
        loadingPlanGoals: true,
        achievements: [],
      };
    case actionTypes.GET_PLAN_GOALS_SUCCESS:
      return {
        ...state,
        planGoals: action.data,
        loadingPlanGoals: false,
        achievements: []
        // achievements: action.data.results && action.data.results.new_achievements ? action.data.results.new_achievements : []
      };
    case actionTypes.GET_PLAN_GOALS_FAIL:
      return  {
        ...state,
        loadingPlanGoals: false
      };

    case actionTypes.UPDATE_PLAN_GOAL_START:
      return  {
        ...state,
      };
    case actionTypes.UPDATE_PLAN_GOAL_SUCCESS:
      let newStateC = Object.assign([], state.planGoals.results);
      const indexC = newStateC.findIndex(item => item.id === action.data.results.id);
      if(indexC >= 0) {
        newStateC[indexC].goal = action.data.results.goal;
        newStateC[indexC].sort_order = action.data.results.sort_order;
      }
      state.planGoals.results = newStateC;
      return {
        ...state,
      };
    case actionTypes.UPDATE_PLAN_GOAL_FAIL:
      return  {
        ...state,
      };

    case actionTypes.CREATE_PLAN_GOAL_START:
      return  {
        ...state,
      };
    case actionTypes.CREATE_PLAN_GOAL_SUCCESS:
      let copyPlanGoalState = Object.assign([], state.planGoals.results);
      copyPlanGoalState.unshift(action.data.results);
      state.planGoals.results = copyPlanGoalState;
      return {
        ...state,
      };
    case actionTypes.CREATE_PLAN_GOAL_FAIL:
      return  {
        ...state,
      };


    case actionTypes.DELETE_PLAN_GOAL_START:
      return  {
        ...state,
      };
    case actionTypes.DELETE_PLAN_GOAL_SUCCESS:
      let newStateD = Object.assign([], state.planGoals);
      const indexD = newStateD.results.findIndex(item => item.id === action.data.results.id);
      newStateD.results.splice(indexD, 1);
      return  {
        ...state,
        planGoals: newStateD,
      };
    case actionTypes.DELETE_PLAN_GOAL_FAIL:
      return  {
        ...state,
      };


    case actionTypes.GET_PLAN_ACTIONS_START:
      return  {
        ...state,
        loadingPlanActions: true,
        achievements: [],
      };
    case actionTypes.GET_PLAN_ACTIONS_SUCCESS:
      return {
        ...state,
        planActions: action.data,
        loadingPlanActions: false,
        achievements: []
      };
    case actionTypes.GET_PLAN_ACTIONS_FAIL:
      return  {
        ...state,
        loadingPlanActions: false
      };


    default:
      return state;
  }
}
