import React, { useEffect } from "react"
import {useDispatch, useSelector} from "react-redux";
import { useSearchParams } from "react-router-dom"

import { Row, Col, Card, CardBody, Container } from "reactstrap"

import {verifyEmail} from "../../store/actions/auth";
import logo from "../../assets/images/laufid_logo.svg"
import Snackbar from "../../components/Common/Snackbar";
import {LoaderSpinner} from "../../components/Common/Loader";

const VerifyEmail = (props) => {
    // Translation
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const {loading, success, msg} = useSelector( state => state.auth.verify );

    useEffect(() => {      
      dispatch(verifyEmail({
        email: searchParams.get("email"),
        time: searchParams.get("time"),
        hash: searchParams.get("hash"),
      }));
    }, []);
  return (
    <React.Fragment>
      <div className="verify-page" style={{marginTop: '10%'}}>
        <Container>
          <Row className="justify-content-center">

            <Col md={8} lg={6} xl={5}>
              <Card className="auth_card">
                <CardBody>
                  <div className="auth_logo mb-5"> <img src={logo} alt="" height="100" /></div>
                  
                  {loading && (<div className="d-flex flex-column justify-content-center align-items-center">
                    <h3>Staðfesti netfangið þitt...</h3>
                    <LoaderSpinner/>
                  </div>)}
                  

                   <div className="d-flex flex-column justify-content-center align-items-center text-center">
                    {!loading && success && (<h4>Netfangið hefur verið staðfest</h4>)}
                    {!loading && !success && (<h4>Villa kom upp við að staðfesta netfangið þitt</h4>)}
                    {!loading && !success && (<p>{msg}</p>)}

                     <p className="toLogin" onClick={() => window.location = '/login'}>Innskráningarsíðu</p>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <span>© {new Date().getFullYear()} Laufið</span>
              </div>
            </Col>
          </Row>

        </Container>
        <Snackbar />
      </div>
    </React.Fragment>
  )
};

export default VerifyEmail;