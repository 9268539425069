
export const AddCommas = num => num.toString()
  .replace('.', ',')
  .replace(/\B(?=(\d{3})+(?!\d))/g, ".");

export const AddCommasToFloat = num => {
  let numFloat = parseFloat(num).toFixed(2);
  return numFloat.toString()
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const RemoveNonNumeric = num => num.toString()
  .replace(/[^0-9,]/g, "");

export const RemoveNonNumericForSave = num => {
  let numFloat = num.toString().replace(/[^0-9,]/g, "");
 return numFloat.indexOf(',') > -1 ? numFloat.replace(',', '.') : numFloat;
}