import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux';
import Swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import {Card, CardBody} from "reactstrap"
import AddCompany from "./addCompany";
import {LoaderSpinner} from "../../components/Common/Loader";
import CompanyTable from "./companyTable";
import {
  getCompanies,
  companyCreateIn1819,
  disableEnableCompany,
  createUpdateCompany
} from "../../store/actions/company";
import "./index.scss"

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const {companies, loading} = useSelector( state => state.company );
  const [companiesData, setCompaniesData] = useState([]);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    dispatch(getCompanies());
  }, []);

  useEffect(() => {
    if(companies.results) {
      let data = [...companies.results];
      data.forEach((item,index) => {
        data[index].company_categories = item.company_category_nxns ? item.company_category_nxns.map(data => data.company_category_id) : []
      });
      setCompaniesData(data)
    }
  },[companies.results]);

  const confirmConnected = (status, data) => {
    Swal({
      title: status === true ? t('company_page.disconnect_1819') : t('company_page.connect_1819'),
      icon: "warning",
      dangerMode: true,
      buttons: {confirm: t('yes'), cancel: t('no')},
    }).then((confirm) => {
      if (confirm) {
        let saveData = {
          "id": data.id,
          "visible": !status
        };
        dispatch(createUpdateCompany(true, saveData, 'companies'));
      } else {
        Swal.close();
      }
    });
  };

  const confirmCreateCompany = (kennitala) => {
    Swal({
      title: t('are_you_sure'),
      icon: "warning",
      dangerMode: true,
      buttons: {confirm: t('yes'), cancel: t('no')},
    }).then((confirm) => {
      if (confirm) {
        dispatch(companyCreateIn1819(kennitala));
      } else {
        Swal.close();
      }
    });
  };

  const confirmDisableEnableCompany = (type, data) => {
    Swal({
      title: type === 'disable' ? 'Þessi aðgerð óvirkjar aðgang viðkomandi fyrirtækis. Ertu viss um að þú viljir óvirkja?' : t('are_you_sure'),
      icon: "warning",
      dangerMode: true,
      buttons: {confirm: t('yes'), cancel: t('no')},
    }).then((confirm) => {
      if (confirm) {
        dispatch(disableEnableCompany(type, data));
      } else {
        Swal.close();
      }
    });
  };

  const editCompany = (data) => {
    setAlert(
      <AddCompany data={data} onCancel={() => setAlert(null)} />
    );
  };

  if (loading) return (<LoaderSpinner/>);

  return (
    <div className="page-content admin_companies_page">
      {alert}
      <Card>
        <CardBody>
          <CompanyTable
            data={companiesData}
            leafsData={[]}
            confirmConnected={confirmConnected}
            confirmCreateCompany={confirmCreateCompany}
            confirmDisableEnableCompany={confirmDisableEnableCompany}
            editCompany={editCompany}
          />
        </CardBody>
      </Card>
    </div>
  )
};

export default Index
