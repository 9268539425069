import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  loadingPhone: false,
  verify: {
    loading: true,
    success: false,
    msg: '',
  },
  msg: '',
  smsPhone: '',
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function userReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.LOGIN_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: 'success' }
      };
    case actionTypes.LOGIN_FAIL:
      return  {
        ...state,
        msg: '',
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.LOGIN_PHONE_START:
      return  {
        ...state,
        loadingPhone: true
      };
    case actionTypes.LOGIN_PHONE_SUCCESS:
      return {
        ...state,
        msg: action.data.msg,
        smsPhone: action.data.phone,
        loadingPhone: false,
        notification: { status: true, type: 'success', message: 'SMS sent' }
      };
    case actionTypes.LOGIN_PHONE_FAIL:
      return  {
        ...state,
        msg: action.data,
        loadingPhone: false,
        notification: { status: true, type: 'error', message: action.data }
      };
    case actionTypes.LOGIN_SMS_FAIL:
      return  {
        ...state,
        msg: '',
        loadingPhone: false,
        notification: { status: true, type: 'error', message: action.data }
      };

      case actionTypes.LOGIN_REGISTRATION_START:
        return  {
          ...state,
          loadingPhone: true
        };
      case actionTypes.LOGIN_REGISTRATION_SUCCESS:
        return {
          ...state,
          msg: "",
          smsPhone: action.data.phone,
          loadingPhone: false,
          notification: { status: true, type: 'success', message: 'Registration Login Success' }
          };
        case actionTypes.LOGIN_REGISTRATION_FAIL:
          return  {
            ...state,
            msg: action.data,
            loadingPhone: false,
            notification: { status: true, type: 'error', message: action.data }
              };
    case actionTypes.LOGOUT_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        notification: { status: true, type: 'success', message: 'success' }
      };
    case actionTypes.LOGOUT_FAIL:
      return  {
        ...state,
        loading: false,
        notification: { status: true, type: 'error', message: action.data }
      };

      case actionTypes.VERIFY_EMAIL_START:
        return  {
          ...state,
          verify: {
            ...state.verify,
            loading:true
          },
        };
      case actionTypes.VERIFY_EMAIL_SUCCESS:
        return {
          ...state,
          verify: {
            ...state.verify,
            loading:false,
            success:true
          },
                    notification: { status: true, type: 'success', message: 'success' }
        };
      case actionTypes.VERIFY_EMAIL_FAIL:
        return  {
          ...state,
          verify: {
            ...state.verify,
            loading:false,
            msg: action.data
          },
          notification: { status: true, type: 'error', message: action.data }
        };

    default:
      return state;
  }
}
