import * as actionTypes from '../actionTypes';

const initialState = {
  plans: [],
  companyKennitalaData: [],
  finishedStep: false,
  continueBtn: false,

  loadingPlan: false,
  loadingRegistrations: false,
  loadingCreateRegistration: false,

  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function registrationReducer(state = initialState, action = {}) {
  switch (action.type) {


    case actionTypes.GET_REGISTRATION_PLAN_START:
      return  {
        ...state,
        loadingPlan: true
      };
    case actionTypes.GET_REGISTRATION_PLAN_SUCCESS:
      return {
        ...state,
        plans: action.data,
        loadingPlan: false
      };
    case actionTypes.GET_REGISTRATION_PLAN_FAIL:
      return  {
        ...state,
        loadingPlan: false
      };


    case actionTypes.GET_REGISTRATION_COMPANY_KENNITALA_START:
      return  {
        ...state,
        loadingRegistrations: true
      };
    case actionTypes.GET_REGISTRATION_COMPANY_KENNITALA_SUCCESS:
      return {
        ...state,
        companyKennitalaData: action.data,
        loadingRegistrations: false,
        continueBtn: true,
      };
    case actionTypes.GET_REGISTRATION_COMPANY_KENNITALA_FAIL:
      return  {
        ...state,
        loadingRegistrations: false,
        continueBtn: false,
        source:null,
        results:null,
      };


    case actionTypes.REGISTER_COMPANY_START:
      return  {
        ...state,
        loadingCreateRegistration: true,
        finishedStep: false,
      };
    case actionTypes.REGISTER_COMPANY_SUCCESS:
      return {
        ...state,
        finishedStep: true,
        loadingCreateRegistration: false
      };
    case actionTypes.REGISTER_COMPANY_FAIL:
      return  {
        ...state,
        finishedStep: false,
        loadingCreateRegistration: false
      };


    case actionTypes.VERIFY_ONBOARDING_START:
      return  {
        ...state
      };
    case actionTypes.VERIFY_ONBOARDING_SUCCESS:
      let objStore = JSON.parse(localStorage.getItem('p_user'));
      if(action.data.message === 'Onboarded is TRUE'){
        objStore.companies[0].onboarded = true;
        localStorage.setItem('p_user', JSON.stringify(objStore));
      }
      return {
        ...state
      };
    case actionTypes.VERIFY_ONBOARDING_FAIL:
      let objStoreFail = JSON.parse(localStorage.getItem('p_user'));
      if(action.data.message === 'Onboarded is TRUE'){
        objStoreFail.companies[0].onboarded = true;
        localStorage.setItem('p_user', JSON.stringify(objStoreFail));
      }
      return  {
        ...state
      };

    default:
      return state;
  }
}
