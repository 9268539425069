import React from "react"
import avatar from "../../../assets/images/avatar.jpg";

const Index = (props) => {
  const {name, profile, key, styleclass = ''} = props;
  const keyIndex = key ? key : "user_avatar_"+name;
  if(profile) {
    return (<img key={keyIndex} src={profile} className="rounded-circle avatar-xs m-1" title={name} alt={name}/>)
  } else {
    if(name === '' || !name) {
      return (<img key={keyIndex} src={avatar} className="rounded-circle avatar-xs m-1" alt="User"/>)
    } else {
      const colors = ['#264653', '#2a9d8f', '#f4a261', '#e76f51', '#fb5607', '#588157', '#457b9d', '#e63946', '#219ebc', '#52796f'];
      let users = {};
      let userColor;

      let nameAr = name.split(' ');
      let shortName = nameAr[0].charAt(0).toUpperCase() + ' ' + nameAr[nameAr.length-1].charAt(0).toUpperCase();

      if(localStorage.getItem('p_user_colors')){
         users = JSON.parse(localStorage.getItem('p_user_colors'));
      }
      if(users.hasOwnProperty(name)){
        userColor = users[name];
      } else {
        userColor = colors[Math.floor(Math.random() * 9)];
        users[name] = userColor;
        localStorage.setItem('p_user_colors', JSON.stringify(users));
      }

      return <div key={key} className={styleclass} title={name} style={{backgroundColor: userColor}}>
        {shortName}
      </div>
    }
  }

};

export default Index
