import React from 'react';
import {Card} from "reactstrap";
import i18n from "../../../i18n";
import './index.scss';

const Index = (props) => {
  const {tips, mainCategoryIndex} = props;
  const currentLang = i18n.language || 'is';

  return (
    <div className="row greenStepNotes">
      {
        tips && tips.results.filter(f => f.category_id === mainCategoryIndex).map((item, tipIndex) =>
          <div className="col-sm-10 col-md-6 col-lg-6 col-xl-4 col-xxl-4" key={"noteBox_"+tipIndex}>
            <Card>
              <div className="note categoryTextColor">
                {item["tip_"+currentLang] ? item["tip_"+currentLang] : item.tip}
              </div>
            </Card>
          </div>
        )
      }

    </div>
  )
};

export default Index