import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from "react-redux";
import Select from "react-select";
import {Card, CardTitle, Label} from "reactstrap";
import DatePicker from "react-datepicker";

import ActivityLog from "./activityLog";
import Notification from "./notification";

import {customDropdownStyles} from "../../helpers/dropdown";
import "react-datepicker/dist/react-datepicker.css"
import './index.scss';


const days = ['Mán', 'Þri', 'Mið', 'Fim', 'Fös', 'Lau', 'Sun'];
const months = ['janúar', 'febrúar', 'mars', 'apríl', 'maí', 'júní', 'júlí', 'ágúst', 'september', 'október', 'nóvember', 'desember'];
const locale = {localize: {day: n => days[n], month: n => months[n]}, formatLong: {date: () => 'dd/mm/yyyy'}};

const Index = () => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState({label: 'Tilkynningar', value: 'announcements'});
  const [selectedUser, setSelectedUser] = useState({label:t('log_page.all'), value: 'all'});
  const [selectedDateFrom, setSelectedDateFrom] = useState(null);
  const [selectedDateTo, setSelectedDateTo] = useState(null);

  const {activityLogUsers} = useSelector(state => state.activityLog);

  return (
    <div className={`page-content announcementPage`}>

      <div className="row completedContainer">
        <div className="col cols-sm-12 col-md-12 col-lg-12 col-xl-10 col-xxl-10">
          <Card>

            <CardTitle>
              <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-3 col-xxl-3 d-flex flex-column">
                  <Label className="form_label">{t('log_page.category')}</Label>
                  <Select
                    isSearchable={false}
                    placeholder={t('plan_page.select')}
                    value={selectedType}
                    onChange={(event) => setSelectedType(event)}
                    styles={customDropdownStyles}
                    options={[{label: 'Tilkynningar', value: 'announcements'}, {label: 'Virkni saga', value: "history"}]}
                  />
                </div>

                {selectedType.value === 'history' &&
                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex flex-column">
                    <Label className="form_label">{t('log_page.assignee')}</Label>
                    <Select
                      placeholder={t('plan_page.select')}
                      value={selectedUser}
                      onChange={(event) => setSelectedUser(event)}
                      styles={customDropdownStyles}
                      options={activityLogUsers && activityLogUsers.results ? [
                        {label: t('log_page.all'), value: 'all'},
                        ...activityLogUsers.results.map(item => {
                          return {label: item.name, value: item.id}
                        })
                      ] : []}
                    />
                  </div>
                }

                <div className="col-2  d-flex flex-column">
                  <Label className="form_label">{t('log_page.date_from')}</Label>
                  <DatePicker
                    selected={selectedDateFrom}
                    isClearable
                    onChange={(event) => setSelectedDateFrom(event)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale={locale} className="form-control form_datepicker react-datepicker"
                  />
                </div>

                <div className="col-2  d-flex flex-column">
                  <Label className="form_label">{t('log_page.date_to')}</Label>
                  <DatePicker
                    selected={selectedDateTo}
                    isClearable
                    onChange={(event) => setSelectedDateTo(event)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    locale={locale} className="form-control form_datepicker react-datepicker"
                  />
                </div>
              </div>
            </CardTitle>

            {selectedType.value === 'announcements' && <Notification dateFrom={selectedDateFrom} dateTo={selectedDateTo}/>}
            {selectedType.value === 'history' && <ActivityLog user={selectedUser} dateFrom={selectedDateFrom} dateTo={selectedDateTo}/>}

          </Card>
          <br/><br/><br/><br/><br/><br/>
        </div>
      </div>

    </div>
  )
};

export default Index