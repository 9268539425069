import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import Select from "react-select";
import {CardBody, CardFooter} from "reactstrap";
import {NavigateBefore, NavigateNext, Notifications} from "@mui/icons-material";

import {LoaderSpinner} from "../../components/Common/Loader";
import {customDropdownPaginationStyles} from "../../helpers/dropdown";
import {helperFormatDateWithMonthName} from "../../helpers/main";
import {getNotifications, notificationMarkAsRead} from "../../store/actions/notification";


const Notification = (props) => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;

  const {notifications, loading} = useSelector(state => state.notification);

  const [logs, setLogs] = useState([]);
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const pageSize = 50;

  useEffect(() => {
      dispatch(getNotifications(companyId));
  }, []);

  useEffect(() => {
    if(notifications && notifications.results) {
      let total = Math.ceil(notifications.results.length / pageSize);
      let pagesList = [];
      for(let i = 1; i <= total; i++) {
        pagesList.push({selectedLabel: `síða ${i} af ${total}`, label: <div><span className="m_list">{i}</span><span className="m_select">síða {i} af {total}</span></div>, value: i})
      }
      setPageTotal(total);
      setPages(pagesList);
      setLogs(notifications.results.slice(0, pageSize));
    }
  }, [notifications]);

  useEffect(() => {
    if(notifications.results) {
      sortingData();
    }
  }, [props.dateFrom, props.dateTo]);

  if (loading) return (<LoaderSpinner/>);


  const sortingData = (event, sortingType ) => {
    let newData = notifications.results;
    let dateFrom = props.dateFrom;
    let dateTo = props.dateTo;

    if(dateFrom) {
      let dateFromTime = new Date(dateFrom).getTime();
      newData = newData.filter(item => item.created_at && new Date(item.created_at).getTime() > dateFromTime);
    }
    if(dateTo) {
      let dateToTime = new Date(dateTo).setHours(23,59,59,0);
      newData = newData.filter(item => item.created_at && new Date(item.created_at).getTime() < dateToTime);
    }

    if(sortingType === 'page') {
      newData = newData.slice(pageSize*(event.value-1), event.value * pageSize);
      setCurrentPage(event.value);
    } else {
      let total = Math.ceil(newData.length / pageSize);
      let pagesList = [];
      for(let i = 1; i <= total; i++) {
        pagesList.push({label:<div><span className="m_list">{i}</span><span className="m_select">síða {i} af {total}</span></div>, value: i})
      }
      newData = newData.slice(0, pageSize);
      setPageTotal(total);
      setPages(pagesList);
      setCurrentPage(1);
    }

    setLogs(newData);
  };

  const redirectToGreenStep = (id, index) => {
    navigate('/adgerdir', { state: {"showCategory": true, categoryId: id, categoryIndex: index}});
  };

  const markAsRead = (notification) => {
    if(notification.read_at === null) {
      dispatch(notificationMarkAsRead(notification.id));
    }
  };

  return (
    <div className={`page-content notificationPage`}>

      <CardBody>
        {logs.map((notification, index) => {
          let logData = notification.data ? notification.data : false;
          return (
            <div
              className={`containerRow ${notification.read_at === null ? 'notRead' : 'readed'}`}
              key={"activityLog_"+index}
              onClick={() => markAsRead(notification)}
            >
              <div className="logRow logDate">{helperFormatDateWithMonthName(notification.created_at)}</div>
              <div className="logRow">
                <span className="logType">
                  {notification.read_at === null &&  <span className="badge"/>}
                  <Notifications/>
                </span>
                <span className="logCategory">{logData.type}</span>
              </div>
              <div className={`logRow desc ${logData.greenStepMainCategory ? "mainIndex_"+logData.greenStepMainCategory.value : '0'}`}>
                <span className="logDesc">{
                  logData.description ?
                    logData.greenStepMainCategory ? <div onClick={() => redirectToGreenStep(logData.greenStepId, logData.greenStepMainCategory.index)}  dangerouslySetInnerHTML={{__html: logData.description}}/> : logData.description
                  : ''
                }</span>
              </div>
            </div>
          )
        })}
      </CardBody>

      <CardFooter>
        {pageTotal > 1 &&
        <div className="pagination">
          <NavigateBefore className={`arrow prev ${currentPage === 1 && 'disabled'}`} onClick={() => sortingData({label: currentPage-1, value: currentPage-1}, 'page')}/>
          <Select
            isSearchable={false}
            value={pages.find(item => item.value === currentPage)}
            onChange={(event) => sortingData(event, 'page')}
            styles={customDropdownPaginationStyles}
            options={pages}
          />
          <NavigateNext className={`arrow next ${currentPage === pages.length && 'disabled'}`} onClick={() => sortingData({label: currentPage+1, value: currentPage+1}, 'page')}/>
        </div>
        }
      </CardFooter>


    </div>
  )
};

export default Notification