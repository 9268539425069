// strings should be unique across reducers so namespace them with the reducer name

export const TOGGLE_SNACKBAR_OPEN = 'TOGGLE_SNACKBAR_OPEN';
export const TOGGLE_SNACKBAR_CLOSE = 'TOGGLE_SNACKBAR_CLOSE';

////////////////////// Auth User ///////////////////////////////
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGIN_PHONE_START = 'LOGIN_PHONE_START';
export const LOGIN_PHONE_SUCCESS = 'LOGIN_PHONE_SUCCESS';
export const LOGIN_PHONE_FAIL = 'LOGIN_PHONE_FAIL';

export const LOGIN_REGISTRATION_START = 'LOGIN_REGISTRATION_START';
export const LOGIN_REGISTRATION_SUCCESS = 'LOGIN_REGISTRATION_SUCCESS';
export const LOGIN_REGISTRATION_FAIL = 'LOGIN_REGISTRATION_FAIL';

export const LOGIN_SMS_FAIL = 'LOGIN_SMS_FAIL';

export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';

export const VERIFY_EMAIL_START = 'VERIFY_EMAIL_START';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL';

////////////////////// Dashboard ///////////////////////////////

export const GET_DASHBOARD_REPORT_START = 'GET_DASHBOARD_REPORT_START';
export const GET_DASHBOARD_REPORT_SUCCESS = 'GET_DASHBOARD_REPORT_SUCCESS';
export const GET_DASHBOARD_REPORT_FAIL = 'GET_DASHBOARD_REPORT_FAIL';

export const GET_DASHBOARD_MUNICIPALITY_START = 'GET_DASHBOARD_MUNICIPALITY_START';
export const GET_DASHBOARD_MUNICIPALITY_SUCCESS = 'GET_DASHBOARD_MUNICIPALITY_SUCCESS';
export const GET_DASHBOARD_MUNICIPALITY_FAIL = 'GET_DASHBOARD_MUNICIPALITY_FAIL';


////////////////////// Leafs ///////////////////////////////
export const GET_LEAF_START = 'GET_LEAF_START';
export const GET_LEAF_SUCCESS = 'GET_LEAF_SUCCESS';
export const GET_LEAF_FAIL = 'GET_LEAF_FAIL';

export const UPDATE_LEAF_START = 'UPDATE_LEAF_START';
export const UPDATE_LEAF_SUCCESS = 'UPDATE_LEAF_SUCCESS';
export const UPDATE_LEAF_FAIL = 'UPDATE_LEAF_FAIL';

export const CREATE_LEAF_START = 'CREATE_LEAF_START';
export const CREATE_LEAF_SUCCESS = 'CREATE_LEAF_SUCCESS';
export const CREATE_LEAF_FAIL = 'CREATE_LEAF_FAIL';

export const UPDATE_LEAF_ITEM_START = 'UPDATE_LEAF_ITEM_START';
export const UPDATE_LEAF_ITEM_SUCCESS = 'UPDATE_LEAF_ITEM_SUCCESS';
export const UPDATE_LEAF_ITEM_FAIL = 'UPDATE_LEAF_ITEM_FAIL';

export const CREATE_LEAF_ITEM_START = 'CREATE_LEAF_ITEM_START';
export const CREATE_LEAF_ITEM_SUCCESS = 'CREATE_LEAF_ITEM_SUCCESS';
export const CREATE_LEAF_ITEM_FAIL = 'CREATE_LEAF_ITEM_FAIL';

export const DELETE_LEAF_ITEM_START = 'DELETE_LEAF_ITEM_START';
export const DELETE_LEAF_ITEM_SUCCESS = 'DELETE_LEAF_ITEM_SUCCESS';
export const DELETE_LEAF_ITEM_FAIL = 'DELETE_LEAF_ITEM_FAIL';

export const GET_COMPANY_LEAF_START = 'GET_COMPANY_LEAF_START';
export const GET_COMPANY_LEAF_SUCCESS = 'GET_COMPANY_LEAF_SUCCESS';
export const GET_COMPANY_LEAF_FAIL = 'GET_COMPANY_LEAF_FAIL';

export const CREATE_OR_UPDATE_COMPANY_LEAF_START = 'CREATE_OR_UPDATE_COMPANY_LEAF_START';
export const CREATE_OR_UPDATE_COMPANY_LEAF_SUCCESS = 'CREATE_OR_UPDATE_COMPANY_LEAF_SUCCESS';
export const CREATE_OR_UPDATE_COMPANY_LEAF_FAIL = 'CREATE_OR_UPDATE_COMPANY_LEAF_FAIL';

export const GET_LEAF_ITEMS_COUNT_START = 'GET_LEAF_ITEMS_COUNT_START';
export const GET_LEAF_ITEMS_COUNT_SUCCESS = 'GET_LEAF_ITEMS_COUNT_SUCCESS';
export const GET_LEAF_ITEMS_COUNT_FAIL = 'GET_LEAF_ITEMS_COUNT_FAIL';

////////////////////// Lauf ///////////////////////////////
export const GET_LAUF_BY_CATEGORY_START = 'GET_LAUF_BY_CATEGORY_START';
export const GET_LAUF_BY_CATEGORY_SUCCESS = 'GET_LAUF_BY_CATEGORY_SUCCESS';
export const GET_LAUF_BY_CATEGORY_FAIL = 'GET_LAUF_BY_CATEGORY_FAIL';

export const CREATE_COMPANY_LEAF_START = 'CREATE_COMPANY_LEAF_START';
export const CREATE_COMPANY_LEAF_SUCCESS = 'CREATE_COMPANY_LEAF_SUCCESS';
export const CREATE_COMPANY_LEAF_FAIL = 'CREATE_COMPANY_LEAF_FAIL';

export const DELETE_COMPANY_LEAF_START = 'DELETE_COMPANY_LEAF_START';
export const DELETE_COMPANY_LEAF_SUCCESS = 'DELETE_COMPANY_LEAF_SUCCESS';
export const DELETE_COMPANY_LEAF_FAIL = 'DELETE_COMPANY_LEAF_FAIL';

export const EDIT_COMPANY_LEAF_COMMENT_START = 'EDIT_COMPANY_LEAF_COMMENT_START';
export const EDIT_COMPANY_LEAF_COMMENT_SUCCESS = 'EDIT_COMPANY_LEAF_COMMENT_SUCCESS';
export const EDIT_COMPANY_LEAF_COMMENT_FAIL = 'EDIT_COMPANY_LEAF_COMMENT_FAIL';

////////////////////// ACTIVITY LOG ///////////////////////////////
export const GET_ACTIVITY_LOG_BY_COMPANY_START = 'GET_ACTIVITY_LOG_BY_COMPANY_START';
export const GET_ACTIVITY_LOG_BY_COMPANY_SUCCESS = 'GET_ACTIVITY_LOG_BY_COMPANY_SUCCESS';
export const GET_ACTIVITY_LOG_BY_COMPANY_FAIL = 'GET_ACTIVITY_LOG_BY_COMPANY_FAIL';

export const GET_ACTIVITY_LOG_USERS_BY_COMPANY_START = 'GET_ACTIVITY_LOG_USERS_BY_COMPANY_START';
export const GET_ACTIVITY_LOG_USERS_BY_COMPANY_SUCCESS = 'GET_ACTIVITY_LOG_USERS_BY_COMPANY_SUCCESS';
export const GET_ACTIVITY_LOG_USERS_BY_COMPANY_FAIL = 'GET_ACTIVITY_LOG_USERS_BY_COMPANY_FAIL';

////////////////////// GREEN STEPS NEW ///////////////////////////////
export const GET_GREEN_STEPS_NEW_START = 'GET_GREEN_STEPS_NEW_START';
export const GET_GREEN_STEPS_NEW_SUCCESS = 'GET_GREEN_STEPS_NEW_SUCCESS';
export const GET_GREEN_STEPS_NEW_FAIL = 'GET_GREEN_STEPS_NEW_FAIL';

export const GET_GREEN_STEPS_REGISTRATION_START = 'GET_GREEN_STEPS_REGISTRATION_START';
export const GET_GREEN_STEPS_REGISTRATION_SUCCESS = 'GET_GREEN_STEPS_REGISTRATION_SUCCESS';
export const GET_GREEN_STEPS_REGISTRATION_FAIL = 'GET_GREEN_STEPS_REGISTRATION_FAIL';

export const GET_TIPS_START = 'GET_TIPS_START';
export const GET_TIPS_SUCCESS = 'GET_TIPS_SUCCESS';
export const GET_TIPS_FAIL = 'GET_TIPS_FAIL';

export const GET_UN_GOALS_START = 'GET_UN_GOALS_START';
export const GET_UN_GOALS_SUCCESS = 'GET_UN_GOALS_SUCCESS';
export const GET_UN_GOALS_FAIL = 'GET_UN_GOALS_FAIL';



////////////////////// GREEN STEPS ///////////////////////////////
export const UPDATE_GREEN_STEP_START = 'UPDATE_GREEN_STEP_START';
export const UPDATE_GREEN_STEP_SUCCESS = 'UPDATE_GREEN_STEP_SUCCESS';
export const UPDATE_GREEN_STEP_FAIL = 'UPDATE_GREEN_STEP_FAIL';

export const CREATE_GREEN_STEP_START = 'CREATE_GREEN_STEP_START';
export const CREATE_GREEN_STEP_SUCCESS = 'CREATE_GREEN_STEP_SUCCESS';
export const CREATE_GREEN_STEP_FAIL = 'CREATE_GREEN_STEP_FAIL';

export const DELETE_GREEN_STEP_START = 'DELETE_GREEN_STEP_START';
export const DELETE_GREEN_STEP_SUCCESS = 'DELETE_GREEN_STEP_SUCCESS';
export const DELETE_GREEN_STEP_FAIL = 'DELETE_GREEN_STEP_FAIL';

export const GET_GREEN_STEPS_CATEGORIES_START = 'GET_GREEN_STEPS_CATEGORIES_START';
export const GET_GREEN_STEPS_CATEGORIES_SUCCESS = 'GET_GREEN_STEPS_CATEGORIES_SUCCESS';
export const GET_GREEN_STEPS_CATEGORIES_FAIL = 'GET_GREEN_STEPS_CATEGORIES_FAIL';

export const CREATE_COMPANY_GREEN_STEP_START = 'CREATE_COMPANY_GREEN_STEP_START';
export const CREATE_COMPANY_GREEN_STEP_SUCCESS = 'CREATE_COMPANY_GREEN_STEP_SUCCESS';
export const CREATE_COMPANY_GREEN_STEP_FAIL = 'CREATE_COMPANY_GREEN_STEP_FAIL';

export const UPDATE_COMPANY_GREEN_STEP_START = 'UPDATE_COMPANY_GREEN_STEP_START';
export const UPDATE_COMPANY_GREEN_STEP_SUCCESS = 'UPDATE_COMPANY_GREEN_STEP_SUCCESS';
export const UPDATE_COMPANY_GREEN_STEP_FAIL = 'UPDATE_COMPANY_GREEN_STEP_FAIL';

export const CREATE_ACTION_FOR_GREEN_STEP_SUCCESS = 'CREATE_ACTION_FOR_GREEN_STEP_SUCCESS';
export const DELETE_ACTION_FOR_GREEN_STEP_SUCCESS = 'DELETE_ACTION_FOR_GREEN_STEP_SUCCESS';

export const MARK_CATEGORY_AS_IRRELEVANT_START = 'MARK_CATEGORY_AS_IRRELEVANT_START';
export const MARK_CATEGORY_AS_IRRELEVANT_SUCCESS = 'MARK_CATEGORY_AS_IRRELEVANT_SUCCESS';
export const MARK_CATEGORY_AS_IRRELEVANT_FAIL = 'MARK_CATEGORY_AS_IRRELEVANT_FAIL';

export const REMOVE_CATEGORY_AS_IRRELEVANT_START = 'REMOVE_CATEGORY_AS_IRRELEVANT_START';
export const REMOVE_CATEGORY_AS_IRRELEVANT_SUCCESS = 'REMOVE_CATEGORY_AS_IRRELEVANT_SUCCESS';
export const REMOVE_CATEGORY_AS_IRRELEVANT_FAIL = 'REMOVE_CATEGORY_AS_IRRELEVANT_FAIL';

export const DELETE_COMPANY_GREEN_STEP_START = 'DELETE_COMPANY_GREEN_STEP_START';
export const DELETE_COMPANY_GREEN_STEP_SUCCESS = 'DELETE_COMPANY_GREEN_STEP_SUCCESS';
export const DELETE_COMPANY_GREEN_STEP_FAIL = 'DELETE_COMPANY_GREEN_STEP_FAIL';

export const EDIT_GREEN_STEP_CATEGORY_START = 'EDIT_GREEN_STEP_CATEGORY_START';
export const EDIT_GREEN_STEP_CATEGORY_SUCCESS = 'EDIT_GREEN_STEP_CATEGORY_SUCCESS';
export const EDIT_GREEN_STEP_CATEGORY_FAIL = 'EDIT_GREEN_STEP_CATEGORY_FAIL';

export const EDIT_COMMENT_START = 'EDIT_COMMENT_START';
export const EDIT_COMMENT_SUCCESS = 'EDIT_COMMENT_SUCCESS';
export const EDIT_COMMENT_FAIL = 'EDIT_COMMENT_FAIL';

export const EDIT_GREEN_STEP_PDF_START = 'EDIT_GREEN_STEP_PDF_START';
export const EDIT_GREEN_STEP_PDF_SUCCESS = 'EDIT_GREEN_STEP_PDF_SUCCESS';
export const EDIT_GREEN_STEP_PDF_FAIL = 'EDIT_GREEN_STEP_PDF_FAIL';


////////////////////// Action Plan ///////////////////////////////
export const GET_ACTION_PLAN_GOAL_START = 'GET_ACTION_PLAN_GOAL_START';
export const GET_ACTION_PLAN_GOAL_SUCCESS = 'GET_ACTION_PLAN_GOAL_SUCCESS';
export const GET_ACTION_PLAN_GOAL_FAIL = 'GET_ACTION_PLAN_GOAL_FAIL';

export const CREATE_ACTION_PLAN_GOAL_START = 'CREATE_ACTION_PLAN_GOAL_START';
export const CREATE_ACTION_PLAN_GOAL_SUCCESS = 'CREATE_ACTION_PLAN_GOAL_SUCCESS';
export const CREATE_ACTION_PLAN_GOAL_FAIL = 'CREATE_ACTION_PLAN_GOAL_FAIL';

export const UPDATE_ACTION_PLAN_GOAL_START = 'UPDATE_ACTION_PLAN_GOAL_START';
export const UPDATE_ACTION_PLAN_GOAL_SUCCESS = 'UPDATE_ACTION_PLAN_GOAL_SUCCESS';
export const UPDATE_ACTION_PLAN_GOAL_FAIL = 'UPDATE_ACTION_PLAN_GOAL_FAIL';

export const GET_ACTION_PLAN_BY_COMPANY_START = 'GET_ACTION_PLAN_BY_COMPANY_START';
export const GET_ACTION_PLAN_BY_COMPANY_SUCCESS = 'GET_ACTION_PLAN_BY_COMPANY_SUCCESS';
export const GET_ACTION_PLAN_BY_COMPANY_FAIL = 'GET_ACTION_PLAN_BY_COMPANY_FAIL';

export const CREATE_ACTION_PLAN_START = 'CREATE_ACTION_PLAN_START';
export const CREATE_ACTION_PLAN_SUCCESS = 'CREATE_ACTION_PLAN_SUCCESS';
export const CREATE_ACTION_PLAN_FAIL = 'CREATE_ACTION_PLAN_FAIL';

export const UPDATE_ACTION_PLAN_START = 'UPDATE_ACTION_PLAN_START';
export const UPDATE_ACTION_PLAN_SUCCESS = 'UPDATE_ACTION_PLAN_SUCCESS';
export const UPDATE_ACTION_PLAN_FAIL = 'UPDATE_ACTION_PLAN_FAIL';

export const DELETE_ACTION_PLAN_GOAL_START = 'DELETE_ACTION_PLAN_GOAL_START';
export const DELETE_ACTION_PLAN_GOAL_SUCCESS = 'DELETE_ACTION_PLAN_GOAL_SUCCESS';
export const DELETE_ACTION_PLAN_GOAL_FAIL = 'DELETE_ACTION_PLAN_GOAL_FAIL';

export const DELETE_ACTION_PLAN_START = 'DELETE_ACTION_PLAN_START';
export const DELETE_ACTION_PLAN_SUCCESS = 'DELETE_ACTION_PLAN_SUCCESS';
export const DELETE_ACTION_PLAN_FAIL = 'DELETE_ACTION_PLAN_FAIL';


////////////////////// Company ///////////////////////////////
export const GET_COMPANY_START = 'GET_COMPANY_START';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAIL = 'GET_COMPANY_FAIL';

export const GET_COMPANY_ONLY_START = 'GET_COMPANY_ONLY_START';
export const GET_COMPANY_ONLY_SUCCESS = 'GET_COMPANY_ONLY_SUCCESS';
export const GET_COMPANY_ONLY_FAIL = 'GET_COMPANY_ONLY_FAIL';

export const GET_MORE_COMPANY_START = 'GET_MORE_COMPANY_START';
export const GET_MORE_COMPANY_SUCCESS = 'GET_MORE_COMPANY_SUCCESS';
export const GET_MORE_COMPANY_FAIL = 'GET_MORE_COMPANY_FAIL';

export const GET_COMPANY_CATEGORY_START = 'GET_COMPANY_CATEGORY_START';
export const GET_COMPANY_CATEGORY_SUCCESS = 'GET_COMPANY_CATEGORY_SUCCESS';
export const GET_COMPANY_CATEGORY_FAIL = 'GET_COMPANY_CATEGORY_FAIL';

export const CREATE_COMPANY_CATEGORY_START = 'CREATE_COMPANY_CATEGORY_START';
export const CREATE_COMPANY_CATEGORY_SUCCESS = 'CREATE_COMPANY_CATEGORY_SUCCESS';
export const CREATE_COMPANY_CATEGORY_FAIL = 'CREATE_COMPANY_CATEGORY_FAIL';

export const UPDATE_COMPANY_CATEGORY_START = 'UPDATE_COMPANY_CATEGORY_START';
export const UPDATE_COMPANY_CATEGORY_SUCCESS = 'UPDATE_COMPANY_CATEGORY_SUCCESS';
export const UPDATE_COMPANY_CATEGORY_FAIL = 'UPDATE_COMPANY_CATEGORY_FAIL';

export const DELETE_COMPANY_CATEGORY_START = 'DELETE_COMPANY_CATEGORY_START';
export const DELETE_COMPANY_CATEGORY_SUCCESS = 'DELETE_COMPANY_CATEGORY_SUCCESS';
export const DELETE_COMPANY_CATEGORY_FAIL = 'DELETE_COMPANY_CATEGORY_FAIL';

export const COMPANY_CONNECT_1819_START = 'COMPANY_CONNECT_1819_START';
export const COMPANY_CONNECT_1819_SUCCESS = 'COMPANY_CONNECT_1819_SUCCESS';
export const COMPANY_CONNECT_1819_FAIL = 'COMPANY_CONNECT_1819_FAIL';

export const COMPANY_DISCONNECT_1819_START = 'COMPANY_DISCONNECT_1819_START';
export const COMPANY_DISCONNECT_1819_SUCCESS = 'COMPANY_DISCONNECT_1819_SUCCESS';
export const COMPANY_DISCONNECT_1819_FAIL = 'COMPANY_DISCONNECT_1819_FAIL';

export const COMPANY_CREATE_1819_START = 'COMPANY_CREATE_1819_START';
export const COMPANY_CREATE_1819_SUCCESS = 'COMPANY_CREATE_1819_SUCCESS';
export const COMPANY_CREATE_1819_FAIL = 'COMPANY_CREATE_1819_FAIL';

export const DISABLE_ENABLE_COMPANY_START = 'DISABLE_ENABLE_COMPANY_START';
export const DISABLE_ENABLE_COMPANY_SUCCESS = 'DISABLE_ENABLE_COMPANY_SUCCESS';
export const DISABLE_ENABLE_COMPANY_FAIL = 'DISABLE_ENABLE_COMPANY_FAIL';

export const CREATE_COMPANY_START = 'CREATE_COMPANY_START';
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAIL = 'CREATE_COMPANY_FAIL';

export const UPDATE_COMPANY_START = 'UPDATE_COMPANY_START';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';

////////////////////// Category ///////////////////////////////
export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';


////////////////////// environmental ///////////////////////////////

export const GET_COMPANY_ENV_START = 'GET_COMPANY_ENV_START';
export const GET_COMPANY_ENV_SUCCESS = 'GET_COMPANY_ENV_SUCCESS';
export const GET_COMPANY_ENV_FAIL = 'GET_COMPANY_ENV_FAIL';

export const GET_COMPANY_ENV_BY_COMPANY_START = 'GET_COMPANY_ENV_BY_COMPANY_START';
export const GET_COMPANY_ENV_BY_COMPANY_SUCCESS = 'GET_COMPANY_ENV_BY_COMPANY_SUCCESS';
export const GET_COMPANY_ENV_BY_COMPANY_FAIL = 'GET_COMPANY_ENV_BY_COMPANY_FAIL';

export const UPLOAD_COMPANY_ENV_PDF_START = 'UPLOAD_COMPANY_ENV_PDF_START';
export const UPLOAD_COMPANY_ENV_PDF_SUCCESS = 'UPLOAD_COMPANY_ENV_PDF_SUCCESS';
export const UPLOAD_COMPANY_ENV_PDF_FAIL = 'UPLOAD_COMPANY_ENV_PDF_FAIL';

export const DELETE_COMPANY_ENV_START = 'DELETE_COMPANY_ENV_START';
export const DELETE_COMPANY_ENV_SUCCESS = 'DELETE_COMPANY_ENV_SUCCESS';
export const DELETE_COMPANY_ENV_FAIL = 'DELETE_COMPANY_ENV_FAIL';

export const UPDATE_COMPANY_ENV_START = 'UPDATE_COMPANY_ENV_START';
export const UPDATE_COMPANY_ENV_SUCCESS = 'UPDATE_COMPANY_ENV_SUCCESS';
export const UPDATE_COMPANY_ENV_FAIL = 'UPDATE_COMPANY_ENV_FAIL';



////////////////////// USERS ///////////////////////////////
export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const GET_USERS_BY_COMPANY_START = 'GET_USERS_BY_COMPANY_START';
export const GET_USERS_BY_COMPANY_SUCCESS = 'GET_USERS_BY_COMPANY_SUCCESS';
export const GET_USERS_BY_COMPANY_FAIL = 'GET_USERS_BY_COMPANY_FAIL';

export const CREATE_USERS_START = 'CREATE_USERS_START';
export const CREATE_USERS_SUCCESS = 'CREATE_USERS_SUCCESS';
export const CREATE_USERS_FAIL = 'CREATE_USERS_FAIL';

export const UPDATE_USERS_START = 'UPDATE_USERS_START';
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS';
export const UPDATE_USERS_FAIL = 'UPDATE_USERS_FAIL';

export const DELETE_USERS_START = 'DELETE_USERS_START';
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS';
export const DELETE_USERS_FAIL = 'DELETE_USERS_FAIL';

export const UPDATE_USER_PROFILE_START = 'UPDATE_USER_PROFILE_START';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAIL = 'UPDATE_USER_PROFILE_FAIL';

export const GET_LOGIN_ACTIVITY_START = 'GET_LOGIN_ACTIVITY_START';
export const GET_LOGIN_ACTIVITY_SUCCESS = 'GET_LOGIN_ACTIVITY_SUCCESS';
export const GET_LOGIN_ACTIVITY_FAIL = 'GET_LOGIN_ACTIVITY_FAIL';

export const CHECK_USER_EXIST_START = 'CHECK_USER_EXIST_START';
export const CHECK_USER_EXIST_SUCCESS = 'CHECK_USER_EXIST_SUCCESS';
export const CHECK_USER_EXIST_FAIL = 'CHECK_USER_EXIST_FAIL';


////////////////////// STORIES ///////////////////////////////
export const GET_STORIES_START = 'GET_STORIES_START';
export const GET_STORIES_SUCCESS = 'GET_STORIES_SUCCESS';
export const GET_STORIES_FAIL = 'GET_STORIES_FAIL';

export const GET_STORIES_COMPANY_START = 'GET_STORIES_COMPANY_START';
export const GET_STORIES_COMPANY_SUCCESS = 'GET_STORIES_COMPANY_SUCCESS';
export const GET_STORIES_COMPANY_FAIL = 'GET_STORIES_COMPANY_FAIL';

export const CREATE_STORIES_START = 'CREATE_STORIES_START';
export const CREATE_STORIES_SUCCESS = 'CREATE_STORIES_SUCCESS';
export const CREATE_STORIES_FAIL = 'CREATE_STORIES_FAIL';

export const UPDATE_STORIES_START = 'UPDATE_STORIES_START';
export const UPDATE_STORIES_SUCCESS = 'UPDATE_STORIES_SUCCESS';
export const UPDATE_STORIES_FAIL = 'UPDATE_STORIES_FAIL';

export const DELETE_STORIES_START = 'DELETE_STORIES_START';
export const DELETE_STORIES_SUCCESS = 'DELETE_STORIES_SUCCESS';
export const DELETE_STORIES_FAIL = 'DELETE_STORIES_FAIL';


////////////////////// Plans ///////////////////////////////
export const GET_PLAN_GOALS_START = 'GET_PLAN_GOALS_START';
export const GET_PLAN_GOALS_SUCCESS = 'GET_PLAN_GOALS_SUCCESS';
export const GET_PLAN_GOALS_FAIL = 'GET_PLAN_GOALS_FAIL';

export const UPDATE_PLAN_GOAL_START = 'UPDATE_PLAN_GOAL_START';
export const UPDATE_PLAN_GOAL_SUCCESS = 'UPDATE_PLAN_GOAL_SUCCESS';
export const UPDATE_PLAN_GOAL_FAIL = 'UPDATE_PLAN_GOAL_FAIL';

export const CREATE_PLAN_GOAL_START = 'CREATE_PLAN_GOAL_START';
export const CREATE_PLAN_GOAL_SUCCESS = 'CREATE_PLAN_GOAL_SUCCESS';
export const CREATE_PLAN_GOAL_FAIL = 'CREATE_PLAN_GOAL_FAIL';

export const DELETE_PLAN_GOAL_START = 'DELETE_PLAN_GOAL_START';
export const DELETE_PLAN_GOAL_SUCCESS = 'DELETE_PLAN_GOAL_SUCCESS';
export const DELETE_PLAN_GOAL_FAIL = 'DELETE_PLAN_GOAL_FAIL';

export const GET_PLAN_ACTIONS_START = 'GET_PLAN_ACTIONS_START';
export const GET_PLAN_ACTIONS_SUCCESS = 'GET_PLAN_ACTIONS_SUCCESS';
export const GET_PLAN_ACTIONS_FAIL = 'GET_PLAN_ACTIONS_FAIL';

export const UPDATE_PLAN_ACTION_START = 'UPDATE_PLAN_ACTION_START';
export const UPDATE_PLAN_ACTION_SUCCESS = 'UPDATE_PLAN_ACTION_SUCCESS';
export const UPDATE_PLAN_ACTION_FAIL = 'UPDATE_PLAN_ACTION_FAIL';

export const CREATE_PLAN_ACTION_START = 'CREATE_PLAN_ACTION_START';
export const CREATE_PLAN_ACTION_SUCCESS = 'CREATE_PLAN_ACTION_SUCCESS';
export const CREATE_PLAN_ACTION_FAIL = 'CREATE_PLAN_ACTION_FAIL';

export const DELETE_PLAN_ACTION_START = 'DELETE_PLAN_ACTION_START';
export const DELETE_PLAN_ACTION_SUCCESS = 'DELETE_PLAN_ACTION_SUCCESS';
export const DELETE_PLAN_ACTION_FAIL = 'DELETE_PLAN_ACTION_FAIL';


////////////////////// Certificates ///////////////////////////////
export const GET_CERTIFICATES_START = 'GET_CERTIFICATES_START';
export const GET_CERTIFICATES_SUCCESS = 'GET_CERTIFICATES_SUCCESS';
export const GET_CERTIFICATES_FAIL = 'GET_CERTIFICATES_FAIL';

export const CREATE_CERTIFICATE_START = 'CREATE_CERTIFICATE_START';
export const CREATE_CERTIFICATE_SUCCESS = 'CREATE_CERTIFICATE_SUCCESS';
export const CREATE_CERTIFICATE_FAIL = 'CREATE_CERTIFICATE_FAIL';

export const UPDATE_CERTIFICATE_START = 'UPDATE_CERTIFICATE_START';
export const UPDATE_CERTIFICATE_SUCCESS = 'UPDATE_CERTIFICATE_SUCCESS';
export const UPDATE_CERTIFICATE_FAIL = 'UPDATE_CERTIFICATE_FAIL';

export const DELETE_CERTIFICATE_START = 'DELETE_CERTIFICATE_START';
export const DELETE_CERTIFICATE_SUCCESS = 'DELETE_CERTIFICATE_SUCCESS';
export const DELETE_CERTIFICATE_FAIL = 'DELETE_CERTIFICATE_FAIL';

export const GET_COMPANY_CERTIFICATES_START = 'GET_COMPANY_CERTIFICATES_START';
export const GET_COMPANY_CERTIFICATES_SUCCESS = 'GET_COMPANY_CERTIFICATES_SUCCESS';
export const GET_COMPANY_CERTIFICATES_FAIL = 'GET_COMPANY_CERTIFICATES_FAIL';

export const CREATE_COMPANY_CERTIFICATE_START = 'CREATE_COMPANY_CERTIFICATE_START';
export const CREATE_COMPANY_CERTIFICATE_SUCCESS = 'CREATE_COMPANY_CERTIFICATE_SUCCESS';
export const CREATE_COMPANY_CERTIFICATE_FAIL = 'CREATE_COMPANY_CERTIFICATE_FAIL';

export const UPDATE_COMPANY_CERTIFICATE_START = 'UPDATE_COMPANY_CERTIFICATE_START';
export const UPDATE_COMPANY_CERTIFICATE_SUCCESS = 'UPDATE_COMPANY_CERTIFICATE_SUCCESS';
export const UPDATE_COMPANY_CERTIFICATE_FAIL = 'UPDATE_COMPANY_CERTIFICATE_FAIL';

export const DELETE_COMPANY_CERTIFICATE_START = 'DELETE_COMPANY_CERTIFICATE_START';
export const DELETE_COMPANY_CERTIFICATE_SUCCESS = 'DELETE_COMPANY_CERTIFICATE_SUCCESS';
export const DELETE_COMPANY_CERTIFICATE_FAIL = 'DELETE_COMPANY_CERTIFICATE_FAIL';

export const UPDATE_COMPANY_CERTIFICATE_STATUS_START = 'UPDATE_COMPANY_CERTIFICATE_STATUS_START';
export const UPDATE_COMPANY_CERTIFICATE_STATUS_SUCCESS = 'UPDATE_COMPANY_CERTIFICATE_STATUS_SUCCESS';
export const UPDATE_COMPANY_CERTIFICATE_STATUS_FAIL = 'UPDATE_COMPANY_CERTIFICATE_STATUS_FAIL';

////////////////////// Notifications ///////////////////////////////
export const CREATE_NOTIFICATIONS_START = 'CREATE_NOTIFICATIONS_START';
export const CREATE_NOTIFICATIONS_SUCCESS = 'CREATE_NOTIFICATIONS_SUCCESS';
export const CREATE_NOTIFICATIONS_FAIL = 'CREATE_NOTIFICATIONS_FAIL';

export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const GET_NOTIFICATIONS_MENU_START = 'GET_NOTIFICATIONS_MENUSTART';
export const GET_NOTIFICATIONS_MENU_SUCCESS = 'GET_NOTIFICATIONS_MENU_SUCCESS';
export const GET_NOTIFICATIONS_MENU_FAIL = 'GET_NOTIFICATIONS_MENU_FAIL';

export const GET_NOTIFICATIONS_READ_START = 'GET_NOTIFICATIONS_READ_START';
export const GET_NOTIFICATIONS_READ_SUCCESS = 'GET_NOTIFICATIONS_READ_SUCCESS';
export const GET_NOTIFICATIONS_READ_FAIL = 'GET_NOTIFICATIONS_READ_FAIL';

export const GET_NOTIFICATIONS_MENU_READ_START = 'GET_NOTIFICATIONS_MENU_READ_START';
export const GET_NOTIFICATIONS_MENU_READ_SUCCESS = 'GET_NOTIFICATIONS_MENU_READ_SUCCESS';
export const GET_NOTIFICATIONS_MENU_READ_FAIL = 'GET_NOTIFICATIONS_MENU_READ_FAIL';


////////////////////// Laufid MUNICIPALITIES ///////////////////////////////
export const GET_MUNICIPALITIES_START = 'GET_MUNICIPALITIES_START';
export const GET_MUNICIPALITIES_SUCCESS = 'GET_MUNICIPALITIES_SUCCESS';
export const GET_MUNICIPALITIES_PLAN_FAIL = 'GET_MUNICIPALITIES_FAIL';

export const GET_INSTITUTIONS_START = 'GET_INSTITUTIONS_START';
export const GET_INSTITUTIONS_SUCCESS = 'GET_INSTITUTIONS_SUCCESS';
export const GET_INSTITUTIONS_FAIL = 'GET_INSTITUTIONS_PLAN_FAIL';

export const ADD_INSTITUTIONS_START = 'ADD_INSTITUTIONS_START';
export const ADD_INSTITUTIONS_SUCCESS = 'ADD_INSTITUTIONS_SUCCESS';
export const ADD_INSTITUTIONS_FAIL = 'ADD_INSTITUTIONS_PLAN_FAIL';

////////////////////// Laufid Registration ///////////////////////////////

export const GET_REGISTRATION_PLAN_START = 'GET_REGISTRATION_PLAN_START';
export const GET_REGISTRATION_PLAN_SUCCESS = 'GET_REGISTRATION_PLAN_SUCCESS';
export const GET_REGISTRATION_PLAN_FAIL = 'GET_REGISTRATION_PLAN_FAIL';

export const GET_REGISTRATION_COMPANY_KENNITALA_START = 'GET_REGISTRATION_COMPANY_KENNITALA_START';
export const GET_REGISTRATION_COMPANY_KENNITALA_SUCCESS = 'GET_REGISTRATION_COMPANY_KENNITALA_SUCCESS';
export const GET_REGISTRATION_COMPANY_KENNITALA_FAIL = 'GET_REGISTRATION_COMPANY_KENNITALA_FAIL';

export const REGISTER_COMPANY_START = 'REGISTER_COMPANY_START';
export const REGISTER_COMPANY_SUCCESS = 'REGISTER_COMPANY_SUCCESS';
export const REGISTER_COMPANY_FAIL = 'REGISTER_COMPANY_FAIL';


export const VERIFY_ONBOARDING_START = 'VERIFY_ONBOARDING_START';
export const VERIFY_ONBOARDING_SUCCESS = 'VERIFY_ONBOARDING_SUCCESS';
export const VERIFY_ONBOARDING_FAIL = 'VERIFY_ONBOARDING_FAIL';


////////////////////// Permission ///////////////////////////////
export const GET_PERMISSION_START = 'GET_PERMISSION_START';
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_PERMISSION_FAIL = 'GET_PERMISSION_FAIL';

export const GET_ROLE_START = 'GET_ROLE_START';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAIL = 'GET_ROLE_FAIL';

////////////////////// Company setting ///////////////////////////////
export const GET_COMPANY_USER_START = 'GET_COMPANY_USER_START';
export const GET_COMPANY_USER_SUCCESS = 'GET_COMPANY_USER_SUCCESS';
export const GET_COMPANY_USER_FAIL = 'GET_COMPANY_USER_FAIL';

export const CREATE_COMPANY_USER_START = 'CREATE_COMPANY_USER_START';
export const CREATE_COMPANY_USER_SUCCESS = 'CREATE_COMPANY_USER_SUCCESS';
export const CREATE_COMPANY_USER_FAIL = 'CREATE_COMPANY_USER_FAIL';

export const UPDATE_COMPANY_USER_START = 'UPDATE_COMPANY_USER_START';
export const UPDATE_COMPANY_USER_SUCCESS = 'UPDATE_COMPANY_USER_SUCCESS';
export const UPDATE_COMPANY_USER_FAIL = 'UPDATE_COMPANY_USER_FAIL';

export const DELETE_COMPANY_USER_START = 'DELETE_COMPANY_USER_START';
export const DELETE_COMPANY_USER_SUCCESS = 'DELETE_COMPANY_USER_SUCCESS';
export const DELETE_COMPANY_USER_FAIL = 'DELETE_COMPANY_USER_FAIL';


////////////////////// Carbon ///////////////////////////////
export const GET_CARBON_SERVICES_START = 'GET_CARBON_SERVICES_START';
export const GET_CARBON_SERVICES_SUCCESS = 'GET_CARBON_SERVICES_SUCCESS';
export const GET_CARBON_SERVICES_FAIL = 'GET_CARBON_SERVICES_FAIL';

export const GET_CARBON_EMISSIONS_START = 'GET_CARBON_EMISSIONS_START';
export const GET_CARBON_EMISSIONS_SUCCESS = 'GET_CARBON_EMISSIONS_SUCCESS';
export const GET_CARBON_EMISSIONS_FAIL = 'GET_CARBON_EMISSIONS_FAIL';


export const GET_COMPANY_CARBON_EMISSIONS_START = 'GET_COMPANY_CARBON_EMISSIONS_START';
export const GET_COMPANY_CARBON_EMISSIONS_SUCCESS = 'GET_COMPANY_CARBON_EMISSIONS_SUCCESS';
export const GET_COMPANY_CARBON_EMISSIONS_FAIL = 'GET_COMPANY_CARBON_EMISSIONS_FAIL';

export const CREATE_COMPANY_CARBON_EMISSION_START = 'CREATE_COMPANY_CARBON_EMISSION_START';
export const CREATE_COMPANY_CARBON_EMISSION_SUCCESS = 'CREATE_COMPANY_CARBON_EMISSION_SUCCESS';
export const CREATE_COMPANY_CARBON_EMISSION_FAIL = 'CREATE_COMPANY_CARBON_EMISSION_FAIL';

export const UPDATE_COMPANY_CARBON_EMISSION_START = 'UPDATE_COMPANY_CARBON_EMISSION_START';
export const UPDATE_COMPANY_CARBON_EMISSION_SUCCESS = 'UPDATE_COMPANY_CARBON_EMISSION_SUCCESS';
export const UPDATE_COMPANY_CARBON_EMISSION_FAIL = 'UPDATE_COMPANY_CARBON_EMISSION_FAIL';


export const UPDATE_CARBON_EMISSION_START = 'UPDATE_CARBON_EMISSION_START';
export const UPDATE_CARBON_EMISSION_SUCCESS = 'UPDATE_CARBON_EMISSION_SUCCESS';
export const UPDATE_CARBON_EMISSION_FAIL = 'UPDATE_CARBON_EMISSION_FAIL';

export const GET_COMPANY_CARBON_EMISSIONS_3RDPARTY_START = 'GET_COMPANY_CARBON_EMISSIONS_3RDPARTY_START';
export const GET_COMPANY_CARBON_EMISSIONS_3RDPARTY_SUCCESS = 'GET_COMPANY_CARBON_EMISSIONS_3RDPARTY_SUCCESS';
export const GET_COMPANY_CARBON_EMISSIONS_3RDPARTY_FAIL = 'GET_COMPANY_CARBON_EMISSIONS_3RDPARTY_FAIL';

export const CREATE_COMPANY_CARBON_EMISSION_3RDPARTY_START = 'CREATE_COMPANY_CARBON_EMISSION_3RDPARTY_START';
export const CREATE_COMPANY_CARBON_EMISSION_3RDPARTY_SUCCESS = 'CREATE_COMPANY_CARBON_EMISSION_3RDPARTY_SUCCESS';
export const CREATE_COMPANY_CARBON_EMISSION_3RDPARTY_FAIL = 'CREATE_COMPANY_CARBON_EMISSION_3RDPARTY_FAIL';

export const UPDATE_COMPANY_CARBON_EMISSION_3RDPARTY_START = 'UPDATE_COMPANY_CARBON_EMISSION_3RDPARTY_START';
export const UPDATE_COMPANY_CARBON_EMISSION_3RDPARTY_SUCCESS = 'UPDATE_COMPANY_CARBON_EMISSION_3RDPARTY_SUCCESS';
export const UPDATE_COMPANY_CARBON_EMISSION_3RDPARTY_FAIL = 'UPDATE_COMPANY_CARBON_EMISSION_3RDPARTY_FAIL';

export const DELETE_COMPANY_CARBON_EMISSION_3RDPARTY_START = 'DELETE_COMPANY_CARBON_EMISSION_3RDPARTY_START';
export const DELETE_COMPANY_CARBON_EMISSION_3RDPARTY_SUCCESS = 'DELETE_COMPANY_CARBON_EMISSION_3RDPARTY_SUCCESS';
export const DELETE_COMPANY_CARBON_EMISSION_3RDPARTY_FAIL = 'DELETE_COMPANY_CARBON_EMISSION_3RDPARTY_FAIL';
