import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Card, CardBody} from "reactstrap";
import {useLocation} from "react-router-dom";
import {LoaderSpinner} from "../../components/Common/Loader";
import {getCarbonEmissions, getCarbonServices, getCompanyCarbonEmissions} from "../../store/actions/carbon";

import CreateCarbonInitialPage from "./createCarbonInitialPage";
import CreateCarbonMainPage from "./createCarbonMainPage";
import ResultPage from "./resultPage";
import './index.scss';


const Index = () => {
  const dispatch = useDispatch();
  const {state} = useLocation();
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;
  const {loadingServices, loadingEmissions, loadingCompanyEmissions, services, emissions, companyEmissions} = useSelector( state => state.carbon );

  const [initStep, setInitStep] = useState(0);
  const [mainStep, setMainStep] = useState('-1');
  const [showResultPage, setShowResultPage] = useState(false);
  const [continueYear, setContinueYear] = useState('');

  useEffect(() => {
    dispatch(getCompanyCarbonEmissions(companyId));
    services.length === 0 && dispatch(getCarbonServices());
    emissions.length === 0 && dispatch(getCarbonEmissions());
  }, []);

  useEffect(() => {
    if(companyEmissions && companyEmissions.results) {
      let companyEmissionsKeys = Object.keys(companyEmissions.results);
      if(companyEmissionsKeys.length === 1) {
        let itemKeys = Object.keys(companyEmissions.results[companyEmissionsKeys]).filter(f => f !== 'total_sum');
        if(itemKeys.includes('2') && itemKeys.includes('3')) {
          setShowResultPage(true);
        } else {
          setMainStep('1.0');
          setContinueYear(companyEmissionsKeys[0]);
          setShowResultPage(false);
        }
      } else if(companyEmissionsKeys.length > 0) {
        setShowResultPage(true);
      } else {
        if(state && !state.showCreate) {
          setInitStep(1);
        } else {
          setInitStep(1);
        }
      }
    }
  }, [companyEmissions]);

  useEffect(() => {
    if(state) {
      if(state.showInit) {
        setInitStep(1);
        setMainStep('-1');
        setShowResultPage(false);
      }
      if(state.showCreate) {
        setMainStep('0');
        setInitStep(0);
        setShowResultPage(false);
      }
      if(state.showResult) {
        setInitStep(0);
        setMainStep('-1');
        setShowResultPage(true);
      }
    }
  }, [state]);

  if (loadingCompanyEmissions || loadingServices || loadingEmissions) return (<LoaderSpinner/>);

  return (
    <div className="page-content carbonPage">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
          <Card>
            <CardBody>

              {companyEmissions.results && Object.keys(companyEmissions.results).length === 0 && initStep > 0 &&
                <CreateCarbonInitialPage
                  initStep={initStep}
                  onChangeInitStep={(step) => setInitStep(step)}
                  onChangeMainStep={(step) => setMainStep(step)}
                />
              }

              {mainStep >= 0 &&
                <CreateCarbonMainPage
                  mainStep={mainStep}
                  continueYear={continueYear}
                  onChangeMainStep={(step) => setMainStep(step)}
                />
              }

              {showResultPage && mainStep === '-1' &&
               <ResultPage
                 locationState={state}
                 onChangeShowResultPage={(data) => setShowResultPage(data)}
                 onChangeMainStep={(step) => setMainStep(step)}
                 handleContinue={(year) => setContinueYear(year)}
               />
              }

            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  )
};

export default Index