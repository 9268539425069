import PropTypes from "prop-types"
import React, {useState, useEffect, useMemo} from "react"
import { Collapse } from "reactstrap"
import {useTranslation} from "react-i18next"
import {Link, useLocation} from "react-router-dom"
import classname from "classnames"
import {ExpandMore, NavigateNext} from "@mui/icons-material";

const Navbar = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const store = JSON.parse(localStorage.getItem('p_user'));
  const permission = store ? store.permission : [];
  const role = store ? store.roles : [];

  const [showAcl, setShowAcl] = useState(false);

  const access = useMemo(
    () => {
      return {
        "dashboard_page": permission.includes('dashboard_page'),
        "pluses_page": permission.includes('pluses_page'),
        "carbon_page": permission.includes('pluses_page'),
        "plan_page": permission.includes('plan_page'),

        "admin_users_page": permission.includes('admin_users_page'),
        "admin_green_steps_page": permission.includes('admin_pluses_page'),
        "admin_certificates_page": permission.includes('admin_certificates_page'),
        "admin_certificates_company_page": permission.includes('admin_certificates_company_page'),
        "admin_companies_page": permission.includes('admin_companies_page'),
        "admin_company_categories_page": permission.includes('admin_company_categories_page'),
        "admin_company_environmental_page": permission.includes('admin_company_environmental_page'),
        "admin_roles_page": permission.includes('admin_roles_page'),
        "admin_permissions_page": permission.includes('admin_permissions_page'),
      }
    },
    []
  );

  useEffect(() => {
    if(props.leftMenu) {
      props.closeMenu();
    }

    let matchingMenuItem = null;
    let path = location.pathname === '/' ? '/dashboard' : location.pathname;
    let ul = document.getElementById("navigation");
    let items = ul.getElementsByTagName("a");
    let dropDowns = ul.getElementsByClassName("dropdown");

    for (let i = 0; i < dropDowns.length; ++i) {
      dropDowns[i].classList.remove('active');
    }

    for (let i = 0; i < items.length; ++i) {
      if (path === items[i].pathname) {
        matchingMenuItem = items[i]
      } else {
        items[i].classList.remove('active');
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [location.pathname]);

  const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent && !parent.classList.contains('navbar-nav')) {
      activateParentDropdown(parent);
    }
    return false
  };

  return (
    <div className="topnav">
      <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
        <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content">
          <ul className="navbar-nav">

            {access.dashboard_page &&
              <li className="nav-item"> <Link to="/dashboard" className="nav-link"> {t("dashboard")} </Link> </li>
            }

            {access.pluses_page &&
              <li className="nav-item"> <Link to="/adgerdir" className="nav-link"> {t("pluses")} </Link> </li>
            }

            {access.plan_page &&
              <li className="nav-item"> <Link to="/action-plan" className="nav-link"> {t("actionPlan")} </Link> </li>
            }

            {access.carbon_page &&
              <li className="nav-item"> <Link to="/carbon" className="nav-link"> {t("carbon")} </Link> </li>
            }

            {access.carbon_page &&
              <li className="nav-item"> <Link to="/education" className="nav-link"> {t("education")} </Link> </li>
            }

            {(role && (role.includes('super_admin') || role.includes('manager'))) &&
              <li className="nav-item dropdown">
                <Link to="/#" className="nav-link"> {t("Admin")} <ExpandMore/> </Link>
                <div className={"dropdown-menu"}>

                  {access.admin_users_page && <Link to="/users" className="dropdown-item"> {t('users')} </Link>}
                  {access.admin_companies_page && <Link to="/companies" className="dropdown-item"> {t('companies_list')} </Link>}
                  {access.admin_green_steps_page && <Link to="/green-steps-list" className="dropdown-item"> {t('pluses')} </Link>}
                  {access.admin_green_steps_page && <Link to="/company-history" className="dropdown-item"> {t('company-history')} </Link>}
                  {access.admin_certificates_page && <Link to="/certificates-list" className="dropdown-item"> {t('certificate_page.list_of_certificates')} </Link>}
                  {access.admin_green_steps_page && <Link to="/carbon-list" className="dropdown-item"> {t('carbon-list')} </Link>}
                  {access.admin_green_steps_page && <Link to="/announcements-list" className="dropdown-item"> {t('announcements')} </Link>}

                  <div className="nav-item dropdown">
                    <Link to="/#" className="dropdown-item" onClick={() => setShowAcl(!showAcl)}> {t("acl")} <NavigateNext/> </Link>
                    <div className={classname("dropdown-menu", {show: showAcl})}>
                      {access.admin_roles_page && <Link to="/roles" className="dropdown-item"> {t('roles')} </Link>}
                      {access.admin_permissions_page && <Link to="/permissions" className="dropdown-item"> {t('permissions')} </Link>}
                    </div>
                  </div>

                </div>
              </li>
            }

          </ul>
        </Collapse>
      </nav>
    </div>
  )
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
};

export default Navbar