import React, {useEffect} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Table, Card, CardBody, Button} from "reactstrap"

import {Edit} from '@mui/icons-material';
import DeleteButton from "../../components/Common/CustomButtons/DeleteButton";


import {createUpdatePermission, deletePermission, getPermissions} from "../../store/actions/acl";
import AddPermission from "./addPermission";
import {LoaderSpinner} from "../../components/Common/Loader";
import "./index.scss"

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const [alert, setAlert] = React.useState(null);
  const {loadingPermission, permissions} = useSelector( state => state.acl );
  const store = JSON.parse(localStorage.getItem('p_user'));
  const permission = store ? store.permission : [];
  const canCreate = permission.includes('admin_permissions_create');
  const canEdit = permission.includes('admin_permissions_edit');
  const canDelete = permission.includes('admin_permissions_delete');

  useEffect(() => {
    dispatch(getPermissions());
  }, []);

  if (loadingPermission) return (<LoaderSpinner/>);

  const onSubmit = (data, update) => {
    dispatch(createUpdatePermission(data, update));
  };

  const htmlAlert = (data) => {
    setAlert(
      <AddPermission data={data} onCancel={() => setAlert(null)} onSubmit={(data, update) => onSubmit(data, update)}/>
    );
  };

  const tableData = !permissions.results ? '' :
    permissions.results.map((value, index) => {
      return (<tr key={index}>
        <td>{index+1}</td>
        <td>{value.name}</td>
        <td width="120px">
          {canEdit && <Button key={value.id+'editBtn'} color="success" size="sm" onClick={() => htmlAlert(value)}><Edit style={{fontSize: '12px'}}/></Button>}
          {canDelete && <DeleteButton key={value.id+'_delete'} action={() => dispatch(deletePermission({...value}))} />}
        </td>
      </tr>)
    });

  return (
    <div className="page-content">
      {alert}
      <Card>
        <CardBody>
          <div className="row">
            <div className="col col-12">
              {canCreate && <Button color="success" className="mb-4 float-end" size="sm" style={{float: 'right'}} onClick={() => htmlAlert(null)}>{t('acl_page.add_new')}</Button>}
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table table-striped table-hover">
              <thead className="table-light">
                <tr>
                  <th>#</th>
                  <th>{t('acl_page.name')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableData}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  )
};

export default Index
