import React, {useState, useEffect} from "react"
import { useTranslation } from 'react-i18next';
import {Row, Modal, Input,} from "reactstrap"
import Select from "react-select";
import PictureUpload from "../../components/Common/CustomUpload/PictureUpload.js";
import "./index.scss"

const AddCategory = (props) => {
  const { t } = useTranslation();
  const [errorStatus, setErrorStatus] = useState(false);
  const [state, setState] = useState({
    id: props.data.id || null,
    parent_id: props.data.parent_id || null,
    title: props.data.title || "",
    title_en: props.data.title_en || "",
    title_pl: props.data.title_pl || "",
    titleValid: false,
    sort_order: props.data.sort_order || 0,
    logo: props.data.logo || "",
    categories: props.categories.map((category) => {return {label: category.title, value: category.id}})
  });

  useEffect(() => {
    if(state.categories && state.categories.length>0) {
      let temp = {...state};
      temp.categories.unshift({label: 'Parent', value: null});
      setState(temp);
    }
  }, [state.categories]);


  const change = (event, stateName) => {
    setState({...state, [stateName]: event.target.value});
  };

  const onChangeCategory = (event) => {
    setState({...state, parent_id: event.value});
  };

  const onUpload = (data) => {
    setState({...state, "logo": data});
  };

  const isValidated = () => {
    let data = {...state};
    data['titleValid'] = state.title.length > 3;
    setState(data);

    if(data['titleValid']) {
      return true
    } else {
      setErrorStatus(true);
      return false;
    }
  };

  const onConfirm = () => {
    if (isValidated()) {
      props.onSubmit(state, !!state.id);
      props.onCancel();
    }
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{state.id ? t('category_page.update') : t('category_page.add_new')}</h5>
      </div>
      <div className="modal-body">
        <Row>
          <div className="col-md-12 mb-3">
            <label className="form-label">{t('user_page.role')} <small>({t('required')})</small></label>
            <Select
              isSearchable={false}
              placeholder={t('user_page.role')}
              value={state.categories.find(f => f.value === state.parent_id)}
              onChange={e => onChangeCategory(e)}
              options={state.categories}
            />
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">{t('category_page.title')} <small>({t('required')})</small></label>
            <Input
              id="title"
              name="title"
              type="text"
              value={state.title}
              className="form-control"
              onChange={event => change(event, 'title')}
              invalid={errorStatus && !state.titleValid}
            />
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">{t('category_page.title_en')}</label>
            <Input
              id="title"
              name="title"
              type="text"
              value={state.title_en}
              className="form-control"
              onChange={event => change(event, 'title_en')}
            />
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">{t('category_page.title_pl')} </label>
            <Input
              id="title"
              name="title"
              type="text"
              value={state.title_pl}
              className="form-control"
              onChange={event => change(event, 'title_pl')}
            />
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">{t('category_page.order')}</label>
            <Input
              id="order"
              name="order"
              type="text"
              value={state.sort_order}
              className="form-control"
              onChange={event => change(event, 'sort_order')}
            />
          </div>
          <div className="col-md-12 mb-3">
            <PictureUpload imgHolder={state.logo ? state.logo : null} onUpload={(data) => onUpload(data)} />
          </div>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => onCancel()}
          className="btn btn-danger waves-effect"
          data-dismiss="modal"
        >
          {t('close')}
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  );

};

export default AddCategory