export const customDropdownStyles = (color = '#009448') => {
    return {
        control: (base, state) => ({
            ...base,
            borderRadius: '15px',  cursor: 'pointer',
            boxShadow: state.isFocused ? '0 0 0 1px #009448' : null,
            borderColor: state.isFocused ? '#009448 !important' : '#cccccc'
        }),
        valueContainer: (base) => ({...base, padding: '5px 10px 5px'}),
        input: (base) => ({...base, margin: '0px'}),
        menu: (base) => ({...base, borderRadius: '15px'}),
        group: (base) => ({...base, paddingLeft: '20px'}),
        groupHeading: (base) => ({...base, fontSize: '14px', paddingLeft: '0'}),
        menuList: (base) => ({...base, borderRadius: '15px'}),
        indicatorSeparator: state => ({display: 'none'}),
        indicatorsContainer: (base, state) => ({...base,  '&>div': {color: color}}),
        option: (base, state) => ({
            ...base,
            cursor: 'pointer',
            color: state.isFocused || state.isSelected ? '#fff' : color, fontSize: '14px',
            backgroundColor: state.isFocused || state.isSelected ? color : '#fff',

        }),
    }
};

export const customDropdownSmallStyles = {
    control: (base, state) => ({
        ...base,
        borderRadius: '20px',  cursor: 'pointer', minHeight: '30px', height: '30px',
        boxShadow: state.isFocused ? '0 0 0 1px #009448' : null,
        borderColor: state.isFocused ? '#009448 !important' : '#cccccc'
    }),
    valueContainer: (base) => ({...base, padding: '0 10px 0'}),
    input: (base) => ({...base, margin: '0px', padding: 0}),
    menu: (base) => ({...base, borderRadius: '15px'}),
    group: (base) => ({...base, paddingLeft: '20px'}),
    groupHeading: (base) => ({...base, fontSize: '14px', paddingLeft: '0'}),
    menuList: (base) => ({...base, borderRadius: '20px'}),
    indicatorSeparator: state => ({display: 'none'}),
    indicatorsContainer: (base, state) => ({...base,  '&>div': {color: '#009448', padding: '0 8px'}}),
    option: (base, state) => ({
        ...base,
        cursor: 'pointer',
        color: state.isFocused || state.isSelected ? '#fff' : '#009448', fontSize: '14px',
        backgroundColor: state.isFocused || state.isSelected ? '#009448' : '#fff',

    }),
};

export const customDropdownStylesCreate = {
    control: (base, state) => ({
        ...base,
        borderRadius: '15px', minHeight: '30px', cursor: 'pointer',
        boxShadow: state.isFocused ? '0 0 0 1px #009448' : null,
        borderColor: state.isFocused ? '#009448 !important' : '#cccccc'
    }),
    valueContainer: (base) => ({...base, minHeight: '30px', padding: '0 10px'}),
    input: (base) => ({...base, margin: '0px'}),
    indicatorSeparator: state => ({display: 'none'}),
    indicatorsContainer: (base) => ({...base, minHeight: '30px', '&>div': {color: '#009448'}}),
    option: (base, state) => ({
        ...base,
        cursor: 'pointer',
        color: state.isFocused || state.isSelected ? '#fff' : '#009448',  fontSize: '14px',
        backgroundColor: state.isFocused || state.isSelected ? '#009448' : '#fff'
    }),
};

export const customDropdownPaginationStyles = {
    control: (base, state) => ({
        ...base,
        borderRadius: '30px', cursor: 'pointer', minHeight: '35px', height: '35px',
        boxShadow: state.isFocused ? '0 0 0 1px #009448' : null,
        borderColor: state.isFocused ? '#009448 !important' : '#cccccc',
        backgroundColor: '#009448', width: '120px', textAlign: 'center'
    }),
    valueContainer: (base) => ({...base, padding: '5px 10px 5px', '&>div':{color: '#fff', fontSize: '16px'}, '& .m_list': {display: 'none'} }),
    input: (base) => ({...base, margin: '0px', color: '#fff'}),
    menu: (base) => ({...base, borderRadius: '15px'}),
    group: (base) => ({...base, paddingLeft: '20px'}),
    groupHeading: (base) => ({...base, fontSize: '14px', paddingLeft: '0'}),
    menuList: (base) => ({...base, borderRadius: '15px', '& .m_select': {display: 'none'}}),
    indicatorSeparator: state => ({display: 'none'}),
    indicatorsContainer: (base, state) => ({...base,  '&>div': {display: 'none'}}),
    option: (base, state) => ({
        ...base,
        cursor: 'pointer',
        color: state.isFocused || state.isSelected ? '#fff' : '#009448', fontSize: '14px',
        backgroundColor: state.isFocused || state.isSelected ? '#009448' : '#fff',
        textAlign: 'center'
    }),
};


export const customDropdownActionPlanStyles = {
    control: (base, state) => ({
        ...base,
        borderRadius: '30px',  cursor: 'pointer', minHeight: '35px', height: '35px',
        boxShadow: state.isFocused ? '0 0 0 1px #009448' : null,
        borderColor: state.isFocused ? '#009448 !important' : '#cccccc'
    }),
    valueContainer: (base) => ({...base, padding: '5px 10px 5px'}),
    input: (base) => ({...base, margin: '0px'}),
    menu: (base) => ({...base, borderRadius: '15px', zIndex: '99999'}),
    group: (base) => ({...base, paddingLeft: '0'}),
    groupHeading: (base) => ({...base, fontSize: '14px', paddingLeft: '12px'}),
    menuList: (base) => ({...base, borderRadius: '15px'}),
    indicatorSeparator: state => ({display: 'none'}),
    indicatorsContainer: (base, state) => ({...base,  '&>div': {color: '#009448'}}),
    option: (base, state) => ({
        ...base,
        cursor: 'pointer',
        color: state.isFocused || state.isSelected ? '#fff' : '#009448', fontSize: '14px',
        backgroundColor: state.isFocused || state.isSelected ? '#009448' : '#fff',

    }),
};


export const filterDropdownGreenStepStyles = {
    control: (base, state) => ({
        ...base,
        borderRadius: '30px',  cursor: 'pointer', minHeight: '30px', height: '30px', fontSize: '16px',
        boxShadow: state.isFocused ? '0 0 0 0 #fff' : null,
        border: 'none !important',
        width: '120px'
    }),
    valueContainer: (base) => ({...base, padding: '0'}),
    singleValue: (base) => ({...base, color: '#565958'}),
    input: (base) => ({...base, margin: '0px'}),
    menu: (base) => ({...base, borderRadius: '15px', zIndex: '99999', width: 'auto', left:'-30px'}),
    group: (base) => ({...base, paddingLeft: '0'}),
    groupHeading: (base) => ({...base, fontSize: '14px', paddingLeft: '12px'}),
    menuList: (base) => ({...base, borderRadius: '15px'}),
    indicatorSeparator: state => ({display: 'none'}),
    indicatorsContainer: (base, state) => ({display: 'none'}),
    option: (base, state) => ({
        ...base,
        cursor: 'pointer',
        color: state.isFocused || state.isSelected ? '#000' : '#434242', fontSize: '14px',
        backgroundColor: state.isFocused || state.isSelected ? '#fff' : '#fff',
        paddingLeft: state.isSelected ? '10px' : '30px',
        svg: {color: '#565958', fontSize: '12px', marginRight: '10px', height: '100%'},
        whiteSpace: 'nowrap'
    }),
};

export const categoryDropdownGreenStepStyles = {
    control: (base, state) => ({
        ...base,
        borderRadius: '30px',  cursor: 'pointer', minHeight: '30px', height: '30px', fontSize: '16px',
        boxShadow: state.isFocused ? '0 0 0 0 #fff' : null,
        border: 'none !important',
        width: '120px'
    }),
    valueContainer: (base) => ({...base, padding: '0'}),
    singleValue: (base) => ({...base, color: '#565958'}),
    input: (base) => ({...base, margin: '0px'}),
    menu: (base) => ({...base, borderRadius: '15px', zIndex: '99999', width: '150px'}),
    group: (base) => ({...base, paddingLeft: '0'}),
    groupHeading: (base) => ({...base, fontSize: '14px', paddingLeft: '12px'}),
    menuList: (base) => ({...base, borderRadius: '15px'}),
    indicatorSeparator: state => ({display: 'none'}),
    indicatorsContainer: (base, state) => ({display: 'none'}),
    option: (base, state) => ({
        ...base,
        cursor: 'pointer',
        color: state.isFocused || state.isSelected ? '#000' : '#434242', fontSize: '14px',
        backgroundColor: state.isFocused || state.isSelected ? '#fff' : '#fff',
        paddingLeft: state.isSelected ? '10px' : '30px',
        svg: {color: '#565958', fontSize: '12px', marginRight: '10px'}
    }),
};


export const customDropdownPaginationGreenStepsStyles = {
    control: (base, state) => ({
        ...base,
        borderRadius: '30px', cursor: 'pointer', minHeight: '35px', height: '35px',
        boxShadow: null,
        borderColor: 'transparent',
        backgroundColor: 'transparent', width: '120px', textAlign: 'center'
    }),
    valueContainer: (base) => ({...base, padding: '5px 0px 5px', '&>div':{color: '#000', fontSize: '16px'}, '& .m_list': {display: 'none'} }),
    input: (base) => ({...base, margin: '0px', color: '#000'}),
    menu: (base) => ({...base, borderRadius: '15px'}),
    group: (base) => ({...base, paddingLeft: '20px'}),
    groupHeading: (base) => ({...base, fontSize: '14px', paddingLeft: '0'}),
    menuList: (base) => ({...base, borderRadius: '15px', '& .m_select': {display: 'none'}}),
    indicatorSeparator: state => ({display: 'none'}),
    indicatorsContainer: (base) => ({...base, minHeight: '30px', '&>div': {color: '#009448'}}),
    option: (base, state) => ({
        ...base,
        cursor: 'pointer',
        color: state.isFocused || state.isSelected ? '#fff' : '#009448', fontSize: '14px',
        backgroundColor: state.isFocused || state.isSelected ? '#009448' : '#fff',
        textAlign: 'center'
    }),
};



export const customDropdownCO2Styles = {
    control: (base, state) => ({
        ...base,
        borderRadius: '20px',  cursor: 'pointer', minHeight: '30px', height: '30px',
        boxShadow: state.isFocused ? '0 0 0 1px #009448' : null,
        borderColor: state.isFocused ? '#009448 !important' : '#cccccc'
    }),
    valueContainer: (base) => ({...base, padding: '0 10px 0'}),
    input: (base) => ({...base, margin: '0px', padding: 0}),
    menu: (base) => ({...base, borderRadius: '15px'}),
    group: (base) => ({...base, paddingLeft: '20px'}),
    groupHeading: (base) => ({...base, fontSize: '14px', paddingLeft: '0'}),
    menuList: (base) => ({...base, borderRadius: '15px', padding: '0'}),
    indicatorSeparator: state => ({display: 'none'}),
    indicatorsContainer: (base, state) => ({...base,  '&>div': {color: '#4FC878', padding: '0 8px'}}),
    option: (base, state) => ({
        ...base,
        cursor: 'pointer',
        color: state.isFocused || state.isSelected ? '#fff' : '#4FC878', fontSize: '14px',
        backgroundColor: state.isFocused || state.isSelected ? '#4FC878' : '#fff',
    }),
};

export const customGreenStepDropdownStyles = (color = '#009448') => {
    return {
        control: (base, state) => ({
            ...base,
            borderRadius: '15px',  cursor: 'pointer',
            boxShadow: state.isFocused ? '0 0 0 1px #009448' : null,
            borderColor: state.isFocused ? '#009448 !important' : '#cccccc'
        }),
        valueContainer: (base) => ({...base, padding: '5px 10px 5px'}),
        input: (base) => ({...base, margin: '0px'}),
        menu: (base) => ({...base, borderRadius: '15px'}),
        group: (base) => ({...base, paddingLeft: '20px'}),
        groupHeading: (base) => ({...base, fontSize: '14px', paddingLeft: '0'}),
        menuList: (base) => ({...base, borderRadius: '15px'}),
        indicatorSeparator: state => ({display: 'none'}),
        indicatorsContainer: (base, state) => ({...base,  '&>div': {color: color}}),
        option: (base, state) => ({
            ...base,
            cursor: 'pointer',
            color: state.isFocused || state.isSelected ? '#fff' : color, fontSize: '14px',
            backgroundColor: state.isFocused || state.isSelected ? color : '#fff',
            display: 'grid',
            gridTemplateColumns: '1fr min-content',
            alignItems: "center",
            minHeight: "2.5rem", // adding extra height to accomodate buttons
            span: { cursor: "pointer" },
            button: { cursor: "pointer",borderColor: '#fff', borderRadius: '5px', color: '#009448' }
        }),
    }
};
