import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationIS from './locales/is/translation.json';
// the translations
const resources = {
  en: {
    translation: translationEN
  },
  is: {
    translation: translationIS
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("lang") ||  "is",
    fallbackLng: 'is',
    debug: false,
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false
    }
  });


export default i18n;