import React from "react"
import { Navigate } from 'react-router-dom'
import Pages403 from "../pages/pages-403"

export const PrivateRoute = ({ component: RouteComponent, permissions }) => {
  let data = JSON.parse(localStorage.getItem('p_user'));
  if(!data) return <Navigate to="/login" />;

  // let isAuthenticated = !!(data && data.user && data.access_token);
  let isAuthenticated = !!(data && data.user && data.companyId && data.access_token && data.roles && data.permission);
  if(!isAuthenticated) return <Navigate to="/login" />;

  let userHasRequiredPermission = !!(data && data.permission.includes(permissions));
  if (!userHasRequiredPermission) return <Pages403/>;

  if (isAuthenticated && userHasRequiredPermission) {
    return <RouteComponent />
  }

};

export const AuthRoute = ({ component: RouteComponent, permissions }) => {
  let data = JSON.parse(localStorage.getItem('p_user'));
  let isAuthenticated = !!(data && data.user && data.access_token && data.roles && data.permission);
  if(isAuthenticated) return <Navigate to="/" />;

  // let userHasRequiredPermission = !!(data && data.permission.includes(permissions))
  // if (!userHasRequiredPermission) return <Pages403/>;

  // if (isAuthenticated && userHasRequiredPermission) {
    return <RouteComponent />
  // }

};