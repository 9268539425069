import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Row, Modal, Button,} from "reactstrap"
import "./index.scss"
import {updateCompanyCategory} from "../../store/actions/company";
import {getCompanyCategories} from "../../store/actions/companyCategory";
import {LoaderSpinner} from "../../components/Common/Loader";


const AddCompany = (props) => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const {categories, loadingCategories} = useSelector( state => state.companyCategory);
  const [data, setData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(props.data && props.data.company_category_nxns ? props.data.company_category_nxns.map(t => t.company_category_id) : []);

  useEffect(() => {
    dispatch(getCompanyCategories());
  }, []);

  useEffect(() => {
    if(categories && categories.results) {
      setData(categories.results);
    }
  },[categories]);

  if (loadingCategories) return (
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{t('pluses_page.update_category')}</h5>
      </div>
      <div className="modal-body">
        <LoaderSpinner/>
      </div>
    </Modal>
  );

  const onCancel = () => { props.onCancel() };

  const onConfirm = () => {
    dispatch(updateCompanyCategory(props.data.id, selectedCategories));
    onCancel();
  };

  const onClickChildBtn = (categoryId) => {
    let selectedItems = [...selectedCategories];
    let index = selectedItems.indexOf(categoryId);
    (index === -1) ? selectedItems.push(categoryId) : selectedItems.splice(index, 1);
    setSelectedCategories(selectedItems);
  };

  const renderData = (data) => {
    let type = data && data.length>0 && data[0].sub_categories ? 'parent' : 'child';
    let items = data.map((item, index) => {

      let count = 0;
      if(type === 'parent' && item.sub_categories.length>0) {
        item.sub_categories.forEach(scat => {
          if(selectedCategories.find(x => x === scat.id)) {
            count++;
          }
        })
      }

      return <div className="col-4" key={"data"+index}>
        <Button
          className="company_category_btn"
          color={(selectedCategories.indexOf(item.id) !== -1) ? 'warning' : type === 'parent' ? 'primary' : 'info' }
          onClick={() => type === 'parent' ? setData(item.sub_categories) : onClickChildBtn(item.id)}
        >
          <img src={item.logo} className="company_category_img" alt=""/>
          {item.title}
          {count>0 && <span className="badge rounded-pill bg-danger company_category_badge">{count}</span>}
        </Button>
      </div>
    });

    if(type === 'child') {
      items.push(
        <div className="col-4" key={"data_back_btn"} onClick={() => setData(categories.results)}>
          <Button className="company_category_btn" color="danger">Back</Button>
        </div>
      );
    }
    return items;
  };


  return (
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"} style={{maxWidth: '900px', width: '100%'}}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{t('update')}</h5>
      </div>
      <div className="modal-body">
        <Row>
          {!data ? '' : renderData(data)}
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => onCancel()} className="btn btn-danger waves-effect" data-dismiss="modal">
          {t('close')}
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  );

};

export default AddCompany