import * as actionTypes from '../actionTypes';

const initialState = {
  loading: false,
  leafs: [],
  leafsCompany: [],
  leafsCompany2: [],
  leafsItemsCount: [],
  achievements: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function leafReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_LEAF_START:
      return  {
        ...state,
        loading: true,
        achievements: [],
      };
    case actionTypes.GET_LEAF_SUCCESS:
      return {
        ...state,
        leafs: action.data,
        loading: false
      };
    case actionTypes.GET_LEAF_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.GET_COMPANY_LEAF_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_COMPANY_LEAF_SUCCESS:
      let leafCompanies = {};
      action.data.results.map((val) =>
        leafCompanies[val.leaf_id] = {
          "leaf_items": val.leaf_items ? val.leaf_items.split(',').map(Number) : [],
          "users": val.approved_user_ids ? JSON.parse(val.approved_user_ids) : [],
          "approved": val.approved
        }
      );
      return {
        ...state,
        leafsCompany: leafCompanies,
        leafsCompany2: action.data.results,
        loading: false
      };
    case actionTypes.GET_COMPANY_LEAF_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.CREATE_OR_UPDATE_COMPANY_LEAF_START:
      return {
        ...state,
      };
    case actionTypes.CREATE_OR_UPDATE_COMPANY_LEAF_SUCCESS:
      let newStateC = Object.assign({}, state.leafsCompany);
      if(newStateC[action.data.results.leaf_id]) {
        newStateC[action.data.results.leaf_id]['users'] = action.data.results.approved_user_ids ? JSON.parse(action.data.results.approved_user_ids) : [];
        newStateC[action.data.results.leaf_id]['leaf_items'] = action.data.results.leaf_items ? action.data.results.leaf_items.split(',').map(Number) : [];
        newStateC[action.data.results.leaf_id]['approved'] = action.data.results.approved;
      } else {
        newStateC[action.data.results.leaf_id] = {
          "leaf_items": action.data.results.leaf_items ? action.data.results.leaf_items.split(',').map(Number) : [],
          "users": action.data.results.approved_user_ids ? JSON.parse(action.data.results.approved_user_ids) : [],
          "approved": action.data.results.approved
        }
      }
      state.leafsCompany = newStateC;
      // if(action.data.results && action.data.results.new_achievements) {state.achievements = action.data.results.new_achievements}
      return {
        ...state,
      };
    case actionTypes.CREATE_OR_UPDATE_COMPANY_LEAF_FAIL:
      return  {
        ...state,
      };

    case actionTypes.GET_LEAF_ITEMS_COUNT_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_LEAF_ITEMS_COUNT_SUCCESS:
      let leafItems = [];
      action.data.results.map((val, index) =>
        leafItems[index] = {
          "id": val.id,
          "name": val.name,
          "name_en": val.name_en,
          "name_pl": val.name_pl,
          "leaf_items": val.leaf_items.length
        }
      );
      return {
        ...state,
        leafsItemsCount: leafItems,
        loading: false
      };
    case actionTypes.GET_LEAF_ITEMS_COUNT_FAIL:
      return  {
        ...state,
        loading: false
      };

    default:
      return state;
  }
}
