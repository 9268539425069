import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getCompanyCategories = () => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_CATEGORY_START});
  try {
    const res = await axios.get('/company_category');
    dispatch({type: actionTypes.GET_COMPANY_CATEGORY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_CATEGORY_FAIL});
  }
};

export const createUpdateCompanyCategory = ({update, ...data}) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_COMPANY_CATEGORY_START : actionTypes.CREATE_COMPANY_CATEGORY_START});
  try {
    const res = await axios({
      url: update ? '/company_category/' + data.id : '/company_category',
      method: update ? 'PUT' : 'POST',
      data: { ...data }
    });
    dispatch({type: update ? actionTypes.UPDATE_COMPANY_CATEGORY_SUCCESS : actionTypes.CREATE_COMPANY_CATEGORY_SUCCESS, data: res.data});
    dispatch(getCompanyCategories());
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_COMPANY_CATEGORY_FAIL : actionTypes.CREATE_COMPANY_CATEGORY_FAIL, data: errorMessage});
  }
};


export const deleteCompanyCategory = ({...data}) => async dispatch => {
  dispatch({type: actionTypes.DELETE_COMPANY_CATEGORY_START});
  try {
    const res = await axios.delete('/company_category/' + data.id);
    dispatch({type: actionTypes.DELETE_COMPANY_CATEGORY_SUCCESS, data: res.data});
    dispatch(getCompanyCategories());
  } catch (error) {
    dispatch({type: actionTypes.DELETE_COMPANY_CATEGORY_FAIL});
  }
};
