import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  loadingMore: false,
  loadingSave: false,
  loadingCompanies: true,
  updatedStore: false,
  companies: [],
  companies2: [],
  company: [],
  error: false,
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function companyReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_COMPANY_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_COMPANY_SUCCESS:
      return {
        ...state,
        companies: action.data,
        loading: false
      };
    case actionTypes.GET_COMPANY_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.GET_COMPANY_ONLY_START:
      return  {
        ...state,
        loadingCompanies: true
      };
    case actionTypes.GET_COMPANY_ONLY_SUCCESS:
      return {
        ...state,
        companies2: action.data,
        loadingCompanies: false
      };
    case actionTypes.GET_COMPANY_ONLY_FAIL:
      return  {
        ...state,
        loadingCompanies: false
      };

    case actionTypes.GET_MORE_COMPANY_START:
      return  {
        ...state,
        loadingMore: true
      };
    case actionTypes.GET_MORE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: {
          ...state.companies,
          results: [...state.companies.results, ...action.data.results]
        },
        loadingMore: false
      };
    case actionTypes.GET_MORE_COMPANY_FAIL:
      return  {
        ...state,
        loadingMore: false
      };



    case actionTypes.CREATE_COMPANY_START:
      return  {
        ...state,
      };
    case actionTypes.CREATE_COMPANY_SUCCESS:
      return  {
        ...state,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_COMPANY_FAIL:
      return  {
        ...state,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.UPDATE_COMPANY_START:
      return  {
        ...state,
        updatedStore: false,
      };
    case actionTypes.UPDATE_COMPANY_SUCCESS:
      let objStore = JSON.parse(localStorage.getItem('p_user'));
      let copyStore = Object.assign({}, objStore);
      copyStore.companies[0] = action.data.results;
      localStorage.setItem('p_user', JSON.stringify(copyStore));

      return  {
        ...state,
        updatedStore: true,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_COMPANY_FAIL:
      return  {
        ...state,
        updatedStore: false,
        notification: { status: true, type: 'error', message: action.data }
      };



    default:
      return state;
  }
}
