import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getLaufidUsers = () => async dispatch => {
  dispatch({type: actionTypes.GET_USERS_START});
  try {
    const res = await axios.get('/user');
    dispatch({type: actionTypes.GET_USERS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_USERS_FAIL});
  }
};

export const getLaufidUsersByCompany = (companyId) => async dispatch => {
  dispatch({type: actionTypes.GET_USERS_BY_COMPANY_START});
  try {
    const res = await axios.get('/user/by_company/'+companyId);
    dispatch({type: actionTypes.GET_USERS_BY_COMPANY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_USERS_BY_COMPANY_FAIL});
  }
};

export const createUpdateLaufidUsers = (data, update) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_USERS_START : actionTypes.CREATE_USERS_START});
  try {
    const res = await axios({
      url: update ? '/user/' + data.id : '/user',
      method: update ? 'PUT' : 'POST',
      data: {...data}
    });
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Success', style:'success'});
    dispatch({type: update ? actionTypes.UPDATE_USERS_SUCCESS : actionTypes.CREATE_USERS_SUCCESS, data: res.data});
    dispatch(getLaufidUsers());
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_USERS_FAIL : actionTypes.CREATE_USERS_FAIL, data: errorMessage});
  }
};

export const checkUserExist = (email, phone) => async dispatch => {
  dispatch({type: actionTypes.CHECK_USER_EXIST_START});
  try {
    const res = await axios.get(`/registration/check_emailphone?email=${email}&phone=${phone}`);
    dispatch({type: actionTypes.CHECK_USER_EXIST_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'The phone or email already exist', style:'error'});
  }
};

export const updateUserProfile = ({...obj}) => async dispatch => {
  dispatch({type: actionTypes.UPDATE_USER_PROFILE_START});
  try {
    const res = await axios({
      url: '/user/'+obj.id,
      method: 'PUT',
      data: {...obj}
    });

    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Snið uppfært!', style:'success'});
    dispatch({type: actionTypes.UPDATE_USER_PROFILE_SUCCESS, data: res.data, userData: obj});
  } catch (error) {
    if (error.response) {
      const res = error.response.data;
      const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
      const errorMessage = res.message + ". " + message;
      dispatch({type: actionTypes.UPDATE_USER_PROFILE_FAIL, data: errorMessage});
      dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
    }
  }
};

export const deleteUser = ({...data}) => async dispatch => {
  dispatch({type: actionTypes.DELETE_USERS_START});
  try {
    const res = await axios.delete('/user/' + data.id);
    dispatch({type: actionTypes.DELETE_USERS_SUCCESS, data: res.data});
    dispatch(getLaufidUsers());
  } catch (error) {
    dispatch({type: actionTypes.DELETE_USERS_FAIL});
  }
};

export const getLoginActivityByUserID = (id) => async dispatch => {
  dispatch({type: actionTypes.GET_LOGIN_ACTIVITY_START});
  try {
    const res = await axios.get('/login_activity/by_user/'+id);
    dispatch({type: actionTypes.GET_LOGIN_ACTIVITY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_LOGIN_ACTIVITY_FAIL});
  }
};