import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Edit, KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import {Table, Button, UncontrolledTooltip, Collapse} from "reactstrap"

import DeleteButton from "../../components/Common/CustomButtons/DeleteButton";
import {LoaderSpinner} from "../../components/Common/Loader";
import {getGreenStepsNew, createUpdateGreenStep, deleteGreenStep, editGreenStepCategory} from "../../store/actions/greenStep";
import AddGreenStep from "./addGreenStep";
import EditCategory from "./editCategory";
import "./index.scss"

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const [alert, setAlert] = useState(null);
  const store = JSON.parse(localStorage.getItem('p_user'));
  const permission = store ? store.permission : [];
  const canCreate = permission.includes('admin_pluses_create');
  const canEdit = permission.includes('admin_pluses_edit');
  const canDelete = permission.includes('admin_pluses_delete');

  const {loading, greenSteps} = useSelector( state => state.greenStep );
  const [collapse, setCollapse] = useState([1]);

  useEffect(() => {
    dispatch(getGreenStepsNew());
  }, []);

  if (loading) return (<LoaderSpinner/>);

  const onSubmit = (data, update) => {
    dispatch(createUpdateGreenStep(data, update));
  };

  const onEditCategory = (data, update) => {
    dispatch(editGreenStepCategory(data, update));
  };

  const htmlAlert = (data, subCategory, mainCategoryIndex, subCategoryIndex, childName) => {
    setAlert(
      <AddGreenStep data={data} subCategory={subCategory} childName={childName} parent_index={mainCategoryIndex} child_index={subCategoryIndex} onCancel={() => setAlert(null)} onSubmit={(data, update) => onSubmit(data, update)}/>
    );
  };

  const editCategory = (data) => {
    setAlert(
      <EditCategory data={data} onCancel={() => setAlert(null)} onSubmit={(data, update) => onEditCategory(data, update)}/>
    );
  };

  const handleCollapseCategory = (item) => {
    let items = [...collapse];
    let index = items.indexOf(item);
    (index === -1) ? items.push(item) : items.splice(index, 1);
    setCollapse(items);
  };

  return (
    <div className="page-content greenStepAdminPage">
      {alert}
      <div className="row">
        <div className="col col-12">
          {canCreate && <Button color="success" className="mb-4" size="sm" style={{float: 'right'}} onClick={htmlAlert}>{t('pluses_page.add_new2')}</Button>}
        </div>
      </div>
      <div className="row">
        {!greenSteps.results ? '' :
          greenSteps.results.map((category, mainCategoryIndex) => {
            let isOpenAccordion = collapse.indexOf(category.id) >= 0;
            return (
              <div className="accordion" id="accordionExample" key={category.id + 'category'}>
                <div className="accordion-item">
                  <div className="accordion-button categoryTextColor categoryBorderColor" onClick={() => {handleCollapseCategory(category.id)}}>
                    {category.name} {isOpenAccordion ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                  </div>
                  {canEdit &&
                  <Button
                    id="tooltip_leaf_list_edit"
                    key={category.id + 'edit_category_btn'}
                    color="success"
                    size="sm"
                    style={{position: 'absolute', right: '20px', top: '14px', zIndex: '999'}}
                    onClick={() => editCategory(category)}
                  >
                    <Edit style={{fontSize: '12px'}}/>
                  </Button>
                  }
                  <Collapse id="collapseOne" className="accordion-collapse" isOpen={isOpenAccordion}>
                    <div className="accordion-body row">
                      <div className="table-responsive">
                        <Table className="table table-striped table-hover">
                          <thead className="table-light">
                            <tr>
                              <th>#</th>
                              <th>{t('pluses_page.title')}</th>
                              <th>{t('pluses_page.irrelevant_admin')}</th>
                              <th>Score</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {category.children_category.map((subCategory, subCategoryIndex) => {
                              return [
                                <tr key={subCategoryIndex + 'parent'} className="table-success">
                                  <td>{subCategoryIndex+1}</td>
                                  <td>{subCategory.name}</td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {canEdit &&
                                      <Button
                                        id="tooltip_leaf_list_edit"
                                        key={subCategory.id + 'edit_sub_category_btn'}
                                        color="success"
                                        size="sm"
                                        style={{marginRight: '10px'}}
                                        onClick={() => editCategory(subCategory)}
                                      >
                                        <Edit style={{fontSize: '12px'}}/>
                                      </Button>
                                    }
                                  </td>
                                </tr>,

                                !subCategory.new_green_steps ? '' :
                                  subCategory.new_green_steps.map((item, index) => {
                                    return <tr key={index + 'child'}>
                                      <td>{subCategoryIndex+1+'.'+(index+1)}</td>
                                      <td>{item.title}</td>
                                      <td>{item.irrelevant ? t('yes') : ''}</td>
                                      <td>{item.score}</td>
                                      <td width="120">

                                        {canEdit &&
                                        <Button
                                          id="tooltip_leaf_list_edit"
                                          key={item.id + 'type'}
                                          color="success"
                                          size="sm"
                                          style={{marginRight: '10px'}}
                                          onClick={() => htmlAlert(item, subCategory.name)}
                                        >
                                          <Edit style={{fontSize: '12px'}}/>
                                        </Button>}
                                        {canDelete &&
                                        <DeleteButton id="tooltip_leaf_list_delete" action={() => dispatch(deleteGreenStep(item.id))}/>}
                                      </td>
                                    </tr>
                                  }),

                                !subCategory.categories.length === 0 ? '' :
                                  subCategory.categories.map((childCategory, indexCategory) => {
                                    return <React.Fragment>
                                      <tr key={indexCategory + 'child'} className="table-success">
                                        <td>{subCategoryIndex+1+'.'+(indexCategory+1)}</td>
                                        <td>{childCategory.name}</td>
                                        <td></td>
                                        <td>{childCategory.total_score}</td>
                                        <td>
                                          {canEdit &&
                                            <Button
                                              id="tooltip_leaf_list_edit"
                                              key={childCategory.id + 'subCategory.categories_edit_btn'}
                                              color="success"
                                              size="sm"
                                              style={{marginRight: '10px'}}
                                              onClick={() => editCategory(childCategory)}
                                            >
                                              <Edit style={{fontSize: '12px'}}/>
                                            </Button>
                                            }
                                        </td>
                                      </tr>
                                        {
                                          childCategory.new_green_steps.map((categoryGreenStep, indexCgreenStep) => {
                                            return <tr key={indexCgreenStep + 'child'}>
                                              <td>{subCategoryIndex+1+'.'+(indexCgreenStep+1)}</td>
                                              <td>{categoryGreenStep.title}</td>
                                              <td>{categoryGreenStep.irrelevant ? t('yes') : ''}</td>
                                              <td>{categoryGreenStep.score}</td>
                                              <td width="120">

                                                {canEdit &&
                                                <Button
                                                  id="tooltip_leaf_list_edit"
                                                  key={categoryGreenStep.id + 'type'}
                                                  color="success"
                                                  size="sm"
                                                  style={{marginRight: '10px'}}
                                                  onClick={() => htmlAlert(categoryGreenStep, subCategory.name, mainCategoryIndex, subCategoryIndex, childCategory.name)}
                                                >
                                                  <Edit style={{fontSize: '12px'}}/>
                                                </Button>}
                                                {canDelete &&
                                                <DeleteButton id="tooltip_leaf_list_delete" action={() => dispatch(deleteGreenStep(categoryGreenStep.id))}/>}
                                              </td>
                                            </tr>
                                          })
                                        }
                                    </React.Fragment>
                                  })
                              ];
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            )
          })
        }
        <UncontrolledTooltip placement="bottom" target={'tooltip_leaf_list_edit'}>{t('edit')}</UncontrolledTooltip>
        <UncontrolledTooltip placement="bottom" target={'tooltip_leaf_list_delete'}>{t('delete')}</UncontrolledTooltip>
      </div>
    </div>
  )
};

export default Index
