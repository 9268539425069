/*eslint-disable*/
import React, {useEffect} from "react";

import imgHolder from "../../../assets/images/avatar.jpg";
import defaultImage from "../../../assets/images/company_placeholder.png";
import Swal from 'sweetalert';

import "./fileupload.scss"
import {useTranslation} from "react-i18next";
import {Label} from "reactstrap";

export default function PictureUpload(props) {
  const { t } = useTranslation();
  const [file, setFile] = React.useState(null);
  const [msg, setMsg] = React.useState(props.msg || t('upload_choose_file'));
  const [titleMsg, setTitleMsg] = React.useState(props.titleMsg || '');
  const [disable] = React.useState(props.disabled || false);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(props.imgHolder || defaultImage);

  useEffect(() => {
    if(props.msg && props.msg !== msg) {
      setMsg(props.msg);
    }
  },[props.msg]);


  useEffect(() => {
    props.imgHolder && props.imgHolder !== imagePreviewUrl && setImagePreviewUrl(props.imgHolder);
  }, [props.imgHolder]);

  const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    if (e.target.value.length == 0) {
      setImagePreviewUrl(defaultImage);
      setMsg(<div className="error" >{t('upload_not_selected')}</div>);
      return;
    }
    let newFile = e.target.files[0];
    var fileTypes = ['jpg', 'jpeg', 'png', 'svg'];  //acceptable file types
    var extension = newFile && newFile.name.split('.').pop().toLowerCase(),  //file extension from input file
      isSuccess = fileTypes.indexOf(extension) > -1;  //is extension in acceptable types

    if(newFile && newFile.size >= 800000) {
      Swal({text:t('upload_file_size')+'800KB', icon: "warning", timer: 4000, buttons: false});
     return;
    }

    if (!isSuccess) {
      Swal({text:t('upload_file_type')+' ('+fileTypes+')', icon: "warning", timer: 4000, buttons: false});
      return;
    }

    reader.onloadend = () => {
      setFile(newFile);
      setImagePreviewUrl(reader.result);
      setMsg(<div className="success">{t('upload_success')}</div>);
      props.onUpload(reader.result);
    };
    if (newFile) {
      reader.readAsDataURL(newFile);
    }
  };

  return (
    <div className="picture-container">
      {titleMsg && <h6 className="titleMsg">{titleMsg}</h6>}
      <div className="picture" >
        <div style={{ backgroundImage: "url(" + imagePreviewUrl + ")" }} className="picture-src" alt="..." />
        <input id="file" type="file" onChange={e => handleImageChange(e)} disabled={disable} title="Engin mynd valin"/>
      </div>
      <Label for="file" className="description" style={{cursor: 'pointer'}}>{msg}</Label>
    </div>
  );
}
