import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getPermissions = () => async dispatch => {
  dispatch({type: actionTypes.GET_PERMISSION_START});
  try {
    const res = await axios.get('/permission');
    dispatch({type: actionTypes.GET_PERMISSION_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_PERMISSION_FAIL});
  }
};

export const createUpdatePermission = (data, update) => async dispatch => {
  try {
    await axios({
      url: update ? '/permission/'+ data.id : '/permission',
      method: update ? 'PUT' : 'POST',
      data: {...data}
    });
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Heimildir vistað', style:'success'});
    dispatch(getPermissions());
  } catch (error) {
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};

export const deletePermission = ({...data}) => async dispatch => {
  try {
    await axios.delete('/permission/' + data.id);
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Heimildir eytt', style:'success'});
    dispatch(getPermissions());
  } catch (error) {
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};

export const getRoles = () => async dispatch => {
  dispatch({type: actionTypes.GET_ROLE_START});
  try {
    const res = await axios.get('/role');
    dispatch({type: actionTypes.GET_ROLE_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_ROLE_FAIL});
  }
};

export const createUpdateRole = (data, update) => async dispatch => {
  try {
    await axios({
      url: update ? '/role/'+ data.id : '/role',
      method: update ? 'PUT' : 'POST',
      data: {...data}
    });
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Hlutverk vistað', style:'success'});
    dispatch(getRoles());
  } catch (error) {
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};

export const deleteRole = ({...data}) => async dispatch => {
  try {
    await axios.delete('/role/' + data.id);
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Hlutverk eytt', style:'success'});
    dispatch(getRoles());
  } catch (error) {
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};

