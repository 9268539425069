import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { Link } from "react-router-dom"
import {Notifications} from "@mui/icons-material";

import faviconNotification from "../../../assets/images/favicon-not-32x32.png";
import {getNotifications} from "../../../store/actions/notification";

const Notification = () => {
  const dispatch = useDispatch();
  const userdata = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userdata?.companyId;
  const {notifications} = useSelector(state => state.notification);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if(notifications.length===0) {
      dispatch(getNotifications(companyId));
    }
  }, []);

  useEffect(() => {
    if(notifications && notifications.results) {
      let filter = notifications.results.filter(f => f.read_at === null);
      if(filter.length>0) {
        document.querySelector("link[rel~='icon']").href = faviconNotification;
        setCount(filter.length);
      } else {
        setCount(-1);
      }
    }
  }, [notifications]);


  return (
    <Link to="/announcements" className="notificationIcon">
      <Notifications className="topbar_icon"/>
      {count>0 && <span className="badge">{count > 99 ? '99+' : count}</span>}
    </Link>
  )
}

export default Notification

