import React from "react"
import { useTranslation } from 'react-i18next';
import {Row, Modal, Table} from "reactstrap"
import "./index.scss"
const LoginActivities = (props) => {
  const { t } = useTranslation();

  return (
    <Modal size="lg" isOpen={true} toggle={() => {props.onCancel()}}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{props.data.name}</h5>
      </div>
      <div className="modal-body">
        <Row style={{margin: '10px'}}>
          <Table className="table table-striped table-hover">
            <thead className="table-light">
              <tr>
                <th>#</th>
                <th>IP address</th>
                <th>User agent</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {
                !props.data.login_activities ? '' :
                  props.data.login_activities.map((value, index) => {
                    return (<tr key={index}>
                      <td>{index+1}</td>
                      <td>{value.ip_address}</td>
                      <td>{value.user_agent}</td>
                      <td>{value.updated_at}</td>
                    </tr>)
                  })
              }
            </tbody>
          </Table>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => props.onCancel()}
          className="btn btn-danger waves-effect"
          data-dismiss="modal"
        >
          {t('close')}
        </button>
      </div>
    </Modal>
  );

};

export default LoginActivities