import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Check} from '@mui/icons-material';
import {Input} from "reactstrap";

import {HelperGetCurrentDate} from "../../../../helpers/main";
import {editComment} from "../../../../store/actions/greenStep";

const GreenStep179 = (props) => {
  const dispatch = useDispatch();
  const {comment, item, commentIndex, greenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const [commentInput, setCommentInput] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if(props.item) {
      setCommentInput(item.comment)
    }
  }, [props.item]);

  const saveEditComment = () => {
    if(commentInput !== '') {
      let newComment = comment;
      newComment.splice(commentIndex, 1);
      newComment.unshift({
        "comment": commentInput,
        "user_id": userData.user.id,
        "name": userData.user.name,
        "date": HelperGetCurrentDate(),
        "customComment": true
      });

      dispatch(editComment(greenStep.new_company_green_steps[0].id, newComment, greenStep.main_category-1, 'comment'));
      props.close();
    } else {
      setError(true);
    }
  };

  return (
    <div className="row" style={{alignItems: 'center'}}>
      <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-xxl-8">
        <Input
          id="text"
          type="text"
          className="form-control goalField"
          value={commentInput}
          onChange={(event) =>{setCommentInput(event.target.value)}}
          invalid={error && commentInput.length === 0}
        />
      </div>
      <div className="col-2">
        <div className="companyCompletedIcon categoryBorderColor true" onClick={()=>saveEditComment()}><Check/></div>
      </div>
    </div>
  )
};

export default GreenStep179