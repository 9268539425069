import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getCompanyEnvironmentals = () => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_ENV_START});
  try {
    const res = await axios.get('/company_environmental_policy');
    dispatch({type: actionTypes.GET_COMPANY_ENV_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_ENV_FAIL});
  }
};

export const getCompanyEnvironmentalByCompany = (company_id) => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_ENV_BY_COMPANY_START});
  try {
    const res = await axios.get('/company_environmental_policy/by_company/'+company_id);
    dispatch({type: actionTypes.GET_COMPANY_ENV_BY_COMPANY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_ENV_BY_COMPANY_FAIL});
  }
};

export const saveCompanyEnvironmentalPDF = (data, company_id) => async dispatch => {
  dispatch({type: actionTypes.UPLOAD_COMPANY_ENV_PDF_START});
  try {
    const res = await axios({
      url: '/company_environmental_policy',
      method: 'POST',
      data: {
        "company_id": company_id,
        "pdf": data,
        "approved": true
      }
    });
    dispatch({type: actionTypes.UPLOAD_COMPANY_ENV_PDF_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Breytingar vistaðar', style:'success'});
  } catch (error) {
    dispatch({type: actionTypes.UPLOAD_COMPANY_ENV_PDF_FAIL, data: 'errorMessage'});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Currently cannot upload at that size, Try to upload file less than 1 MB', style:'error'});
  }
};

export const deleteCompanyEnvironmental = (id, type) => async dispatch => {
  dispatch({type: actionTypes.DELETE_COMPANY_ENV_START});
  try {
    const res = await axios.delete('/company_environmental_policy/' + id);
    dispatch({type: actionTypes.DELETE_COMPANY_ENV_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Company environmental policy deleted!', style:'success'});
    if(type === 'leaves') {
      dispatch(getCompanyEnvironmentalByCompany(res.data.results.company_id));
    } else {
      dispatch(getCompanyEnvironmentals());
    }
  } catch (error) {
    dispatch({type: actionTypes.DELETE_COMPANY_ENV_FAIL});
  }
};

export const updateCompanyEnvironmentalStatus = (id, status, user) => async dispatch => {
  dispatch({type: actionTypes.UPDATE_COMPANY_ENV_START});
  try {
    const res = await axios({
      url: '/company_environmental_policy/'+id,
      method: 'PUT',
      data: {
        'approved' : status,
        'approved_by' : user
      }
    });
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Breytingar vistaðar', style:'success'});
    dispatch({type: actionTypes.UPDATE_COMPANY_ENV_SUCCESS, data: res.data});
    dispatch(getCompanyEnvironmentals());
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.UPDATE_COMPANY_ENV_FAIL, data: errorMessage});
  }
};
