import React, {useEffect, useState} from "react"
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Row, Modal, FormGroup, Label,} from "reactstrap"
import TextField from '@mui/material/TextField';
import Select from "react-select";
import Switch from "react-switch";

import {getGreenStepsCategories} from "../../store/actions/greenStep";
import {LoaderSpinner} from "../../components/Common/Loader";
import "./index.scss"
import TextareaAutosize from "react-textarea-autosize";

const AddGreenStep = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const {loadingCategories, greenStepsCategories} = useSelector( state => state.greenStep );
  const [state, setState] = useState({
    id: props.data.id || null,
    title: props.data.title || "",
    title_en: props.data.title_en || "",
    titleState: false,
    description: props.data.description || "",
    description_en: props.data.description_en || "",
    pdf_description: props.data.pdf_description || "",
    pdf_description2: props.data.pdf_description2 || "",
    pdf_description_en: props.data.pdf_description_en || "",
    pdf_description2_en: props.data.pdf_description2_en || "",
    slider_description: props.data.slider_description || "",
    slider_description_en: props.data.slider_description_en || "",
    sort_order: props.data.sort_order || "",
    new_green_step_category_id: props.data.new_green_step_category_id || '',
    green_step_category_name: props.subCategory || '',
    new_green_step_category_id2: props.parent_index>= 0 && props.child_index >= 0 ? props.data.new_green_step_category_id : '',
    green_step_category_name2: props.childName || '',
    child_index: props.child_index >= 0 ? props.child_index : '',
    parent_index: props.parent_index>= 0 ? props.parent_index : '',
    main_category: props.data.main_category || null,
    score: props.data.score || null,
    selectError: false,
    irrelevant: props.data.irrelevant || false,
    type: props.data.irrelevant || null
  });


  useEffect(() => {
    greenStepsCategories.length === 0 && dispatch(getGreenStepsCategories());
  }, []);

  if (loadingCategories) return(
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{t('pluses_page.update_category')}</h5>
      </div>
      <div className="modal-body">
        <LoaderSpinner/>
      </div>
    </Modal>
  );

  const handleSelectCategory = (event) => {
    setState({
      ...state,
      'selectError': true,
      'new_green_step_category_id': event.value,
      'green_step_category_name': event.label,
      'child_index': event.index,
      'parent_index': event.parentIndex,
      'main_category': event.parentId
    });
  };

  const handleSelectSubCategory = (event) => {
    setState({
      ...state,
      'selectError': true,
      'new_green_step_category_id2': event.value,
      'green_step_category_name2': event.label
    });
  };

  const verifyLength = (value, length) => {
    return value.length < length;
  };

  const isValidated = () => {
    if (state.title.length > 3  && state.new_green_step_category_id){
      return true;
    } else {
      setState({
        ...state,
        selectError: !state.new_green_step_category_id,
        titleState: state.title.length < 3
      });
      return false;
    }
  };

  const onConfirm = () => {
    if (isValidated()) {
      if(state.new_green_step_category_id2 !== '' && state.green_step_category_name2 !== '') {
        let newState = state;
        newState["new_green_step_category_id"] = newState.new_green_step_category_id2;
        newState["green_step_category_name"] = newState.green_step_category_name2;
        props.onSubmit(newState, !!newState.id);
      } else {
        props.onSubmit(state, !!state.id);
      }
      props.onCancel();
    }
  };

  const change = (event, stateName, type, stateNameEqualTo) => {
    let status = false;
    if(type === 'length') {
      status = verifyLength(event.target.value, stateNameEqualTo);
    }
    setState({
      ...state,
      [stateName]: event.target.value,
      [stateName+'State']: status,
    });
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <Modal isOpen={true} size="lg" toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{state.id ? t('pluses_page.update') : t('pluses_page.add_new')}</h5>
      </div>
      <div className="modal-body greenStepAdminPageAdd">
        <div className="row">
          <div className="col col-12">
            <FormGroup className="mb-4">
              <Label className="col-form-label">{t('pluses_page.categories')}</Label>
              <Select
                isSearchable={false}
                value={{label: state.green_step_category_name, value: state.new_green_step_category_id}}
                placeholder={t('plan_page.select')}
                className={`react-select ${state.selectError && 'has-error'}`}
                onChange={(event) => handleSelectCategory(event)}
                options={
                  greenStepsCategories.results && greenStepsCategories.results.map((item, itemIndex) => {
                    return {label: item.name,
                      options: item.children_category.map((item2, item2Index) => {
                        return {label: item2.name, value: item2.id, index: item2Index, parentId:item.id, parentIndex: itemIndex}
                      })
                    }
                  })
                }
              />
            </FormGroup>

            <FormGroup className="mb-4">
              <Label className="col-form-label">Categories:</Label>
              {greenStepsCategories.results && state.parent_index !== '' && state.child_index !== '' &&
              greenStepsCategories.results[state.parent_index].children_category[state.child_index].categories.length> 0 &&
                <Select
                  isSearchable={false}
                  value={{label: state.green_step_category_name2, value: state.new_green_step_category_id2}}
                  placeholder={t('plan_page.select')}
                  className={`react-select`}
                  onChange={(event) => handleSelectSubCategory(event)}
                  options={
                    greenStepsCategories.results[state.parent_index].children_category[state.child_index].categories.map(item => {
                      return {label: item.name, value: item.id}
                    })
                  }
                />
              }
            </FormGroup>
          </div>
        </div>

        {/*<div className="row mb-4">*/}
        {/*  <div className="col-12">*/}
        {/*    <Label className="form_label">Tegund reksturs</Label>*/}
        {/*    <Select*/}
        {/*      isSearchable={false}*/}
        {/*      placeholder={t('plan_page.select')}*/}
        {/*      value={state.type === null ? {label: 'Allur rekstur', value: null} : {label: 'Fyrirtæki', value: "company"}}*/}
        {/*      onChange={(event) => setState({...state, "type": event.value})}*/}
        {/*      options={[{label: 'Fyrirtæki', value: "company"}, {label: 'Allur rekstur', value: null}]}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</div>*/}

        <Row className="justify-content-evenly">
          <div className="col-6 mb-3">
            <FormGroup row>
              <Label className="col-form-label">{t('pluses_page.title')}  <small>({t('required')})</small></Label>
              <TextareaAutosize
                onChange={(e) => {
                  change(e, 'title', "length", 3)
                }}
                value={state.title}
                minRows={3}
                maxRows={6}
              />
            </FormGroup>
          </div>
          <div className="col-5 mb-3">
            <FormGroup row>
              <Label className="col-form-label">{t('pluses_page.title_en')}</Label>
              <TextareaAutosize
                onChange={(e) => {
                  change(e, 'title_en', "length", 3)
                }}
                value={state.title_en}
                minRows={3}
                maxRows={6}
              />
            </FormGroup>
          </div>
        </Row>

        <Row className="justify-content-evenly">
          <div className="col-6 mb-3">
            <FormGroup row>
              <Label className="col-form-label">{t('pluses_page.description')}</Label>
              <TextareaAutosize
                onChange={(e) => {
                  change(e, 'description', "length", 3)
                }}
                value={state.description}
                minRows={3}
                maxRows={6}
              />
            </FormGroup>
          </div>
          <div className="col-5 mb-3">
            <FormGroup row>
              <Label className="col-form-label">{t('pluses_page.description_en')}</Label>
              <TextareaAutosize
                onChange={(e) => {
                  change(e, 'description_en', "length", 3)
                }}
                value={state.description_en}
                minRows={3}
                maxRows={6}
              />
            </FormGroup>
          </div>
        </Row>

        <Row className="justify-content-evenly">
          <div className="col-6 mb-3">
            <FormGroup row>
              <Label className="col-form-label">PDF {t('pluses_page.description')}</Label>
              <TextareaAutosize
                onChange={(e) => {change(e, 'pdf_description')}}
                value={state.pdf_description}
                minRows={1}
                maxRows={3}
              />
            </FormGroup>
          </div>
          <div className="col-5 mb-3">
            <FormGroup row>
              <Label className="col-form-label">PDF {t('pluses_page.description_en')}</Label>
              <TextareaAutosize
                onChange={(e) => {change(e, 'pdf_description_en')}}
                value={state.pdf_description_en}
                minRows={1}
                maxRows={3}
              />
            </FormGroup>
          </div>
        </Row>

        <Row className="justify-content-evenly">
          <div className="col-6 mb-3">
            <FormGroup row>
              <Label className="col-form-label">PDF {t('pluses_page.description')} 2</Label>
              <TextareaAutosize
                onChange={(e) => {change(e, 'pdf_description2')}}
                value={state.pdf_description2}
                minRows={1}
                maxRows={3}
              />
            </FormGroup>
          </div>
          <div className="col-5 mb-3">
            <FormGroup row>
              <Label className="col-form-label">PDF {t('pluses_page.description_en')} 2</Label>
              <TextareaAutosize
                onChange={(e) => {change(e, 'pdf_description2_en')}}
                value={state.pdf_description2_en}
                minRows={1}
                maxRows={3}
              />
            </FormGroup>
          </div>
        </Row>

        <Row className="justify-content-evenly">
          <div className="col-6 mb-3">
            <FormGroup row>
              <Label className="col-form-label">{t('slider description')}</Label>
              <TextareaAutosize
                onChange={(e) => {change(e, 'slider_description')}}
                value={state.slider_description}
                minRows={1}
                maxRows={3}
              />
            </FormGroup>
          </div>
          <div className="col-5 mb-3">
            <FormGroup row>
              <Label className="col-form-label">{t('slider description en')}</Label>
              <TextareaAutosize
                onChange={(e) => {change(e, 'slider_description_en')}}
                value={state.slider_description_en}
                minRows={1}
                maxRows={3}
              />
            </FormGroup>
          </div>
        </Row>

        <Row className="justify-content-evenly">
          <div className="col-2 mb-3">
            <TextField
              className="textField"
              label={ <span> Score </span> }
              variant="standard"
              fullWidth
              value={state.score}
              onChange={event => change(event, 'score')}
            />
          </div>

          <div className="col-2 mb-3">
            <TextField
              className="textField"
              label={ <span> {t('pluses_page.sort_order')} </span> }
              variant="standard"
              fullWidth
              value={state.sort_order}
              onChange={event => change(event, 'sort_order')}
            />
          </div>

          <div className="col-md-3 mb-3">
            <FormGroup row>
              <label className="form-label irrel_text">{t('pluses_page.irrelevant_admin')}</label>
              <Switch
                uncheckedIcon={<div className="swithStyle"> {" "} No </div>}
                checkedIcon={<div className="swithStyle">{" "}Yes</div>}
                onColor="#009369"
                className="form-label irrelevant_checkbox"
                onChange={() => { setState({...state, 'irrelevant': !state.irrelevant})}}
                checked={state.irrelevant}
              />
            </FormGroup>
          </div>
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => onCancel()} className="btn btn-danger waves-effect" data-dismiss="modal">
          {t('close')}
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  );

};

export default AddGreenStep