import React, {useEffect, useState} from "react"
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {Modal, Label} from "reactstrap"
import Select from "react-select";
import TextareaAutosize from "react-textarea-autosize";

import {customDropdownStyles} from "../../helpers/dropdown";
import {getGreenStepsNew} from "../../store/actions/greenStep";
import {LoaderSpinner} from "../../components/Common/Loader";
import {createNotification} from "../../store/actions/notification";
import "./index.scss"

const AddAnnouncement = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {loading, greenSteps} = useSelector( state => state.greenStep );
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;


  const [type, setType] = useState();
  const [notificationType, setNotificationType] = useState({label: 'All', value: 'all'});
  const [greenStepMainCategory, setGreenStepMainCategory] = useState(false);
  const [greenStepSubCategory, setGreenStepSubCategory] = useState(false);
  const [greenStepItem, setGreenStepItem] = useState(false);
  const [desc, setDesc] = useState('');
  const [email, setEmail] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);

  useEffect(() => {
    if(greenSteps.length === 0) {
      dispatch(getGreenStepsNew(companyId));
    }
  }, []);

  const isValidated = () => {
    if (type && desc.length>3) {
      return true
    } else {
      setErrorStatus(true);
      return false;
    }
  };

  const onConfirm = () => {
    if (isValidated()) {
      let saveData = {
        data: {
          type: type.label,
          greenStepMainCategory: greenStepMainCategory,
          greenStepSubCategory: greenStepSubCategory,
          greenStepId: greenStepItem ? greenStepItem.value : 0,
          description: desc
        },
        notifyes: notificationType.value,
        email: email
      }
      dispatch(createNotification(saveData))
      props.onCancel();
    }
  };

  const onCancel = () => {
    props.onCancel();
  };

  const onChangeType = (event) => {
    let tempDesc = event.value === 'new_step' ? 'Skrefinu' : 'Skrefið';
    setType(event);
    setDesc(tempDesc);
  };

  const onChangeGreenStep = (event) => {
    let tempDesc = type.value === 'new_step' ? 'Skrefinu' : 'Skrefið';
    tempDesc += ` <span className="categoryTextColor">"${event.label}"</span> `
    tempDesc += type.value === 'updated_step' ? 'hefur verið uppfært.' : type.value === 'expired_step' ? 'er útrunnið.' : 'hefur verið bætt við.'
    setGreenStepItem(event);
    setDesc(tempDesc);
  };

  if (loading) return (
    <Modal isOpen={true} centered toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header"> <h5 className="modal-title">{t('announcements')}</h5> </div>
      <div className="modal-body"> <LoaderSpinner/> </div>
    </Modal>
  );

  return (
    <Modal isOpen={true} size="lg" className="addAnnouncementModal" toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">Add new announcement</h5>
      </div>
      <div className="modal-body">

        <div className="row">
          <div className={"col-6"}>
            <Label className="form_label">Type</Label>
            <Select
              isSearchable={false}
              placeholder={t('plan_page.select')}
              value={type || {}}
              onChange={(event) => onChangeType(event)}
              styles={customDropdownStyles}
              options={[
                {label: 'Uppfært skref', value: 'updated_step'},
                {label: 'Útrunnið skref', value: "expired_step"},
                {label: 'Nýtt skref', value: "new_step"}
              ]}
              className={`react-select ${errorStatus && !type && 'has-error'}`}
            />
          </div>

          <div className={"col-6"}>
            <Label className="form_label">Notification Type</Label>
            <Select
              isSearchable={false}
              placeholder={t('plan_page.select')}
              value={notificationType}
              onChange={(event) => setNotificationType(event)}
              styles={customDropdownStyles}
              options={[
                {label: 'All', value: 'all'},
                {label: 'Companies', value: "companies"},
                {label: 'Municipalities', value: "municipalities"}
              ]}
            />
          </div>
        </div>

        <div className="row">
          <div className={"col-3"}>
            <Label className="form_label">{t('adgerdir')}</Label>
            <Select
              placeholder={t('plan_page.select')}
              value={greenStepMainCategory || {}}
              onChange={(event) => setGreenStepMainCategory(event)}
              styles={customDropdownStyles}
              options={greenSteps && greenSteps.results && greenSteps.results.map((item, index) => { return {label: item.name, value: item.id, index: index } })}
            />
          </div>

          <div className={"col-3"}>
            <Label className="form_empty"></Label>
            <Select
              placeholder={t('plan_page.select')}
              value={greenStepSubCategory || {}}
              onChange={(event) => setGreenStepSubCategory(event)}
              styles={customDropdownStyles}
              options={greenStepMainCategory ?
                greenSteps.results[greenStepMainCategory.index].children_category.map((item, index) => {
                  return {label: item.name, value: item.id, index: index}
                })
                : []
              }
            />
          </div>

          <div className={"col-6"}>
            <Label className="form_empty"></Label>
            <Select
              placeholder={t('plan_page.select')}
              value={greenStepItem || {}}
              onChange={(event) => onChangeGreenStep(event)}
              styles={customDropdownStyles}
              options={greenStepMainCategory && greenStepSubCategory ?
                greenSteps.results[greenStepMainCategory.index].children_category[greenStepSubCategory.index].new_green_steps.map((item, index) => {
                  return {label: item.title, value: item.id, index: index}
                })
                : []
              }
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <TextareaAutosize
              onChange={event => setDesc(event.target.value)}
              value={desc}
              minRows={6}
              maxRows={10}
              className={errorStatus && desc.length<3 ? 'error' : ''}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-check mt-2">
            <input className="form-check-input" type="checkbox" onChange={() => {setEmail(!email)}} checked={email}/>
            <label className="form-check-label"> Send email</label>
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" onClick={() => onCancel()} className="btn btn-danger waves-effect" data-dismiss="modal">
          {t('close')}
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  );

};

export default AddAnnouncement