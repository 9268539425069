import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  companyEnvs: [],
  companyEnv: {},
  loadingUpload: false,
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function categoryReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_COMPANY_ENV_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_COMPANY_ENV_SUCCESS:
      return {
        ...state,
        companyEnvs: action.data,
        loading: false
      };
    case actionTypes.GET_COMPANY_ENV_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.UPLOAD_COMPANY_ENV_PDF_START:
      return  {
        ...state,
        loadingUpload: true
      };
    case actionTypes.UPLOAD_COMPANY_ENV_PDF_SUCCESS:
      let newStateEnv = Object.assign({}, state.companyEnv);
      newStateEnv['pdf'] =  action.data.results.pdf;
      return {
        ...state,
        loadingUpload: false,
        companyEnv: newStateEnv,
      };
    case actionTypes.UPLOAD_COMPANY_ENV_PDF_FAIL:
      return  {
        ...state,
        loadingUpload: false,
      };


    case actionTypes.GET_COMPANY_ENV_BY_COMPANY_START:
      return  {
        ...state
      };
    case actionTypes.GET_COMPANY_ENV_BY_COMPANY_SUCCESS:
      return {
        ...state,
        companyEnv: action.data.results,
      };
    case actionTypes.GET_COMPANY_ENV_BY_COMPANY_FAIL:
      return  {
        ...state,
        companyEnv: {},
      };

    default:
      return state;
  }
}
