import { combineReducers } from "redux"

import auth from './reducers/auth'
import dashboard from './reducers/dashboard'
import snackbar from './reducers/snackbar'
import leaf from './reducers/leaf'
import lauf from './reducers/lauf'
import greenStep from './reducers/greenStep'
import actionPlan from './reducers/actionPlan'
import company from './reducers/company'
import companyCategory from './reducers/companyCategory'
import companyEnvironmental from './reducers/companyEnvironmental'
import companySetting from './reducers/companySetting'
import user from './reducers/user'
import story from './reducers/story'
import plan from './reducers/plan'
import certificate from './reducers/certificate'
import notification from './reducers/notification'
import registration from "./reducers/registration"
import acl from './reducers/acl'
import activityLog from './reducers/activityLog'
import municipality from './reducers/municipality'
import carbon from './reducers/carbon'

export default combineReducers({
  auth,
  dashboard,
  snackbar,
  leaf,
  lauf,
  greenStep,
  actionPlan,
  company,
  companyCategory,
  companyEnvironmental,
  companySetting,
  user,
  story,
  plan,
  certificate,
  notification,
  registration,
  acl,
  activityLog,
  municipality,
  carbon
})