import * as actionTypes from '../actionTypes';

const initialState = {
  loading: false,
  laufs: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function laufReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_LAUF_BY_CATEGORY_START:
      return  {
        ...state,
        loading: true,
        achievements: [],
      };
    case actionTypes.GET_LAUF_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        laufs: action.data,
        loading: false
      };
    case actionTypes.GET_LAUF_BY_CATEGORY_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.CREATE_COMPANY_LEAF_START:
      return  {
        ...state
      };
    case actionTypes.CREATE_COMPANY_LEAF_SUCCESS:
      let newCopyLeaf = {...state.laufs};
      if(action.categoryType === 'laufs') {
        let leafIndex = newCopyLeaf.results[action.activeCategory].laufs.findIndex(item => item.id === action.lauf_id);
        newCopyLeaf.results[action.activeCategory].laufs[leafIndex].company_laufs = [action.data.results];
      } else {
        let categoryIndex = newCopyLeaf.results[action.activeCategory].sub_category.findIndex(item => item.id === action.category);
        let leafIndex = newCopyLeaf.results[action.activeCategory].sub_category[categoryIndex].laufs.findIndex(item => item.id === action.data.results.lauf_id);
        newCopyLeaf.results[action.activeCategory].sub_category[categoryIndex].laufs[leafIndex].company_laufs = [action.data.results];
      }
      return {
        ...state,
        laufs: newCopyLeaf
      };
    case actionTypes.CREATE_COMPANY_LEAF_FAIL:
      return  {
        ...state
      };



    case actionTypes.DELETE_COMPANY_LEAF_START:
      return  {
        ...state
      };
    case actionTypes.DELETE_COMPANY_LEAF_SUCCESS:
      let copyLaufs = {...state.laufs};
      if(action.categoryType === 'laufs') {
        let newLeafIndex = copyLaufs.results[action.category].laufs.findIndex(item => item.id === action.data.results.lauf_id);
        copyLaufs.results[action.category].laufs[newLeafIndex].company_laufs = [{...action.data.results, "deletedItem": true}];
      } else {
        let categoryIndex = copyLaufs.results[action.category].sub_category.findIndex(item => item.id === action.laufCategoryId);
        let newLeafIndex = copyLaufs.results[action.category].sub_category[categoryIndex].laufs.findIndex(item => item.id === action.data.results.lauf_id);
        copyLaufs.results[action.category].sub_category[categoryIndex].laufs[newLeafIndex].company_laufs = [{...action.data.results, "deletedItem": true}];
      }
      return {
        ...state,
        laufs: copyLaufs
      };
    case actionTypes.DELETE_COMPANY_LEAF_FAIL:
      return  {
        ...state
      };


    case actionTypes.EDIT_COMPANY_LEAF_COMMENT_START:
      return  {
        ...state
      };
    case actionTypes.EDIT_COMPANY_LEAF_COMMENT_SUCCESS:
      let newCommentLauf = {...state.laufs};
      if(action.categoryType === 'laufs') {
        let foundedComment = newCommentLauf.results[action.category].laufs.findIndex(item => item.id === action.data.results.lauf_id);
        newCommentLauf.results[action.category].laufs[foundedComment].company_laufs[0]["comment"] = action.data.results["comment"];
      } else {
        let categoryIndex = newCommentLauf.results[action.category].sub_category.findIndex(item => item.id === action.laufCategoryId);
        let newLeafIndex = newCommentLauf.results[action.category].sub_category[categoryIndex].laufs.findIndex(item => item.id === action.data.results.lauf_id);
        newCommentLauf.results[action.category].sub_category[categoryIndex].laufs[newLeafIndex].company_laufs[0]["comment"] = action.data.results["comment"];
      }
      return {
        ...state,
        laufs: newCommentLauf,
      };
    case actionTypes.EDIT_COMPANY_LEAF_COMMENT_FAIL:
      return  {
        ...state
      };


    default:
      return state;
  }
}
