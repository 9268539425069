import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {Check} from '@mui/icons-material';
import {useNavigate} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {Card, CardBody, CardTitle, Input, FormFeedback} from "reactstrap"

import {getRegistrationsPlan, getRegistrationsByKennitala, registerCompany} from "../../store/actions/registration";
import {getMunicipalities, getInstitutions} from "../../store/actions/municipality";
import Snackbar from "../../components/Common/Snackbar";
import {customDropdownSmallStyles} from "../../helpers/dropdown";
import {checkUserExist} from "../../store/actions/user";

const verifyEmail = (value) => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRex.test(value);
};

const defaultData = {
  name: "",
  nameValid: false,
  email: "",
  emailValid: false,
  phone: "",
  phoneValid: false,
  type: "",
  typeValid: false,
  municipalityItem: {},
  municipalityId: null,
  municipalityValid: false,
  institutionItem: {},
  institutionId: null,
  institutionValid: false,
  kennitala: "",
  kennitalaValid: false,

  companyId: '',
  companyName: '',
  companyAddress: '',
  companyCity: '',
  companyZip: '',
  companyPhone: '',
  companyWebsite: '',

  planId: 0,
  planItem: {},
  planPeriod: '',
  showStep3Part2: false,
  term: false
};

const Registration = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {plans, companyKennitalaData, continueBtn, finishedStep} = useSelector( state => state.registration );
  const {municipalities, institutions} = useSelector( state => state.municipality );
  const {checkUserExistRes} = useSelector( state => state.user );

  const [activeStep, setActiveStep] = useState(1);
  const [errorStatus, setErrorStatus] = useState(false);

  const [state, setState] = useState(defaultData);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Registration | Laufið";
    document.body.className = "authentication-bg";

    dispatch(getRegistrationsPlan());
    dispatch(getMunicipalities());
  }, []);

  useEffect(() => {
    if(companyKennitalaData && companyKennitalaData.source === '1819' && companyKennitalaData.results.length>0) {
      let res = companyKennitalaData.results[0];
      setState({
        ...state,
        companyName: res.name || '',
        companyAddress: res.address || '',
        companyCity: res.city || '',
        companyZip: res.zip || '',
        companyPhone: res.phone || '',
        companyWebsite: res.website || ''
      });
      // setActiveStep(2);
    }
    if(companyKennitalaData && companyKennitalaData.source === 'thjodskra' && companyKennitalaData.results) {
      let res = companyKennitalaData.results;
      setState({
        ...state,
        companyName: res.name || '',
        companyAddress: res.address || '',
        companyCity: res.city || '',
        companyZip: res.zip || '',
        companyPhone: res.phone || '',
        companyWebsite: res.website || ''
      });
      // setActiveStep(2);
    }
  }, [companyKennitalaData]);

  useEffect(() => {
    if(checkUserExistRes && continueBtn) {
      if(checkUserExistRes.message === "You can continue") {
        setActiveStep(2);
      }
    }
  }, [checkUserExistRes, continueBtn]);


  useEffect(() => {
    if(finishedStep === true) {
      setState(defaultData);
      setActiveStep(4);
    }
  }, [finishedStep]);


  const change = (event, stateName) => {
    setState({...state, [stateName]: event.target.value});
  };

  const changePlan = (plan) => {
    setState({...state, planId: plan.id, planItem: plan});
  };

  const selectMunicipality = (item) => {
    setState({...state, municipalityId: item.value, municipalityItem: item});
    dispatch(getInstitutions(item.value));
  };

  const selectInstitution = (item) => {
    setState({...state, institutionId: item.value, institutionItem: item});
  };

  const isValidated = () => {
    let data = {...state};
    data['nameValid'] = state.name.length > 3;
    data['emailValid'] = verifyEmail(state.email);
    data['phoneValid'] = (/^[0-9]+$/i.test(state.phone));
    data['typeValid'] = state.type.length > 3;
    data['kennitalaValid'] = (/^[0-9]+$/i.test(state.kennitala));
    data['municipalityValid'] =  state.municipalityId > 0;
    data['institutionValid'] = state.institutionId > 0;
    setState(data);

    if(data.nameValid && data.emailValid && data.phoneValid && data.typeValid &&
      ((state.type === 'company' && data.kennitalaValid) || (state.type === 'municipality' && data.municipalityValid && data.institutionValid))
    ) {
      return true
    } else {
      setErrorStatus(true);
      return false;
    }
  };

  const onConfirmSteps1 = () => {
    if (isValidated()) {
      if(state.type === 'company') {
        dispatch(getRegistrationsByKennitala(state.kennitala));
        dispatch(checkUserExist(state.email, state.phone));
      } else {
        onFinish();
      }
    }
  };

  const onFinish = () => {
    if(isValidated() && state.planId >0) {
      let saveData = {
        "user_name": state.name,
        "user_email": state.email,
        "user_phone": state.phone,
      };

      if(state.type === 'company') {
        saveData["company_name"] = state.companyName;
        saveData["company_kennitala"] = state.kennitala;
        saveData["plan_id"] = state.planId;
        saveData["billing_period"] = state.planPeriod;
      }

      if(state.type === 'municipality') {
        saveData["institution_id"] = state.institutionId;
      }

      dispatch(registerCompany(saveData));
    }
  };

  const openLoginPage = () => {
    setActiveStep(1);
    setState(defaultData);
    window.location = '/login';
  };

  const goBack = (index) => {
    if(continueBtn) {
      setActiveStep(index);
    }
  };

  const onCancel = () => {
    navigate('/login');
  };

  return (
    <React.Fragment>
      <Snackbar />
      <div className="registration-page">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-10 col-xl-7 col-xxl-7">
            <Card>
              <CardTitle>
                <div className="steps">
                  <div className={`step ${activeStep === 1 && 'active'}`} onClick={() => goBack(1)}>1</div>
                  <div className={`step ${activeStep === 2 && 'active'}`} onClick={() => goBack(2)}>2</div>
                  <div className={`step ${(activeStep === 3 || activeStep === 4) && 'active'}`} onClick={() => goBack(3)}>3</div>
                </div>
              </CardTitle>
              <CardBody>
                {activeStep === 1 &&
                  <div>
                    <div className="titles">
                      <div className="title">{t('onboarding_page.welcome_to_laufid')}</div>
                      <div className="secondTitle">{t('onboarding_page.about_you')}</div>
                    </div>

                    <div className="row">
                      <div className="col-8">
                        <label className="form-label">{t('onboarding_page.name')}</label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          value={state.name}
                          className="form-control"
                          onChange={event => change(event, 'name')}
                          invalid={errorStatus && !state.nameValid}
                        />
                        {errorStatus && !state.nameValid && <FormFeedback>Length should be more than 3</FormFeedback>}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-5">
                        <label className="form-label">{t('onboarding_page.work_email')}</label>
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          value={state.email}
                          className="form-control"
                          onChange={event => change(event, 'email')}
                          invalid={errorStatus && !state.emailValid}
                        />
                        {errorStatus && !state.emailValid && <FormFeedback>{t('onboarding_page.work_email_wrong')}</FormFeedback>}
                      </div>
                      <div className="col-3">
                        <label className="form-label">{t('onboarding_page.phone_number')}</label>
                        <Input
                          id="phone"
                          name="phone"
                          type="text"
                          className="form-control"
                          value={state.phone}
                          onChange={event => change(event, 'phone')}
                          invalid={errorStatus && !state.phoneValid}
                        />
                        {errorStatus && !state.phoneValid &&  <FormFeedback>{t('onboarding_page.phone_number7')}</FormFeedback>}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-8">
                        <label className="form-label">{t('onboarding_page.type_of_activity')}</label>
                        <div className="radioInputsRow">
                          <div className="radioCheck">
                            <Input
                              name="type"
                              id="companyType1"
                              type="radio"
                              checked={state.type === 'company'}
                              value="company"
                              onChange={event => change(event, 'type')}
                            />
                            <label htmlFor="companyType1"> {t('onboarding_page.private_company')} </label>
                          </div>
                          <div className="radioCheck">
                            <Input
                              name="type"
                              id="companyType2"
                              type="radio"
                              checked={state.type === 'municipality'}
                              value="municipality"
                              onChange={event => change(event, 'type')}
                            />
                            <label htmlFor="companyType2"> {t('onboarding_page.public_entity')} </label>
                          </div>
                        </div>
                        {errorStatus && !state.typeValid &&  <FormFeedback>{t('onboarding_page.select_private_company')}</FormFeedback>}
                      </div>
                    </div>

                    {state.type === 'company' &&
                      <div className="row">
                        <div className="col-8">
                          <label className="form-label">{t('onboarding_page.company_id')}</label>
                          <Input
                            id="kennitala"
                            name="kennitala"
                            type="text"
                            value={state.kennitala}
                            className="form-control"
                            onChange={event => change(event, 'kennitala')}
                          />
                          {errorStatus && !state.kennitalaValid &&  <FormFeedback>{t('onboarding_page.kennitala_wrong')}</FormFeedback>}
                        </div>
                      </div>
                    }

                    {state.type === 'municipality' &&
                      <React.Fragment>
                        <div className="row">
                          <div className="col-8">
                            <label className="form-label">{t('onboarding_page.public_entity')}</label>
                            <Select
                              value={state.municipalityItem}
                              onChange={(event) => selectMunicipality(event)}
                              options={municipalities && municipalities.results && municipalities.results.map(item => {return {'value': item.id, 'label': item.name}})}
                              styles={customDropdownSmallStyles}
                              className={`react-select ${errorStatus && !state.municipalityValid && 'has-error'}`}
                            />
                          </div>
                        </div>

                        <div className="row" style={{marginBottom: '80px'}}>
                          <div className="col-8">
                            <label className="form-label">{t('onboarding_page.institution')}</label>
                            <Select
                              value={state.institutionItem}
                              onChange={(event) => selectInstitution(event)}
                              options={institutions && institutions.results && institutions.results.map(item => {return {'value': item.id, 'label': item.name}})}
                              styles={customDropdownSmallStyles}
                              className={`react-select ${errorStatus && !state.institutionValid && 'has-error'}`}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    }

                    <button type="button" onClick={() => onCancel()} className="btn btn-primary waves-effect cancel">
                      {t('onboarding_page.cancel')}
                    </button>

                    <button type="button" onClick={() => onConfirmSteps1()} className="btn btn-primary waves-effect">
                      {t('onboarding_page.next')}
                    </button>

                  </div>
                }

                {activeStep === 2 &&
                  <div>
                    <div className="titles">
                      <div className="title">{t('onboarding_page.correct_information')}</div>
                      <div className="secondTitle">{t('onboarding_page.update_information1')} <br/>{t('onboarding_page.update_information2')}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-8">
                        <label className="form-label">{t('onboarding_page.company_name')}</label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          value={state.companyName}
                          disabled={true}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-8">
                        <label className="form-label">{t('onboarding_page.address')}</label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          value={state.companyAddress}
                          disabled={true}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-4">
                        <label className="form-label">{t('onboarding_page.postal_code')}</label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          value={state.companyZip}
                          disabled={true}
                          className="form-control"
                        />
                      </div>
                      <div className="col-4">
                        <label className="form-label">{t('onboarding_page.phone_number')}</label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          value={state.companyPhone}
                          disabled={true}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row" style={{marginBottom: '80px'}}>
                      <div className="col-8">
                        <label className="form-label">{t('onboarding_page.website')}</label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          value={state.companyWebsite}
                          disabled={true}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <button type="button" onClick={() => setActiveStep(3)} className="btn btn-primary waves-effect">
                    {t('onboarding_page.next')}
                    </button>
                  </div>
                }

                {activeStep === 3 &&
                  <div>
                    <div className="titles">
                      <div className="title">{t('onboarding_page.which_subscription')}</div>
                      <div className="secondTitle">{t('onboarding_page.first_month_is_free')}</div>
                    </div>

                    {!state.showStep3Part2 &&
                    <div className="plan">
                      <div className="planHead">
                        <div className="name">{' '}</div>
                        <div className="name">{t('onboarding_page.price')}</div>
                        <div className="name">{t('onboarding_page.software')}</div>
                        <div className="name">{t('onboarding_page.number_of_users')}</div>
                        <div className="name">{t('onboarding_page.visibility')}</div>
                        <div className="name">{t('onboarding_page.data_hosting')}</div>
                        <div className="name">{t('onboarding_page.introduction_meeting')}</div>
                        <div className="name">{t('onboarding_page.personal_advisor')}</div>
                      </div>

                      {plans && plans.results && plans.results.map((plan, planIndex) => {
                        return (
                          <div
                            className={`planCol ${'planCol_'+planIndex} ${state.planId === plan.id && 'selected'}`}
                            onClick={() => changePlan(plan)}
                            key={"planItem" + planIndex}
                          >
                            <div className="planName"> {plan.name} </div>
                            <div className="planItem">
                              <div className="item price"> {parseFloat(plan.price).toFixed(3)} kr.</div>
                              <div className="item software"> <Check/> </div>
                              <div className="item users"> {plan.users}{plan.id === 3 && '+'}</div>
                              <div className="item visibility"> <Check/> </div>
                              <div className="item hosting"> <Check/> </div>
                              <div className="item meeting"> <Check/> </div>
                              <div className="item personal"> <Check/> </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    }

                    {state.showStep3Part2 && state.planId > 0 &&
                    <div className="periodContainer">
                      <div className="periods">
                        <div
                          className={`period monthly ${state.planPeriod === 'monthly' && 'selected'}`}
                          onClick={() => setState({...state, planPeriod: 'monthly'})}
                        >
                          <div className="title"> {t('onboarding_page.monthly')}</div>
                          <div className="price"> {parseFloat(state.planItem.price).toFixed(3)} kr.</div>
                          <div className="check"><Check/></div>
                        </div>

                        <div
                          className={`period annually ${state.planPeriod === 'annually' && 'selected'}`}
                          onClick={() => setState({...state, planPeriod: 'annually'})}
                        >
                          <div className="title"> {t('onboarding_page.annually')}</div>
                          <div className="prices">
                            <span className="newPrice">
                              {((parseFloat(state.planItem.price) * 12) - ((parseFloat(state.planItem.price) * 12) * state.planItem.discount / 100)).toFixed(3)}
                            </span>
                            <span className="oldPrice">{(parseFloat(state.planItem.price) * 12).toFixed(3)} kr.</span>
                          </div>
                          <div className="check"><Check/></div>
                          <div className="discount"> {t('onboarding_page.save%')} {state.planItem.discount}%</div>
                        </div>
                      </div>

                      <div className="copy">{t('onboarding_page.payment_slip')}</div>

                      <div className="acceptTerms">
                        <Input
                          name="legal"
                          type="checkbox"
                          id="accept-terms"
                          value={true}
                          onChange={event => change(event, 'term')}
                        />
                        <label className="form-check-label" htmlFor="accept-terms">
                        {t('onboarding_page.terms1')}&nbsp;
                          <a href="https://staging.laufid.is/doc/vidskiptaskilmalar.pdf" target="_blank" rel="noreferrer">{t('onboarding_page.terms2')}</a>
                          &nbsp;{t('onboarding_page.terms3')}&nbsp;
                          <a href="https://staging.laufid.is/doc/personuvernd.pdf" target="_blank" rel="noreferrer">{t('onboarding_page.terms4')}</a> {t('onboarding_page.terms5')}
                        </label>
                      </div>

                    </div>
                    }


                    {!state.showStep3Part2 &&
                      <button
                        type="button"
                        disabled={state.planId === 0}
                        onClick={() => setState({...state, showStep3Part2: true})}
                        className="btn btn-primary waves-effect"
                      >
                        {t('onboarding_page.free_trial')}
                      </button>
                    }

                    {state.showStep3Part2 && state.planId > 0 &&
                      <button
                        type="button"
                        disabled={state.term === false || state.planPeriod === ''}
                        onClick={() => onFinish()}
                        className="btn btn-primary waves-effect"
                      >
                        {t('onboarding_page.agree')}
                      </button>
                    }

                  </div>
                }

                {activeStep === 4 &&
                  <div className="finishStepContainer">
                    <div className="titles">
                      <div className="title">{t('onboarding_page.welcome_to_laufid')}!</div>
                      <div className="secondTitle">{t('onboarding_page.please_verify_email1')} <br/> {t('onboarding_page.please_verify_email2')}
                      </div>
                    </div>

                    <div className="finishedCopy">{t('onboarding_page.close_window')} <span className="toLogin" onClick={() => openLoginPage()}>{t('onboarding_page.login_page')}</span>
                    </div>
                    <div className="finishedCopy2">{t('onboarding_page.note1')} <br/>
                      {t('onboarding_page.note2')}
                    </div>
                  </div>
                }
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};

export default Registration;