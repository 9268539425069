import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Label} from "reactstrap";
import Select from "react-select";
import {Add, EventAvailable, FormatListBulleted} from "@mui/icons-material";
// import {PDFDownloadLink} from "@react-pdf/renderer";

import {LoaderSpinner} from "../../components/Common/Loader";
import {getActionPlanByCompany, getActionPlanGoal} from "../../store/actions/actionPlan";
import {customDropdownActionPlanStyles} from "../../helpers/dropdown";

import Goals from "./goals";
import Actions from "./actions";
import Calendar from "./calendar";
// import PlanActionsPdf from "./components/planActionsPdf";
import "./index.scss"
import DatePicker from "react-datepicker";
import {useLocation} from "react-router-dom";
import AddActionPlan from "./components/addActionPlan";

const days = ['Mán', 'Þri', 'Mið', 'Fim', 'Fös', 'Lau', 'Sun'];
const months = ['janúar', 'febrúar', 'mars', 'apríl', 'maí', 'júní', 'júlí', 'ágúst', 'september', 'október', 'nóvember', 'desember'];
const locale = {localize: {day: n => days[n], month: n => months[n]}, formatLong: {date: () => 'dd/mm/yyyy'}};

const Index = () => {
  const dispatch = useDispatch();
  const companyId = JSON.parse(localStorage.getItem('p_user'))?.companyId;
  const { t } = useTranslation();
  const {state} = useLocation();

  const [alert, setAlert] = useState(null);
  const [type, setType] = useState(1);
  const {actionPlansByCompany, loadingActionPlansByCompany, loadingActionPlanGoals} = useSelector( state => state.actionPlan);

  const [usersOptions, setUsersOptions] = useState([]);
  const [goalOptions, setGoalOptions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(state && state.priority ? {label: t('plan_page.high'), value: 'high'} : {label:t('plan_page.all'), value: 'all'});
  const [selectedSorting, setSelectedSorting] = useState({label:t('plan_page.all'), value: 'all'});
  const [selectedFilterUser, setSelectedFilterUser] = useState({label:t('plan_page.all'), value: 'all'});
  const [selectedDateFrom, setSelectedDateFrom] = useState(state && state.dateFrom ? state.dateFrom : null);
  const [selectedDateTo, setSelectedDateTo] = useState(state && state.dateTo ? state.dateTo : null);

  const [planGoalsData, setPlanGoalsData] = useState([]);
  const [noGoalsData, setNoGoalsData] = useState([]);
  const [completedPlanGoalsData, setCompletedPlanGoalsData] = useState([]);
  const [expiredData, setExpiredData] = useState([]);

  useEffect(() => {
    dispatch(getActionPlanGoal(companyId));
    dispatch(getActionPlanByCompany(companyId));
  },[]);

  useEffect(() => {
    if(actionPlansByCompany.results && actionPlansByCompany.results.plan_goals) {
      let newUsers = [];
      let newGoals = [];
      let planGoals = [];
      let noGoals = [];
      let completedPlanGoals = [];
      let expired = [];
      let currentDate = new Date();

      actionPlansByCompany.results.plan_goals.forEach(data => {
        let notCompleted = data.company_plan_actions.filter(item => item.status !== 'Completed');
        let completed = data.company_plan_actions.filter(item => item.status === 'Completed');
        if(notCompleted.length>0) {
          planGoals.push({...data, "company_plan_actions": notCompleted})
        }
        if(completed.length>0) {
          completedPlanGoals.push({...data, "company_plan_actions": completed})
        }
        if(data.company_plan_actions.length>0) {
          data.company_plan_actions.forEach(item => {

            if(item.status !== 'Completed' && item.end_date !== null && currentDate > new Date(item.end_date)) {
              expired.push(item)
            }

            if(item.user_ids && item.user_ids !== 'null') {
              let users = JSON.parse(item.user_ids);
              users && newUsers.push(...users)
            }
          });
        }
        newGoals.push({label: data.goal, value: data.id});
      });

      let completedNoGoalItems = [];
      actionPlansByCompany.results.no_goals.forEach(item => {
        if(item.status !== 'Completed') {
          noGoals.push(item);
        } else {
          completedNoGoalItems.push(item)
        }

        if(item.status !== 'Completed' && item.end_date !== null && currentDate > new Date(item.end_date)) {
          expired.push(item)
        }

        if(item.user_ids && item.user_ids !== 'null') {
          let users = JSON.parse(item.user_ids);
          users && newUsers.push(...users)
        }
      });

      if(completedNoGoalItems.length>0) {
        completedPlanGoals.push({"goal": t('plan_page.not_connected_goal'), goalType: "noGoal", "company_plan_actions": completedNoGoalItems})
      }

      newUsers = newUsers.filter((value, index, self) => index === self.findIndex((t) => (t.label === value.label)));
      newGoals = newGoals.filter((value, index, self) => index === self.findIndex((t) => (t.label === value.label)));
      newUsers.unshift({label: t('plan_page.all'), value: 'all'});

      setUsersOptions(newUsers);
      setGoalOptions(newGoals);
      setPlanGoalsData(planGoals);
      setNoGoalsData(noGoals);
      setCompletedPlanGoalsData(completedPlanGoals);
      setExpiredData(expired);

      if((selectedDateFrom !== null && selectedDateTo !== null) || selectedFilter.value === 'high') {
        sortingData();
      }
    }
  },[actionPlansByCompany]);


  const sortingData = (event, type) => {
    let newData = [];
    let newNoGoalsData = [];
    let filter = selectedFilter.value;
    let sorting = selectedSorting.value;
    let user = selectedFilterUser.label;
    let dateFrom = selectedDateFrom;
    let dateTo = selectedDateTo;


    actionPlansByCompany.results.plan_goals.forEach(item => {
      let notCompleted = item.company_plan_actions.filter(actionItem => actionItem.status !== 'Completed');
      newData.push(...notCompleted)
    });

    newNoGoalsData = actionPlansByCompany.results.no_goals.filter(actionItem => actionItem.status !== 'Completed');

    if(type==='filter') {
      setSelectedFilter(event);
      filter = event.value;
    } else if(type==='sorting') {
      setSelectedSorting(event);
      sorting = event.value;
    } else if(type==='user') {
      setSelectedFilterUser(event);
      user = event.label;
    } else if(type==='dateFrom') {
      setSelectedDateFrom(event);
      dateFrom = event;
    } else if(type==='dateTo') {
      setSelectedDateTo(event);
      dateTo = event;
    }

    switch (filter) {
      case 'all':
        newData = newData.sort(function (a, b) {return b.priority - a.priority});
        newNoGoalsData = newNoGoalsData.sort(function (a, b) {return b.priority - a.priority});
        break;
      case 'green_step':
        newData = newData.filter(i => i.type === 'plus' || i.type === 'green_step').sort(function (a, b) {return b.priority - a.priority});
        newNoGoalsData = newNoGoalsData.filter(i => i.type === 'plus' || i.type === 'green_step').sort(function (a, b) {return b.priority - a.priority});
        break;
      case 'other':
        newData = newData.filter(i => i.type === null).sort(function (a, b) {return b.priority - a.priority});
        newNoGoalsData = newNoGoalsData.filter(i => i.type === null).sort(function (a, b) {return b.priority - a.priority});
        break;
      case 'low':
        newData = newData.filter(i => i.priority === 1);
        newNoGoalsData = newNoGoalsData.filter(i => i.priority === 1);
        break;
      case 'medium':
        newData = newData.filter(i => i.priority === 2);
        newNoGoalsData = newNoGoalsData.filter(i => i.priority === 2);
        break;
      case 'high':
        newData = newData.filter(i => i.priority === 3);
        newNoGoalsData = newNoGoalsData.filter(i => i.priority === 3);
        break;
      case 'in_progress':
        newData = newData.filter(i => i.status === 'In progress');
        newNoGoalsData = newNoGoalsData.filter(i => i.status === 'In progress');
        break;
      case 'on_hold':
        newData = newData.filter(i => i.status === 'On Hold');
        newNoGoalsData = newNoGoalsData.filter(i => i.status === 'On Hold');
        break;
      case 'completed':
        newData = newData.filter(i => i.status === 'Completed');
        newNoGoalsData = newNoGoalsData.filter(i => i.status === 'Completed');
        break;
      default:
        break;
    }

    //filter by goal
    if(type==='filter' && filter>0) {
      newData = newData.filter(i => i.plan_goal_id === filter).sort(function (a, b) {return b.priority - a.priority});
    }

    switch (sorting) {
      case 'ongoing':
        newData = newData.filter(i => i.status === 'Ongoing');
        newNoGoalsData = newNoGoalsData.filter(i => i.status === 'Ongoing');
        break;
      case 'low_to_high':
        newData = newData.sort(function (a, b) {return a.priority - b.priority});
        newNoGoalsData = newNoGoalsData.sort(function (a, b) {return a.priority - b.priority});
        break;
      case 'high_to_low':
        newData = newData.sort(function (a, b) {return b.priority - a.priority});
        newNoGoalsData = newNoGoalsData.sort(function (a, b) {return b.priority - a.priority});
        break;
      case 'in_progress_first':
        let ordering1 = {'In progress': 1, 'Ongoing': 1, 'On Hold': 2, 'Completed': 3};
        newData = newData.sort( function(a, b) {return (ordering1[a.status] - ordering1[b.status])});
        newNoGoalsData = newNoGoalsData.sort( function(a, b) {return (ordering1[a.status] - ordering1[b.status])});
        break;
      case 'on_hold_first':
        let ordering2 = {'In progress': 2, 'Ongoing': 2, 'On Hold': 1, 'Completed': 3};
        newData = newData.sort( function(a, b) {return (ordering2[a.status] - ordering2[b.status])});
        newNoGoalsData = newNoGoalsData.sort( function(a, b) {return (ordering2[a.status] - ordering2[b.status])});
        break;
      case 'completed_first':
        let ordering3 = {'In progress': 2, 'Ongoing': 2, 'On Hold': 3, 'Completed': 1};
        newData = newData.sort( function(a, b) {return (ordering3[a.status] - ordering3[b.status])});
        newNoGoalsData = newNoGoalsData.sort( function(a, b) {return (ordering3[a.status] - ordering3[b.status])});
        break;
      case 'nearest_first':
        newData = newData.sort(function (a, b) {
          return Math.abs(Date.now() - new Date(a.end_date)) - Math.abs(Date.now() - new Date(b.end_date));
        });
        newNoGoalsData = newNoGoalsData.sort(function (a, b) {
          return Math.abs(Date.now() - new Date(a.end_date)) - Math.abs(Date.now() - new Date(b.end_date));
        });
        break;
      case 'latest_first':
        newData = newData.sort(function (a, b) {return new Date(b.end_date) - new Date(a.end_date)});
        newNoGoalsData = newNoGoalsData.sort(function (a, b) {return new Date(b.end_date) - new Date(a.end_date)});
        break;
      default:
        break;
    }

    if(user !== 'Allt') {
      let temp = [];
      newData.forEach(item => {
        if(item.user_ids !== 'null') {
          let users = JSON.parse(item.user_ids);
          let b = users.findIndex((i) => i.label === user);
          if(b >= 0) {
            temp.push(item)
          }
        }
      });
      newData = temp;

      let temp2 = [];
      newNoGoalsData.forEach(item => {
        if(item.user_ids !== 'null') {
          let users = JSON.parse(item.user_ids);
          let b = users.findIndex((i) => i.label === user);
          if(b >= 0) {
            temp2.push(item)
          }
        }
      });
      newNoGoalsData = temp2;
    }

    if(dateFrom) {
      let dateFromTime = dateFrom.getTime();
      newData = newData.filter(item => item.end_date && new Date(item.end_date).getTime() > dateFromTime);
      newNoGoalsData = newNoGoalsData.filter(item => item.end_date && new Date(item.end_date).getTime() > dateFromTime);
    }

    if(dateTo) {
      let dateToTime = dateTo.setHours(23,59,59,0);
      newData = newData.filter(item => item.end_date && new Date(item.end_date).getTime() < dateToTime);
      newNoGoalsData = newNoGoalsData.filter(item => item.end_date && new Date(item.end_date).getTime() < dateToTime);
    }

    if(filter === 'all' && sorting === 'all' && user === 'Allt' && dateFrom === null && dateTo === null) {
      let planGoals = [];
      actionPlansByCompany.results.plan_goals.forEach(data => {
        let notCompleted = data.company_plan_actions.filter(item => item.status !== 'Completed');
        if (notCompleted.length > 0) {
          planGoals.push({...data, "company_plan_actions": notCompleted})
        }
      });

      let noGoalsCopy = actionPlansByCompany.results.no_goals.filter(actionItem => actionItem.status !== 'Completed');
      setNoGoalsData(noGoalsCopy);
      setPlanGoalsData(planGoals);
    } else {
      let resData = [];
      newData.forEach(item => {
        let findGoal = actionPlansByCompany.results.plan_goals.find(goal => goal.id === item.plan_goal_id);
        resData.push({...findGoal, "company_plan_actions": [item]})
      });
      setPlanGoalsData(resData);
      setNoGoalsData(newNoGoalsData);
    }
  };

  const addNewActionPlan = () => {
    setAlert(
      <AddActionPlan
        actionFrom="actionPlan"
        onCancel={() => setAlert(null)}
      />
    );
  };

  if (loadingActionPlansByCompany || loadingActionPlanGoals) return (<LoaderSpinner/>);

  return (
    <div className="page-content actionPlanPage">
      {alert}
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
          <Goals
            goals={actionPlansByCompany.results && actionPlansByCompany.results.plan_goals}
          />
        </div>
      </div>

      <div className="row actionsType">
        <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
          <div className="title">{t('plan_page.actions')}</div>

          <div className="type">
            <FormatListBulleted className={`list ${type === 1 && 'active'}`} onClick={() => setType(1)}/>
            <EventAvailable className={`calendar ${type === 2 && 'active'}`} onClick={() => setType(2)}/>
            <div className="addNewActionPlan" onClick={() => addNewActionPlan()}>
              {t('plan_page.add_new_actions')}
              <span className="add hover-effect"><Add/></span>
            </div>
          </div>
        </div>
      </div>

      <div className="row filter">
        <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 filterItem" style={{paddingLeft: '0'}}>
              <Label className="col-form-label">{t('plan_page.show')}</Label>
              <Select
                isSearchable={false}
                placeholder={t('plan_page.select')}
                onChange={(event) => sortingData(event, 'filter')}
                styles={customDropdownActionPlanStyles}
                options={[
                  {label: t('plan_page.all'), value: 'all'},
                  {label: t('plan_page.plus'), value: 'green_step'},
                  {label: t('plan_page.other'), value: 'other'},
                  {label: t('plan_page.priority'),
                    options: [
                      {label: t('plan_page.low'), value: 'low'},
                      {label: t('plan_page.medium'), value: 'medium'},
                      {label: t('plan_page.high'), value: 'high'},
                    ]
                  },
                  {label: t('plan_page.status'),
                    options: [
                      {label: t('plan_page.in_progress'), value: 'in_progress'},
                      {label: t('plan_page.on_hold'), value: 'on_hold'},
                      {label: t('plan_page.completed'), value: 'completed'},
                    ]
                  },
                  {label: "Markmið", options: goalOptions},
                ]}
                value={selectedFilter}
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2 col-xxl-2 filterItem">
              <Label className="col-form-label">{t('plan_page.sort')}</Label>
              <Select
                isSearchable={false}
                placeholder={t('plan_page.select')}
                onChange={(event) => sortingData(event, 'sorting')}
                styles={customDropdownActionPlanStyles}
                options={[
                  {label: t('plan_page.all'), value: 'all'},
                  {label: t('plan_page.by_priority'),
                    options: [
                      {label: t('plan_page.low_to_high'), value: 'low_to_high'},
                      {label: t('plan_page.high_to_low'), value: 'high_to_low'},
                    ]
                  },
                  {label: t('plan_page.by_status'),
                    options: [
                      {label: t('plan_page.in_progress_first'), value: 'in_progress_first'},
                      {label: t('plan_page.on_hold_first'), value: 'on_hold_first'},
                      {label: t('plan_page.completed_first'), value: 'completed_first'},
                    ]
                  },
                  {label: t('plan_page.chronology'),
                    options: [
                      {label: t('plan_page.nearest_first'), value: 'nearest_first'},
                      {label: t('plan_page.latest_first'), value: 'latest_first'},
                    ]
                  },
                ]}
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xxl-3 filterItem">
              <Label className="col-form-label">{t('plan_page.users')}</Label>
              <Select
                isSearchable={false}
                placeholder={t('plan_page.select')}
                onChange={(event) => sortingData(event, 'user')}
                styles={customDropdownActionPlanStyles}
                options={usersOptions.map(item => item)}
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2 col-xxl-2 filterItem datePickerItem">
              <Label className="form_label">{t('log_page.date_from')}</Label>
              <DatePicker
                selected={selectedDateFrom}
                isClearable
                onChange={(event) => sortingData(event, "dateFrom")}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale={locale} className="form-control form_datepicker react-datepicker"
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-2 col-xl-2 col-xxl-2 filterItem datePickerItem">
              <Label className="form_label">{t('log_page.date_to')}</Label>
              <DatePicker
                selected={selectedDateTo}
                isClearable
                onChange={(event) => sortingData(event, "dateTo")}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                locale={locale} className="form-control form_datepicker react-datepicker"
              />
            </div>

            {/*<div className="col-sm-12 col-md-3 col-lg-2 col-xl-2 col-xxl-2 pdfButton">*/}
              {/*<PDFDownloadLink document={*/}
              {/*  <PlanActionsPdf*/}
              {/*    // data={planGoalsData}*/}
              {/*    data={[]}*/}
              {/*    company={company}*/}
              {/*    filter={selectedFilter.value>0 ? selectedFilter.label : selectedFilter.value}*/}
              {/*    sorting={selectedSorting.value}*/}
              {/*    user={selectedFilterUser.label}*/}
              {/*  />*/}
              {/*} fileName="Aðgerðaáætlun.pdf">*/}
              {/*  {({ blob, url, loading, error }) =>*/}
              {/*    loading ?  'Loading...' : t('plan_page.export_pdf')*/}
              {/*  }*/}
              {/*</PDFDownloadLink>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>

      {type === 1 &&
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <Actions
              noGoals={noGoalsData}
              planGoalsData={planGoalsData}
              completedPlanGoalsData={completedPlanGoalsData}
              expiredData={expiredData}
            />
          </div>
        </div>
      }

      {type === 2 &&
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
            <Calendar
              planGoalsData={planGoalsData}
            />
          </div>
        </div>
      }

    </div>
  )
};

export default Index