import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input} from "reactstrap";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {useDispatch} from "react-redux";
import {createCompanyGreenStep} from "../../../../store/actions/greenStep";
import Select from "react-select";
import {customDropdownStyles} from "../../../../helpers/dropdown";
import {Close} from "@mui/icons-material";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line
const yearOptions = () => {
  let years = [];
  for(let i=0; i<=13; i++) {
    years.push({label: 2010+i, value: 2010+i})
  }
  return years;
};

const GreenStep211 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, descHasLink, desc, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const currentLang = i18n.language || 'is';

  const [error, setError] = useState(false);

  const [state, setState] = useState([{
    'percentage': '',
    'year': '',
  }]);

  const handleSave = () => {
    let checkValid = false;
    state.forEach(item => {
      if(item.percentage === '' || item.year === '') {
        checkValid = true;
      }
    });

    if(checkValid) {
      setError(true);
    } else {
      let commentsData = [];
      state.forEach(item => {
        commentsData.push({
          "comment": `Vinnuslysatíðni árið ${item.year} var ${item.percentage}`,
          "percentage": item.percentage,
          "year": item.year,
          "user_id": userData.user.id,
          "name": userData.user.name,
          "date": HelperGetCurrentDate(),
          "customComment": true
        });
      });

      let data = {
        "new_green_step_id": item.id,
        "comment": commentsData,
        "irrelevant": false,
        "reason": [],
        "pdf": null,
        "pdf_name": null
      };

      dispatch(createCompanyGreenStep(data, userData.companyId, item.main_category-1));
      setError(false);
    }
  };

  const addMore = () => {
    let copyState = [...state];
    copyState.push({
      'percentage': '',
      'year': '',
    });
    setState(copyState);
  };

  const onChangeState = (value, item, index) => {
    if(item === 'percentage' && value > 100) {
      return
    }
    let copyState = [...state];
    copyState[index][item] = value;
    setState(copyState);
  };

  const removeItem = (index) => {
    let copyState = [...state];
    copyState.splice(index, 1);
    setState(copyState);
  }

  return (
    <div className="customGreenStep211">
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
            :
            desc
        }
      </div>

      {state.map((item, index) => {
        return (
          <div className="customGreenStep" key={"customGreenStep211_"+index}>
            <div className="row">
              <div className="col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-2">
                <label className="categoryTextColor">Vinnuslysatíðni árið</label>
                <div className="inputCol">
                  <Input
                    id="text"
                    type="number"
                    className="form-control goalField"
                    value={state[index].percentage}
                    onChange={(event) => {
                      onChangeState(event.target.value, 'percentage', index)
                    }}
                    invalid={error && state[index].percentage.length === 0}
                  />
                  <span className="categoryTextColor">%</span>
                </div>
              </div>
              <div className="col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <label className="categoryTextColor">Ár</label>
                <Select
                  placeholder={t('select')}
                  isSearchable={false}
                  onChange={(event) => onChangeState(event.value, 'year', index)}
                  styles={customDropdownStyles('#FD974F')}
                  options={yearOptions()}
                  value={{label: state[index].year, value: state[index].year}}
                  className={`react-select ${error && state[index].year.length === 0 && 'has-error'}`}
                />
              </div>
              <div className="col-1">
                {index > 0 &&
                  <div className={`companyCompletedIcon categoryBorderColor true remove`}
                       onClick={() => removeItem(index)}
                  >
                  <Close/>
                </div>
                }
              </div>
            </div>
          </div>
        )
      })}


      <div className="saveRow">
        <div className="addMore categoryTextColor" onClick={() => addMore()}>Bæta við ári</div>

        <div className="saveBtn categoryBgColor" onClick={() => handleSave()}>
        {t('save')}
        </div>
      </div>

    </div>
  )
};

export default GreenStep211