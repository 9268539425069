import * as actionTypes from '../actionTypes';

const initialState = {
  toggleSnackbar: false,
  snackbarMessage: null,
  snackbarType: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_SNACKBAR_OPEN: {
      return {
        ...state,
        toggleSnackbar: true,
        snackbarMessage: action.message,
        snackbarType: action.style,
      };
    }

    case actionTypes.TOGGLE_SNACKBAR_CLOSE: {
      return {
        ...state,
        toggleSnackbar: false,
        snackbarMessage: null,
        snackbarType: null,
      };
    }

    default: {
      return state;
    }
  }
}