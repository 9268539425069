import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {LoaderSpinner} from "../../components/Common/Loader";

import {getDashboardMunicipality, getDashboardReport} from "../../store/actions/dashboard";

import DashboardCompany from "./dashboardCompany";
import DashboardMunicipality from "./dashboardMunicipality";
import "./index.scss"
import {getCompanyCarbonEmissions} from "../../store/actions/carbon";
import {getMunicipalities} from "../../store/actions/municipality";


const Dashboard = () => {
  const dispatch = useDispatch();

  const storageData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = storageData ? storageData.companyId : 0;
  const isMunicipality = storageData && storageData.companies && storageData.companies[0].type === 'municipality' && storageData.companies[0].municipality_id > 0;
  const municipalityId = isMunicipality ? storageData.companies[0].municipality_id : 0;
  const {loadingMunicipality, loading} = useSelector(state => state.dashboard);
  const {loadingMunicipality:loadingAllMunicipality} = useSelector(state => state.municipality);

  const [dashboardType, setDashboardType] = useState(isMunicipality ? 'municipality' : 'company');

  useEffect(() => {
    if(isMunicipality) {
      dispatch(getDashboardMunicipality(municipalityId))
    }
    dispatch(getDashboardReport(companyId));
    dispatch(getCompanyCarbonEmissions(companyId));
    dispatch(getMunicipalities());
  }, []);

  if (loadingMunicipality || loading || loadingAllMunicipality) return (<LoaderSpinner/>)

  return (
    <div className="page-content dashboard_page">

      {isMunicipality &&
        <div className="dashboardTypes">
          <div className={`type ${dashboardType === 'company' && 'active'}`} onClick={() => {setDashboardType('company')}}>
            {storageData.companies[0].name}
          </div>
          <div className={`type ${dashboardType === 'municipality' && 'active'}`} onClick={() => {setDashboardType('municipality')}}>
            Mínar stofnanir
          </div>
        </div>
      }

      {dashboardType === 'company' && <DashboardCompany/>}

      {dashboardType === 'municipality' && <DashboardMunicipality/>}

    </div>
  )
};

export default Dashboard