import React, {useEffect} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';

import {Card, CardBody, CardTitle, Button} from "reactstrap"
import {getLaufidUsers, createUpdateLaufidUsers, deleteUser} from "../../store/actions/user";
import {getCompaniesOnly} from "../../store/actions/company";

import AddUser from "./addUser";
import LoginActivities from "./loginActivities";
import UserTable from "./userTable";
import {LoaderSpinner} from "../../components/Common/Loader";

import "./index.scss"

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const [alert, setAlert] = React.useState(null);
  const {users, loadingUsers} = useSelector( state => state.user);
  const {companies2, loadingCompanies} = useSelector( state => state.company);
  const store = JSON.parse(localStorage.getItem('p_user'));
  const permission = store ? store.permission : [];
  const canCreate = permission.includes('admin_users_create');

  useEffect(() => {
    dispatch(getLaufidUsers());
    dispatch(getCompaniesOnly());
  }, []);

  if (loadingUsers || loadingCompanies) return (<LoaderSpinner/>);

  const saveUser = (data, update) => {
    dispatch(createUpdateLaufidUsers(data, update));
  };

  const deleteUserFn = (data) => {
    dispatch(deleteUser({...data}));
  };

  const addUser = (data) => {
    setAlert(
      <AddUser data={data} company={companies2} onCancel={() => setAlert(null)} onSubmit={(data, update) => saveUser(data, update)}/>
    );
  };

  const loginActivities = (data) => {
    setAlert(
      <LoginActivities data={data} onCancel={() => setAlert(null)} />
    );
  };

  return (
    <div className="page-content">
      {alert}

      <Card>
        <CardBody>
          <div className="row">
            <div className="col col-9">
              <CardTitle className="h4">{t('users')}</CardTitle>
            </div>
            <div className="col col-3">
              {canCreate && <Button color="success" className="mb-4" size="sm" style={{float: 'right'}} onClick={addUser}>{t('user_page.add_new')}</Button>}
            </div>
          </div>
          {
            !users.results ? '' : <UserTable
              data={users.results}
              addUser={(data) => addUser(data)}
              login={(data) => loginActivities(data)}
              deleteUser={(data) => deleteUserFn(data)}
            />
          }
        </CardBody>
      </Card>

    </div>
  )
};

export default Index