import React, {Fragment} from "react"
import { useTranslation } from 'react-i18next';
import {Button, Input, Row, Col} from "reactstrap"
import {useTable, useSortBy, useFilters, useExpanded, usePagination} from 'react-table'
import {ArrowDropDown, ArrowDropUp, Edit,  Sort, Verified} from '@mui/icons-material';
import logIcon from '../../assets/images/log_icon.png'
import DeleteButton from "../../components/Common/CustomButtons/DeleteButton";


const formateDate = (date) => {
  const today = new Date(date);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return dd + '-' + mm + '-' + yyyy;
};

function Filter ({ column }) {
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  )
}

function DefaultColumnFilter ({column: { Header, filterValue, setFilter, preFilteredRows: { length }}}) {
  return (
    <Input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={Header}
    />
  );
};

function TableR({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: 25 },
      autoResetExpanded: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? <ArrowDropDown/> : <ArrowDropUp/>) : <Sort/>;
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const { t } = useTranslation();


  // Render the UI for your table
  return (
    <Fragment>
      <table {...getTableProps()} className="table table-striped table-hover">
        <thead className="table-light">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps({
                  style: { minWidth: column.minWidth },
                  className: column.className,
                })}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </div>
                  <Filter column={column} />
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <Fragment key={row.getRowProps().key}>
                <tr>{row.cells.map(cell => <td {...cell.getCellProps()}>{cell.render("Cell")}</td>)}</tr>
              </Fragment>
            )
          })}
        </tbody>
      </table>

      <Row className="pagination_row">
        <Col md={3}>
          <Button color="primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{"<<"}</Button> {' '}
          <Button color="primary" onClick={previousPage} disabled={!canPreviousPage}>{"<"}</Button>
        </Col>
        <Col md={2} style={{ marginTop: 7 }}>
          {t('page')} {" "} <strong> {pageIndex + 1} af {pageOptions.length}</strong>
        </Col>
        <Col md={2}>
          <Input type="number" min={1} style={{ width: 70 }} max={pageOptions.length} defaultValue={pageIndex + 1} onChange={onChangeInInput}/>
        </Col>
        <Col md={2}>
          <select value={pageSize} onChange={onChangeInSelect} className="select_filter">
            {[25, 50, 100].map(pageSize => ( <option key={pageSize} value={pageSize}> Sýna {pageSize} </option>))}
          </select>
        </Col>
        <Col md={3}>
          <Button color="primary" onClick={nextPage} disabled={!canNextPage}>{">"}</Button> {' '}
          <Button color="primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{">>"}</Button>
        </Col>
      </Row>
    </Fragment>
  )
}


const UserTable = (props) => {
  const { t } = useTranslation();
  const store = JSON.parse(localStorage.getItem('p_user'));
  const permission = store ? store.permission : [];
  const canEdit = permission.includes('admin_users_edit');
  const canDelete = permission.includes('admin_users_delete');

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: t('user_page.logo'),
        disableFilters: true,
        disableSortBy: true,
        className: 'logo_c',
        accessor: (values) => {
          let logo = "";
          if(values && values.company_users && values.company_users.length>0)
            if(values.company_users[0].company && values.company_users[0].company.logo) {
              logo = values.company_users[0].company.logo
            }
          return <div style={{ backgroundImage: "url("+ logo +")" }} className="img-thumbnail rounded-circle img_item" />
        },
      },
      {
        Header: t('user_page.name'),
        accessor: 'name',
        Cell: ({ cell }) => {
          const {row} = cell;
          return row.original.type && row.original.type === 'primary' ? <p>{row.original.name} <Verified className="user_verified"/></p>  : row.original.name
        },
      },
      {
        Header: t('user_page.email'),
        accessor: 'email',
      },
      {
        Header: t('user_page.phone'),
        accessor: 'phone',
      },
      {
        Header: t('user_page.type'),
        accessor: (values) => {
          return values.roles && values.roles.length>0 ? values.roles[0].name : null
        },
        Cell: ({ cell }) => {
          const {value} = cell;
          return value && <Button color={value==='super_admin' ? 'danger' : value==='company_user' ? 'warning' : "success"} size="sm">{value}</Button>
        },
      },
      {
        Header: t('user_page.company'),
        accessor: (values) => {
          return values.company_users ? values.company_users.length>1 ? values.company_users.map(comItem => comItem?.company?.name+', ') : values.company_users.map(comItem => comItem?.company?.name) : ''
        },
      },
      {
        Header: t('user_page.last_login'),
        accessor: (values) => {
          return values.login_activities && values.login_activities.length>0 ? formateDate(values.login_activities[0].created_at) : ''
        },
      },
      {
        Header: ' ',
        id: 'expander',
        className: 'action_c',
        Cell: ({ row }) => {
          return(
            <div style={{whiteSpace: 'nowrap'}}>
              <Button color="info" size="sm" onClick={() => props.login(row.original)} style={{padding: '3px', marginRight: '7px'}}>
                <img src={logIcon} style={{width: '20px'}} alt=""/>
              </Button>{'  '}
              {canEdit && <Button color="success" size="sm" onClick={() => props.addUser(row.original)}><Edit style={{fontSize: '12px'}}/></Button>}
              {canDelete && <DeleteButton action={() => props.deleteUser(row.original)} />}
            </div>
          )
        },
      },

    ],
    []
  );

  return (
    <TableR
      columns={columns}
      data={props.data}
    />
  )
};

export default UserTable
