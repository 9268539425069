import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getActivityLogByCompany = (companyId = null) => async dispatch => {
  dispatch({type: actionTypes.GET_ACTIVITY_LOG_BY_COMPANY_START});
  try {
    const res = await axios.get(`/admin_notification/by_company${companyId ? '?company_id='+companyId : ''}`);
    dispatch({type: actionTypes.GET_ACTIVITY_LOG_BY_COMPANY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_ACTIVITY_LOG_BY_COMPANY_FAIL});
  }
};

export const getActivityLogUsersByCompany = (companyId = null) => async dispatch => {
  dispatch({type: actionTypes.GET_ACTIVITY_LOG_USERS_BY_COMPANY_START});
  try {
    const res = await axios.get(`/admin_notification/users_by_company/${companyId ? '?company_id='+companyId : ''}`);
    dispatch({type: actionTypes.GET_ACTIVITY_LOG_USERS_BY_COMPANY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_ACTIVITY_LOG_USERS_BY_COMPANY_FAIL});
  }
};

