import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input} from "reactstrap";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {useDispatch} from "react-redux";
import {createCompanyGreenStep} from "../../../../store/actions/greenStep";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const GreenStep213 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, descHasLink, desc, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const currentLang = i18n.language || 'is';

  const [first, setFirst] = useState('');
  const [second, setSecond] = useState('');
  const [third, setThird] = useState('');
  const [error, setError] = useState(false);


  const handleCheck = () => {
    if(first>0 && second>0 && third>0) {
      let data = {
        "new_green_step_id": item.id,
        "comment": [{
          "comment": `Árið 2023 var árleg breyting starfsfólks í fullu starfi ${first}%, í hlutastarfi ${second}%, og í verktaka og/eða ráðgjöf ${third}%.`,
          "first" : first,
          "second" : second,
          "third" : third,
          "user_id": userData.user.id,
          "name": userData.user.name,
          "date": HelperGetCurrentDate(),
          "customComment": true
        }],
        "irrelevant": false,
        "reason": [],
        "pdf": null,
        "pdf_name": null
      };

      dispatch(createCompanyGreenStep(data, userData.companyId, item.main_category-1));
      setError(false);
    } else {
      setError(true);
    }
  };


  return (
    <div className="customGreenStep210">
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
            :
            desc
        }
      </div>

      <div className="customGreenStep">
        <p className="categoryTextColor">Árleg breyting starfsfólks í fullu starfi, í prósentum</p>

        <div className="row">
          <div className="col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-2 inputCol">
            <Input
              id="text"
              type="number"
              className="form-control goalField"
              value={first}
              onChange={(event) => {
                setFirst(event.target.value)
              }}
              invalid={error && first.length === 0}
            />
            <span className="categoryTextColor">%</span>
          </div>
        </div>

        <br/>

        <p className="categoryTextColor">Árleg breyting starfsfólks í hlutastarfi, í prósentum</p>
        <div className="row">
          <div className="col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-2 inputCol">
            <Input
              id="text"
              type="number"
              className="form-control goalField"
              value={second}
              onChange={(event) => {
                setSecond(event.target.value)
              }}
              invalid={error && second.length === 0}
            />
            <span className="categoryTextColor">%</span>
          </div>
        </div>

        <br/>

        <p className="categoryTextColor">Árleg breyting verktaka og/eða ráðgjafa, í prósentum</p>
        <div className="row">
          <div className="col-sm-10 inputCol">
            <Input
              id="text"
              type="number"
              className="form-control goalField"
              value={third}
              onChange={(event) => {
                setThird(event.target.value)
              }}
              invalid={error && third.length === 0}
            />
            <span className="categoryTextColor">%</span>
          </div>
          <div className="col-2 save">
            <div className="saveBtn categoryBgColor" onClick={() => handleCheck()}>
              {t('save')}
            </div>
          </div>
        </div>
      </div>

    </div>
  )
};

export default GreenStep213