import React from 'react';
import {Edit} from "@mui/icons-material";

const GreenStep203Show = (props) => {
  const {commentIndex, commentItem, greenStep} = props;

  return (
    <React.Fragment>
      <p className="completedCommentText customGreenStep203Show">
        <p>{commentItem.goal.label}</p>
        <p>{commentItem.subGoal.label}</p>
        <p>
          {commentItem.explanation}
          <span
            className="completedAddNewIcon categoryBgColor editCommentBtn hover-effect"
            onClick={() => {
              props.changeCommentState(commentItem.comment, greenStep.new_company_green_steps[0].id, commentIndex)
            }}
          >
            <Edit/>
          </span>
        </p>
      </p>
    </React.Fragment>
  )
};

export default GreenStep203Show