import React, { useState } from "react"
import { Link } from "react-router-dom"
import {Menu} from '@mui/icons-material';

import Navbar from "./Navbar"
import logoSm from "../../assets/images/lauf.svg";
import logoLg from "../../assets/images/laufid_logo.svg";
import LanguageDropdown from "../Common/TopbarDropdown/LanguageDropdown";
import SettingsDropdown from "../Common/TopbarDropdown/SettingsDropdown";
import Notification from "../Common/TopbarDropdown/Notification";

const Header = () => {
  const [leftMenu, setLeftMenu] = useState(false);

  return (
    <div className="navbar-header">

      <div className="child">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            <div className="logo-sm" style={{backgroundImage: "url(" + logoSm + ")"}} />
            <div className="logo-lg" style={{backgroundImage: "url(" + logoLg + ")"}} />
          </Link>
        </div>

        <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
            data-toggle="collapse"
            onClick={() => {setLeftMenu(!leftMenu)}}
            data-target="#topnav-menu-content"
          >
           <Menu/>
        </button>

        <Navbar leftMenu={leftMenu} closeMenu={() => setLeftMenu(!leftMenu)} />
      </div>

      <div className="child">
        <Notification />
        <LanguageDropdown />
        <SettingsDropdown />
      </div>

    </div>
  )
};

export default Header

