import React, {useEffect} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Table,  Card, CardBody, Button} from "reactstrap"

import {Edit} from '@mui/icons-material';
import DeleteButton from "../../components/Common/CustomButtons/DeleteButton";

import AddCertificate from "./addCertificate";
import {createUpdateCertificate, deleteCertificate, getCertificates} from "../../store/actions/certificate";
import {LoaderSpinner} from "../../components/Common/Loader";
import "./index.scss"

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const [alert, setAlert] = React.useState(null);
  const {loadingCertificate, certificates} = useSelector( state => state.certificate );
  const store = JSON.parse(localStorage.getItem('p_user'));
  const permission = store ? store.permission : [];
  const canCreate = permission.includes('admin_certificates_create');
  const canEdit = permission.includes('admin_certificates_edit');
  const canDelete = permission.includes('admin_certificates_delete');

  useEffect(() => {
    dispatch(getCertificates());
  }, []);

  if (loadingCertificate) return (<LoaderSpinner/>);

  const onSubmit = (data, update) => {
    dispatch(createUpdateCertificate(data, update));
  };

  const htmlAlert = (data) => {
    setAlert(
      <AddCertificate data={data} onCancel={() => setAlert(null)} onSubmit={(data, update) => onSubmit(data, update)}/>
    );
  };

  const tableData = !certificates.results ? '' :
    certificates.results.map((value, index) => {
      return (<tr key={index}>
        <td>{index+1}</td>
        <td key={value.id+'img'}><img className="img-thumbnail rounded-circle img_item" src={value.logo} alt=""/></td>
        <td>{value.name}</td>
        <td>{value.nr}</td>
        <td><Button color={value.type==='company' ? 'warning' : 'success'} size="sm">{value.type}</Button></td>
        <td>{value.description}</td>
        <td width="120px">
          {canEdit && <Button key={value.id+'editBtn'} color="success" size="sm" onClick={() => htmlAlert(value)}><Edit style={{fontSize: '12px'}}/></Button>}
          {canDelete && <DeleteButton key={value.id+'_delete'} action={() => dispatch(deleteCertificate({...value}))} />}
        </td>
      </tr>)
    });

  return (
    <div className="page-content">
      {alert}
      <Card>
        <CardBody>
          <div className="row">
            <div className="col col-12">
              {canCreate && <Button color="success" className="mb-4 float-end" size="sm" style={{float: 'right'}} onClick={() => htmlAlert(null)}>{t('certificate_page.add_new')}</Button>}
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table table-striped table-hover">
              <thead className="table-light">
                <tr>
                  <th>#</th>
                  <th>{t('certificate_page.logo')}</th>
                  <th>{t('certificate_page.list_name')}</th>
                  <th>{t('certificate_page.number')}</th>
                  <th>{t('certificate_page.type')}</th>
                  <th>{t('certificate_page.description')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableData}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  )
};

export default Index
