import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import {Input, Modal} from "reactstrap"
import {Check} from "@mui/icons-material";

import {createCompanyGreenStep, deleteCompanyGreenStep, getGreenStepsRegistration} from "../../../store/actions/greenStep";
import LanguageDropdown from "../../../components/Common/TopbarDropdown/LanguageDropdown";
import {LoaderSpinner} from "../../../components/Common/Loader";
import conf from "../../../assets/images/conf.png"
import i18n from "../../../i18n";
import "../index.scss"


const InitialGreenSteps = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData ? userData.companyId : 0;
  const currentLang = i18n.language || 'is';
  const {greenStepsRegistrationLoading, greenStepsRegistration} = useSelector( state => state.greenStep );
  const [activeStep, setActiveStep] = useState(1);
  const [employees, setEmployees] = useState();
  const [socialFactors, setSocialFactors] = useState({
    "woman": '', "kvar": '', "men": '', "other": '',
    "manager_woman": '', "manager_kvar": '', "manager_men": '', "manager_other": '', "total": 0
  });
  const [governance, setGovernance] = useState({"woman": '', "kvar": '', "men": '', "other": '', "total": 0});


  useEffect(() => {
    dispatch(getGreenStepsRegistration());
  }, []);

  if (greenStepsRegistrationLoading) return (
    <Modal isOpen={true} fullscreen  backdrop={"static"}>
      <div className="modal-body"> <LoaderSpinner/> </div>
    </Modal>
  );

  const nextFrame = () => {
    if(activeStep === 3) {
      if(
        socialFactors.woman>0 || socialFactors.kvar>0 || socialFactors.men>0 || socialFactors.other>0 ||
        socialFactors.manager_woman>0 || socialFactors.manager_kvar>0 || socialFactors.manager_men>0 || socialFactors.manager_other>0
      ) {
        handleCheck(205, 1, {...socialFactors, "employees": employees});
      }
    } else if(activeStep === 4) {
      if(governance.woman>0 || governance.kvar>0 || governance.men>0 || governance.other>0) {
        handleCheck(206, 2, {...governance, "employees": employees});
      }
    }

    setActiveStep(activeStep+1);
  };

  const previousFrame = () => {
    setActiveStep(activeStep-1);
  };

  const handleCheck = (id, mainCategoryIndex, comment = []) => {
    let data = {
      "new_green_step_id": id,
      "comment": comment,
      "irrelevant": false,
      "reason": [],
      "pdf": null,
      "pdf_name": null
    };

    dispatch(createCompanyGreenStep(data, companyId, mainCategoryIndex, true));
  };

  const handleUncheck = (id, mainCategoryIndex) => {
    dispatch(deleteCompanyGreenStep(id, mainCategoryIndex, 'deleteCompletely', true));
  };

  const onChangeSocialFactors = (item, event) => {
    if(event.target.value < 1 && event.target.value !== '') {return}
    let temp = {...socialFactors};
    temp[item] = event.target.value;
    let w = parseInt(temp.woman) || 0;
    let k = parseInt(temp.kvar) || 0;
    let m = parseInt(temp.men) || 0;
    let o = parseInt(temp.other) || 0;

    if((w+k+m+o) <= employees) {
      setSocialFactors(temp)
    }
  };

  const onChangeSocialManager = (item, event) => {
    if(event.target.value < 1 && event.target.value !== '') {return}
    let temp = {...socialFactors};
    temp[item] = event.target.value;
    let w = parseInt(temp.manager_woman) || 0;
    let k = parseInt(temp.manager_kvar) || 0;
    let m = parseInt(temp.manager_men) || 0;
    let o = parseInt(temp.manager_other) || 0;
    temp['total'] = w+k+m+o;
    setSocialFactors(temp);
  };

  const onChangeGovernance = (item, event) => {
    if(event.target.value < 1 && event.target.value !== '') {return}
    let temp = {...governance};
    temp[item] = event.target.value;
    let w = parseInt(temp.woman) || 0;
    let k = parseInt(temp.kvar) || 0;
    let m = parseInt(temp.men) || 0;
    let o = parseInt(temp.other) || 0;
    temp['total'] = w+k+m+o;

    setGovernance(temp)
  };

  return (
    <Modal isOpen={true} fullscreen backdrop={"static"} className="initialGreenStepsModal">

      <div className={`modal-body step_${activeStep}`}>
        <div className="skip" onClick={() => props.handleClose()}>{t('onboarding_page.skip')}</div>
        <LanguageDropdown/>

        {greenStepsRegistration && greenStepsRegistration.results &&
          <div className="steps">

            <div className="step step1">
              <div className="header">{t('onboarding_page.lets_get_started')}!</div>
              <div className="title">{t('onboarding_page.how_many_fulltime')}</div>
              <div className="items">
                <Input
                  name="employees"
                  type="number"
                  value={employees || ""}
                  className="form-control"
                  onChange={event => setEmployees(event.target.value)}
                />
              </div>
              <div className="comment">
                <span>{t('onboarding_page.note')}:</span> {t('onboarding_page.how_many_fulltime_note')}
              </div>

            </div>

            <div className="step step2">
              <div className="header">{t('onboarding_page.step2_header1')} <br/> {t('onboarding_page.step2_header2')}</div>
              <div className="category">{t('onboarding_page.environment')}</div>
              <div className="title">{t('onboarding_page.recycle_categories')}</div>
              {
                greenStepsRegistration.results.filter(f => f.main_category === 1)
                  .reduce((r, e, i) => (i % 3 ? r[r.length - 1].push(e) : r.push([e])) && r, [])
                  .map((items, indexItems) =>
                    <div className="items" key={"greenStepsItemsStep2_"+indexItems}>
                      {items.map(greenStep =>
                        <div className="item" key={"initGreenStep"+greenStep.id}>
                          <div
                            className="companyCompleted"
                            onClick={() => {Object.hasOwn(greenStep, 'completed') ? handleUncheck(greenStep.completed.id, 0) : handleCheck(greenStep.id, 0)}}
                          >
                            <div className={`companyCompletedIcon ${Object.hasOwn(greenStep, 'completed')}`}> <Check/> </div>
                          </div>
                          {currentLang === 'en' && greenStep.title_en.length>0 ? greenStep.title_en : greenStep.title}
                        </div>
                      )}
                    </div>
                  )
              }



            </div>

            <div className="step step3">
              <div className="header">{t('onboarding_page.step3_header1')} <br/> {t('onboarding_page.step3_header2')}</div>
              <div className="category">{t('onboarding_page.social')}</div>
              <div className="title">{t('onboarding_page.gender_ratio')}</div>
              <div className="users">
                <div className="userType">
                  <label>{t('onboarding_page.women')}</label>
                  <Input
                    name="woman"
                    type="number"
                    value={socialFactors.woman || ""}
                    className="form-control"
                    onChange={event => onChangeSocialFactors("woman", event)}
                  />
                  {socialFactors.woman && employees>0 && <span className="percent">
                    {Math.round(socialFactors.woman*100/employees)}%
                  </span>}
                </div>
                <div className="userType">
                  <label>{t('onboarding_page.non_binary')}</label>
                  <Input
                    name="kvar"
                    type="number"
                    value={socialFactors.kvar || ""}
                    className="form-control"
                    onChange={event => onChangeSocialFactors("kvar", event)}
                  />
                  {socialFactors.kvar && employees>0 && <span className="percent">
                    {Math.round(socialFactors.kvar*100/employees)}%
                  </span>}
                </div>
                <div className="userType">
                  <label>{t('onboarding_page.men')}</label>
                  <Input
                    name="men"
                    type="number"
                    value={socialFactors.men || ""}
                    className="form-control"
                    onChange={event => onChangeSocialFactors("men", event)}
                  />
                  {socialFactors.men && employees && <span className="percent">
                    {Math.round(socialFactors.men*100/employees)}%
                  </span>}
                </div>
                <div className="userType">
                  <label>{t('onboarding_page.other')}</label>
                  <Input
                    name="other"
                    type="number"
                    value={socialFactors.other || ""}
                    className="form-control"
                    onChange={event => onChangeSocialFactors("other", event)}
                  />
                  {socialFactors.other && employees>0 && <span className="percent">
                    {Math.round(socialFactors.other*100/employees)}%
                  </span>}
                </div>
              </div>

              <br/>

              <div className="title">{t('onboarding_page.gender_ratio_managers')}</div>
              <div className="users">
                <div className="userType">
                  <label>{t('onboarding_page.women')}</label>
                  <Input
                    name="woman"
                    type="number"
                    value={socialFactors.manager_woman || ""}
                    className="form-control"
                    onChange={event => onChangeSocialManager("manager_woman", event)}
                  />
                  {socialFactors.total>0 && socialFactors.manager_woman>0 && <span className="percent">
                    {Math.round(socialFactors.manager_woman*100/socialFactors.total)}%
                  </span>}
                </div>
                <div className="userType">
                  <label>{t('onboarding_page.non_binary')}</label>
                  <Input
                    name="woman"
                    type="number"
                    value={socialFactors.manager_kvar || ""}
                    className="form-control"
                    onChange={event => onChangeSocialManager("manager_kvar", event)}
                  />
                  {socialFactors.total>0 && socialFactors.manager_kvar>0 && <span className="percent">
                    {Math.round(socialFactors.manager_kvar*100/socialFactors.total)}%
                  </span>}
                </div>
                <div className="userType">
                  <label>{t('onboarding_page.men')}</label>
                  <Input
                    name="men"
                    type="number"
                    value={socialFactors.manager_men || ""}
                    className="form-control"
                    onChange={event => onChangeSocialManager("manager_men", event)}
                  />
                  {socialFactors.total>0 && socialFactors.manager_men>0 && <span className="percent">
                    {Math.round(socialFactors.manager_men*100/socialFactors.total)}%
                  </span>}
                </div>
                <div className="userType">
                  <label>{t('onboarding_page.other')}</label>
                  <Input
                    name="other"
                    type="number"
                    value={socialFactors.manager_other || ""}
                    className="form-control"
                    onChange={event => onChangeSocialManager("manager_other", event)}
                  />
                  {socialFactors.total>0 && socialFactors.manager_other>0 && <span className="percent">
                    {Math.round(socialFactors.manager_other*100/socialFactors.total)}%
                  </span>}
                </div>
              </div>


            </div>

            <div className="step step4">
              <div className="header">{t('onboarding_page.step4_header')} :D</div>
              <div className="category">{t('onboarding_page.governance')}</div>
              <div className="title">{t('onboarding_page.gender_ratio_board')}</div>
              <div className="users">
                <div className="userType">
                  <label>{t('onboarding_page.women')}</label>
                  <Input
                    name="woman"
                    type="number"
                    value={governance.woman || ""}
                    className="form-control"
                    onChange={event => onChangeGovernance("woman", event)}
                  />
                  {governance.total>0 && governance.woman.length>0 && <span className="percent">
                    {Math.round(governance.woman*100/governance.total)}%
                  </span>}
                </div>
                <div className="userType">
                  <label>{t('onboarding_page.non_binary')}</label>
                  <Input
                    name="woman"
                    type="number"
                    value={governance.kvar || ""}
                    className="form-control"
                    onChange={event => onChangeGovernance("kvar", event)}
                  />
                  {governance.total>0 && governance.kvar.length>0 && <span className="percent">
                    {Math.round(governance.kvar*100/governance.total)}%
                  </span>}
                </div>
                <div className="userType">
                  <label>{t('onboarding_page.men')}</label>
                  <Input
                    name="men"
                    type="number"
                    value={governance.men || ""}
                    className="form-control"
                    onChange={event => onChangeGovernance("men", event)}
                  />
                  {governance.total>0 && governance.men.length>0 && <span className="percent">
                    {Math.round(governance.men*100/governance.total)}%
                  </span>}
                </div>
                <div className="userType">
                  <label>{t('onboarding_page.other')}</label>
                  <Input
                    name="other"
                    type="number"
                    value={governance.other || ""}
                    className="form-control"
                    onChange={event => onChangeGovernance("other", event)}
                  />
                  {governance.total>0 && governance.other.length>0 && <span className="percent">
                    {Math.round(governance.other*100/governance.total)}%
                  </span>}
                </div>
              </div>

              <div className="comment">
                <span>{t('carbon_page.note1')}:</span> {t('onboarding_page.governance_note1')} <a href="https://www.althingi.is/altext/stjt/2010.013.html" target="_blank" rel="noopener noreferrer">
                {t('onboarding_page.governance_note2')} <br/>{t('onboarding_page.governance_note3')}
                </a> {t('onboarding_page.governance_note4')} <br/>
                {t('onboarding_page.governance_note5')} <br/>
                {t('onboarding_page.governance_note6')} <br/>
                {t('onboarding_page.governance_note7')}  <br/>
                {t('onboarding_page.governance_note8')}
              </div>
            </div>

            <div className="step step5">
              <div className="header">{t('onboarding_page.step5_header1')} <br/> {t('onboarding_page.step5_header2')}</div>
              <img src={conf}/>
              <div className="btn" onClick={() => props.handleClose()}>{t('onboarding_page.view_software')}</div>
            </div>

          </div>
        }

        {activeStep && activeStep > 1 && activeStep < 5 &&
        <button type="button" className="previous btn btn-primary waves-effect waves-light" onClick={() => previousFrame()}>
          {t('onboarding_page.previous')}
        </button>
        }

        {activeStep && activeStep < 5 &&
          <button type="button" className="next btn btn-primary waves-effect waves-light" onClick={() => nextFrame()}>
            {t('onboarding_page.next')}
          </button>
        }
      </div>


    </Modal>
  );

};

export default InitialGreenSteps