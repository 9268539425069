import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Row, Modal} from "reactstrap"
import TextField from '@mui/material/TextField';
import {LoaderSpinner} from "../../components/Common/Loader";
import "./index.scss"
import {getPermissions} from "../../store/actions/acl";

const AddRole = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch ();
  const {loadingPermission, permissions} = useSelector( state => state.acl );
  const [state, setState] = useState({
    id: props.data.id || null,
    name: props.data.name || "",
    nameState: false,
    permissions: props.data.permissions ? props.data.permissions.map(i => i.name) : [],
  });

  useEffect(() => {
    dispatch(getPermissions());
  }, []);

  if (loadingPermission) return (
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{state.id ? t('pluses_page.update_category') : t('pluses_page.add_new_category')}</h5>
      </div>
      <div className="modal-body">
        <LoaderSpinner/>
      </div>
    </Modal>
  );

  const onChangePermission = (item) => {
    let items = [...state.permissions];
    let index = items.indexOf(item);
    (index === -1) ? items.push(item) : items.splice(index, 1);
    setState({...state, permissions: items});
  };

  const verifyLength = (value, length) => {
    return value.length < length;
  };

  const isValidated = () => {
    if (state.name.length > 1 && state.nameState === false && state.permissions.length > 1) {
      return true;
    } else {
      setState({...state, nameState: state.name.length < 3 && true});
      return false;
    }
  };

  const onConfirm = () => {
    if (isValidated()) {
      props.onSubmit(state, !!state.id);
      props.onCancel();
    }
  };

  const change = (event, stateName, type, stateNameEqualTo) => {
    let status = false;
    if(type === 'length') {
      status = verifyLength(event.target.value, stateNameEqualTo);
    }
    setState({...state, [stateName]: event.target.value, [stateName+'State']: status});
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"} style={{maxWidth: '900px', width: '100%'}}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{state.id ? t('pluses_page.update_category') : t('pluses_page.add_new_category')}</h5>
      </div>
      <div className="modal-body">
        <Row>
          <div className="col-md-12 mb-2">
            <TextField
              className="textField"
              label={ <span> {t('acl_page.name')} <small>({t('required')})</small> </span> }
              variant="standard"
              fullWidth
              error={state.nameState}
              value={state.name}
              onChange={event =>  change(event, 'name', "length", 3)}
            />
          </div>
        </Row>
        <Row className="mb-2">
          {!permissions.results ? '' : permissions.results.map(perm => {
            return (
              <div className="col-4" key={perm.id+'filter_div'}>
                <div className="form-check">
                  <input className="form-check-input plus_category_modal_check" id={"defaultCheck"+perm.id} type="checkbox" value={perm.name}
                         checked={state.permissions.indexOf(perm.name)>=0}
                         onChange={(e) => onChangePermission(e.target.value)}
                  />
                  <label className="form-check-label font-size-14" htmlFor={"defaultCheck"+perm.id}>{perm.name}</label>
                </div>
              </div>
            )
          })}
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => onCancel()} className="btn btn-danger waves-effect" data-dismiss="modal">
          {t('close')}
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  );

};

export default AddRole