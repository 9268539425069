import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Card, Input} from "reactstrap";
import Switch from "react-switch";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {Check, KeyboardBackspace, NavigateBefore, NavigateNext, Favorite, FavoriteBorder} from '@mui/icons-material';

import i18n from "../../../i18n";
import is from "date-fns/locale/is";
import {HelperGetCurrentDate} from "../../../helpers/main";
import {customDropdownPaginationGreenStepsStyles} from "../../../helpers/dropdown";
import './index.scss';

const Index = (props) => {
  const { t } = useTranslation();
  const currentLang = i18n.language || 'is';

  const {notCompleted, smallCategoriesName, mainCategoryIndex} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));

  const [greenSteps, setGreenSteps] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([]);

  const [showDescIds, setShowDescIds] = useState([]);
  const [showAddIds, setShowAddIds] = useState([]);
  const [commentInput, setCommentInput] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [favoriteItem, setFavoriteItem] = useState(null);

  useEffect(() => {
    if(notCompleted) {
      let data = [];
      notCompleted.forEach(item => {
        data.push(...item.new_green_steps_not_completed)
      });
      let total = Math.ceil(data.length/9);

      let pagesList = [];
      for(let i = 1; i <= total; i++) {
        pagesList.push({selectedLabel: `Síða ${i}/${total}`, label: <div><span className="m_list">{i}</span><span className="m_select">{t('page')} {i}/{total}</span></div>, value: i})
      }

      setGreenSteps(data);
      if(currentPage === 1) {
        setPaginationData(data.slice(0, 9));
      } else {
        setPaginationData(data.slice(9*(currentPage-1), currentPage * 9));
      }
      setPages(pagesList);
    }
  }, [notCompleted]);

  useEffect(() => {
    if(mainCategoryIndex>=0) {
      setCurrentPage(1);
    }
  }, [mainCategoryIndex]);

  const pagination = (event) => {
    let newData = greenSteps.slice(9*(event.value-1), event.value * 9);
    setCurrentPage(event.value);
    setPaginationData(newData);
  };

  const toggleCard = (id) => {
    let items = Object.assign([], showDescIds);
    let index = items.indexOf(id);
    (index === -1) ? items.push(id) : items.splice(index, 1);
    setShowDescIds(items);
  };

  const toggleAddCard = (id) => {
    let items = Object.assign([], showAddIds);
    let index = items.indexOf(id);
    (index === -1) ? items.push(id) : items.splice(index, 1);
    setShowAddIds(items);
  };


  const saveComment = (id, isIrrelevant) => {
    if(isIrrelevant && commentInput.length<3) {
      setErrorStatus(true);
      return false;
    }

    if(commentInput.length>3) {
      let comment = [{
        "comment": commentInput,
        "user_id": userData.user.id,
        "name": userData.user.name,
        "date": HelperGetCurrentDate(),
      }];
      props.addComment(id, comment,  isIrrelevant ? 'reason' : 'comment');
      setCommentInput("");
      setErrorStatus(false);
    }

  };

  const handleCheck = (id, irrelevant, pdf = null, fileName = "") => {
    let data = {
      "new_green_step_id": id,
      "comment": [],
      "irrelevant": irrelevant,
      "reason": [],
      "pdf": pdf,
      "pdf_name": fileName
    };

    props.check(data);
    setCommentInput('');
    setErrorStatus(false);
    toggleAddCard(id);
  };

  const handleUncheck = (id) => {
    props.unCheck(id);
  };

  const onChangeFavoriteDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const saveFavorite = () => {
    props.addActionPlan(favoriteItem.title, favoriteItem.id, startDate, endDate);
    setFavoriteItem(null);
  };


  return (
    <div className="row greenStepList greenStepSmallCategory">
      {
        paginationData && paginationData.map((greenStep, greenStepIndex) => {
          let categoryParent = smallCategoriesName.find(f => f.id === greenStep.new_green_step_category_id);
          let companyGreenStep = greenStep.new_company_green_steps.length>0 ? greenStep.new_company_green_steps[0] : false;
          let companyGreenStepIsIrrelevant = companyGreenStep && companyGreenStep.hasOwnProperty('irrelevant') && companyGreenStep.irrelevant === true;
          return (
            <div className="col-sm-10 col-md-6 col-lg-6 col-xl-4 col-xxl-4" key={"renderSmallCategory_"+greenStepIndex}>
              <Card className={`
                greenStepListCard 
                ${showAddIds.includes(greenStep.id) ? 'showAdd' : ''}
                ${showDescIds.includes(greenStep.id) ? 'showDesc' : ''}
                ${favoriteItem && favoriteItem.id === greenStep.id ? 'showCalendar' : ''}
              `}>

                <div className="cardFront">

                  <p className="score categoryTextColor">{greenStep.score} {t('pluses_page.points')}</p>
                  <div className="favorite">
                    {greenStep.company_plan_actions && greenStep.company_plan_actions.length>0 ?
                      <Favorite className="categoryTextColor" onClick={() => props.deleteActionPlan(greenStep.company_plan_actions[0].id)}/>
                    :
                      <FavoriteBorder className="categoryTextColor" onClick={() => setFavoriteItem(greenStep)}/>
                    }
                  </div>

                  {favoriteItem && favoriteItem.id === greenStep.id &&
                  <div className="favoriteCalendar">
                    <div className="header categoryTextColor">Tímasetja aðgerð</div>
                    <DatePicker
                      selected={startDate}
                      onChange={onChangeFavoriteDate}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      startDate={startDate}
                      endDate={endDate}
                      dropdownMode="select"
                      selectsRange
                      selectsDisabledDaysInRange
                      inline
                      locale={is}
                    />
                    <div className="footer">
                      <div className="cancel" onClick={() => setFavoriteItem(null)}>{t('cancel')}</div>
                      <div className="save" onClick={() => saveFavorite()}>{t('save')}</div>
                    </div>
                  </div>
                  }

                  {categoryParent && <h5 className="listTitle categoryTextColor">{categoryParent["name_"+currentLang] ? categoryParent["name_"+currentLang] : categoryParent.name}</h5>}
                  <p className="listDesc">{greenStep["title_"+currentLang] ? greenStep["title_"+currentLang] : greenStep.title}</p>

                  <div className="companyCompletedToggleBtns">
                    <div
                      className="companyCompleted"
                      onClick={() => {companyGreenStep ? handleUncheck(companyGreenStep.id) : handleCheck(greenStep.id, false)}}
                    >
                      <div className={`companyCompletedIcon categoryBorderColor ${companyGreenStep && (!companyGreenStep.hasOwnProperty('irrelevant') || companyGreenStep.irrelevant === false)}`}> <Check/> </div>
                      <div className="companyCompletedTitle">{t('yes')}</div>
                    </div>

                    {greenStep.irrelevant && <div className="companyCompleted irrelevant">
                      <Switch
                        id={"irrelevant-card"+"_item_"+greenStepIndex}
                        checked={companyGreenStepIsIrrelevant}
                        onChange={() => companyGreenStep ? handleUncheck(companyGreenStep.id) : handleCheck(greenStep.id, true)}
                        activeBoxShadow="0px 0px 1px 2px #f3f3f3"
                        checkedHandleIcon={
                          <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                            <Check/>
                          </div>
                        }
                        className={`react-custom-switch ${companyGreenStepIsIrrelevant && 'active'}`}
                      />
                      <div className="companyCompletedTitle">{t('pluses_page.irrelevant')}</div>
                    </div>}

                  </div>

                  <div className={`listShowMoreLess categoryTextColor`}>
                    <div className="cardShowMore categoryTextColor" onClick={() => toggleCard(greenStep.id)}>
                      {t('pluses_page.read_more')}
                    </div>
                  </div>
                </div>

                <div className="cardBack" >
                  <p className="listCardDesc">{greenStep["description_"+currentLang] ? greenStep["description_"+currentLang] : greenStep.description}</p>

                  <div className="commentRow">
                    <p className="companyCompletedLabel">
                      {companyGreenStepIsIrrelevant ?
                        <span>{t('pluses_page.why_not_applicable')} <sup style={{color: 'red'}}>*</sup></span> :
                        <span>{t('pluses_page.add_explanation')}</span>
                      }
                    </p>
                    <Input
                      id="title-card"
                      type="text"
                      className="form-control companyCompletedInput"
                      value={commentInput}
                      onKeyDown={evt => evt.key === "Enter" && saveComment(companyGreenStep.id, companyGreenStepIsIrrelevant)}
                      onChange={(e) => {setCommentInput(e.target.value)}}
                      invalid={errorStatus && commentInput.length === 0}
                    />
                  </div>

                  <div className={`listShowMoreLess categoryTextColor`}>
                    <KeyboardBackspace className="hover-effect backIcon" onClick={() => toggleCard(greenStep.id)}/>
                    <KeyboardBackspace className="hover-effect backIcon2" onClick={() => toggleAddCard(greenStep.id)}/>
                    <Check className="hover-effect checkIcon" onClick={() => saveComment(companyGreenStep.id, companyGreenStepIsIrrelevant)} />
                  </div>
                </div>

              </Card>
            </div>
          )
        })
      }

      {paginationData.length > 0 &&
        <div className="paginationRow">
          <div className={`arrow prev ${currentPage === 1 && 'disabled'}`}
               onClick={() => pagination({label: currentPage - 1, value: currentPage - 1}, 'page')}>
            <NavigateBefore/>
            <span>{t('last')}</span>
          </div>
          <Select
            placeholder={t('select')}
            isSearchable={false}
            value={pages.find(item => item.value === currentPage)}
            onChange={(event) => pagination(event)}
            styles={customDropdownPaginationGreenStepsStyles}
            options={pages}
          />
          <div className={`arrow next ${currentPage === pages.length && 'disabled'}`}
               onClick={() => pagination({label: currentPage + 1, value: currentPage + 1}, 'page')}>
            <span><span>{t('next')}</span></span>
            <NavigateNext/>
          </div>
        </div>
      }

    </div>
  )
};

export default Index