import * as actionTypes from '../actionTypes';

const initialState = {
  loading: true,
  greenSteps: [],
  greenStepsRegistration: [],
  greenStepsRegistrationLoading: true,
  loadingCategories: true,
  greenStepsCategories: [],
  achievements: [],
  categoryIndex: 0,
  tips: [],
  loadingTips: false,
  unGoals: [],
  loadingUnGoal: true,
  notification: {
    status: false,
    type: '',
    message: ''
  }
};


const findAndUpdateGreenStep = (data, res, type = 'add', addTag = 'add') => {
  var results = null;

  data.some(function(item, idx) {
    let index = item.new_green_steps.length>0 && item.new_green_steps.findIndex(f => f.id === res.new_green_step_id);

    if (item.new_green_steps.length>0 && index >= 0) {
      results = Object.assign([], data);
      if(type === 'delete') {
        data[idx].new_green_steps[index].new_company_green_steps = [{...res, "deletedItem": true}];
      } else if(type === 'deleteCompletely') {
        data[idx].new_green_steps[index].new_company_green_steps = [];
      } else if(type === 'action_plan') {
        data[idx].new_green_steps[index].company_plan_actions = [res];
      } else if(type === 'action_plan_delete') {
        data[idx].new_green_steps[index].company_plan_actions = [];
      } else {
        if(addTag === 'add') {
          data[idx].new_green_steps[index].new_company_green_steps = [{...res, 'addedRecent': true}];
        } else {
          data[idx].new_green_steps[index].new_company_green_steps = [res];
        }

      }
      return true;
    } else if(item.hasOwnProperty('categories') && item.categories.length>0) {

      let target_branch = findAndUpdateGreenStep(item.categories, res, type);
      if (target_branch) {
        results = Object.assign([], data);
        data[idx].categories = target_branch
        return true;
      }
    }

    return false;
  });

  return results;
};

export default function leafReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_GREEN_STEPS_NEW_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_GREEN_STEPS_NEW_SUCCESS:
      return {
        ...state,
        greenSteps: action.data,
        loading: false
      };
    case actionTypes.GET_GREEN_STEPS_NEW_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.GET_GREEN_STEPS_REGISTRATION_START:
      return  {
        ...state,
        greenStepsRegistrationLoading: true
      };
    case actionTypes.GET_GREEN_STEPS_REGISTRATION_SUCCESS:
      return {
        ...state,
        greenStepsRegistrationLoading: false,
        greenStepsRegistration: action.data
      };
    case actionTypes.GET_GREEN_STEPS_REGISTRATION_FAIL:
      return  {
        ...state,
        greenStepsRegistrationLoading: false
      };


    case actionTypes.GET_GREEN_STEPS_CATEGORIES_START:
      return  {
        ...state,
        loadingCategories: true
      };
    case actionTypes.GET_GREEN_STEPS_CATEGORIES_SUCCESS:
      return {
        ...state,
        greenStepsCategories: action.data,
        loadingCategories: false
      };
    case actionTypes.GET_GREEN_STEPS_CATEGORIES_FAIL:
      return  {
        ...state,
        loadingCategories: false
      };


    case actionTypes.GET_TIPS_START:
      return  {
        ...state,
        loadingTips: true
      };
    case actionTypes.GET_TIPS_SUCCESS:
      return {
        ...state,
        tips: action.data,
        loadingTips: false
      };
    case actionTypes.GET_TIPS_FAIL:
      return  {
        ...state,
        loadingTips: false
      };

    case actionTypes.CREATE_GREEN_STEP_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.CREATE_GREEN_STEP_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case actionTypes.CREATE_GREEN_STEP_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.UPDATE_GREEN_STEP_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_GREEN_STEP_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case actionTypes.UPDATE_GREEN_STEP_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.DELETE_GREEN_STEP_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.DELETE_GREEN_STEP_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_GREEN_STEP_FAIL:
      return  {
        ...state,
        loading: false
      };



    case actionTypes.CREATE_COMPANY_GREEN_STEP_START:
      return  {
        ...state
      };
    case actionTypes.CREATE_COMPANY_GREEN_STEP_SUCCESS:
      let copyInitGreenSteps;
      let updatedDataForCreate = findAndUpdateGreenStep(state.greenSteps.results[action.activeCategory].children_category, action.data.results, 'add', 'addTag')
      let newCopyGreenSteps = {...state.greenSteps};
      if(updatedDataForCreate !== null) {
        newCopyGreenSteps.results[action.activeCategory].children_category = updatedDataForCreate;
      }
      if(action.initAdd === true) {
        copyInitGreenSteps = {...state.greenStepsRegistration};
        let initIndex = copyInitGreenSteps && copyInitGreenSteps.results.length>0 && copyInitGreenSteps.results.findIndex(f => f.id === action.data.results.new_green_step_id);
        if (initIndex >= 0) {
          copyInitGreenSteps.results[initIndex]['completed'] = action.data.results;
        }
      }
      return {
        ...state,
        greenSteps: newCopyGreenSteps,
        greenStepsRegistration: action.initAdd === true ? copyInitGreenSteps : []
      };
    case actionTypes.CREATE_COMPANY_GREEN_STEP_FAIL:
      return  {
        ...state
      };


    case actionTypes.UPDATE_COMPANY_GREEN_STEP_START:
      return  {
        ...state
      };
    case actionTypes.UPDATE_COMPANY_GREEN_STEP_SUCCESS:
      let updatedData = findAndUpdateGreenStep(state.greenSteps.results[action.activeCategory].children_category, action.data.results)
      let newCopyGreenSteps2 = {...state.greenSteps};
      if(updatedData !== null) {
        newCopyGreenSteps2.results[action.activeCategory].children_category = updatedData;
      }
      return {
        ...state,
        greenSteps: newCopyGreenSteps2,
      };
    case actionTypes.UPDATE_COMPANY_GREEN_STEP_FAIL:
      return  {
        ...state
      };


    case actionTypes.CREATE_ACTION_FOR_GREEN_STEP_SUCCESS:
      let updatedDataForCreateActionPlan = findAndUpdateGreenStep(state.greenSteps.results[action.activeCategory].children_category, action.data.results, 'action_plan')
      let newCopyGreenStepsForActionPlan = {...state.greenSteps};
      if(updatedDataForCreateActionPlan !== null) {
        newCopyGreenStepsForActionPlan.results[action.activeCategory].children_category = updatedDataForCreateActionPlan;
      }
      return {
        ...state,
        greenSteps: newCopyGreenStepsForActionPlan
      };

    case actionTypes.DELETE_ACTION_FOR_GREEN_STEP_SUCCESS:
      let updatedDataForDeleteActionPlan = findAndUpdateGreenStep(state.greenSteps.results[action.activeCategory].children_category, action.data.results, 'action_plan_delete')
      let newCopyGreenStepsForDeleteActionPlan = {...state.greenSteps};
      if(updatedDataForDeleteActionPlan !== null) {
        newCopyGreenStepsForDeleteActionPlan.results[action.activeCategory].children_category = updatedDataForDeleteActionPlan;
      }
      return {
        ...state,
        greenSteps: newCopyGreenStepsForDeleteActionPlan
      };


    case actionTypes.MARK_CATEGORY_AS_IRRELEVANT_START:
      return  {
        ...state
      };
    case actionTypes.MARK_CATEGORY_AS_IRRELEVANT_SUCCESS:
    return {
        ...state,
        categoryIndex: 0,
      };
    case actionTypes.MARK_CATEGORY_AS_IRRELEVANT_FAIL:
      return  {
        ...state
      };

    case actionTypes.REMOVE_CATEGORY_AS_IRRELEVANT_START:
      return  {
        ...state
      };
    case actionTypes.REMOVE_CATEGORY_AS_IRRELEVANT_SUCCESS:
      return {
        ...state,
        categoryIndex: 0,
      };
    case actionTypes.REMOVE_CATEGORY_AS_IRRELEVANT_FAIL:
      return  {
        ...state
      };

    case actionTypes.EDIT_COMMENT_START:
      return  {
        ...state
      };
    case actionTypes.EDIT_COMMENT_SUCCESS:
      let updatedDataForEdit = findAndUpdateGreenStep(state.greenSteps.results[action.activeCategory].children_category, action.data.results, '', 'notAdd')
      let newCopyGreenStepsEditComment = {...state.greenSteps};
      if(updatedDataForEdit !== null) {
        newCopyGreenStepsEditComment.results[action.activeCategory].children_category = updatedDataForEdit;
      }
      return {
        ...state,
        greenSteps: newCopyGreenStepsEditComment,
      };
    case actionTypes.EDIT_COMMENT_FAIL:
      return  {
        ...state
      };


    case actionTypes.EDIT_GREEN_STEP_PDF_START:
      return  {
        ...state
      };
    case actionTypes.EDIT_GREEN_STEP_PDF_SUCCESS:
      let updatedDataForEditPdf = findAndUpdateGreenStep(state.greenSteps.results[action.activeCategory].children_category, action.data.results)
      let newCopyGreenStepsEditPdf = {...state.greenSteps};
      if(updatedDataForEditPdf !== null) {
        newCopyGreenStepsEditPdf.results[action.activeCategory].children_category = updatedDataForEditPdf;
      }
      return {
        ...state,
        greenSteps: newCopyGreenStepsEditPdf,
      };
    case actionTypes.EDIT_GREEN_STEP_PDF_FAIL:
      return  {
        ...state
      };



    case actionTypes.DELETE_COMPANY_GREEN_STEP_START:
      return  {
        ...state
      };
    case actionTypes.DELETE_COMPANY_GREEN_STEP_SUCCESS:
      let copyInitDeleteGreenSteps;
      let updatedDataForDelete = findAndUpdateGreenStep(state.greenSteps.results[action.activeCategory].children_category, action.data.results, action.deleteType)
      let oldGreenStepState = {...state.greenSteps};
      if(updatedDataForDelete !== null) {
        oldGreenStepState.results[action.activeCategory].children_category = updatedDataForDelete;
      }
      if(action.initDelete === true) {
        copyInitDeleteGreenSteps = {...state.greenStepsRegistration};
        let initIndex = copyInitDeleteGreenSteps.results.findIndex(f => f.id === action.data.results.new_green_step_id);
        if (initIndex >= 0) {
          delete copyInitDeleteGreenSteps.results[initIndex]['completed']
        }
      }
      return {
        ...state,
        greenSteps: oldGreenStepState,
        greenStepsRegistration: action.initDelete === true ? copyInitDeleteGreenSteps : []
      };
    case actionTypes.DELETE_COMPANY_GREEN_STEP_FAIL:
      return  {
        ...state
      };


    case actionTypes.EDIT_GREEN_STEP_CATEGORY_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.EDIT_GREEN_STEP_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case actionTypes.EDIT_GREEN_STEP_CATEGORY_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.GET_UN_GOALS_START:
      return  {
        ...state,
        loadingUnGoal: true
      };
    case actionTypes.GET_UN_GOALS_SUCCESS:
      return {
        ...state,
        unGoals: action.data,
        loadingUnGoal: false
      };
    case actionTypes.GET_UN_GOALS_FAIL:
      return  {
        ...state,
        loadingUnGoal: false
      };



    default:
      return state;
  }
}
