import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Select from "react-select";
import {Container, CardBody, CardFooter} from "reactstrap";
import {History, Earbuds, NavigateBefore, NavigateNext} from "@mui/icons-material";

import {getActivityLogByCompany, getActivityLogUsersByCompany} from "../../store/actions/activityLog";
import {LoaderSpinner} from "../../components/Common/Loader";
import {customDropdownPaginationStyles} from "../../helpers/dropdown";
import {helperFormatDateWithMonthName} from "../../helpers/main";
import LeafIcon from "../../components/Common/Leaf/leafIcon";

const leafColors = [
  {id: 1, color: "#4FC878", secondColor: "#A7EEBF"},
  {id: 2, color: "#FD974F", secondColor: "#FFCEAC"},
  {id: 3, color: "#4433CD", secondColor: "#E3C9FC"}
];

const categoryType = (type, data) => {
  if(type === "green_step") {
    let catId = data.category_id ? data.category_id : 1;
    let leafColor = leafColors.find(color => color.id === catId);
    return <LeafIcon
      id={"log_leaf_icon"}
      colors={leafColor}
      percentage={10}
    />
  }
  if(type === 'plan') return <Earbuds />;
  return <History/>
};

const ActivityLog = (props) => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;

  const {activityLogs, loading, loadingUsers} = useSelector(state => state.activityLog);
  const [logs, setLogs] = useState([]);
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const pageSize = 50;
  const [logsData, setLogsData] = useState([]);

  useEffect(() => {
      dispatch(getActivityLogByCompany(companyId));
      dispatch(getActivityLogUsersByCompany(companyId));
  }, []);

  useEffect(() => {
    if(activityLogs && activityLogs.results) {
      let data = activityLogs.results.filter(f => f.notification_type !== 'notification');
      let total = Math.ceil(data.length / pageSize);
      let pagesList = [];
      for(let i = 1; i <= total; i++) {
        pagesList.push({selectedLabel: `síða ${i} af ${total}`, label: <div><span className="m_list">{i}</span><span className="m_select">síða {i} af {total}</span></div>, value: i})
      }
      setLogsData(data);
      setPageTotal(total);
      setPages(pagesList);
      setLogs(data.slice(0, pageSize));
    }
  }, [activityLogs]);

  useEffect(() => {
    if(activityLogs.results && logsData) {
      sortingData();
    }
  }, [props.user,  props.dateFrom, props.dateTo]);

  if (loading || loadingUsers) return (<LoaderSpinner/>);

  const sortingData = (event, sortingType ) => {
    let newData = logsData;
    let user = props.user.value;
    let dateFrom = props.dateFrom;
    let dateTo = props.dateTo;

    if(user && user !== 'all') {
      newData = newData.filter(item => item.data.user_id && item.data.user_id === user);
    }
    if(dateFrom) {
      let dateFromTime = new Date(dateFrom).getTime();
      newData = newData.filter(item => item.data.created_at && new Date(item.data.created_at).getTime() > dateFromTime);
    }
    if(dateTo) {
      let dateToTime = new Date(dateTo).setHours(23,59,59,0);
      newData = newData.filter(item => item.data.created_at && new Date(item.data.created_at).getTime() < dateToTime);
    }

    if(sortingType === 'page') {
      newData = newData.slice(pageSize*(event.value-1), event.value * pageSize);
      setCurrentPage(event.value);
    } else {
      let total = Math.ceil(newData.length / pageSize);
      let pagesList = [];
      for(let i = 1; i <= total; i++) {
        pagesList.push({label:<div><span className="m_list">{i}</span><span className="m_select">síða {i} af {total}</span></div>, value: i})
      }
      newData = newData.slice(0, pageSize);
      setPageTotal(total);
      setPages(pagesList);
      setCurrentPage(1);
    }

    setLogs(newData);
  };

  return (
    <div className={`page-content activityLogPage`}>
      <CardBody >
        {logs.map((activityLog, logIndex) => {
          let logData = activityLog.data ? activityLog.data : false;
          return (
            <div className={"containerRow"} key={"activityLog_"+logIndex}>
              <div className="logRow logDate">{logData && logData.created_at ? helperFormatDateWithMonthName(logData.created_at) : ''}</div>
              <div className="logRow">
                <span className={`logType ${logData ? logData.type : ''}`}>
                  {logData ? categoryType(logData.type, logData) : <History/>}
                </span>
                <span className="logCategory">{logData.category || ''}</span>
              </div>
              <div className="logRow desc">
                <span className="logUser">
                  {logData.user_name || ''}
                </span>
                <span className="logDesc">{logData.description || ''}</span>
              </div>
            </div>
          )
        })}
      </CardBody>

      <CardFooter>
        {pageTotal > 1 &&
          <div className="pagination">
            <NavigateBefore
              className={`arrow prev ${currentPage === 1 && 'disabled'}`}
              onClick={() => sortingData({label: currentPage-1, value: currentPage-1}, 'page')}
            />

            <Select
              isSearchable={false}
              value={pages.find(item => item.value === currentPage)}
              onChange={(event) => sortingData(event, 'page')}
              styles={customDropdownPaginationStyles}
              options={pages}
            />

            <NavigateNext
              className={`arrow next ${currentPage === pages.length && 'disabled'}`}
              onClick={() => sortingData({label: currentPage+1, value: currentPage+1}, 'page')}
            />
          </div>
        }
      </CardFooter>
    </div>
  )
};

export default ActivityLog