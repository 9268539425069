import React from 'react';
import { Grid } from 'react-loader-spinner';

export const LoaderSpinner = () => {
  return(
    <div className="d-flex justify-content-center align-items-center" style={{minHeight: '500px'}}>
      <Grid
        height="50"
        width="50"
        color="#4cd74d"
        ariaLabel="grid-loading"
        radius="12.5"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  )
};
