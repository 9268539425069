import React, { useEffect } from "react"
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom"
import { useTranslation } from 'react-i18next';

import { Row, Col, Card, CardBody, Container } from "reactstrap"
import { Field, Form, Formik } from "formik";

import {Tabs, Tab, Box} from '@mui/material';
import {Phone, Email} from '@mui/icons-material';

import {loginUser, loginByPhone, loginSms, loginByPhoneFail, resetPassword} from "../../store/actions/auth";
import logo from "../../assets/images/laufid_logo.svg"
import Snackbar from "../../components/Common/Snackbar";
import {LoaderSpinner} from "../../components/Common/Loader";
import {validateEmail, validatePhone, validateRequired} from "../../helpers/validations";
import LanguageDropdown from "../../components/Common/TopbarDropdown/LanguageDropdown";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {msg, smsPhone, loadingPhone} = useSelector( state => state.auth );
  const [value, setValue] = React.useState(0);
  const [reset, setReset] = React.useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Login | Laufið";
    document.body.className = "authentication-bg";
  });

  const handleVerify = (values) => {
    let data = {
      ...values,
      phone: smsPhone,
    };
    dispatch(loginSms(data, navigate));
  };

  return (
    <React.Fragment>
      <div className="login-page" style={{marginTop: '10%'}}>
        <Container>
          <Row className="justify-content-center">

            <Col md={8} lg={6} xl={6}>
              <Card className="auth_card">
                <CardBody>

                  <LanguageDropdown/>


                  <div className="auth_logo"> <img src={logo} alt="" height="100" /> </div>

                  <Tabs
                    value={value}
                    onChange={(event, newValue) => {setValue(newValue)}}
                    className="auth_tab"
                    style={{marginTop: '30px', cursor: 'pointer'}}
                    centered
                    aria-label="icon label tabs example"
                    textColor="primary"
                    TabIndicatorProps={{style: {background:'#158acb', color: '#158acb'}}}
                  >
                    <Tab icon={<Phone />} label={t('onboarding_page.electronic_id')} />
                    <Tab icon={<Email />} label={t('onboarding_page.email')} />
                  </Tabs>

                  <TabPanel value={value} index={0}>
                    {msg === '' &&
                      <Formik initialValues={{phone: ''}} onSubmit={values => {dispatch(loginByPhone(values, navigate))}}>
                        {({ errors, touched, isValidating }) => (
                          <Form>
                            <label htmlFor="email">{t('onboarding_page.phone_number')}</label>
                            <Field
                              name="phone"
                              type="text"
                              id="phone"
                              placeholder={t('onboarding_page.phone_number')}
                              className="form-control"
                              validate={validatePhone}
                            />
                            {errors.phone && touched.phone && <div>{errors.phone}</div>}

                            <div className="mt-3">
                              {loadingPhone && <LoaderSpinner/>}
                              {!loadingPhone && <button className="btn btn-success w-100 waves-effect waves-light" type="submit">{t('onboarding_page.next')}</button>}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    }

                    {msg === 'SMS sent' &&
                      <React.Fragment>
                        <div className="sms_msg1">Staðfestu símanúmerið þitt <b>{smsPhone}</b></div>
                        <div className="sms_msg2">Við sendum þér SMS með kóða sem þú setur inn hér að neðan.</div>
                        <Formik initialValues={{code: ''}} onSubmit={values => {handleVerify(values)}}>
                          {({ errors, touched, isValidating }) => (
                            <Form>
                              <label htmlFor="email">SMS kóða</label>
                              <Field
                                name="code"
                                type="number"
                                id="code"
                                placeholder="SMS kóða"
                                className="form-control"
                                validate={validateRequired}
                              />
                              {errors.code && touched.code && <div>{errors.code}</div>}
                              <div className="mt-3">
                                <button className="btn btn-success w-100 waves-effect waves-light" type="submit">
                                  Staðfesta kóða
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </React.Fragment>
                    }

                    {(msg !== '' && msg !== 'SMS sent') &&
                      <React.Fragment>
                        <div className="error_phone_login">{t('onboarding_page.login_failed')}</div>
                        <div className="error_phone_login2">{msg}</div>
                        <button className="btn btn-success btn-sm error_phone_btn" type="submit" onClick={()=> {dispatch(loginByPhoneFail())}}>
                          {t('onboarding_page.back')}
                        </button>
                      </React.Fragment>
                    }
                  </TabPanel>

                  <TabPanel value={value} index={1}>
                    {value === 1 && reset === 0 &&
                    <React.Fragment>
                      <Formik initialValues={{email: '', password: ''}} onSubmit={values => { dispatch(loginUser(values, navigate))}}>
                        {({ errors, touched, isValidating }) => (
                          <Form>
                            <label htmlFor="email">{t('onboarding_page.email')}</label>
                            <Field
                              type="text"
                              name="email"
                              id="email"
                              placeholder={t('onboarding_page.email')}
                              className="form-control"
                              validate={validateEmail}
                            />
                            {errors.email && touched.email && <div className="text-danger">{errors.email}</div>}

                            <label htmlFor="email" className="mt-3">{t('onboarding_page.password')}</label>
                            <Field
                              type="password"
                              name="password"
                              id="password"
                              placeholder={t('onboarding_page.password')}
                              className="form-control"
                              validate={validateRequired}
                            />
                            {errors.password && touched.password && <div className="text-danger">{errors.password}</div>}

                            <button className="btn btn-success w-100 waves-effect waves-light mt-3" type="submit">{t('onboarding_page.login')}</button>
                          </Form>
                        )}
                      </Formik>
                      <div className="reset_pass" onClick={() => setReset(1)}> {t('onboarding_page.reset_password')} </div>
                    </React.Fragment>
                    }

                    {value === 1 && reset === 1 &&
                    <React.Fragment>
                      <Formik initialValues={{email: ''}} onSubmit={values => {dispatch(resetPassword(values))}}>
                        {({ errors, touched }) => (
                          <Form>
                            <label htmlFor="email" className="mt-3">Netfang</label>
                            <Field
                              type="text"
                              name="email"
                              id="email"
                              placeholder="Netfang"
                              className="form-control"
                              validate={validateEmail}
                            />
                            {errors.email && touched.email && <div className="text-danger">{errors.email}</div>}
                            <button className="btn btn-success w-100 waves-effect waves-light mb-3 mt-3" type="submit">{t('onboarding_page.reset_password')}</button>
                          </Form>
                        )}
                      </Formik>
                      <button className="btn btn-success btn-sm error_phone_btn" type="submit" onClick={()=> {setReset(0)}}>
                        {t('onboarding_page.back')}
                      </button>
                    </React.Fragment>
                    }

                  </TabPanel>
                </CardBody>
              </Card>
              <Row className="justify-content-center">
                <Link className="btn btn-success w-75 waves-effect waves-light font-size-14 text-center" to="/registration">
                  {t('onboarding_page.register_company')}
                </Link>
              </Row>
              <div className="mt-2 text-center">
                <span>© {new Date().getFullYear()} Laufið</span>
              </div>
            </Col>
          </Row>

        </Container>
        <Snackbar />
      </div>
    </React.Fragment>
  )
};

export default Login;