import React, {useEffect, useState} from "react"
import AddActionPlan from "./components/addActionPlan";

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import BootstrapTheme from "@fullcalendar/bootstrap"
import allLocales from '@fullcalendar/core/locales-all';
import i18n from "../../i18n";

const daysIs = ['Mán', 'Þri', 'Mið', 'Fim', 'Fös', 'Lau', 'Sun'];
const daysEn = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const monthsEn = [
  "January", "February", "March", "April", "May", "June", "July", "August",
  "September", "October", "November", "December"
];
const monthsIs = [
  'janúar', 'febrúar', 'mars', 'apríl', 'maí', 'júní', 'júlí',"ágúst",
  "september", "október", "nóvember", "desember"
];

const Calendar = (props) => {
  const {planGoalsData} = props;
  const currentLang = i18n.language || 'is';

  const priorities = [{label: 'low', value: 1}, {label: 'medium', value: 2}, {label: 'high', value: 3}];
  const [alert, setAlert] = useState(null);
  const [calendarData, setCalendarData] = useState([]);

  useEffect(() => {
    if(planGoalsData) {
      let actionsCalendar = [];
      planGoalsData.forEach(data => {
        if(data.company_plan_actions.length>0) {
          data.company_plan_actions.forEach(item => {
            actionsCalendar.push({
              ...item,
              "title": item.action,
              "start": new Date(item.start_date),
              "end": item.end_date ? new Date(item.end_date) : null,
            })
          });
        }
      });
      setCalendarData(actionsCalendar);
    }
  },[planGoalsData]);

  const renderEventContent = ({event}) => {
    let data = event.extendedProps;
    let users = null;

    if(data && data.user_ids !== 'null') {
      let usersJson = JSON.parse(data.user_ids);
      users = usersJson.map((user, indexUser) => {
        return user.label && <p className="user" key={"user_"+indexUser}>{user.label.split(" ").map(n => n[0]).join("")}</p>
      })
    }

    return (
      <div className={`actionRow ${data.priority ? priorities[data.priority - 1].label : 'low'}`}>
        {users && users}
        <p className='title'>{data.action}</p>
      </div>
    )
  };

  const handleEventClick = ({event}) => {
    setAlert(
      <AddActionPlan
        data={{...event.extendedProps, "id": parseInt(event.id, 10)}}
        onCancel={() => setAlert(null)}
      />
    );
  };

  const moreEvents = (args) => {
    return <span className="moreEvents">{'Show '+args.num+' more'}</span>
  };


  return (
    <div className="actionsCalendar">
      {alert}

        <FullCalendar
          plugins={[
            BootstrapTheme,
            dayGridPlugin,
            // interactionPlugin,
          ]}
          slotDuration={"00:15:00"}
          handleWindowResize={true}
          themeSystem="bootstrap"
          headerToolbar={{
            left: "prev,title,next",
            center: "",
            right: "",
          }}
          titleFormat={ (info) => `${ currentLang === 'is' ? monthsIs[info.date.month+1] : monthsEn[info.date.month+1]} ${info.date.year}`}
          // titleFormat = "Hello, World!"
          locales={allLocales}
          locale={'is'}
          dayMaxEvents={true}
          dayMaxEvents={3}
          dayHeaderContent={function (arg) {
            return currentLang === 'en' ? daysEn[arg.date.getDay()] : daysIs[arg.date.getDay()]
          }}
          moreLinkContent={moreEvents}
          eventContent={renderEventContent}
          events={calendarData}
          editable={true}
          droppable={true}
          selectable={true}
          eventClick={handleEventClick}
        />

    </div>
  )

};

export default Calendar
