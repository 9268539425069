import React from 'react';
import {Card, CardBody, CardTitle} from "reactstrap";
import {useTranslation} from "react-i18next";
import RenderCompletedList from "./renderCompletedList";
import i18n from "../../../i18n";

const CompletedByCategory = (props) => {
  const { t } = useTranslation();
  const currentLang = i18n.language || 'is';
  const {data, mainCategoryIndex, type} = props;

  return (
    <div className="completedByCategory">

      {data.filter(f => f.new_green_steps_completed.length > 0).length > 0 &&
        <Card>
          <CardTitle className="cardCompletedTitle">
            <div className="row justify-content-between">
              <div className="col-4 categoryTextColor mainTitle">
                {type === 'big' ? t('pluses_page.big_steps_completed') : t('pluses_page.small_steps_completed')}
              </div>
            </div>
          </CardTitle>
          <CardBody>
            <div className="row">
              {data.filter(f => f.new_green_steps_completed.length > 0).map((category, categoryIndex) =>
                <div className="containerAll" key={'renderCompleted_' + category.id + '_sub_' + categoryIndex}>
                  <div className="row">
                    <div className="col-12 subCategoryTitle categoryTextColor" style={{paddingLeft: '0'}}>
                      {!category ? '' : category["name_" + currentLang] ? category["name_" + currentLang] : category.name}
                    </div>
                  </div>
                  <div className="row">
                    <RenderCompletedList
                      data={category.new_green_steps_completed}
                      dataType="comment"
                      mainCategoryIndex={mainCategoryIndex}
                      corporateCarItemCount={props.corporateCarItemCount}
                    />
                  </div>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      }

      {data.filter(f => f.new_green_steps_completed_irrelevant.length > 0).length > 0 &&
        <Card>
          <CardTitle className="cardCompletedTitle">
            <div className="row justify-content-between">
              <div className="categoryTextColor mainTitle">
                {t('pluses_page.steps_dont_apply')}
              </div>
            </div>
          </CardTitle>
          <CardBody>
            <div className="row">
              {data.filter(f => f.new_green_steps_completed_irrelevant.length > 0).map((category, categoryIndex) =>
                <RenderCompletedList
                  data={category.new_green_steps_completed_irrelevant}
                  dataType="reason"
                  mainCategoryIndex={mainCategoryIndex}
                  corporateCarItemCount={props.corporateCarItemCount}
                />
              )}

            </div>
          </CardBody>
        </Card>
      }

    </div>
  )
};

export default CompletedByCategory