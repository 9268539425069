import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getCertificates = (companyId = '') => async dispatch => {
  dispatch({type: actionTypes.GET_CERTIFICATES_START});
  try {
    const res = await axios.get('/certificate?company_id='+companyId);
    dispatch({type: actionTypes.GET_CERTIFICATES_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_CERTIFICATES_FAIL});
  }
};

export const createUpdateCertificate = (data, update) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_CERTIFICATE_START : actionTypes.CREATE_CERTIFICATE_START});
  try {
    const res = await axios({
      url: update ? '/certificate/'+ data.id : '/certificate',
      method: update ? 'PUT' : 'POST',
      data: {...data}
    });
    dispatch({type: update ? actionTypes.UPDATE_CERTIFICATE_SUCCESS : actionTypes.CREATE_CERTIFICATE_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Vottun vistuð', style:'success'});
    dispatch(getCertificates());
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_CERTIFICATE_FAIL : actionTypes.CREATE_CERTIFICATE_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};

export const deleteCertificate = (id) => async dispatch => {
  dispatch({type: actionTypes.DELETE_CERTIFICATE_START});
  try {
    const res = await axios.delete('/certificate/' + id);
    dispatch({type: actionTypes.DELETE_CERTIFICATE_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Skírteini eytt', style:'success'});
    dispatch(getCertificates());
  } catch (error) {
    dispatch({type: actionTypes.DELETE_CERTIFICATE_FAIL});
  }
};



export const getCompanyCertificatesAll = () => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_CERTIFICATES_START});
  try {
    const res = await axios.get('/company_certificate');
    dispatch({type: actionTypes.GET_COMPANY_CERTIFICATES_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_CERTIFICATES_FAIL});
  }
};

export const getCompanyCertificates = (companyId) => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_CERTIFICATES_START});
  try {
    const res = await axios.get('/company_certificate/by_company/'+companyId+"?admin=true");
    dispatch({type: actionTypes.GET_COMPANY_CERTIFICATES_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_CERTIFICATES_FAIL});
  }
};

export const createUpdateCompanyCertificate = ({update, data}) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_COMPANY_CERTIFICATE_START : actionTypes.CREATE_COMPANY_CERTIFICATE_START});
  try {
    const res = await axios({
      url: update ? '/company_certificate/'+ data.id : '/company_certificate',
      method: update ? 'PUT' : 'POST',
      data: {...data}
    });
    dispatch({type: update ? actionTypes.UPDATE_COMPANY_CERTIFICATE_SUCCESS : actionTypes.CREATE_COMPANY_CERTIFICATE_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Vottun vistuð', style:'success'});
    dispatch(getCompanyCertificates(data.company_id));
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_COMPANY_CERTIFICATE_FAIL : actionTypes.CREATE_COMPANY_CERTIFICATE_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};

export const deleteCompanyCertificate = (certId) => async dispatch => {
  dispatch({type: actionTypes.DELETE_COMPANY_CERTIFICATE_START});
  try {
    const res = await axios.delete('/company_certificate/' + certId);
    dispatch({type: actionTypes.DELETE_COMPANY_CERTIFICATE_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Skírteini eytt', style:'success'});
  } catch (error) {
    dispatch({type: actionTypes.DELETE_COMPANY_CERTIFICATE_FAIL});
  }
};

export const updateCompanyCertificateStatus = (id, status) => async dispatch => {
  dispatch({type: actionTypes.UPDATE_COMPANY_CERTIFICATE_STATUS_START});
  try {
    const res = await axios({
      url: '/company_certificate/update_status/'+id,
      method: 'PUT',
      data: {
        'approved' : status
      }
    });
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Breytingar vistaðar', style:'success'});
    dispatch({type: actionTypes.UPDATE_COMPANY_CERTIFICATE_STATUS_SUCCESS, data: res.data});
    dispatch(getCompanyCertificatesAll());
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.UPDATE_COMPANY_CERTIFICATE_STATUS_FAIL, data: errorMessage});
  }
};

