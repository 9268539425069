import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getActionPlanGoal = (companyId, categoryId = null) => async dispatch => {
  dispatch({type: actionTypes.GET_ACTION_PLAN_GOAL_START});
  try {
    let res;
    if(categoryId) {
      res = await axios.get(`/plan_goal/?company_id=${companyId}&category_id=${categoryId}`);
    } else {
      res = await axios.get(`/plan_goal/?company_id=${companyId}`);
    }

    dispatch({type: actionTypes.GET_ACTION_PLAN_GOAL_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_ACTION_PLAN_GOAL_FAIL});
  }
};

export const getActionPlanByCompany = (companyId) => async dispatch => {
  dispatch({type: actionTypes.GET_ACTION_PLAN_BY_COMPANY_START});
  try {
    const res = await axios.get('/plan_goal/by_company/'+companyId);
    dispatch({type: actionTypes.GET_ACTION_PLAN_BY_COMPANY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_ACTION_PLAN_BY_COMPANY_FAIL});
  }
};

export const createUpdateActionPlanGoal = (update, data) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_ACTION_PLAN_GOAL_START : actionTypes.CREATE_ACTION_PLAN_GOAL_START});
  try {
    const res = await axios({
      url: update ? '/plan_goal/' + data.id : '/plan_goal',
      method: update ? 'PUT' : 'POST',
      data: {...data}
    });
    if(window.location.pathname === '/action-plan') {
      dispatch(getActionPlanByCompany(data.company_id));
    }
    dispatch({type: update ? actionTypes.UPDATE_ACTION_PLAN_GOAL_SUCCESS : actionTypes.CREATE_ACTION_PLAN_GOAL_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: update ? 'Markmið uppfært!' : 'Markmið vistað', style:'success'});
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_ACTION_PLAN_GOAL_FAIL : actionTypes.CREATE_ACTION_PLAN_GOAL_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};


export const createUpdateActionPlan = (update, data, mainGreenStepCategoryIndex) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_ACTION_PLAN_START : actionTypes.CREATE_ACTION_PLAN_START});
  try {
  let res;
    if(data.plan_goal_id === 0 && data.new_goal) {
      const resGoal = await axios({
        url: '/plan_goal',
        method: 'POST',
        data: {
          "company_id": data.company_id,
          "goal": data.new_goal.label_is
        }
      });

      data['plan_goal_id'] = resGoal.data.results.id;
      res = await axios({
        url: update ? '/company_plan_action/' + data.id : '/company_plan_action',
        method: update ? 'PUT' : 'POST',
        data: {...data}
      });

    } else {
      res = await axios({
        url: update ? '/company_plan_action/' + data.id : '/company_plan_action',
        method: update ? 'PUT' : 'POST',
        data: {...data}
      });
    }

    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: update ? 'Aðgerð uppfært!' : 'Aðgerð vistuð!', style:'success'});
    if(window.location.pathname === '/action-plan') {
      dispatch({type: update ? actionTypes.UPDATE_ACTION_PLAN_SUCCESS : actionTypes.CREATE_ACTION_PLAN_SUCCESS, data: res.data});
      if(data.new_goal) {
        dispatch(getActionPlanGoal(data.company_id));
      }
      dispatch(getActionPlanByCompany(data.company_id));
    }
    if(window.location.pathname === '/adgerdir') {
      dispatch({type: actionTypes.CREATE_ACTION_FOR_GREEN_STEP_SUCCESS, data: res.data, activeCategory: mainGreenStepCategoryIndex});
    }

  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_ACTION_PLAN_FAIL : actionTypes.CREATE_ACTION_PLAN_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};

export const deleteActionPlan = (actionId, companyId, mainGreenStepCategoryIndex = 0) => async dispatch => {
  dispatch({type: actionTypes.DELETE_ACTION_PLAN_START});
  try {
    const res = await axios.delete('/company_plan_action/' + actionId);
    if(window.location.pathname === '/action-plan') {
      dispatch(getActionPlanByCompany(companyId));
      dispatch({type: actionTypes.DELETE_ACTION_PLAN_SUCCESS, data: res.data});
    }
    if(window.location.pathname === '/adgerdir') {
      dispatch({type: actionTypes.DELETE_ACTION_FOR_GREEN_STEP_SUCCESS, data: res.data, activeCategory: mainGreenStepCategoryIndex});
    }


    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Aðgerð eytt!', style:'success'});
  } catch (error) {
    dispatch({type: actionTypes.DELETE_ACTION_PLAN_FAIL});
  }
};

export const deleteActionPlanGoal = (goalId, action = 'all', companyId) => async dispatch => {
  dispatch({type: actionTypes.DELETE_ACTION_PLAN_GOAL_START});
  try {
    const res = await axios.delete('/plan_goal/' + goalId+'?action='+action);
    dispatch(getActionPlanGoal(companyId));
    dispatch(getActionPlanByCompany(companyId));
    dispatch({type: actionTypes.DELETE_ACTION_PLAN_GOAL_SUCCESS, data: res.data, action: action});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Markmið eytt!', style:'success'});
  } catch (error) {
    dispatch({type: actionTypes.DELETE_ACTION_PLAN_GOAL_FAIL});
  }
};
