import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input, Label} from "reactstrap";
import {useDispatch} from "react-redux";
import Select from "react-select";
import {Check, Edit} from "@mui/icons-material";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {customDropdownStyles} from "../../../../helpers/dropdown";
import {createCompanyGreenStep, updateCompanyGreenStep} from "../../../../store/actions/greenStep";
import FileUploadPdf from "../../../../components/Common/CustomUpload/FileUploadPdf";
import pdfIcon from "../../../../assets/images/pdf1.png";


const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const percentOptions = () => {
  let percent = [];
  for(let i=1; i<=100; i++) {
    percent.push({label: i, value: i})
  }
  return percent;
};
const yearOptions = () => {
  let years = [];
  for(let i=0; i<=60; i++) {
    years.push({label: 1990+i, value: 1990+i})
  }
  return years;
};
const yearOptionsTo = () => {
  let years = [];
  for(let i=0; i<=60; i++) {
    years.push({label: 2024+i, value: 2024+i})
  }
  return years;
};

const GreenStep178 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, descHasLink, desc, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const currentLang = i18n.language || 'is';

  const [editPdfId, setEditPdfId] = useState(0);
  const [error, setError] = useState(false);
  const [state, setState] = useState({
    'yearFrom': '',
    'yearTo': '',
    'pdf': '',
    'pdfName': '',
    'link': '',
    'gas': false,
    'gasYearFrom': '',
    'gasYearTo': '',
    'gasPercent': '',
    'waste': false,
    'wasteYearFrom': '',
    'wasteYearTo': '',
    'wastePercent': '',
  });

  useEffect(() => {
    if(companyGreenStep && companyGreenStep.comment.length>0) {
      const comment = JSON.parse(companyGreenStep.comment)[0];
      comment.link = companyGreenStep.link;
      comment.pdf = companyGreenStep.pdf;
      comment.pdfName = companyGreenStep.pdf_name;
      setState(comment);
    }
  }, [props.companyGreenStep]);

  const changeState = (value, item) => {
    let copyState = {...state};
    copyState[item] = value;
    setState(copyState);
  };

  const changePdf= (value, fileName) => {
    let copyState = {...state};
    copyState['pdf'] = value;
    copyState['pdfName'] = fileName;
    setState(copyState);
  };

  const handleSave = () => {
    if(state.yearFrom === '' || state.yearTo === '' ||
      (state.pdf === '' && state.link === '') ||
      (state.gas === true && (state.gasYearFrom === '' || state.gasYearTo === '' || state.gasPercent === '')) ||
      (state.waste === true && (state.wasteYearFrom === '' || state.wasteYearTo === '' || state.wastePercent === ''))
    ) {
      setError(true);
    } else {
      let data = {
        "id": companyGreenStep ? companyGreenStep.id : 0,
        "new_green_step_id": 178,
        "comment": [{
          "comment": `Stefnan gildir frá árinu ${state.yearFrom} til ársins ${state.yearTo}.`,
          "user_id": userData.user.id,
          "name": userData.user.name,
          "yearFrom": state.yearFrom,
          "yearTo": state.yearTo,
          "date": HelperGetCurrentDate(),
          "customComment": true,
          "gas": state.gas,
          "gasYearFrom": state.gasYearFrom,
          "gasYearTo": state.gasYearTo,
          "gasPercent": state.gasPercent,
          "waste": state.waste,
          "wasteYearFrom": state.wasteYearFrom,
          "wasteYearTo": state.wasteYearTo,
          "wastePercent": state.wastePercent
        }],
        "irrelevant": false,
        "reason": [],
        "pdf": state.pdf.includes('https') ? null : state.pdf,
        "pdf_name": state.pdfName,
        "link": state.link,
        "slider_score_percentage": 100
      };

      if(data.id > 0) {
        dispatch(updateCompanyGreenStep(data, userData.companyId, item.main_category-1));
      } else {
        dispatch(createCompanyGreenStep(data, userData.companyId, item.main_category-1));
      }

      setEditPdfId(0);
    }
  };

  return (
    <div className="customGreenStep178">
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
          :
            desc
        }
      </div>

      <p className="categoryTextColor"> Til hvaða árs nær stefnan? </p>

      <div className={`customDropDownYear`}>
        <Label className="col-form-label">Frá árinu</Label>
        <Select
          placeholder={t('select')}
          isSearchable={false}
          onChange={(event) => changeState( event.value, 'yearFrom')}
          styles={customDropdownStyles()}
          options={yearOptions()}
          value={{label: state.yearFrom, value: state.yearFrom}}
          className={`react-select ${error && state.yearFrom.length === 0 && 'has-error'}`}
        />

        <Label className="col-form-label">Til ársins</Label>
        <Select
          placeholder={t('select')}
          isSearchable={false}
          onChange={(event) => changeState( event.value, 'yearTo')}
          styles={customDropdownStyles()}
          options={yearOptionsTo()}
          value={{label: state.yearTo, value: state.yearTo}}
          className={`react-select ${error && state.yearTo.length === 0 && 'has-error'}`}
        />
      </div>


      <div className="confirm_container">
        <div className="confirm_title">
          {currentLang === 'en' && item.pdf_description_en ? item.pdf_description_en : item.pdf_description}
        </div>
        <div className="confirm_title ">
          {currentLang === 'en' && item.pdf_description2_en ? item.pdf_description2_en : item.pdf_description2}
        </div>


        {companyGreenStep && (companyGreenStep.pdf !== "" || companyGreenStep.link !== "") && editPdfId !== item.id &&
          <div className="pdfContainer">
            {companyGreenStep.pdf &&
              <a href={companyGreenStep.pdf} target="_blank" rel="noopener noreferrer">
                <img className='pdf_icon' src={pdfIcon} alt="PDF file"/>
                <span className="pdf_name categoryTextColor"> {companyGreenStep.pdf_name}</span>
              </a>
            }

            {companyGreenStep.link &&
              <div className="pdfLink categoryTextColor">
                {t('pluses_page.link_to_data')}:
                <a href={companyGreenStep.link} target="_blank" rel="noopener noreferrer">{companyGreenStep.link}</a>
              </div>
            }

            <p className="companyCompletedIcon categoryBorderColor true edit hover-effect" onClick={() => {setEditPdfId(item.id)}}>
              <Edit/>
            </p>
          </div>
        }

        {(editPdfId === item.id || !companyGreenStep) && <React.Fragment>
          <div className="pdf">
            <FileUploadPdf
              fileUploadKey={"renderBigCategory_mainItem_" + item.main_category-1 + "_gs178"}
              onUpload={(data, fileName) => changePdf(data, fileName)}
              msg={t('pluses_page.pdf')}/>
          </div>

          <div className="pdfContainerfromLink">
            <Label className="form_label categoryTextColor">{t('pluses_page.link_to_data')}</Label>
            <div className="row">
              <div className="col-sm-10 col-md-10 col-lg-8 col-xl-8 col-xxl-7 inputCol">
                <Input
                  name="action_input"
                  type="text"
                  className="form-control"
                  value={state.link}
                  onChange={(event) => changeState(event.target.value, 'link')}
                />
              </div>
            </div>
          </div>
        </React.Fragment>}

      </div>


      <div className="companyCompletedToggleBtns">
        <div className="companyCompleted" onClick={() => changeState(!state.gas, 'gas')}>
          <div className={`companyCompletedIcon categoryBorderColor checkbox ${state.gas === true}`}>
            <Check/>
          </div>
          <div className="companyCompletedTitle">Fyrirtækið setur fram tölulega áætlun um samdrátt losunar gróðurhúsalofttegunda í umhverfisstefnu.
          </div>
        </div>
      </div>

      {state.gas === true && <React.Fragment>
        <p className="categoryTextColor"> Vinsamlegast setjið inn áætlun fyrir samdrátt losunar
          gróðurhúsalofttegunda. </p>

        <div className={`dropDownBox row`}>
          <div className="col-3">
            <Label className="col-form-label">{t('pluses_page.beginning_year')}</Label>
            <Select
              placeholder={t('select')}
              isSearchable={false}
              onChange={(event) => changeState(event.value, 'gasYearFrom')}
              styles={customDropdownStyles()}
              options={yearOptions()}
              value={{label: state.gasYearFrom, value: state.gasYearFrom}}
              className={`react-select ${error && state.gas === true && state.gasYearFrom.length === 0 && 'has-error'}`}
            />
          </div>
          <div className="col-3">
            <Label className="col-form-label">{t('pluses_page.end_year')}</Label>
            <Select
              placeholder={t('select')}
              isSearchable={false}
              onChange={(event) => changeState(event.value, 'gasYearTo')}
              styles={customDropdownStyles()}
              options={yearOptions()}
              value={{label: state.gasYearTo, value: state.gasYearTo}}
              className={`react-select ${error && state.gas === true && state.gasYearTo.length === 0 && 'has-error'}`}
            />
          </div>
          <div className="col-3">
            <Label className="col-form-label">{t('pluses_page.emission_reductions')}</Label>
            <Select
              placeholder={t('select')}
              isSearchable={false}
              onChange={(event) => changeState(event.value, 'gasPercent')}
              styles={customDropdownStyles()}
              options={percentOptions()}
              value={{label: state.gasPercent, value: state.gasPercent}}
              className={`react-select ${error && state.gas === true && state.gasPercent.length === 0 && 'has-error'}`}
            />
          </div>
        </div>
      </React.Fragment>}


      <div className="companyCompletedToggleBtns">
        <div className="companyCompleted" onClick={() => changeState(!state.waste, 'waste')} >
          <div className={`companyCompletedIcon categoryBorderColor checkbox ${state.waste === true}`}>
            <Check/>
          </div>
          <div className="companyCompletedTitle">Fyrirtækið setur fram tölulega áætlun um samdrátt í úrgangsmyndun í umhverfisstefnu.</div>
        </div>
      </div>


      {state.waste === true && <React.Fragment>
        <p className="categoryTextColor"> Vinsamlegast setjið inn áætlun fyrir samdrátt í úrgangsmyndun. </p>

        <div className={`dropDownBox row`}>
          <div className="col-3">
            <Label className="col-form-label">{t('pluses_page.beginning_year')}</Label>
            <Select
              placeholder={t('select')}
              isSearchable={false}
              onChange={(event) => changeState(event.value, 'wasteYearFrom')}
              styles={customDropdownStyles()}
              options={yearOptions()}
              value={{label: state.wasteYearFrom, value: state.wasteYearFrom}}
              className={`react-select ${error && state.waste === true && state.wasteYearFrom.length === 0 && 'has-error'}`}
            />
          </div>
          <div className="col-3">
            <Label className="col-form-label">{t('pluses_page.end_year')}</Label>
            <Select
              placeholder={t('select')}
              isSearchable={false}
              onChange={(event) => changeState(event.value, 'wasteYearTo')}
              styles={customDropdownStyles()}
              options={yearOptions()}
              value={{label: state.wasteYearTo, value: state.wasteYearTo}}
              className={`react-select ${error && state.waste === true && state.wasteYearTo.length === 0 && 'has-error'}`}
            />
          </div>
          <div className="col-3">
            <Label className="col-form-label">{t('pluses_page.emission_reductions')}</Label>
            <Select
              placeholder={t('select')}
              isSearchable={false}
              onChange={(event) => changeState(event.value, 'wastePercent')}
              styles={customDropdownStyles()}
              options={percentOptions()}
              value={{label: state.wastePercent, value: state.wastePercent}}
              className={`react-select ${error && state.waste === true && state.wastePercent.length === 0 && 'has-error'}`}
            />
          </div>
        </div>
      </React.Fragment>}

      <div className="saveRow">
        <div className="saveBtn categoryBgColor" onClick={()=>handleSave()}>
          {companyGreenStep ? t('pluses_page.update') : t('save')}
        </div>
      </div>


    </div>
  )
};

export default GreenStep178