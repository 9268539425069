import React from "react";
import {useTranslation} from "react-i18next";
import pdfIcon from "../../../assets/images/pdf_icon_add.png";
import Swal from "sweetalert";
import "./fileupload.scss"

const PictureUpload2 = (props) => {
  const { t } = useTranslation();
  const [msg, setMsg] = React.useState(props.msg || t('pluses_page.pdf'));

  const handleFileChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    if (e.target.value.length == 0) {
      setMsg(<span>{t('upload_not_selected')}</span>);
      return;
    }

    let newFile = e.target.files[0];
    var fileTypes = ['jpg', 'jpeg', 'png', 'svg'];  //acceptable file types
    var extension = newFile && newFile.name.split('.').pop().toLowerCase(),  //file extension from input file
      isSuccess = fileTypes.indexOf(extension) > -1;  //is extension in acceptable types

    if(newFile && newFile.size >= 800000) {
      Swal({text:t('upload_file_size')+'800KB', icon: "warning", timer: 4000, buttons: false});
      return;
    }

    if (!isSuccess) {
      Swal({text:t('upload_file_type')+' ('+fileTypes+')', icon: "warning", timer: 4000, buttons: false});
      return;
    }

    reader.onloadend = () => {
      setMsg(<div className="success">{t('upload_success')}</div>);
      props.onUpload(reader.result);
    };
    if (newFile) {
      reader.readAsDataURL(newFile);
    }
  };

  return (
    <div className="pdfUploaderContainer" style={props.containerStyle}>
      <img src={pdfIcon} alt="Image" />
      <label htmlFor={props.fileUploadKey+"inputId"} className="pdf_label"> {msg} </label>
      <input
        type="file"
        id={props.fileUploadKey+"inputId"}
        style={{display: 'none'}}
        onChange={e => handleFileChange(e)} title="Engin mynd valin"
      />
    </div>
  );
};

export default PictureUpload2;