import React, { useEffect } from "react"
import {useDispatch} from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom"
import { Row, Col, Card, CardBody, Container } from "reactstrap"
import {Field, Form, Formik} from "formik";

import {updatePassword} from "../../store/actions/auth";
import logo from "../../assets/images/laufid_logo.svg"
import Snackbar from "../../components/Common/Snackbar";
import {validateEmail, validateRequired} from "../../helpers/validations";


const ResetPassword = (props) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Endursetja lykilorð | Laufið";
  },[]);

  const handleReset = (values) => {
    let data = {
      ...values,
      hash: searchParams.get('hash'),
      time: searchParams.get('time'),
    };
    dispatch(updatePassword(data, navigate));
  };

  return (
    <React.Fragment>
      <div className="reset-page" style={{marginTop: '10%'}}>
        <Container>
          <Row className="justify-content-center">

            <Col md={8} lg={6} xl={5}>
              <Card className="auth_card">
                <CardBody>
                  <div className="auth_logo mb-5"> <img src={logo} alt="" height="100" /> </div>
                  <Formik initialValues={{email: searchParams.get('email') || '', password: '', new_password: ''}} onSubmit={values => {handleReset(values)}}>
                    {({ errors, touched, values }) => (
                      <Form>
                        <label htmlFor="email">Netfang</label>
                        <Field
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Netfang"
                          className="form-control"
                          validate={validateEmail}
                        />
                        {errors.email && touched.email && <div className="text-danger">{errors.email}</div>}

                        <label htmlFor="password" className="mt-3">Settu inn nýja lykilorðið</label>
                        <Field
                          type="password"
                          name="password"
                          id="password"
                          label="Settu inn nýja lykilorðið"
                          placeholder="Settu inn nýja lykilorðið"
                          className="form-control"
                          validate={validateRequired}
                        />
                        {errors.password && touched.password && <div className="text-danger">{errors.password}</div>}

                        <label htmlFor="new_password" className="mt-3">Settu aftur inn nýja lykilorðið</label>
                        <Field
                          type="password"
                          name="new_password"
                          id="new_password"
                          placeholder="Settu aftur inn nýja lykilorðið"
                          className="form-control"
                          validate={validateRequired}
                        />
                        {errors.new_password && touched.new_password && <div className="text-danger">{errors.new_password}</div>}
                        <div className="mt-3">
                          <button className="btn btn-primary w-100 waves-effect waves-light" type="submit" disabled={values.password !== values.new_password}>
                            Endursetja lykilorð
                          </button>
                        </div>
                       </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <span>© {new Date().getFullYear()} Laufið</span>
              </div>
            </Col>
          </Row>

        </Container>
        <Snackbar />
      </div>
    </React.Fragment>
  )
};

export default ResetPassword;