import * as actionTypes from '../actionTypes';

const initialState = {
  loadingUsers: true,
  users: [],
  loadingUsersByCompany: true,
  usersByCompany: [],
  loginActivities: [],
  checkUserExistRes: '',
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function categoryReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_USERS_START:
      return  {
        ...state,
        loadingUsers: true
      };
    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.data,
        loadingUsers: false
      };
    case actionTypes.GET_USERS_FAIL:
      return  {
        ...state,
        loadingUsers: false
      };

      case actionTypes.GET_USERS_BY_COMPANY_START:
      return  {
        ...state,
        loadingUsersByCompany: true
      };
    case actionTypes.GET_USERS_BY_COMPANY_SUCCESS:
      return {
        ...state,
        usersByCompany: action.data,
        loadingUsersByCompany: false
      };
    case actionTypes.GET_USERS_BY_COMPANY_FAIL:
      return  {
        ...state,
        loadingUsersByCompany: false
      };


    case actionTypes.UPDATE_USERS_START:
      return  {
        ...state,
        // loadingUsers: true
      };
    case actionTypes.UPDATE_USERS_SUCCESS:
      let objStore = JSON.parse(localStorage.getItem('p_user'));
      if(action.data.message === 'User Updated' && action.data.results.id === objStore.user.id){
        const copyStore = {...objStore};
        copyStore.user.name = action.data.results.name;
        copyStore.user.email = action.data.results.email;
        copyStore.user.phone = action.data.results.phone;
        copyStore.user.profile_picture = action.data.results.profile_picture;
        copyStore.user.kennitala = action.data.results.kennitala;
        copyStore.user.type = action.data.results.type;
        copyStore.companyId = action.data.results.company_users[0].company_id;
        copyStore.companyIds = action.data.results.company_users.map((value) => value.company_id);
        copyStore.companies = action.data.results.company_users.map((value) => value.company);
        localStorage.setItem('p_user', JSON.stringify(copyStore));
      }
      window.location.reload();
      return {
        ...state,
        // loadingUsers: false
      };
    case actionTypes.UPDATE_USERS_FAIL:
      return  {
        ...state,
        // loadingUsers: false
      };


    case actionTypes.UPDATE_USER_PROFILE_START:
      return  {
        ...state,
        loadingUsers: true
      };
    case actionTypes.UPDATE_USER_PROFILE_SUCCESS:
      let obj = JSON.parse(localStorage.getItem('p_user'));
      if(action.data.message === 'User Updated'){
        const temp = {...obj};
        temp.user.name = action.userData.name;
        temp.user.email = action.userData.email;
        temp.user.phone = action.userData.phone;
        temp.user.kennitala = action.userData.kennitala;
        if(action.userData.profile_picture.length>0) {
          temp.user.profile_picture = action.userData.profile_picture;
        }
        localStorage.setItem('p_user', JSON.stringify(temp));
      }
      window.location.reload();
      return {
        ...state,
        loadingUsers: false
      };
    case actionTypes.UPDATE_USER_PROFILE_FAIL:
      return  {
        ...state,
        loadingUsers: false
      };


    case actionTypes.GET_LOGIN_ACTIVITY_START:
      return  {
        ...state
      };
    case actionTypes.GET_LOGIN_ACTIVITY_SUCCESS:
      return {
        ...state,
        loginActivities: action.data,
      };
    case actionTypes.GET_LOGIN_ACTIVITY_FAIL:
      return  {
        ...state
      };


    case actionTypes.CHECK_USER_EXIST_START:
      return  {
        ...state
      };
    case actionTypes.CHECK_USER_EXIST_SUCCESS:
      return {
        ...state,
        checkUserExistRes: action.data
      };
    case actionTypes.CHECK_USER_EXIST_FAIL:
      return  {
        ...state
      };

    default:
      return state;
  }
}
