import React, {useState} from "react"
import { useTranslation } from 'react-i18next';
import Swal from "sweetalert";
import {useDispatch} from "react-redux";
import {Card, CardBody, UncontrolledTooltip} from "reactstrap"
import {Edit, Close, Check} from '@mui/icons-material';

import {helperFormatDateWithMonthName} from "../../helpers/main";
import AddActionPlan from "./components/addActionPlan";
import {deleteActionPlan, createUpdateActionPlan} from "../../store/actions/actionPlan";
import i18n from "../../i18n";

const Actions = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLang = i18n.language || 'is';
  const {planGoalsData, noGoals, completedPlanGoalsData, expiredData} = props;
  const priorities = [{label: t('plan_page.low'), value: 1}, {label: t('plan_page.medium'), value: 2}, {label: t('plan_page.high'), value: 3}];
  const [alert, setAlert] = useState(null);

  const editActionPlan = (data) => {
    setAlert(
      <AddActionPlan
        actionFrom="greenStep"
        data={data}
        onCancel={() => setAlert(null)}
      />
    );
  };

  const deleteActionPlanConfirm = (data) => {
    Swal({
      title: "Staðfesta aðgerð",
      dangerMode: true,
      buttons: {confirm: t('yes'), cancel: t('no')},
    }).then((confirm) => {
      if (confirm) {
        dispatch(deleteActionPlan(data.id, data.company_id))
      } else {
        Swal.close();
      }
    });
  };

  const toggleAction = (data) => {
    let newData = {
      ...data,
      "user_ids": data.user_ids && data.user_ids !== "null" ? JSON.parse(data.user_ids) : [],
      "status": data.status === "Completed" ? "In progress" : "Completed"
    };
    dispatch(createUpdateActionPlan(data.id>0, newData));
  };

  return (
    <div className="actionsContent">
      {alert}

      {expiredData.length > 0 &&
        <div className="expiredActions">
          <div className="subTitle">{t('plan_page.expired_actions')}</div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <Card>
                <CardBody>
                  {expiredData.map((item, index) => {
                    return (
                      <div className="action" data-green-step-id={item.new_green_step ? item.new_green_step.main_category : 0} key={"expiredAction_" + index}>
                        <span className="icon" onClick={() => toggleAction(item)}><Check/></span>
                        <div className="actionTitle">
                          {item.new_green_step ?
                            item.new_green_step["title_"+currentLang] ? item.new_green_step["title_"+currentLang] : item.new_green_step.title
                          : item.action}
                          <p className="date"> {helperFormatDateWithMonthName(item.end_date)} </p>
                        </div>
                        <span className="edit hover-effect" onClick={() => editActionPlan(item)}><Edit/></span>
                      </div>
                    )
                  })}
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      }


      <div className="actionWithGoals">
        <div className="subTitle">{t('plan_page.connected_actions')}</div>

        {planGoalsData.length > 0 ?
          <div className="row actions">
            {planGoalsData.filter(item => item.company_plan_actions.length > 0).map((value, index) =>
              <div className="col" key={"actionWithGoal_" + index}>
                <Card>
                  <CardBody>
                    <div className="goalTitle">
                      {value["goal_" + currentLang] ? value["goal_" + currentLang] : value.goal}
                    </div>

                    {value.company_plan_actions.map((item, itemIndex) => {
                      let usersJson = item.user_ids !== 'null' ? JSON.parse(item.user_ids) : [];
                      return (
                        <div className="action" data-green-step-id={item.new_green_step ? item.new_green_step.main_category : 0} key={"actionWithGoal_" + index + "_item_" + itemIndex}>
                          <div className="actionTitle">
                            <span className="icon" onClick={() => toggleAction(item)}><Check/></span>
                            {item.new_green_step ?
                              item.new_green_step["title_"+currentLang] ? item.new_green_step["title_"+currentLang] : item.new_green_step.title
                            : item.action}
                          </div>
                          <div className="info">
                            {item.priority && <div className="status"> {priorities[item.priority - 1].label} </div>}
                            {usersJson.length > 0 && <React.Fragment>
                              {
                                usersJson.map((user, indexUser) => {
                                  return user.label &&
                                    <React.Fragment>
                                      <div className="users" key={itemIndex+"_item_user_action"+indexUser} id={'tooltip_'+item.id+"_"+indexUser}>
                                        {user.label.split(" ").map(n => n[0].toUpperCase()).join("")}
                                      </div>
                                      <UncontrolledTooltip placement="bottom" target={'tooltip_'+item.id+"_"+indexUser}>{user.label}</UncontrolledTooltip>
                                    </React.Fragment>
                                })
                              }
                            </React.Fragment>}
                            {item.end_date !== null && <div className="date"> {helperFormatDateWithMonthName(item.end_date)} </div>}
                            <div className="edit hover-effect" onClick={() => editActionPlan(item)}><Edit/></div>
                            <div className="edit hover-effect" onClick={() => deleteActionPlanConfirm(item)}><Close/></div>
                          </div>
                        </div>
                      )
                    })}
                  </CardBody>
                </Card>
              </div>
            )}
          </div>
        : ''
        }

        {planGoalsData.length === 0 && noGoals.length === 0 &&
          <div className = "row actionsNotExist" >
            {t('plan_page.is_there_action')} <br/>{t('plan_page.add_action_plan')}
          </div>
        }
      </div>

      {noGoals.length>0 &&
        <div className="actionWithoutGoals">
          <div className="subTitle">{t('plan_page.not_connected_actions')}</div>

          <div className="row actions">
            {noGoals.map((item, index) => {
              let usersJson = item.user_ids !== 'null' ? JSON.parse(item.user_ids) : [];
              return (
                <div className="col"  key={"actionWithoutGoals_"+index}>
                  <Card>
                    <CardBody>
                      <div className="action" data-green-step-id={item.new_green_step ? item.new_green_step.main_category : 0}>
                        <div className="actionTitle">
                          <span className="icon" onClick={() => toggleAction(item)}><Check/></span>
                          {/*// 158...166 - green step with categories, need show custom action title for it */}
                          {item.new_green_step && item.new_green_step_id && item.new_green_step_id < 158 && item.new_green_step_id > 166  ?
                            item.new_green_step["title_"+currentLang] ? item.new_green_step["title_"+currentLang] : item.new_green_step.title
                          : item.action}
                        </div>
                        <div className="info">
                          {item.priority && <div className="status"> {priorities[item.priority-1].label} </div>}
                          {usersJson.length > 0 && <React.Fragment>
                            {
                              usersJson.map((user, indexUser) => {
                                return user.label &&
                                  <React.Fragment>
                                    <div className="users" key={"actionWithoutGoals_"+index+"_no_goals_users_"+indexUser} id={'tooltip_'+item.id+"_"+indexUser}>
                                      {user.label.split(" ").map(n => n[0].toUpperCase()).join("")}
                                    </div>
                                    <UncontrolledTooltip placement="bottom" target={'tooltip_'+item.id+"_"+indexUser}>{user.label}</UncontrolledTooltip>
                                  </React.Fragment>
                              })
                            }
                          </React.Fragment>}
                          {item.end_date !== null && <div className="date"> {helperFormatDateWithMonthName(item.end_date)} </div>}
                          <div className="edit hover-effect" onClick={() => editActionPlan(item)}> <Edit/> </div>
                          <div className="edit hover-effect" onClick={() => deleteActionPlanConfirm(item)}><Close/></div>
                        </div>
                        <div className="connectToGoal" onClick={() => editActionPlan(item)}>
                          {t('plan_page.connect_to_goal')}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              )
            })}
          </div>
        </div>
      }

      {completedPlanGoalsData.length > 0 &&
        <div className="completedActionGoals">
          <div className="subTitle">{t('plan_page.completed_actions')}</div>

          {completedPlanGoalsData.length > 0 ?
            <div className="row actions">
              {completedPlanGoalsData.map((value, index) =>
                <div className="col" key={"completedActionGoal_" + index}>
                  <Card>
                    <CardBody>
                      <div className={`goalTitle ${value?.goalType}`}>
                        {value["goal_" + currentLang] ? value["goal_" + currentLang] : value.goal}
                      </div>

                      {value.company_plan_actions.map((item, itemIndex) => {
                        let usersJson = item.user_ids !== 'null' ? JSON.parse(item.user_ids) : [];
                        return (
                          <div className="action" data-green-step-id={item.new_green_step ? item.new_green_step.main_category : 0} key={"completedActionGoal_" + index + "_item_" + itemIndex}>
                            <div className="actionTitle">
                              <span className={`icon active ${value?.goalType}`} onClick={() => toggleAction(item)}><Check/></span>
                              {item.new_green_step ?
                                item.new_green_step["title_"+currentLang] ? item.new_green_step["title_"+currentLang] : item.new_green_step.title
                              : item.action}
                            </div>
                            <div className="info">
                              {item.priority && <div className="status"> {priorities[item.priority - 1].label} </div>}
                              {usersJson.length > 0 && <React.Fragment>
                                {
                                  usersJson.map((user, indexUser) => {
                                    return user.label &&
                                      <React.Fragment>
                                        <div className="users" key={itemIndex + "_item_user_action" + indexUser} id={'tooltip_'+item.id+"_"+indexUser}>
                                          {user.label.split(" ").map(n => n[0].toUpperCase()).join("")}
                                        </div>
                                        <UncontrolledTooltip placement="bottom" target={'tooltip_'+item.id+"_"+indexUser}>{user.label}</UncontrolledTooltip>
                                      </React.Fragment>
                                  })
                                }
                              </React.Fragment>}
                              {item.end_date !== null &&
                              <div className="date"> {helperFormatDateWithMonthName(item.end_date)} </div>}
                              <div className="edit hover-effect" onClick={() => editActionPlan(item)}><Edit/></div>
                              <div className="edit hover-effect" onClick={() => deleteActionPlanConfirm(item)}><Close/>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </CardBody>
                  </Card>
                </div>
              )}
            </div>
            : ''
          }
        </div>
      }

    </div>
  )

};

export default Actions
