import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {CardBody, Card, CardTitle, UncontrolledTooltip} from "reactstrap"
import { useTranslation } from 'react-i18next';
import ReactApexChart from "react-apexcharts";
import {AddCircle} from "@mui/icons-material"
import LeafIcon from "../../components/Common/Leaf/leafIcon";
import AddUser from "./components/addUser";
import {t} from "i18next";

const leafColors = [
  {id: 1, color: "#4FC878", secondColor: "#A7EEBF"},
  {id: 2, color: "#FD974F", secondColor: "#FFCEAC"},
  {id: 3, color: "#4433CD", secondColor: "#E3C9FC"}
];

const renderGreenStepCircleChart = (item, id) => {
  let bgColor = '#293450';
  let fillColor = '#293450';
  let textColor = '#ffffff';
  let itemColor = leafColors.find(leaf => leaf.id === id );

  if(item === 100) {
    bgColor = itemColor.color; fillColor = itemColor.color; textColor="#ffffff"
  } else {
    bgColor = itemColor.secondColor; fillColor = itemColor.color; textColor=itemColor.color
  }

  let chartOptions = {
    chart: {
      offsetY: 0,
      type: "radialBar",
      zoom: {
        autoScaleYaxis: true
      }
    },
    plotOptions: {
      radialBar: {
        offsetX: 0,
        offsetY: 0,
        hollow: {margin: 12, size: "70%", background: bgColor},
        track: {
          background: '#fff'
        },
        dataLabels: {
          name: {show: false},
          value: {offsetY: 5, color: textColor, fontSize: "16px", show: true}
        }
      }
    },
    fill: {type: 'solid', colors: [fillColor]},
    stroke: {lineCap: "round"}
  };

  return <ReactApexChart options={chartOptions} series={[item]} type="radialBar" height={155}/>
};

const renderMunicipalitiesChart = (data) => {
  let total = 100;
  let active = 0;
  let nonActive = 0;
  if(data && data.results) {
    active = (data.results.active_companies * 100)/data.results.total_companies;
    nonActive = (data.results.non_active_companies * 100)/data.results.total_companies;
  }

  let chartOptions2 = {
    chart: {
      offsetY: 0,
      type: "radialBar",
      zoom: {
        autoScaleYaxis: true
      }
    },
    plotOptions: {
      radialBar: {
        offsetX: 0,
        offsetY: 0,
        hollow: {size: "30%", background: '#fff'},
        track: {
          background: '#fff'
        },
        dataLabels: {
          name: {show: false},
          total: {show: false},
          value: {show: false}
        }
      }
    },
    fill: {type: 'solid', colors: ['#818282', '#009448', '#005725']},
    stroke: {lineCap: "round"}
  };

  return <ReactApexChart options={chartOptions2} series={[total, active, nonActive]} type="radialBar" height={150}/>
};

const greenStepChartOptions = (period, data) => {
  return {
    colors : ['#009448', '#4FC878', '#FD974F', '#4433CD'],
    chart: {
      height: 300,
      type: 'line',
      zoom: {enabled: false},
      toolbar: {show: false,}
    },
    dataLabels: {enabled: false},
    stroke: {width: [6,6,6,6,6,6], curve: 'straight'},
    legend: {offsetY: 5, offsetX: 5, horizontalAlign: 'left'},
    markers: {size: 0, hover: {sizeOffset: 6}},
    xaxis: { categories: []
      // categories: period === 1 ?
      //   Object.keys(data).slice(0).slice(-6).map(item => i18n.language === 'en' ? item : months[item]) :
      //   Object.keys(data).map(item => i18n.language === 'en' ? item : months[item])
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 5,
      labels: {
        formatter: (val) => { return val+'%' },
        style: {fontSize: '12px',},
      },
    }
  }
};

const lefItem = (id, data, title, key) => {
  let customClass = '';
  customClass =  id === 2 ? 'social' : id === 3 ? 'governance' : 'environment';
  return <div className="leaf_item">
    <LeafIcon
      id={"tooltip_leaf_item_"+key+id}
      colors={leafColors.find(color => color.id === id)}
      percentage={data/100}
    />
    <UncontrolledTooltip className={'tooltip_'+customClass} placement="bottom" target={"tooltip_leaf_item_"+key+id}>
      {title} - {data}%
    </UncontrolledTooltip>
  </div>
};


const DashboardMunicipality = () => {
  const { t } = useTranslation();
  const {municipality} = useSelector(state => state.dashboard);

  const [companies, setCompanies] = useState([]);
  const [totalCountByCategories, setTotalCountByCategories] = useState({"environment": 0, "social": 0, "governance":0});
  const [greenStepPeriod, setGreenStepPeriod] = useState(1);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    if(municipality.results && municipality.results.companies){
      let environment = 0; //Umhverfi
      let social = 0; //Félagslegir þættir
      let governance = 0; //Stjórnarhættir

      municipality.results.companies.forEach(item => {
        environment += item.green_step_category_count["Umhverfi"];
        social += item.green_step_category_count["Félagslegir þættir"];
        governance += item.green_step_category_count["Stjórnarhættir"];
      });

      let filteredCompanies = municipality.results.companies.sort( (a,b) => {
        let aCount = a.green_step_category_count["Umhverfi"]+a.green_step_category_count["Félagslegir þættir"]+a.green_step_category_count["Stjórnarhættir"];
        let bCount = b.green_step_category_count["Umhverfi"]+b.green_step_category_count["Félagslegir þættir"]+b.green_step_category_count["Stjórnarhættir"];
        if ( aCount > bCount ){
          return -1;
        }
        if ( aCount < bCount ){
          return 1;
        }
        return 0;
      });

      setCompanies(filteredCompanies);
      setTotalCountByCategories({
        "environment": Math.round(environment/municipality.results.companies.length),
        "social": Math.round(social/municipality.results.companies.length),
        "governance": Math.round(governance/municipality.results.companies.length)
      });
    }
  },[municipality.results]);

  const addNewOrganization = () => {
    setAlert(<AddUser onCancel={() => setAlert(null)}/>);
  };

  const carbonEmissionChartLine = (data) => {
    if(!data) return;
    let labels = [];
    let series = [{name: t('carbon_page.total_emissions'), data: []}];
    let series1Colors = ['#4FC878', '#007338'];

    data.forEach(function(item, index) {
      labels.push(item.year);
      series[0].data.push(parseFloat(item.average_sum));
    });

    let chartOptions = {
      colors : series1Colors,
      chart: {
        height: 300,
        type: 'line',
        zoom: {enabled: false},
        toolbar: {show: false,}
      },
      dataLabels: {enabled: false},
      stroke: {width: [6,6,6,6,6,6], curve: 'straight'},
      legend: {offsetY: 6, offsetX: 60, horizontalAlign: 'left'},
      markers: {size: 0, hover: {sizeOffset: 6}},
      xaxis: {
        categories: labels,
        offsetY: 8,
      },
      yaxis: {
        labels: {
          formatter: (val) => {return val+' ' +t('carbon_page.kg_co2_eq')},
          style: {fontSize: '12px', color: '#4FC878'},
        },
      }
    };

    return <ReactApexChart options={chartOptions} series={series} type="line" height={300}/>
  };

  return (
    <div className="dashboard_municipality">
      {alert}

      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">

          <div className="row firstRow">
            <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
              <div className="cardTitle">Topp 3</div>
              <Card className="top3">
                <CardBody>
                  {companies.slice(0, 3).map((item, index) =>
                    <div className="item" key={"top3Item"+index}>
                      <div className="title">{item.name}</div>
                      <div className="leaves">
                        {lefItem(1, item.green_step_category_count["Umhverfi"], "Umhverfi", "card_"+index)}
                        {lefItem(2, item.green_step_category_count["Félagslegir þættir"], "Félagslegir þættir", "card_"+index)}
                        {lefItem(3, item.green_step_category_count["Stjórnarhættir"], "Stjórnarhættir", "card_"+index)}
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="cardTitle">Fjöldi</div>
              <Card className="municipalityCount">
                <CardBody>
                  <div className="chart">
                    {renderMunicipalitiesChart(municipality)}
                  </div>
                  <div className="item">
                    <div className="title">Heildarfjöldi <br/>stofnana</div>
                    <div className="count first">{municipality.results ? municipality.results.total_companies : 0}</div>
                  </div>
                  <div className="item">
                    <div className="title">Fjöldi virkra <br/>stofnana</div>
                    <div className="count second">{municipality.results ? municipality.results.active_companies : 0}</div>
                  </div>
                  <div className="item">
                    <div className="title">Fjöldi óvirkra <br/>stofnana</div>
                    <div className="count third">{municipality.results ? municipality.results.non_active_companies : 0}</div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>


          <div className="row secondRow">
            <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
              <div className="cardTitle">{t('carbon_page.total_emissions')}</div>
              <Card className="greenStepsChart">
                <CardBody>
                  {
                    municipality && municipality.results ? carbonEmissionChartLine(municipality.results.carbon_emissions_graph) : ''
                  }
                </CardBody>
              </Card>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-5 col-xl-5 col-xxl-5">
              <div className="cardTitle">Meðal frammistaða</div>
              <Card className="greenStepsCircle">
                <CardBody>
                  <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <div className="chart">
                        {renderGreenStepCircleChart(totalCountByCategories["environment"], 1)}
                      </div>
                      <div className={`category categoryItem_1`}>Umhverfi</div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <div className="chart">
                        {renderGreenStepCircleChart(totalCountByCategories["social"], 2)}
                      </div>
                      <div className={`category categoryItem_2`}>Félagslegir þættir</div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                      <div className="chart">
                        {renderGreenStepCircleChart(totalCountByCategories["governance"], 3)}
                      </div>
                      <div className={`category categoryItem_3`}>Stjórnarhættir</div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>


          <div className="row thirdRow">
            <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
              <div className="cardHead">
                <div className="cardTitle">Mínar stofnanir</div>
                <div className="cardAddNew" onClick={() => addNewOrganization()}>Bjóða stofnun í Laufið <AddCircle/> </div>
              </div>
              <Card className="companiesCard">
                <CardBody>
                  <div className="table">
                    <div className="head">
                      <div className="col first">Stofnanir</div>
                      <div className="col">Einkunn</div>
                      <div className="col">Tengiliður</div>
                      <div className="col">Heildarlosun</div>
                    </div>
                    <div className="body">
                      {companies.map((company, companyIndex) => {
                        return (
                          <div className="row" key={"companyTableRow_"+companyIndex}>
                            <div className="col name">
                              <a href={"https://www.laufid.is/vidskiptavinir/"+company.id} target="_blank" rel="noopener noreferrer">{company.name}</a>
                            </div>
                            <div className="col leaves">
                              {lefItem(1, company.green_step_category_count["Umhverfi"], "Umhverfi", "table_row_"+companyIndex+"_")}
                              {lefItem(2, company.green_step_category_count["Félagslegir þættir"], "Félagslegir þættir", "table_row_"+companyIndex+"_")}
                              {lefItem(3, company.green_step_category_count["Stjórnarhættir"], "Stjórnarhættir", "table_row_"+companyIndex+"_")}
                            </div>
                            <div className="col users">
                              {company.company_users && company.company_users.filter(f => f.user_id !== 62 && f.user_id !== 32 && f.user_id !== 5 && f.user_id !== 3 && f.user_id !== 64)
                                .map((cUser, cUserIndex) =>
                                <div className="user" key={"companyTableRow_"+companyIndex+"_user"+cUserIndex}>
                                  {cUser.user ? cUser.user.name : ''}
                                </div>
                              )}
                            </div>
                            <div className="col"> {company.carbon_emission.year !== null && company.carbon_emission.total_sum+' CO2-íg árið '+ company.carbon_emission.year} </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>

        </div>
      </div>


    </div>
  )
};

export default DashboardMunicipality