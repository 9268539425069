import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getMunicipalities = () => async dispatch => {
  dispatch({type: actionTypes.GET_MUNICIPALITIES_START });
  try {
    const res = await axios.get(`municipality`);
    dispatch({type: actionTypes.GET_MUNICIPALITIES_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
    dispatch({type: actionTypes.GET_MUNICIPALITIES_PLAN_FAIL});
  }
};

export const getInstitutions = (id) => async dispatch => {
  dispatch({type: actionTypes.GET_INSTITUTIONS_START });
  try {
    const res = await axios.get(`municipality/get_insitutions/`+id);
    dispatch({type: actionTypes.GET_INSTITUTIONS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
    dispatch({type: actionTypes.GET_INSTITUTIONS_FAIL});
  }
};


export const addInstitutions = (data) => async dispatch => {
  dispatch({type: actionTypes.ADD_INSTITUTIONS_START });
  try {
    const res = await axios({
      url: 'municipality/add_institution',
      method: 'POST',
      data: data
    });

    dispatch({type: actionTypes.ADD_INSTITUTIONS_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: res.data.message, style:'success'});
  } catch (error) {
    const res = error?.response?.data;
    const message = (res && res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res && res.message + ". " + message;
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: errorMessage, style:'error'});
    dispatch({type: actionTypes.ADD_INSTITUTIONS_FAIL});
  }
};