import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import { useTranslation } from 'react-i18next';
import {Input, Label} from "reactstrap";
import Select from "react-select";
import {Check, Edit} from "@mui/icons-material";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {customDropdownStyles} from "../../../../helpers/dropdown";
import FileUploadPdf from "../../../../components/Common/CustomUpload/FileUploadPdf";
import {createCompanyGreenStep, updateCompanyGreenStep} from "../../../../store/actions/greenStep";
import pdfIcon from "../../../../assets/images/pdf2.png";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const yearOptions = () => {
  let years = [];
  for(let i=0; i<=60; i++) {
    years.push({label: 1990+i, value: 1990+i})
  }
  return years;
};

const yearOptionsTo = () => {
  let years = [];
  for(let i=0; i<=60; i++) {
    years.push({label: 2024+i, value: 2024+i})
  }
  return years;
};

const GreenStep218 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, descHasLink, desc, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const currentLang = i18n.language || 'is';

  const [error, setError] = useState(false);
  const [id, setId] = useState(0);
  const [state, setState] = useState({
    'hiringProcess': false,
    'equalPaySexes': false,
    'processesAgainstLabour': false,
    'communicationWorkplace': false,
    'balanceBetweenLife': false,
    'processesPreventStaff': false,
    'racial': false,
    'genderExpression': false,
    'genderIdentity1': false,
    'religion': false,
    'sexualOrientation': false,
    'impairedWorkingCapacity': false,
    'language': false,
    'age': false,
    'origin': false,
    'disability': false,
    'genderIdentity2': false,
    'viewsLife': false,

    'yearFrom': '',
    'yearTo': '',
    'pdf': '',
    'pdf_name': '',
    'link': '',
    'type': 'add',
  });

  useEffect(() => {
    if(companyGreenStep) {
      let comment = JSON.parse(companyGreenStep.comment);
      comment[0]['pdf'] = companyGreenStep.pdf || '';
      comment[0]['pdf_name'] = companyGreenStep.pdf_name || '';
      comment[0]['link'] = companyGreenStep.link || '';
      comment[0]['type'] = 'show';
      setState(comment[0]);
      setId(companyGreenStep.id);
    }
  }, [props.companyGreenStep]);

  const onChangeState = (item) => {
    let copyState = {...state};
    copyState[item] = !copyState[item];

    if(
      copyState.racial && copyState.genderExpression && copyState.genderIdentity1 && copyState.religion &&
      copyState.sexualOrientation && copyState.impairedWorkingCapacity && copyState.language && copyState.age &&
      copyState.origin && state.disability && copyState.genderIdentity2 && copyState.viewsLife
    ) {
      copyState['processesPreventStaff'] = true;
    } else {
      copyState['processesPreventStaff'] = false;
    }

    setState(copyState);
  };

  const onChangeStateParent = () => {
    let copyState = {...state};
    let value = !copyState['processesPreventStaff']
    copyState['processesPreventStaff'] = value;
    copyState['racial'] = value;
    copyState['genderExpression'] = value;
    copyState['genderIdentity1'] = value;
    copyState['religion'] = value;
    copyState['sexualOrientation'] = value;
    copyState['impairedWorkingCapacity'] = value;
    copyState['language'] = value;
    copyState['age'] = value;
    copyState['origin'] = value;
    copyState['disability'] = value;
    copyState['genderIdentity2'] = value;
    copyState['viewsLife'] = value;
    setState(copyState);
  };

  const onChangeStateItem = (item, value) => {
    let copyState = {...state};
    copyState[item] = value;
    setState(copyState);
  };

  const onChangePdf = (pdf, name) => {
    let copyState = {...state};
    copyState['pdf'] = pdf;
    copyState['pdf_name'] = name;
    setState(copyState);
  };

  const handleSave = () => {
    if(state.yearFrom === '' || state.yearTo === '' || (state.pdf === '' && state.link === '')) {
      setError(true);
      return
    }

    let score = 0;
    score += state.hiringProcess ? 1.6 : 0;
    score += state.equalPaySexes ? 1.6 : 0;
    score += state.processesAgainstLabour ? 1.6 : 0;
    score += state.communicationWorkplace ? 1.6 : 0;
    score += state.balanceBetweenLife ? 1.6 : 0;
    score += state.racial ? 0.133 : 0;
    score += state.genderExpression ? 0.133 : 0;
    score += state.genderIdentity1 ? 0.133 : 0;
    score += state.religion ? 0.133 : 0;
    score += state.sexualOrientation ? 0.133 : 0;
    score += state.impairedWorkingCapacity ? 0.133 : 0;
    score += state.language ? 0.133 : 0;
    score += state.age ? 0.133 : 0;
    score += state.origin ? 0.133 : 0;
    score += state.disability ? 0.133 : 0;
    score += state.genderIdentity2 ? 0.133 : 0;
    score += state.viewsLife ? 0.133 : 0;

    let commentsData = [{
      "comment": `Stefnan gildir frá árinu ${state.yearFrom} til ársins ${state.yearTo}.`,
      'hiringProcess': state.hiringProcess,
      'equalPaySexes': state.equalPaySexes,
      'processesAgainstLabour': state.processesAgainstLabour,
      'communicationWorkplace': state.communicationWorkplace,
      'balanceBetweenLife': state.balanceBetweenLife,
      'processesPreventStaff': state.processesPreventStaff,
      'racial': state.racial,
      'genderExpression': state.genderExpression,
      'genderIdentity1': state.genderIdentity1,
      'religion': state.religion,
      'sexualOrientation': state.sexualOrientation,
      'impairedWorkingCapacity': state.impairedWorkingCapacity,
      'language': state.language,
      'age': state.age,
      'origin': state.origin,
      'disability': state.disability,
      'genderIdentity2': state.genderIdentity2,
      'viewsLife': state.viewsLife,
      'yearFrom': state.yearFrom,
      'yearTo': state.yearTo,

      "user_id": userData.user.id,
      "name": userData.user.name,
      "date": HelperGetCurrentDate(),
      "customComment": true
    }];

    let data = {
      "id": id,
      "new_green_step_id": item.id,
      "slider_score": Math.round(score),
      "slider_score_percentage": Math.round(score),
      "comment": commentsData,
      "irrelevant": false,
      "reason": [],
      "pdf": state.pdf.includes('https') ? null :  state.pdf,
      "pdf_name": state.pdf_name,
      "link": state.link
    };

    if(id > 0) {
      dispatch(updateCompanyGreenStep(data, userData.companyId, item.main_category-1));
    } else {
      dispatch(createCompanyGreenStep(data, userData.companyId, item.main_category-1));
    }

    setState({...state, "pdf": ''})

    setError(false);
  }

  return (
    <div className="customGreenStep218">
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
            :
            desc
        }
      </div>


      <div className="companyCompleted checkBox" onClick={() => onChangeState('hiringProcess')}>
        <div className={`companyCompletedIcon categoryBorderColor ${state.hiringProcess}`}><Check/></div>
        <div className="companyCompletedTitle">Ráðningarferli</div>
      </div>

      <div className="companyCompleted checkBox" onClick={() => onChangeState('equalPaySexes')}>
        <div className={`companyCompletedIcon categoryBorderColor ${state.equalPaySexes}`}><Check/></div>
        <div className="companyCompletedTitle">Jöfn laun kynja</div>
      </div>

      <div className="companyCompleted checkBox" onClick={() => onChangeState('processesAgainstLabour')}>
        <div className={`companyCompletedIcon categoryBorderColor ${state.processesAgainstLabour}`}><Check/></div>
        <div className="companyCompletedTitle"> Ferla gegn barnaþrælkun og nauðungavinnu</div>
      </div>

      <div className="companyCompleted checkBox" onClick={() => onChangeState('communicationWorkplace')}>
        <div className={`companyCompletedIcon categoryBorderColor ${state.communicationWorkplace}`}><Check/></div>
        <div className="companyCompletedTitle">Samskipti á vinnustað</div>
      </div>

      <div className="companyCompleted checkBox" onClick={() => onChangeState('balanceBetweenLife')}>
        <div className={`companyCompletedIcon categoryBorderColor ${state.balanceBetweenLife}`}><Check/></div>
        <div className="companyCompletedTitle">Jafnvægi milli vinnu og einkalífs</div>
      </div>

      <div className="companyCompleted checkBox" onClick={() => onChangeStateParent()}>
        <div className={` companyCompletedIcon categoryBorderColor 
          ${
            state.processesPreventStaff && state.racial && state.genderExpression && state.genderIdentity1 && 
            state.religion && state.sexualOrientation && state.impairedWorkingCapacity && state.language && 
            state.age && state.origin && state.disability && state.genderIdentity2 && state.viewsLife
          }
        `}>
          <Check/>
        </div>
        <div className="companyCompletedTitle"> Ferlar sem koma í veg fyrir að starfsfólk upplifi mismunun vegna:</div>
      </div>

      <div className="subItems">
        <div className="companyCompleted checkBox" onClick={() => onChangeState('racial')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.racial}`}><Check/></div>
          <div className="companyCompletedTitle">Kynþáttar</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('genderExpression')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.genderExpression}`}><Check/></div>
          <div className="companyCompletedTitle">Kyntjáningu</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('genderIdentity1')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.genderIdentity1}`}><Check/></div>
          <div className="companyCompletedTitle">Kynvitundar</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('religion')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.religion}`}><Check/></div>
          <div className="companyCompletedTitle">Trúarbragða</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('sexualOrientation')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.sexualOrientation}`}><Check/></div>
          <div className="companyCompletedTitle">Kynhneigðar</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('impairedWorkingCapacity')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.impairedWorkingCapacity}`}><Check/></div>
          <div className="companyCompletedTitle">Skertrar starfsgetu</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('language')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.language}`}><Check/></div>
          <div className="companyCompletedTitle">Tungumáls</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('age')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.age}`}><Check/></div>
          <div className="companyCompletedTitle">Aldurs</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('origin')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.origin}`}><Check/></div>
          <div className="companyCompletedTitle">Uppruna</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('disability')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.disability}`}><Check/></div>
          <div className="companyCompletedTitle">Fötlunar</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('genderIdentity2')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.genderIdentity2}`}><Check/></div>
          <div className="companyCompletedTitle">Kyneinkenna</div>
        </div>

        <div className="companyCompleted checkBox" onClick={() => onChangeState('viewsLife')}>
          <div className={`companyCompletedIcon categoryBorderColor ${state.viewsLife}`}><Check/></div>
          <div className="companyCompletedTitle">Lífsskoðana</div>
        </div>

      </div>

      <p className="categoryTextColor"> Til hvaða árs nær stefnan? </p>

      <div className={`customDropDownYear`}>
        <Label className="col-form-label">Frá árinu</Label>
        <Select
          placeholder={t('select')}
          isSearchable={false}
          onChange={(event) => onChangeStateItem('yearFrom', event.value)}
          value={state.yearFrom ? {label: state.yearFrom, value: state.yearFrom} : {}}
          styles={customDropdownStyles('#FD974F')}
          options={yearOptions()}
          className={`react-select ${error && state.yearFrom.length === 0 && 'has-error'}`}
        />

        <Label className="col-form-label">Til ársins</Label>
        <Select
          placeholder={t('select')}
          isSearchable={false}
          onChange={(event) => onChangeStateItem('yearTo', event.value)}
          value={state.yearTo ? {label: state.yearTo, value: state.yearTo} : {}}
          styles={customDropdownStyles('#FD974F')}
          options={yearOptionsTo()}
          className={`react-select ${error && state.yearTo.length === 0 && 'has-error'}`}
        />
      </div>


      <div className="confirm_container">
        <div className="confirm_title categoryTextColor">
          {currentLang === 'en' && item.pdf_description_en ? item.pdf_description_en : item.pdf_description}
        </div>
        <div className="confirm_title ">
          {currentLang === 'en' && item.pdf_description2_en ? item.pdf_description2_en : item.pdf_description2}
        </div>

        {state.type === 'show' && state.pdf.length>0 &&
          <div className="pdfContainer">
            <a href={state.pdf} target="_blank" rel="noopener noreferrer">
              <img className='pdf_icon' src={pdfIcon} alt="PDF file"/>
              <span className="pdf_name categoryTextColor"> {state.pdf_name}</span>
            </a>
            <p
              className="companyCompletedIcon categoryBorderColor true edit hover-effect"
              onClick={() => {onChangeStateItem('type', 'add')}}
            >
              <Edit/>
            </p>
          </div>
        }

        {state.type === 'show' && state.link.length > 0 &&
          <div className="pdfContainer">
            <div className="link">
              {t('pluses_page.link_to_data')}: <a href={state.link} className="categoryTextColor" target="_blank"
                                                  rel="noopener noreferrer">{state.link}</a>
            </div>
            <p
              className="companyCompletedIcon categoryBorderColor true edit hover-effect"
              onClick={() => {
                onChangeStateItem('type', 'add')
              }}
            >
              <Edit/>
            </p>
          </div>
        }

        {state.type === 'add' &&
          <div className="pdf">
            <FileUploadPdf
              fileUploadKey={"greenStep218addNewPdf"}
              onUpload={(data, name) => onChangePdf(data, name)}
              msg={t('pluses_page.pdf')}
              mainCategoryIndex={1}
            />
          </div>
        }

        {state.type === 'add' &&
          <div className="link">
            <span>{t('pluses_page.link_to_data')}</span>
            <Input
              name="other"
              type="text"
              value={state.link}
              className="form-control"
              onChange={event => onChangeStateItem("link", event.target.value)}
            />
          </div>
        }
      </div>

      <div className="saveRow">
        <div className="saveBtn categoryBgColor" onClick={() => handleSave()}>
          {t('save')}
        </div>
      </div>


    </div>
  )
};

export default GreenStep218