import * as actionTypes from '../actionTypes';

const initialState = {
  loadingUsers: true,
  users: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function categoryReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_COMPANY_USER_START:
      return  {
        ...state,
        loadingUsers: true
      };
    case actionTypes.GET_COMPANY_USER_SUCCESS:
      return {
        ...state,
        users: action.data,
        loadingUsers: false
      };
    case actionTypes.GET_COMPANY_USER_FAIL:
      return  {
        ...state,
        loadingUsers: false
      };


    case actionTypes.CREATE_COMPANY_USER_START:
      return  {
        ...state,
        loadingUsers: true
      };
    case actionTypes.CREATE_COMPANY_USER_SUCCESS:
      return  {
        ...state,
        loadingUsers: false,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.CREATE_COMPANY_USER_FAIL:
      return  {
        ...state,
        loadingUsers: false,
        notification: { status: true, type: 'error', message: action.data }
      };


    case actionTypes.UPDATE_COMPANY_USER_START:
      return  {
        ...state
      };
    case actionTypes.UPDATE_COMPANY_USER_SUCCESS:
      return  {
        ...state,
        notification: { status: true, type: 'success', message: action.data.message }
      };
    case actionTypes.UPDATE_COMPANY_USER_FAIL:
      return  {
        ...state,
        notification: { status: true, type: 'error', message: action.data }
      };



    case actionTypes.DELETE_COMPANY_USER_START:
      return  {
        ...state
      };
    case actionTypes.DELETE_COMPANY_USER_SUCCESS:
      return {
        ...state
      };
    case actionTypes.DELETE_COMPANY_USER_FAIL:
      return  {
        ...state
      };


    default:
      return state;
  }
}
