import React, {useState} from "react"
import {useTranslation} from 'react-i18next';
import {Row, Modal} from "reactstrap"
import TextField from '@mui/material/TextField';
import "./index.scss"

const EditCategory = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    id: props.data.id || null,
    name: props.data.name || "",
    name_en: props.data.name_en || "",
    nameState: false,
    sort_order: props.data.sort_order || "",
  });

  const verifyLength = (value, length) => {
    return value.length < length;
  };

  const isValidated = () => {
    if (state.name.length > 3){
      return true;
    } else {
      setState({
        ...state,
        nameState: state.name.length < 3
      });
      return false;
    }
  };

  const onConfirm = () => {
    if (isValidated()) {
      props.onSubmit(state, !!state.id);
      props.onCancel();
    }
  };

  const change = (event, stateName, type, stateNameEqualTo) => {
    let status = false;
    if(type === 'length') {
      status = verifyLength(event.target.value, stateNameEqualTo);
    }
    setState({
      ...state,
      [stateName]: event.target.value,
      [stateName+'State']: status,
    });
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{state.id ? t('pluses_page.update') : t('pluses_page.add_new')}</h5>
      </div>
      <div className="modal-body greenStepAdminPageAdd">
        <Row>
          <div className="col-md-12 mb-3">
            <TextField
              className="textField"
              label={ <span> {t('pluses_page.title')}  <small>({t('required')})</small> </span> }
              variant="standard"
              fullWidth
              error={state.nameState}
              value={state.name}
              onChange={event => change(event, 'name', "length", 3)}
            />
          </div>
          <div className="col-md-12 mb-3">
            <TextField
              className="textField"
              label={ <span> {t('pluses_page.title_en')}</span> }
              variant="standard"
              fullWidth
              value={state.name_en}
              onChange={event => change(event, 'name_en', "length", 3)}
            />
          </div>
          <div className="col-md-12 mb-3">
            <TextField
              className="textField"
              label={ <span> {t('pluses_page.sort_order')} </span> }
              variant="standard"
              fullWidth
              value={state.sort_order}
              onChange={event => change(event, 'sort_order')}
            />
          </div>
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => onCancel()} className="btn btn-danger waves-effect" data-dismiss="modal">
          {t('close')}
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  );

};

export default EditCategory