import axios from 'axios';
// const base = 'http://laufid-env.eba-3hsi3ujn.eu-west-2.elasticbeanstalk.com/api'
const base = 'https://api.laufid.is/api'

// http://laufid-env.eba-3hsi3ujn.eu-west-2.elasticbeanstalk.com/
// http://api.laufid.is/


const instance = axios.create({
  baseURL: base,
  headers: {
    "Content-Type": 'application/json',
    "Accept": 'application/json',
    'APP-KEY': '3f8a97e6-51ea-44d7-a9c9-87ae6040f7fb',
  }
});

instance.interceptors.request.use(request => {
  let store = localStorage.p_user ? JSON.parse(localStorage.getItem('p_user')) : '';
  if(store) {
    request.headers.Authorization = 'Bearer ' + store.access_token;
    // request.headers.User_id = store.user.torgid_user.id;
    // request.headers.Person = store.user.torgid_user.name;
  }
  return request;
});

instance.interceptors.response.use(response => {
  return response;
}, error => {
  if (!axios.isCancel(error)) {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      window.location = '/';
    } else {
      return Promise.reject(error);
    }
  }
});

export default instance;