import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from "../../../../i18n";
import ScoreAndFavorite from "../scoreAndFavorite";
import {Check} from "@mui/icons-material";
import Switch from "react-switch";
import RenderDropdownContainer from "../renderDropdownContainer";
import {createCompanyGreenStep, deleteCompanyGreenStep} from "../../../../store/actions/greenStep";
import {useDispatch} from "react-redux";
const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const GreenStep157 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, descHasLink, desc, companyGreenStep, companyGreenStepIsIrrelevant} = props;
  const currentLang = i18n.language || 'is';
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;

  const handleCheck = (id, irrelevant) => {
    let data = {
      "new_green_step_id": id,
      "comment": [],
      "irrelevant": irrelevant,
      "reason": [],
      "pdf": null,
      "pdf_name": null
    };

    dispatch(createCompanyGreenStep(data, companyId, item.main_category-1));
  };

  const handleUncheck = (id) => {
    dispatch(deleteCompanyGreenStep(id, item.main_category-1, 'deleteCompletely'));
  };


  return (
    <React.Fragment>
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <p className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
          :
            desc
        }
      </p>

      <div className={`companyCompletedToggleBtns ${companyGreenStep ? companyGreenStepIsIrrelevant ? 'irrelevant' : 'notIrrelevant' : ''}`}>
        {item.irrelevant &&
          <div className="companyCompleted irrelevant">
            <Switch
              id={'card_carousel_' + item.id + "irrelevant-list"}
              checked={companyGreenStepIsIrrelevant}
              onChange={() => companyGreenStep ? handleUncheck(companyGreenStep.id) : handleCheck(item.id, true)}
              activeBoxShadow="0px 0px 1px 2px #f3f3f3"
              checkedHandleIcon={
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                  <Check/>
                </div>
              }
              className={`react-custom-switch ${companyGreenStepIsIrrelevant && 'active'}`}
            />
            <div className="companyCompletedTitle">{t('pluses_page.irrelevant')}</div>
          </div>
        }
      </div>

      <div className="confirm_container">
        <div className="confirm_title categoryTextColor">
          {currentLang === 'en' && item.pdf_description_en ? item.pdf_description_en : item.pdf_description}
        </div>
        <div className="confirm_title ">
          {currentLang === 'en' && item.pdf_description2_en ? item.pdf_description2_en : item.pdf_description2}
        </div>

        <RenderDropdownContainer mainCategoryIndex={item.main_category-1} itemId={item.id}/>

      </div>


    </React.Fragment>
  )
};

export default GreenStep157