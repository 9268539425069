import React, {useState} from "react"
import { useTranslation } from 'react-i18next';
import {useDispatch} from "react-redux";
import Swal from 'sweetalert';
import {Close} from "@mui/icons-material";
import {Row, Label, Input, Modal} from "reactstrap"
import {createUpdateActionPlanGoal} from "../../../store/actions/actionPlan";
import "../index.scss"

const CreateGoalModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {data} = props;

  const companyId = JSON.parse(localStorage.getItem('p_user'))?.companyId;

  const goalId = data ? data.id : 0;
  const [newGoal, setNewGoal] = useState( data ? data.goal : "");
  const [errorStatus, setErrorStatus] = useState(false);

  const onCancel = () => {
    props.onCancel();
  };

  const handleSave = () => {
    if(newGoal === '') {
      showErrorDialog();
      setErrorStatus(true);
      return
    }

    let newData = {
      "id": goalId,
      "company_id": companyId,
      "goal": newGoal
    };

    dispatch(createUpdateActionPlanGoal(goalId > 0, newData));
    onCancel();
  };

  const showErrorDialog = () => {
    Swal({text:"Vinsamlega fylltu út í alla nauðsynlega textareiti", icon: "warning", timer: 4000, buttons: false});
  };

  return (
    <Modal isOpen={true} size="md" toggle={() => {props.onCancel(null)}} centered backdrop={"static"} className="createNewGoal">
      <div className="modal-header justify-content-between">
        <span className="modal-title">{goalId > 0 ? t('plan_page.update_goal') : t('plan_page.add_new')}</span>
        <Close className="modal-close-icon" onClick={() => onCancel()} />
      </div>
      <div className="modal-body">
        <Row>
          <div className="col col-12 form_element">
            <Input
              id="goal"
              name="goal"
              type="text"
              className="form-control goalField"
              placeholder={t('plan_page.goal_placeholder')}
              value={newGoal}
              onChange={(event) =>{setNewGoal(event.target.value)}}
              invalid={errorStatus && newGoal.length === 0}
            />
            <Label style={{fontWeight: '400', fontSize: '12px'}}><span style={{fontWeight: '500'}}>{t('example')}</span> {t('plan_page.goal_example')}</Label>
          </div>
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => handleSave()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  )
};

export default CreateGoalModal