import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { toggleSnackbarClose } from "../../../store/actions/snackbar";

import {Error, CheckCircle} from '@mui/icons-material';
import "./snackbar.scss"

const Snackbar = () => {
  const timeout = 5000;
  const dispatch = useDispatch();
  const {toggleSnackbar, snackbarMessage, snackbarType} = useSelector( state => state.snackbar );
  let TIMER;

  function handleTimeout() {
    TIMER = setTimeout(() => {
      dispatch(toggleSnackbarClose());
    }, timeout);
  }

  function handleClose() {
    clearTimeout(TIMER);
    dispatch(toggleSnackbarClose());
  }

  useEffect(() => {
    if (toggleSnackbar) {
      handleTimeout();
    }
    return () => {
      clearTimeout(TIMER);
    };
  }, [toggleSnackbar, TIMER]);

  return (
    toggleSnackbar && (
      <div className={`snackbar-container ${snackbarType}`}>
        <div className={"snackbar-icon"}> {snackbarType === 'success' ? <CheckCircle/> : <Error/> } </div>
        {snackbarMessage}
        <div className="snackbar-button"  onClick={handleClose}>x</div>
      </div>
    )
  );
};

export default Snackbar;