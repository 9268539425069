import * as actionTypes from '../actionTypes';

const initialState = {
  loading: false,
  activityLogs: [],
  loadingUsers: false,
  activityLogUsers: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function laufReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_ACTIVITY_LOG_BY_COMPANY_START:
      return  {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ACTIVITY_LOG_BY_COMPANY_SUCCESS:
      return {
        ...state,
        activityLogs: action.data,
        loading: false
      };
    case actionTypes.GET_ACTIVITY_LOG_BY_COMPANY_FAIL:
      return  {
        ...state,
        loading: false
      };

    case actionTypes.GET_ACTIVITY_LOG_USERS_BY_COMPANY_START:
      return  {
        ...state,
        loadingUsers: true,
      };
    case actionTypes.GET_ACTIVITY_LOG_USERS_BY_COMPANY_SUCCESS:
      return {
        ...state,
        activityLogUsers: action.data,
        loadingUsers: false
      };
    case actionTypes.GET_ACTIVITY_LOG_USERS_BY_COMPANY_FAIL:
      return  {
        ...state,
        loadingUsers: false
      };

    default:
      return state;
  }
}
