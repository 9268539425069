import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input} from "reactstrap";
import {useDispatch} from "react-redux";
import {createCompanyGreenStep, editComment} from "../../../../store/actions/greenStep";

const GreenStep205 = (props) => {
  const {item} = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;

  const [socialFactors, setSocialFactors] = useState({
    "id": 0, "employees": '',
    "woman": '', "kvar": '', "men": '', "other": '',
    "manager_woman": '', "manager_kvar": '', "manager_men": '', "manager_other": '', "total": 0
  });
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if(props.item && props.item.id === 205) {
      if(item.new_company_green_steps.length>0 && item.new_company_green_steps[0]['comment'] && item.new_company_green_steps[0]['comment'].length>0) {
        let comment = JSON.parse(item.new_company_green_steps[0]['comment']);
        setSocialFactors({...comment, 'id':item.new_company_green_steps[0].id })
      }
    }
  }, [props.item]);



  const onChangeSocialFactors = (item, event) => {
    if(event.target.value < 1 && event.target.value !== '') {return}
    let temp = {...socialFactors};
    temp[item] = event.target.value;
    let employees = parseInt(temp.employees) || 0;
    let w = parseInt(temp.woman) || 0;
    let k = parseInt(temp.kvar) || 0;
    let m = parseInt(temp.men) || 0;
    let o = parseInt(temp.other) || 0;

    if(item === "employees" || (w+k+m+o) <= employees) {
      setSocialFactors(temp);
      clearTimeout(timer);

      const newTimer = setTimeout(() => {
        if(temp.id>0) {
          dispatch(editComment(temp.id, temp, item.main_category-1, 'comment'));
        } else {
          let newGreenStep = {
            "new_green_step_id": 205,
            "comment": temp,
            "irrelevant": false,
            "reason": [],
            "pdf": null,
            "pdf_name": null
          };
          dispatch(createCompanyGreenStep(newGreenStep, companyId, item.main_category-1));
        }
      }, 1000);

      setTimer(newTimer);
    }

  };

  const onChangeSocialManager = (item, value) => {
    if(value < 1 && value !== '') { return}
    let data = {...socialFactors};
    data[item] = value;

    let w = parseInt(data.manager_woman) || 0;
    let k = parseInt(data.manager_kvar) || 0;
    let m = parseInt(data.manager_men) || 0;
    let o = parseInt(data.manager_other) || 0;
    data['total'] = w+k+m+o;

    setSocialFactors(data);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      if(data.id>0) {
        dispatch(editComment(data.id, data, item.main_category-1, 'comment'));
      } else {
        let newGreenStep = {
          "new_green_step_id": 205,
          "comment": data,
          "irrelevant": false,
          "reason": []
        };
        dispatch(createCompanyGreenStep(newGreenStep, companyId, item.main_category-1));
      }
    }, 1000);

    setTimer(newTimer);
  };

  return (
    <div className={"customGreenStep gs2"}>
      <p className="score categoryTextColor">{item.score} {t('pluses_page.points')}</p>

      <div className="employees">
        <div className="title">{t('pluses_page.equality_title')}</div>
        <Input
          name="employees"
          type="number"
          value={socialFactors.employees || ""}
          className="form-control"
          onChange={event => onChangeSocialFactors("employees", event)}
        />
      </div>
      <br/>
      <div className="title">{t('pluses_page.equality_title2')}</div>
      <div className="users">
        <div className="userType">
          <label>{t('pluses_page.equality_women')}</label>
          <Input
            name="woman"
            type="number"
            value={socialFactors.woman || ""}
            className="form-control"
            onChange={event => onChangeSocialFactors("woman", event)}
          />
          {socialFactors.woman && socialFactors.employees>0 &&
            <span className="percent">{Math.round(socialFactors.woman*100/socialFactors.employees)}%</span>
          }
        </div>
        <div className="userType">
          <label>{t('pluses_page.equality_nonbinary')}</label>
          <Input
            name="kvar"
            type="number"
            value={socialFactors.kvar || ""}
            className="form-control"
            onChange={event => onChangeSocialFactors("kvar", event)}
          />
          {socialFactors.kvar && socialFactors.employees>0 &&
            <span className="percent">{Math.round(socialFactors.kvar*100/socialFactors.employees)}%</span>
          }
        </div>
        <div className="userType">
          <label>{t('pluses_page.equality_men')}</label>
          <Input
            name="men"
            type="number"
            value={socialFactors.men || ""}
            className="form-control"
            onChange={event => onChangeSocialFactors("men", event)}
          />
          {socialFactors.men && socialFactors.employees>0 &&
            <span className="percent">{Math.round(socialFactors.men*100/socialFactors.employees)}%</span>
          }
        </div>
        <div className="userType">
        <label>{t('pluses_page.equality_other')}</label>
          <Input
            name="other"
            type="number"
            value={socialFactors.other || ""}
            className="form-control"
            onChange={event => onChangeSocialFactors("other", event)}
          />
          {socialFactors.other && socialFactors.employees>0 &&
            <span className="percent">{Math.round(socialFactors.other*100/socialFactors.employees)}%</span>
          }
        </div>
      </div>

      <br/>

      <div className="title">{t('pluses_page.equality_title3')}</div>
      <div className="users">
        <div className="userType">
          <label>{t('pluses_page.equality_women')}</label>
          <Input
            name="woman"
            type="number"
            value={socialFactors.manager_woman || ""}
            className="form-control"
            onChange={event => onChangeSocialManager("manager_woman", event.target.value)}
          />
          {socialFactors.total>0 && socialFactors.manager_woman>0 &&
            <span className="percent">{Math.round(socialFactors.manager_woman*100/socialFactors.total)}%</span>
          }
        </div>
        <div className="userType">
          <label>{t('pluses_page.equality_nonbinary')}</label>
          <Input
            name="woman"
            type="number"
            value={socialFactors.manager_kvar || ""}
            className="form-control"
            onChange={event => onChangeSocialManager("manager_kvar", event.target.value)}
          />
          {socialFactors.total>0 && socialFactors.manager_kvar>0 &&
            <span className="percent">{Math.round(socialFactors.manager_kvar*100/socialFactors.total)}%</span>
          }
        </div>
        <div className="userType">
          <label>{t('pluses_page.equality_men')}</label>
          <Input
            name="men"
            type="number"
            value={socialFactors.manager_men || ""}
            className="form-control"
            onChange={event => onChangeSocialManager("manager_men", event.target.value)}
          />
          {socialFactors.total>0 && socialFactors.manager_men>0 &&
            <span className="percent">{Math.round(socialFactors.manager_men*100/socialFactors.total)}%</span>
          }
        </div>
        <div className="userType">
          <label>{t('pluses_page.equality_other')}</label>
          <Input
            name="other"
            type="number"
            value={socialFactors.manager_other || ""}
            className="form-control"
            onChange={event => onChangeSocialManager("manager_other", event.target.value)}
          />
          {socialFactors.total>0 && socialFactors.manager_other>0 &&
            <span className="percent">{Math.round(socialFactors.manager_other*100/socialFactors.total)}%</span>
          }
        </div>
      </div>

    </div>
  )
};

export default GreenStep205