import React, {useEffect} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Table,  Card, CardBody, Button} from "reactstrap"
import {Done, RemoveDone} from '@mui/icons-material';

import DeleteButton from "../../components/Common/CustomButtons/DeleteButton";
import CustomButton from "../../components/Common/CustomButtons/CustomButton";
import pdfIcon from "../../assets/images/pdf_icon.png";
import {deleteCompanyCertificate, getCompanyCertificatesAll, updateCompanyCertificateStatus} from "../../store/actions/certificate";
import {LoaderSpinner} from "../../components/Common/Loader";
import "./index.scss"

const formateDate = (date) => {
  const today = new Date(date);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return dd + '-' + mm + '-' + yyyy;
};

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const {loading, companyCertificates} = useSelector( state => state.certificate );
  const store = JSON.parse(localStorage.getItem('p_user'));
  const permission = store ? store.permission : [];
  const canDelete = permission.includes('admin_certificates_company_delete');

  useEffect(() => {
    dispatch(getCompanyCertificatesAll());
  }, []);

  if (loading) return (<LoaderSpinner/>);

  const tableData = !companyCertificates.results ? '' :
    companyCertificates.results.map((value, index) => {
      return (<tr key={index}>
        <td>{index+1}</td>
        <td>{value.name}</td>
        <td><img className="img-thumbnail rounded category_img" src={value.company.logo} alt="" /> {value.company.name} </td>
        <td><Button color={value.approved ? 'success' : 'warning'} size="sm"> {t(`certificate_page.${value.approved ? 'approved' : 'not_approved'}`)}</Button></td>
        <td>{value.type} </td>
        <td>{value.certificate ? <img className="img-thumbnail rounded category_img" src={value.certificate.logo} title={value.certificate.name} alt={value.certificate.name}/> : 'Önnur vottun' } </td>
        <td><a href={value.pdf} target="_blank" rel="noopener noreferrer"><img style={{width: '20px'}} src={pdfIcon} alt="pdf" /> </a></td>
        <td>{formateDate(value.updated_at)}</td>
        <td>
          {
            value.approved ?
              <CustomButton color="success" icon={<RemoveDone style={{fontSize: '12px'}}/>} action={() => dispatch(updateCompanyCertificateStatus(value.id, false))}/>
            :
              <CustomButton color="success" icon={<Done style={{fontSize: '12px'}}/>} action={() => dispatch(updateCompanyCertificateStatus(value.id, true))}/>
          }
          {canDelete && <DeleteButton action={() => dispatch(deleteCompanyCertificate(value.id))}/>}
        </td>
      </tr>)
    });

  return (
    <div className="page-content">
      <Card>
        <CardBody>
          <div className="table-responsive">
            <Table className="table table-striped table-hover">
              <thead className="table-light">
                <tr>
                  <th>#</th>
                  <th>{t('certificate_page.name')}</th>
                  <th>{t('certificate_page.company')}</th>
                  <th>{t('certificate_page.status')}</th>
                  <th>{t('certificate_page.type')}</th>
                  <th>Certificate</th>
                  <th>PDF</th>
                  <th>{t('certificate_page.date')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableData}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  )
};

export default Index