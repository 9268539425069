import * as actionTypes from '../actionTypes';

const initialState = {
  storiesCompany: [],
  loadingStoriesCompany: true,
  stories: [],
  loading: true,
  loadingSave: false,
  loadingDelete: false,
  achievements: [],
  error: false,
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function companyReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_STORIES_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_STORIES_SUCCESS:
      return {
        ...state,
        stories: action.data,
        loading: false
      };
    case actionTypes.GET_STORIES_FAIL:
      return  {
        ...state,
        loading: false
      };

  case actionTypes.GET_STORIES_COMPANY_START:
      return  {
        ...state,
        loadingStoriesCompany: true,
        achievements: [],
      };
    case actionTypes.GET_STORIES_COMPANY_SUCCESS:
      return {
        ...state,
        storiesCompany: action.data,
        loadingStoriesCompany: false,
        achievements: []
      };
    case actionTypes.GET_STORIES_COMPANY_FAIL:
      return  {
        ...state,
        loadingStoriesCompany: false
      };


    // case actionTypes.CREATE_STORIES_START:
    //   return  {
    //     ...state,
    //     loading: true
    //   };
    // case actionTypes.CREATE_STORIES_SUCCESS:
    //   return  {
    //     ...state,
    //     loading: false
    //   };
    // case actionTypes.CREATE_STORIES_FAIL:
    //   return  {
    //     ...state,
    //     loading: false
    //   };
    //
    //
    // case actionTypes.UPDATE_STORIES_START:
    //   return  {
    //     ...state,
    //     loading: true
    //   };
    // case actionTypes.UPDATE_STORIES_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false
    //   };
    // case actionTypes.UPDATE_STORIES_FAIL:
    //   return  {
    //     ...state,
    //     loading: false
    //   };


    case actionTypes.DELETE_STORIES_START:
      return  {
        ...state,
        loadingDelete: true
      };
    case actionTypes.DELETE_STORIES_SUCCESS:
      const id = action.data.results.id;
      const storyData = state.stories.results.filter((item) => item.id !== id);
      state.stories.results = storyData;
      return {
        ...state,
        loadingDelete: false
      };
    case actionTypes.DELETE_STORIES_FAIL:
      return  {
        ...state,
        loadingDelete: false
      };


    default:
      return state;
  }
}
