import React, {useEffect, useState} from "react"
import {useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {LoaderSpinner} from "../../components/Common/Loader";

const Subscription = () => {
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const {plans, loadingPlan} = useSelector(state => state.registration);
  const firstCompany = userData && userData.companies.length>0 ? userData.companies[0] : false;
  const { t } = useTranslation();

  const [currentPlan, setCurrentPlan] = useState(false);

  useEffect(() => {
    if(plans && plans.results) {
      let plan = plans.results.find(f => f.id === firstCompany.plan_id)
      setCurrentPlan(plan);
    }
  }, [plans]);

  if (loadingPlan) return (<LoaderSpinner/>);

  // const chooseSubscription = () => {
  //   setAlert(<ChangePlan plans={plans} onCancel={() => setAlert('')}/>)
  // };
  //
  // const chooseUnsubscribe = () => {
  //   setAlert(<UnSubscribe onCancel={() => setAlert('')}/>)
  // };

  return (
    <div className="row subscription">
      <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11 col-xxl-11">
        <div className="card activePlan">
          <div className="card-body">
            <div className="title">{t('settings_page.current_subscription')}</div>
            <div className="currentPlanName">{currentPlan && currentPlan.name}</div>
            <div className="plan">
              {currentPlan && parseFloat(currentPlan.price).toFixed(3)} kr. <br/>
              {t('settings_page.software')}<br/>
              {currentPlan && currentPlan.users}{currentPlan && currentPlan.id === 3 && '+'} {t('settings_page.user')} <br/>
              {t('settings_page.visibility')} <br/>
              {t('settings_page.data_hosting')} <br/>
              {currentPlan && (currentPlan.id === 2 || currentPlan.id === 3) && t('settings_page.induction_meeting')} <br/>
              {currentPlan && currentPlan.id === 3 && t('settings_page.personal_advisor')}
            </div>

            <div className="nextPay">
              {/*Næsti reikningur: {parseFloat(currentPlan && currentPlan.price).toFixed(3)} kr þann {HelperAddMonthsToDate(firstCompany.start_date, 1)}*/}
            </div>
            <a className="changeOrCancelSubscription" href="mailto:laufid@laufid.is">{t('settings_page.change_or_cancel')}</a>
            {/*<div className="chooseSubscription hover-effect" onClick={() => chooseSubscription()}>Breyta áskrift</div>*/}
            {/*<div className="unSubscribe" onClick={() => chooseUnsubscribe()}>Breyta áskrift</div>*/}
          </div>
        </div>

        {/*<div className="card history">*/}
        {/*  <div className="card-body">*/}
        {/*    <div className="title">*/}
        {/*      Fyrri reikningar*/}
        {/*    </div>*/}
        {/*    <div className="table">*/}
        {/*      <div className="head">*/}
        {/*        <div className="col first">Dagsetning</div>*/}
        {/*        <div className="col">Áskriftartegund</div>*/}
        {/*        <div className="col">Kostnaður</div>*/}
        {/*      </div>*/}
        {/*      <div className="tableBody">*/}
        {/*        {firstCompany && firstCompany.plan_history && firstCompany.plan_history.length>0 && JSON.parse(firstCompany.plan_history).map((history, index) =>*/}
        {/*          <div className="row" key={"historyTableRow_"+index}>*/}
        {/*            <div className="col name">{helperFormatDate(history.start_date)}</div>*/}
        {/*            <div className="col email">{history.plan}</div>*/}
        {/*            <div className="col email">{history.price}</div>*/}
        {/*          </div>*/}
        {/*        )}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  )
};
export default Subscription;