import React, {useState} from "react"
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert';
import Select from "react-select";
import {Row, Modal, Input, FormFeedback} from "reactstrap"
import TextField from '@mui/material/TextField';
import Autocomplete from "@mui/material/Autocomplete";
import "./index.scss"

const AddUser = (props) => {
  const { t } = useTranslation();
  const roles = [{label: 'Manager', value: 'manager'},  {label: 'Company user', value: 'company_user'}];
  const [errorStatus, setErrorStatus] = useState(false);
  const [state, setState] = useState({
    id: props.data.id || null,
    name: props.data.name || "",
    nameValid: !props.data.name,
    email: props.data.email || "",
    emailValid: !props.data.email ,
    phone: props.data.phone || "",
    phoneValid: !props.data.phone,
    password: "",
    passwordValid: true,
    kennitala: props.data.kennitala || '',
    role: props.data.roles && props.data.roles.length>0 ? props.data.roles[0].name : '',
    roleValid: !!(props.data.roles && props.data.roles.length > 0),
    company_id: props.data.company_users && props.data.company_users.length>0 ? props.data.company_users.map(data => data.company_id) : [],
    companyValid: !!(props.data.company_users && props.data.company_users.length > 0),
  });

  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  };

  const isValidated = () => {
    let data = {...state};
    data['nameValid'] = state.name.length > 3;
    data['emailValid'] = verifyEmail(state.email);
    data['phoneValid'] = (/^\d{7}$/.test(state.phone));
    data['roleValid'] = state.role;
    data['companyValid'] = !!state.company_id;
    if(!props.data.id) {data['passwordValid'] = state.password.length > 6}
    setState(data);

    if(data.nameValid && data.emailValid && data.phoneValid && data.passwordValid && data.role && data.companyValid) {
      return true
    } else {
      Swal({text:'Vinsamlega fylltu út í alla nauðsynlega textareiti', icon: "warning", timer: 4000, buttons: false});
      setErrorStatus(true);
      return false;
    }
  };

  const onConfirm = () => {
    if (isValidated()) {
      props.onSubmit(state, !!state.id);
      props.onCancel();
    }
  };

  const change = (event, stateName) => {
    setState({...state, [stateName]: event.target.value});
  };

  const changeRole = (event) => {
    setState({...state, role: event.value});
  };

  const onCancel = () => {
    props.onCancel();
  };

  const options = props.company.results && props.company.results.map((option) => {
    const firstLetter = option.name.charAt(0).toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const handleChangeCompany = (data) => {
    if(data) {
      let ids = data.map(data => data.id);
      setState({...state, company_id: ids});
    }
  };

  return (
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{state.id ? t('user_page.update') : t('user_page.add_new')}</h5>
      </div>
      <div className="modal-body">
        <Row>
          <div className="col-md-12 mb-3">
            <label className="form-label">{t('user_page.name')} <small>({t('required')})</small></label>
            <Input
              id="name"
              name="name"
              type="text"
              value={state.name}
              className="form-control"
              onChange={event => change(event, 'name')}
              invalid={errorStatus && !state.nameValid}
            />
            {errorStatus && !state.nameValid && <FormFeedback>Length should be more than 3</FormFeedback>}
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">{t('user_page.email')} <small>({t('required')})</small></label>
            <Input
              id="email"
              name="email"
              type="email"
              value={state.email}
              className="form-control"
              onChange={event => change(event, 'email')}
              invalid={errorStatus && !state.emailValid}
            />
            {errorStatus && !state.emailValid && <FormFeedback>The entered email is incorrect example:test@laufid.is</FormFeedback>}
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">{t('user_page.phone')} <small>({t('required')})</small></label>
            <Input
              id="phone"
              name="phone"
              type="text"
              className="form-control"
              value={state.phone}
              onChange={event => change(event, 'phone')}
              invalid={errorStatus && !state.phoneValid}
            />
            {errorStatus && !state.phoneValid &&  <FormFeedback>Phone number must contain 7 digits</FormFeedback>}
          </div>
          {state.id === null && <div className="col-md-12 mb-3">
            <label className="form-label">{t('user_page.password')} <small>({t('required')})</small></label>
            <Input
              id="password"
              name="password"
              type="text"
              value={state.password}
              className="form-control"
              onChange={event => change(event, 'password')}
              invalid={errorStatus && !state.passwordValid}
            />
            {errorStatus && !state.passwordValid && <FormFeedback>Length should be more than 6</FormFeedback>}
          </div>}
          <div className="col-md-12 mb-3">
            <label className="form-label">{t('user_page.kennitala')}</label>
            <Input
              id="kennitala"
              name="kennitala"
              type="text"
              value={state.kennitala}
              className="form-control"
              onChange={event => change(event, 'kennitala')}
            />
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">{t('user_page.role')} <small>({t('required')})</small></label>
            <Select
              isSearchable={false}
              placeholder={t('user_page.role')}
              value={roles.find(f => f.value === state.role)}
              onChange={e => changeRole(e)}
              options={roles.map(item => item)}
              className={`react-select ${errorStatus && !state.roleValid && 'has-error'}`}
            />
          </div>
          <div className="col-md-12 mb-3">
            <Autocomplete
              multiple
              id={"companies"}
              onChange={(event, newValue) => {
                handleChangeCompany(newValue)
              }}
              value={state.company_id ? state.company_id.map(data => options.find(item => item.id === data)) : [] }
              getOptionLabel={(option) => option?.name}
              getOptionSelected={(option, value) => option.id === value.id}
              options={options ? options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) : []}
              groupBy={(option) => option.firstLetter}
              renderInput={(params) => <TextField {...params} variant="standard" label={`${t('user_page.choose_company')}`} />}
            />
            {errorStatus && !state.companyValid && <span style={{color: '#ff715b'}}>Company should be selected</span>}
          </div>
        </Row>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => onCancel()}
          className="btn btn-danger waves-effect"
          data-dismiss="modal"
        >
          {t('close')}
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  );

};

export default AddUser