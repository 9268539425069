import * as actionTypes from '../actionTypes';

export const toggleSnackbarOpen = (message, style) => ({
  type: actionTypes.TOGGLE_SNACKBAR_OPEN,
  message,
  style,
});

export const toggleSnackbarClose = () => ({
  type: actionTypes.TOGGLE_SNACKBAR_CLOSE
});

