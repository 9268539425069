import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Slider from "@mui/material/Slider";
import i18n from "../../../../i18n";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const GreenStepMunicipality = (props) => {
  const { t } = useTranslation();
  const {item, descHasLink, desc, myMunicipality} = props;
  const currentLang = i18n.language || 'is';

  const [sliderItem, setSliderItem] = useState(false);

  useEffect(() => {
    if(props.item && props.item.slider === true) {
      setSliderItem({
        "id": props.item.id,
        "score": props.item.new_company_green_steps.length>0 ? props.item.new_company_green_steps[0].slider_score*100/props.item.score : 0,
        "scorePercentage": props.item.new_company_green_steps.length>0 ? props.item.new_company_green_steps[0].slider_score_percentage : 0
      });
    }
  }, [props.item]);

  return (
    <React.Fragment>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <p className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          item.id === 171 ?
            <div dangerouslySetInnerHTML={{__html: desc}}/>
          :
            descHasLink ?
              <div dangerouslySetInnerHTML={{
                __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
              }}/>
            :
              desc
        }
      </p>

      {item.slider === true &&
        <div className="slider col-sm-12 col-md-8 col-lg-6 col-xl-4 col-xxl-4">
          <div className="title">{currentLang === 'en' && item.slider_description_en ? item.slider_description_en : item.slider_description}</div>
          <Slider
            value={sliderItem ? sliderItem.scorePercentage : 0}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => `${value}%`}
            step={1}
            min={0}
            max={100}
            disabled={true}
          />
          <p className="sliderFinish categoryTextColor">100%</p>
        </div>
      }

      <p className="categoryTextColor">
        {t('pluses_page.note')} {myMunicipality && myMunicipality.name} {t('pluses_page.confirm_step')}
      </p>

    </React.Fragment>
  )
};

export default GreenStepMunicipality