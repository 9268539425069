import React from "react"
import {useDispatch} from "react-redux";

import {Modal} from "reactstrap"
import {Close} from "@mui/icons-material";
import {deleteActionPlanGoal} from "../../../store/actions/actionPlan";
import "../index.scss"

const DeleteGoalModal = (props) => {
  const {data} = props;
  const dispatch = useDispatch();
  const companyId = JSON.parse(localStorage.getItem('p_user'))?.companyId;

  const onCancel = () => {
    props.onCancel();
  };

  const handleSave = (action) => {
    dispatch(deleteActionPlanGoal(data.id, action, companyId))
    onCancel();
  };

  return (
    <Modal isOpen={true} size="md" toggle={() => {props.onCancel(null)}} centered backdrop={"static"} className="deleteActionGoalModal">

      <div className="modal-body">
        <Close className="modal-close-icon" onClick={() => onCancel()} />
        <div className="row title">
          {data.company_plan_actions.length} aðgerðir eru tengdar þessu markmiði
        </div>
        <div className="row subTitle">
          Viltu: eyða aðgerðunum eða færa þær yfir í aðgerðir ekki tengdar markmiði flokknum?
        </div>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary waves-effect" onClick={() => handleSave('all')}>
          Eyða
        </button>
        <button type="button" className="btn btn-primary waves-effect" onClick={() => handleSave('category')}>
          Færa um flokk
        </button>
      </div>
    </Modal>
  )
};

export default DeleteGoalModal