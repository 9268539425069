import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input} from "reactstrap";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {useDispatch} from "react-redux";
import {createCompanyGreenStep} from "../../../../store/actions/greenStep";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const GreenStep207 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, descHasLink, desc, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const currentLang = i18n.language || 'is';

  const [assessor, setAssessor] = useState('');
  const [placePublication, setPlacePublication] = useState('');
  const [error, setError] = useState(false);


  const handleCheck = () => {
    if(assessor.length>3 && placePublication.length>3) {
      let data = {
        "new_green_step_id": item.id,
        "comment": [{
          "comment": `Sjálfbærniupplýsingar fyrirtækisins eru staðfestar af ${assessor} og birtar hér ${placePublication}.`,
          "assessor" : assessor,
          "placePublication": placePublication,
          "user_id": userData.user.id,
          "name": userData.user.name,
          "date": HelperGetCurrentDate(),
          "customComment": true
        }],
        "irrelevant": false,
        "reason": [],
        "pdf": null,
        "pdf_name": null
      };

      dispatch(createCompanyGreenStep(data, userData.companyId, item.main_category-1));
      setError(false);
    } else {
      setError(true);
    }
  };


  return (
    <div className="customGreenStep207">
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
            :
            desc
        }
      </div>

      <div className="row" style={{alignItems: 'center'}}>
        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-xxl-8">
          <label htmlFor="">Úttektaraðili</label>
          <Input
            id="text"
            type="text"
            className="form-control goalField"
            value={assessor}
            onChange={(event) => {
              setAssessor(event.target.value)
            }}
            invalid={error && assessor.length === 0}
          />
        </div>
      </div>

      <br/>

      <div className="row">
        <div className="col-sm-10 col-md-10 col-lg-10 col-xl-8 col-xxl-8">
          <label htmlFor="">Birtingarstaður</label>
          <Input
            id="text"
            type="text"
            className="form-control goalField"
            value={placePublication}
            onChange={(event) => {
              setPlacePublication(event.target.value)
            }}
            invalid={error && placePublication.length === 0}
          />
        </div>
        <div className="col-2 save">
          <div className="saveBtn categoryBgColor" onClick={() => handleCheck()}>
            {t('save')}
          </div>
        </div>
      </div>

    </div>
  )
};

export default GreenStep207