import React, {useState} from "react"
import { useTranslation } from 'react-i18next';
import {Row, Modal} from "reactstrap"
import TextField from '@mui/material/TextField';
import PictureUpload from "../../components/Common/CustomUpload/PictureUpload.js";
import "./index.scss"

const AddCertificate = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    id: props.data ? props.data.id : null,
    name: props.data ? props.data.name : '',
    nameState: false,
    number: props.data ? props.data.number : '',
    link: props.data ? props.data.link : '',
    description: props.data ? props.data.description : '',
    type: props.data ? props.data.type : "company",
    logo: props.data ? props.data.logo : '',
  });

  const isValidated = () => {
    if (state.name.length > 1 && state.nameState === false && state.type) {
      return true
    } else {
      setState({...state, nameState: true});
      return false;
    }
  };

  const onConfirm = () => {
    if (isValidated()) {
      props.onSubmit(state, !!state.id);
      props.onCancel();
    }
  };

  const changeName = (event) => {
    setState({
      ...state,
      "name": event.target.value,
      "nameState": event.target.value.length < 3,
    });
  };

  const change = (event, stateName) => {
    setState({...state, [stateName]: event.target.value});
  };

  const onUpload = (data) => {
    setState({...state, "logo": data});
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{state.id ? t('certificate_page.update') : t('certificate_page.add_new')}</h5>
      </div>
      <div className="modal-body">
        <Row>
          <div className="col-md-12 mb-3">
            <TextField
              className="textField"
              label={ <span> {t('certificate_page.name')} <small>({t('required')})</small> </span> }
              variant="standard"
              fullWidth
              error={state.nameState}
              value={state.name}
              onChange={event => changeName(event)}
            />
          </div>
          <div className="col-md-12 mb-3">
            <TextField
              className="textField"
              label={ <span> {t('certificate_page.number')}</span> }
              variant="standard"
              fullWidth
              value={state.number}
              onChange={event => change(event, "number")}
            />
          </div>
          <div className="col-md-12 mb-3">
            <TextField
              className="textField"
              label={ <span> {t('certificate_page.link')} </span> }
              variant="standard"
              fullWidth
              value={state.link}
              onChange={event => change(event, "link")}
            />
          </div>
          <div className="col-md-12 mb-3">
            <TextField
              className="textField"
              label={ <span> {t('certificate_page.description')} </span> }
              variant="standard"
              fullWidth
              value={state.description}
              onChange={event => change(event, "description")}
            />
          </div>
          <div className="col-md-12 mb-3">
            <select value={state.role} onChange={e => change(e, "type")} className="select_role">
              <option value="">{t('certificate_page.type')}</option>
              <option value="company">Company</option>
              <option value="product">Product</option>
              <option value="awards">Awards</option>
            </select>
          </div>
          <div className="col-md-12 mb-3">
            <PictureUpload imgHolder={state.logo ? state.logo : null} msg={t('certificate_page.upload_logo')} onUpload={(data) => onUpload(data)} />
          </div>
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => onCancel()} className="btn btn-danger waves-effect" data-dismiss="modal">
          {t('close')}
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  );

};

export default AddCertificate