import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import {Modal, Label, Input, UncontrolledTooltip} from "reactstrap"

import DatePicker from "react-datepicker";
import {Close, Add, Edit} from "@mui/icons-material";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import Swal from "sweetalert";

import {getLaufidUsersByCompany} from "../../../store/actions/user";
import {getActionPlanGoal, createUpdateActionPlan} from "../../../store/actions/actionPlan";
import {LoaderSpinner} from "../../../components/Common/Loader";
import {helperFormatDate, HelperGetCurrentDate} from "../../../helpers/main";
import CreateGoalModal from "./createGoalModal";
import {customDropdownStyles, customDropdownStylesCreate} from "../../../helpers/dropdown";
import {getGreenStepsNew} from "../../../store/actions/greenStep";

import "react-datepicker/dist/react-datepicker.css"
import "../index.scss"
import i18n from "../../../i18n";

const days = ['Mán', 'Þri', 'Mið', 'Fim', 'Fös', 'Lau', 'Sun'];
const months = ['janúar', 'febrúar', 'mars', 'apríl', 'maí', 'júní', 'júlí', 'ágúst', 'september', 'október', 'nóvember', 'desember'];
const locale = {localize: {day: n => days[n], month: n => months[n]}, formatLong: {date: () => 'dd/mm/yyyy'}};

// const getGreenStepData = (category) => {
//   let notCompleted = [];
//   category.children_category.forEach((subCategory) => {
//     if(subCategory.new_green_steps.length>0) {
//       let notCompletedGreenSteps = subCategory.new_green_steps.filter(item => item.new_company_green_steps.length === 0);
//       notCompletedGreenSteps && notCompleted.push(...notCompletedGreenSteps);
//     }
//
//     if(subCategory.categories.length>0) {
//       subCategory.categories.forEach((cat) => {
//         let notCompletedCategory = cat.new_green_steps.filter(item => item.id !== 188 && item.id !== 189 && item.new_company_green_steps.length === 0);
//         notCompletedCategory && notCompleted.push(...notCompletedCategory);
//       });
//     }
//
//   });
//
//   return notCompleted && notCompleted.map(item => {
//     return {label: item.title, value: item.id, category_id: category.id}
//   });
// };

const AddActionPlan = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLang = i18n.language || 'is';
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData ? userData.companyId : 0;
  const {data, actionFrom} = props;

  const {actionPlanGoals, loadingActionPlanGoals} = useSelector( state => state.actionPlan);
  const {greenSteps, loading:lodingGreenSteps} = useSelector( state => state.greenStep );

  const {usersByCompany, loadingUsersByCompany} = useSelector(state => state.user);
  const priorities = [{label: t('plan_page.low'), value: 1}, {label: t('plan_page.medium'), value: 2}, {label: t('plan_page.high'), value: 3}];

  const [alert, setAlert] = React.useState(null);
  const [errorStatus, setErrorStatus] = useState(false);
  // const [notCompletedList, setNotCompletedList] = useState([]);
  const [actionPlanGoalsList, setActionPlanGoalsList] = useState([]);

  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [id, setId] = useState(data && data.id ? data.id : 0 );
  const [actionInput, setActionInput] = useState(data && data.action ? data.action : '');
  const [action, setAction] = useState(data && data.action ? data.action : '');
  const [priority, setPriority] = useState(data && data.priority ? priorities[data.priority-1] : null);
  const [status, setStatus] = useState(data && data.status ? {label: data.status, value: data.status} : {label: t('plan_page.in_progress'), value: 'In progress'});
  const [startDate, setStartDate] = useState(data && data.start_date ? new Date(data.start_date) : new Date());
  const [endDate, setEndDate] = useState(data && data.end_date ? new Date(data.end_date) : null);
  const [comment, setComment] = useState(data && data.comment ? data.comment : '');
  const usersJson = data && data.user_ids && data.user_ids !== "null" ? JSON.parse(data.user_ids) : [];
  const [users, setUsers] = useState(usersJson.length>0 ? usersJson.map(item => {return {label: item.label, value: item.value, profile: item.profile}}) : "");
  const [goal, setGoal] = useState(null);

  const getActionPlanGoalsByCategory = (catId) => {
    let planGoalList = [];
    if(actionPlanGoals.results) {

      actionPlanGoals.results.forEach(item => {
        planGoalList.push({label: item.goal, label_is: item.goal, label_en: null, value: item.id, companyId: item.company_id})
      });

      if(!planGoalList.find(f => f.label === 'Draga úr rafmagnsnotkun um 10% fyrir árið 2024')) {
        planGoalList.push({
          label_is: 'Draga úr rafmagnsnotkun um 10% fyrir árið 2024',
          label_en: 'Reduce electricity consumption by 10% by 2024',
          value: 0,
          companyId: companyId
        })
      }
      if(!planGoalList.find(f => f.label === 'Ná 70% flokkunarhlutfalli fyrir árið 2025')) {
        planGoalList.push({
          label_is: 'Ná 70% flokkunarhlutfalli fyrir árið 2025',
          label_en: 'Achieve a 70% classification rate by 2025',
          value: 0,
          companyId: companyId
        })
      }
      if(!planGoalList.find(f => f.label === 'Reikna losun gróðurhúsalofttegunda í umfangi 1 og 2')) {
        planGoalList.push({
          label_is: 'Reikna losun gróðurhúsalofttegunda í umfangi 1 og 2',
          label_en: 'Calculate greenhouse gas emissions in scopes 1 and 2',
          value: 0,
          companyId: companyId
        })
      }
      if(!planGoalList.find(f => f.label === 'Hljóta jafnlaunavottun fyrir lok árs')) {
        planGoalList.push({
          label_is: 'Hljóta jafnlaunavottun fyrir lok árs',
          label_en: 'Receive equal pay certification before the end of the year',
          value: 0,
          companyId: companyId
        })
      }
      if(!planGoalList.find(f => f.label === 'Innleiða jafnréttistefnu fyrir lok árs')) {
        planGoalList.push({
          label_is: 'Innleiða jafnréttistefnu fyrir lok árs',
          label_en: 'Implement an equality policy before the end of the year',
          value: 0,
          companyId: companyId
        })
      }
      if(!planGoalList.find(f => f.label === 'Styðja starfsfólk til símenntunar')) {
        planGoalList.push({
          label_is: 'Styðja starfsfólk til símenntunar',
          label_en: 'Support staff for lifelong learning',
          value: 0,
          companyId: companyId
        })
      }

      setActionPlanGoalsList(planGoalList)
    }
  };

  // const findAndRenderGoalsByCategory = () => {
  //   let tempNotCompletedList = [];
  //   if(notCompletedList.length === 0 && greenSteps && greenSteps.results) {
  //     greenSteps.results.forEach(item => {
  //       let data = getGreenStepData(item);
  //       tempNotCompletedList.push(...data)
  //     });
  //     setNotCompletedList(tempNotCompletedList);
  //   } else {
  //     tempNotCompletedList = notCompletedList;
  //   }
  //
  //
  //   if(data && data.action) {
  //     let findAction = tempNotCompletedList.find(item => item.label === data.action);
  //     if(findAction) {
  //       setAction(findAction);
  //       setSelectedCategoryId(findAction.category_id);
  //       getActionPlanGoalsByCategory(findAction.category_id);
  //     } else {
  //       getActionPlanGoalsByCategory();
  //     }
  //   } else {
  //     getActionPlanGoalsByCategory();
  //   }
  // };

  useEffect(() => {
    greenSteps.length === 0 &&  dispatch(getGreenStepsNew(companyId));
    actionPlanGoals.length === 0 && dispatch(getActionPlanGoal(companyId));
    usersByCompany.length === 0 && dispatch(getLaufidUsersByCompany(companyId));
  }, []);

  useEffect(()=> {
    const findActionGoal = data && data.plan_goal_id && actionPlanGoals.results ? actionPlanGoals.results.find(item => item.id === data.plan_goal_id) : false;
    if(findActionGoal) {
      setGoal({
        label_is: findActionGoal.goal,
        label_en: findActionGoal.goal_en ? findActionGoal.goal_en : findActionGoal.goal,
        value: findActionGoal.id
      })
    }
    getActionPlanGoalsByCategory();
  },[actionPlanGoals]);


  if (loadingActionPlanGoals || loadingUsersByCompany || lodingGreenSteps) return (
    <Modal isOpen={true} centered toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header"> <h5 className="modal-title">Stofna aðgerð</h5> </div>
      <div className="modal-body"> <LoaderSpinner/> </div>
    </Modal>
  );

  const onCancel = () => { props.onCancel() };

  const onConfirm = () => {
    if((action === '' && actionInput === '') || !priority || startDate === '') {
      setErrorStatus(true);
      Swal({text:'Vinsamlega fylltu út í alla nauðsynlega textareiti', timer: 4000, buttons: false});
    } else {
      let newData = {
        "id": id,
        "company_id": companyId,
        "action": actionFrom === "greenStep" && data ? data.action : actionFrom === "actionPlan" ? actionInput : action.label,
        "type": data && data.type ? data.type : actionFrom ? actionFrom : 'green_step',
        "priority": priority.value,
        "start_date": startDate,
        "end_date": endDate,
        "plan_goal_id": goal ? goal.value : null,
        "new_goal": goal && goal.value === 0 ? goal : false,
        "subgoal": "",
        "status": status.value,
        "user_ids": users,
        "comment": comment,
      };
      dispatch(createUpdateActionPlan(id>0, newData,  0));
      props.onCancel();
    }
  };

  const onChangeStartDate = (e) => {
    let currentDate = HelperGetCurrentDate();
    let selectedDate = helperFormatDate(e);
    if(currentDate === selectedDate) {
      setStatus({label: t('plan_page.in_progress'), value: 'In progress' })
    } else if (currentDate < selectedDate) {
      setStatus({label: t('plan_page.on_hold'), value: 'On Hold' })
    }
    setStartDate(e);
  };

  const createActionGoal = (data = null) => {
    setAlert(<CreateGoalModal
      data={data}
      onCancel={() => setAlert(null)}/>);
  };

  // const changeAction = (event) => {
  //   setAction(event);
  //   if(selectedCategoryId !== event.category_id) {
  //     setSelectedCategoryId(event.category_id);
  //     getActionPlanGoalsByCategory(event.category_id);
  //   }
  // };

  return (
    <Modal isOpen={true} size="lg" toggle={() => {onCancel()}} centered backdrop={"static"} className="addActionPlanModal">
      {alert}
      <div className="modal-header justify-content-between">
        <span className="modal-title">{id > 0 ? t('plan_page.update_action') : t('plan_page.add_action')}</span>
        <Close className="modal-close-icon" onClick={() => onCancel()} />
      </div>

      <div className="modal-body">
        <div className="row form_row_item">
          <div className="col col-10 form_element">

            {((data && data.type === "green_step" && actionFrom && actionFrom === "greenStep")) &&
              <div className="editActionTitle">
                {data.action}
              </div>
            }

            {((data && data.type === "actionPlan") || (actionFrom && actionFrom === "actionPlan")) &&
              <Input
                name="action_input"
                type="text"
                className="form-control"
                placeholder={t('plan_page.create_enter_action')}
                value={actionInput}
                onChange={(e) => {setActionInput(e.target.value)}}
              />
            }


            {/*{ data && !actionFrom &&*/}
            {/*    <Select*/}
            {/*      isSearchable={false}*/}
            {/*      placeholder={t('plan_page.select')}*/}
            {/*      value={action}*/}
            {/*      onChange={(event) => changeAction(event)}*/}
            {/*      styles={customDropdownStyles}*/}
            {/*      options={notCompletedList.map(item => item)}*/}
            {/*      className={`react-select ${errorStatus && action.length === 0 && 'has-error'}`}*/}
            {/*    />*/}
            {/*}*/}

          </div>
        </div>

        <div className="row form_row_item">
          <div className="col col-2"> <Label className="form_label">{t('plan_page.goal')}</Label> </div>
          <div className="col col-8 form_element">
            <Select
              isSearchable={false}
              styles={customDropdownStyles()}
              placeholder={t('plan_page.goal_placeholder2')}
              value={goal ? {...goal, label: goal["label_"+currentLang]} : {}}
              onChange={(event) => setGoal(event)}
              options={
                actionPlanGoalsList.map((item) => {
                  return {...item, label: item["label_"+currentLang] ? item["label_"+currentLang] : item.label_is}
                })
              }
              className={`react-select`}
            />
          </div>
          <div className="col col-2">
            <div id={'tooltip_add_plan_goals_btn'} className="add_new_goal" onClick={() => createActionGoal()}>
              <Add/>
            </div>
            {goal !== null && goal.companyId !== null &&
              <div className="add_new_goal edit" onClick={() => createActionGoal({"id": goal.value, "goal": goal.label})}>
                <Edit/>
              </div>
            }
            <UncontrolledTooltip placement="bottom" target={'tooltip_add_plan_goals_btn'} >
              bæta við nýju markmiði
            </UncontrolledTooltip>
          </div>
        </div>

        <div className="row form_row_item">
          <div className="col col-10"> <Label className="form_label comment_label">{t('plan_page.achieve_goal')}</Label> </div>
          <div className="col col-10 form_element">
            <Input
              name="Comment"
              type="text"
              className="form-control"
              placeholder={t('plan_page.enter_comment')}
              value={comment}
              onChange={(e) => {setComment(e.target.value)}}
            />
          </div>
        </div>

        <div className="row form_row_item">
          <div className="col col-2"> <Label className="form_label">{t('plan_page.priority')}</Label> </div>
          <div className="col col-5 form_element">
            <Select
              isSearchable={false}
              styles={customDropdownStyles()}
              placeholder={t('plan_page.select')}
              options={priorities.map(item => item)}
              value={priority}
              onChange={(event) => setPriority(event)}
              className={`react-select ${errorStatus && !priority && 'has-error'}`}
            />
          </div>
        </div>

        <div className="row form_row_item">
          <div className="col col-4"> <Label className="form_label">{t('plan_page.project')}</Label> </div>
          <div className="col col-3 form_element">
            <DatePicker dateFormat="dd/MM/yyyy" locale={locale} className={`form-control form_datepicker react-datepicker ${errorStatus && !startDate && 'has-error'}`} selected={startDate} onChange={(e) => {onChangeStartDate(e)}}/>
          </div>
          <div className="col col-3 form_element">
            <DatePicker dateFormat="dd/MM/yyyy" locale={locale} className={`form-control form_datepicker`} selected={endDate} onChange={(e) => {setEndDate(e)}}/>
          </div>
        </div>

        <div className="row form_row_item">
          <div className="col col-2"> <Label className="form_label">{t('plan_page.status')}</Label> </div>
          <div className="col col-5 form_element">
            <Select
              isSearchable={false}
              styles={customDropdownStyles()}
              placeholder={t('plan_page.select')}
              // value={status}
              value={status ? {...status, label: t('plan_page.'+status.value)} : {}}
              onChange={(event) => setStatus(event)}
              options={[{label: t('plan_page.in_progress'), value: 'In progress'}, {label: t('plan_page.completed'), value: 'Completed'}, {label: t('plan_page.on_hold'), value: 'On Hold'}]}
            />
          </div>
        </div>

        <div className="row form_row_item">
          <div className="col col-2"> <Label className="form_label">{t('plan_page.responsible')}</Label> </div>
          <div className="col col-8 form_element user_creatable_select">
            <CreatableSelect
              isMulti
              styles={customDropdownStylesCreate}
              placeholder={t('plan_page.select_or_new_user')}
              value={users}
              onChange={(newValue) => {setUsers(newValue)}}
              options={usersByCompany.results ? usersByCompany.results.map(item => {return {label: item.name, value: item.id, profile: item.profile_picture}}) : []}
            />
          </div>
        </div>

      </div>

      <div className="modal-footer">
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('plan_page.save_action')}
        </button>
      </div>

    </Modal>
  );

};

export default AddActionPlan