import * as actionTypes from '../actionTypes';

const initialState = {
  loadingServices: true,
  loadingEmissions: true,
  loadingEmissions3rdparty: true,
  loadingCompanyEmissions: true,
  services: [],
  emissions: [],
  emissions3rdparty: [],
  companyEmissions: [],
  error: false,
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function companyReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_CARBON_SERVICES_START:
      return  {
        ...state,
        loadingServices: true
      };
    case actionTypes.GET_CARBON_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.data,
        loadingServices: false
      };
    case actionTypes.GET_CARBON_SERVICES_FAIL:
      return  {
        ...state,
        loadingServices: false
      };

    case actionTypes.GET_CARBON_EMISSIONS_START:
      return  {
        ...state,
        loadingEmissions: true
      };
    case actionTypes.GET_CARBON_EMISSIONS_SUCCESS:
      return {
        ...state,
        emissions: action.data,
        loadingEmissions: false
      };
    case actionTypes.GET_CARBON_EMISSIONS_FAIL:
      return  {
        ...state,
        loadingEmissions: false
      };


    case actionTypes.GET_COMPANY_CARBON_EMISSIONS_START:
      return  {
        ...state,
        loadingCompanyEmissions: true
      };
    case actionTypes.GET_COMPANY_CARBON_EMISSIONS_SUCCESS:
      return {
        ...state,
        companyEmissions: action.data,
        loadingCompanyEmissions: false
      };
    case actionTypes.GET_COMPANY_CARBON_EMISSIONS_FAIL:
      return  {
        ...state,
        loadingCompanyEmissions: false
      };


    case actionTypes.GET_COMPANY_CARBON_EMISSIONS_3RDPARTY_START:
      return  {
        ...state,
        loadingEmissions3rdparty: true
      };
    case actionTypes.GET_COMPANY_CARBON_EMISSIONS_3RDPARTY_SUCCESS:
      return {
        ...state,
        emissions3rdparty: action.data,
        loadingEmissions3rdparty: false
      };
    case actionTypes.GET_COMPANY_CARBON_EMISSIONS_3RDPARTY_FAIL:
      return  {
        ...state,
        loadingEmissions3rdparty: false
      };

    default:
      return state;
  }
}
