import React, {useState} from "react"
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {Row, Modal, Input, FormFeedback} from "reactstrap"
import {addInstitutions} from "../../../store/actions/municipality";

const AddUser = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const storageData = JSON.parse(localStorage.getItem('p_user'));
  const [errorStatus, setErrorStatus] = useState(false);
  const [state, setState] = useState({
    name:  "",
    nameValid: false,
    email: "",
    emailValid: false,
    municipality_id : storageData.companies[0].municipality_id,
    kennitala : storageData.companies[0].kennitala,
    logo : storageData.companies[0].logo,
    company_name : storageData.companies[0].name
  });

  const verifyEmail = (value) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRex.test(value);
  };

  const isValidated = () => {
    let data = {...state};
    data['nameValid'] = state.name.length > 3;
    data['emailValid'] = verifyEmail(state.email);

    if(data.nameValid && data.emailValid) {
      return true
    } else {
      setState(data);
      setErrorStatus(true);
      return false;
    }
  };

  const onConfirm = () => {
    if (isValidated()) {
      dispatch(addInstitutions(state));
      onCancel();
    }
  };

  const change = (event, stateName) => {
    setState({...state, [stateName]: event.target.value});
  };

  const onCancel = () => {
    props.onCancel();
  };


  return (
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"}  centered className="newOrganizationModal">
      <div className="modal-header">
        Bjóða stofnun í Laufið
      </div>
      <div className="modal-body">
        <Row>
          <div className="col-md-12 mb-3">
            <label className="form-label">Nafn stofnunar</label>
            <Input
              id="name"
              name="name"
              type="text"
              value={state.name}
              className="form-control"
              onChange={event => change(event, 'name')}
              invalid={errorStatus && !state.nameValid}
            />
            {errorStatus && !state.nameValid && <FormFeedback>Length should be more than 3</FormFeedback>}
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">Netfang tengiliðs</label>
            <Input
              id="email"
              name="email"
              type="email"
              value={state.email}
              className="form-control"
              onChange={event => change(event, 'email')}
              invalid={errorStatus && !state.emailValid}
            />
            {errorStatus && !state.emailValid && <FormFeedback>Netfangið sem slegið var inn er rangt</FormFeedback>}
          </div>
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => onCancel()} className="btn closeBtn waves-effect" data-dismiss="modal">
          {t('cancel')}
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('send')}
        </button>
      </div>
    </Modal>
  );

};

export default AddUser