import i18n from "../i18n";
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
const monthNamesIs = ['janúar', 'febrúar', 'mars', 'apríl', 'maí', 'júní', 'júlí', 'ágúst', 'september', 'október', 'nóvember', 'desember'];

export const HelperGetCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return dd + '-' + mm + '-' + yyyy;
};

export const HelperAddMonthsAndDaysToCurrentDate = (months = 0, days = 0, type = '') => {
    const date = new Date();

    if(type === 'endDay') {
        date.setHours(23,59,59,0);
    } else {
        date.setHours(0,0,0,0);
    }

    if(months>0) {
        date.setMonth(date.getMonth() + months);
    }

    if(days>0) {
        date.setDate(date.getDate() + days);
    }
    return date;
};

export const HelperAddMonthsToDate = (cDate, months = 0, days = 0, type = '') => {
    const currentLang = i18n.language || 'is';
    const newDate = new Date(cDate);
    newDate.setMonth(newDate.getMonth() + months);
    const yyyy = newDate.getFullYear();
    let mm = newDate.getMonth(); // Months start at 0!
    let dd = newDate.getDate();
    let monthsByLang =  currentLang === 'is' ? monthNamesIs[mm] : monthNames[mm];
    return dd + '. ' + monthsByLang + ' ' + yyyy;
};

export const helperFormatDate = (date) => {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    return dd + '-' + mm + '-' + yyyy;
};

export const helperFormatDateWithMonthName = (date) => {
    const currentLang = i18n.language || 'is';
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth(); // Months start at 0!
    let dd = today.getDate();
    let monthsByLang =  currentLang === 'is' ? monthNamesIs[mm] : monthNames[mm];
    return dd + '. ' + monthsByLang + ' ' + yyyy;
};
