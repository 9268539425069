import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {Check} from '@mui/icons-material';
import {Input, Label} from "reactstrap";

import {HelperGetCurrentDate} from "../../../../helpers/main";
import {editComment} from "../../../../store/actions/greenStep";

const GreenStep210 = (props) => {
  const dispatch = useDispatch();
  const {comment, item, commentIndex, greenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));

  const [percentage, setPercentage] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if(props.item) {
      setPercentage(item.percentage);
    }
  }, [props.item]);

  const saveEditComment = () => {
    if(percentage>0) {
      let newComment = comment;
      newComment.splice(commentIndex, 1);
      newComment.unshift({
        "comment": `Hlutfall launa og bónusgreiðsla forstjóra miðað við hlutfall meðallauna starfsfólks í fullu starfi er ${percentage}:1`,
        "percentage" : percentage,
        "user_id": userData.user.id,
        "name": userData.user.name,
        "date": HelperGetCurrentDate(),
        "customComment": true
      });

      dispatch(editComment(greenStep.new_company_green_steps[0].id, newComment, greenStep.main_category-1, 'comment'));
      props.close();
    } else {
      setError(true);
    }
  };

  return (
    <div className="row customGreenStep210Completed" style={{alignItems: 'center'}}>
      <div className="col-6">
        <Label className="col-form-label categoryTextColor">Vinsamlegast setjið inn hlutfallið í forminu X:1.</Label>
        <div className="inputCol">
          <Input
            id="text"
            type="number"
            className="form-control goalField"
            value={percentage}
            onChange={(event) =>{setPercentage(event.target.value)}}
            invalid={error && percentage.length === 0}
          /> : 1
        </div>
      </div>
      <div className="col-2">
        <div className="companyCompletedIcon categoryBorderColor true" style={{marginTop: '40px'}} onClick={()=>saveEditComment()}><Check/></div>
      </div>
    </div>
  )
};

export default GreenStep210