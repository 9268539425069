import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
    apiKey: 'da8f9bcf1e41d705ab25447ab40e9407',
    plugins: [new BugsnagPluginReact(React)],
});

export default Bugsnag;
