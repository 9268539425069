import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getCarbonServices = () => async dispatch => {
  dispatch({type: actionTypes.GET_CARBON_SERVICES_START});
  try {
    const res = await axios.get('/service_provider');
    dispatch({type: actionTypes.GET_CARBON_SERVICES_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_CARBON_SERVICES_FAIL});
  }
};

export const getCarbonEmissions = () => async dispatch => {
  dispatch({type: actionTypes.GET_CARBON_EMISSIONS_START});
  try {
    const res = await axios.get('/carbon_emission');
    dispatch({type: actionTypes.GET_CARBON_EMISSIONS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_CARBON_EMISSIONS_FAIL});
  }
};

export const getCompanyCarbonEmissions = (companyId) => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_CARBON_EMISSIONS_START});
  try {
    const res = await axios.get('/company_carbon_emission/get_result_by_company/'+companyId);
    dispatch({type: actionTypes.GET_COMPANY_CARBON_EMISSIONS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_CARBON_EMISSIONS_FAIL});
  }
};

export const createCompanyCarbonEmission = (data) => async dispatch => {
  dispatch({type: actionTypes.CREATE_COMPANY_CARBON_EMISSION_START});
  try {
    const res = await axios({
      url: '/company_carbon_emission',
      method: 'POST',
      data: data
    });
    dispatch({type: actionTypes.CREATE_COMPANY_CARBON_EMISSION_SUCCESS, data: res.data});
    if(data.currentStep === "3.1" || data.type === "save") {
      dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Vistað!', style:'success'});
    }

  } catch (error) {
    const res = error?.response?.data;
    const message = (res && res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res && res.message + ". " + message;
    dispatch({type: actionTypes.CREATE_COMPANY_CARBON_EMISSION_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};


export const updateCompanyCarbonEmission = (id, value, result) => async dispatch => {
  dispatch({type: actionTypes.UPDATE_COMPANY_CARBON_EMISSION_START});
  try {
    const res = await axios({
      url: '/company_carbon_emission/'+id,
      method: 'PUT',
      data: {
        "value": value,
        "result": result
      }
    });
    dispatch({type: actionTypes.UPDATE_COMPANY_CARBON_EMISSION_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Vistað!', style:'success'});

  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.UPDATE_COMPANY_CARBON_EMISSION_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};

export const updateCarbonEmission = (id, value) => async dispatch => {
  dispatch({type: actionTypes.UPDATE_CARBON_EMISSION_START});
  try {
    const res = await axios({
      url: '/carbon_emission/'+id,
      method: 'PUT',
      data: {
        "value": value
      }
    });
    dispatch({type: actionTypes.UPDATE_CARBON_EMISSION_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Vistað!', style:'success'});
    dispatch(getCarbonEmissions());

  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.UPDATE_CARBON_EMISSION_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};



export const getCompanyCarbonEmissions3rdparty = (companyId) => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_CARBON_EMISSIONS_3RDPARTY_START});
  try {
    const res = await axios.get('/company_carbon_3rdparty/get_result_by_company/'+companyId);
    dispatch({type: actionTypes.GET_COMPANY_CARBON_EMISSIONS_3RDPARTY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_CARBON_EMISSIONS_3RDPARTY_FAIL});
  }
};


export const createCompanyCarbonEmission3rdparty = (data) => async dispatch => {
  dispatch({type: actionTypes.CREATE_COMPANY_CARBON_EMISSION_3RDPARTY_START});
  try {
    const res = await axios({
      url: '/company_carbon_3rdparty',
      method: 'POST',
      data: data
    });
    dispatch({type: actionTypes.CREATE_COMPANY_CARBON_EMISSION_3RDPARTY_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Vistað!', style:'success'});
    window.location.reload();

  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.CREATE_COMPANY_CARBON_EMISSION_3RDPARTY_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};


export const updateCompanyCarbonEmission3rdparty = (id, data) => async dispatch => {
  dispatch({type: actionTypes.UPDATE_COMPANY_CARBON_EMISSION_3RDPARTY_START});
  try {
    const res = await axios({
      url: '/company_carbon_3rdparty/'+id,
      method: 'PUT',
      data: data
    });
    dispatch({type: actionTypes.UPDATE_COMPANY_CARBON_EMISSION_3RDPARTY_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Vistað!', style:'success'});
    window.location.reload();
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.UPDATE_COMPANY_CARBON_EMISSION_3RDPARTY_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};

export const deleteCompanyCarbonEmission3rdparty = (id, companyId) => async dispatch => {
  dispatch({type: actionTypes.DELETE_COMPANY_CARBON_EMISSION_3RDPARTY_START});
  try {
    const res = await axios.delete('/company_carbon_3rdparty/' + id);
    dispatch({type: actionTypes.DELETE_COMPANY_CARBON_EMISSION_3RDPARTY_SUCCESS, data: res.data});
    // dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Blað atriði eytt!', style:'success'});
    dispatch(getCompanyCarbonEmissions3rdparty(companyId));
  } catch (error) {
    dispatch({type: actionTypes.DELETE_COMPANY_CARBON_EMISSION_3RDPARTY_FAIL});
  }
};