import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import { useTranslation } from 'react-i18next';
import Slider from "@mui/material/Slider";
import {Check} from "@mui/icons-material";
import Switch from "react-switch";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import RenderPdfFileContainer from "../renderPdfFileContainer";
import {createCompanyGreenStep, deleteCompanyGreenStep, updateCompanyGreenStep} from "../../../../store/actions/greenStep";
import CommentContainer from "../commentContainer";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const GreenStepDefault = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {item, descHasLink, desc, companyGreenStep, companyGreenStepIsIrrelevant} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;
  const currentLang = i18n.language || 'is';
  const [sliderItem, setSliderItem] = useState(false);

  useEffect(() => {
    if(props.item && props.item.slider === true) {
      setSliderItem({
        "id": props.item.id,
        "score": props.item.new_company_green_steps.length>0 ? props.item.new_company_green_steps[0].slider_score*100/props.item.score : 0,
        "scorePercentage": props.item.new_company_green_steps.length>0 ? props.item.new_company_green_steps[0].slider_score_percentage : 0
      });
    }
  }, [props.item]);

  const handleCheck = (id, irrelevant) => {
    let data = {
      "new_green_step_id": id,
      "comment": [],
      "irrelevant": irrelevant,
      "reason": [],
      "pdf": null,
      "pdf_name": null
    };

    dispatch(createCompanyGreenStep(data, companyId, item.main_category-1));
  };

  const handleUncheck = (id) => {
    dispatch(deleteCompanyGreenStep(id, item.main_category-1, 'deleteCompletely'));
  };

  const sliderHandleChange = ( newValue, item) => {
    let copySliderItem = {...sliderItem};
    copySliderItem.score = newValue;
    copySliderItem.scorePercentage = newValue;
    setSliderItem(copySliderItem);
  };

  const sliderHandleChangeCommitted = (newValue, item) => {
    let score = Math.round(newValue*item.score/100);

    let data = {
      "id": item.new_company_green_steps.length>0 ? item.new_company_green_steps[0].id : null,
      "new_green_step_id": item.id,
      "slider_score": score,
      "slider_score_percentage": newValue,
      "comment": [],
      "irrelevant": false,
      "reason": [],
      "pdf": null,
      "pdf_name": null
    };

    if(item.new_company_green_steps.length>0) {
      dispatch(updateCompanyGreenStep(data, companyId, item.main_category-1));
    } else {
      dispatch(createCompanyGreenStep(data, companyId, item.main_category-1));
    }
  };

  return (
    <React.Fragment>
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          item.id === 171 ?
            <div dangerouslySetInnerHTML={{__html: desc}}/>
            :
            descHasLink ?
              <div dangerouslySetInnerHTML={{
                __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
              }}/>
            :
              desc
        }
      </div>

      {item.pdf_description === null &&
        <div className={`companyCompletedToggleBtns ${companyGreenStep ? companyGreenStepIsIrrelevant ? 'irrelevant' : 'notIrrelevant' : ''}`}>
          <div
            className="companyCompleted checkBox"
            onClick={() => {companyGreenStep ? handleUncheck(companyGreenStep.id) : handleCheck(item.id, false)}}
          >
            <div
              className={`
              companyCompletedIcon categoryBorderColor 
              ${companyGreenStep && (!companyGreenStep.hasOwnProperty('irrelevant') || companyGreenStep.irrelevant === false)}
              `}
            >
              <Check/>
            </div>
            <div className="companyCompletedTitle">{t('yes')}</div>
          </div>


          {item.irrelevant &&
            <div className="companyCompleted irrelevant">
              <Switch
                id={'card_carousel_' + item.id + "irrelevant-list"}
                checked={companyGreenStepIsIrrelevant}
                onChange={() => companyGreenStep ? handleUncheck(companyGreenStep.id) : handleCheck(item.id, true)}
                activeBoxShadow="0px 0px 1px 2px #f3f3f3"
                checkedHandleIcon={
                  <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                    <Check/>
                  </div>
                }
                className={`react-custom-switch ${companyGreenStepIsIrrelevant && 'active'}`}
              />
              <div className="companyCompletedTitle">{t('pluses_page.irrelevant')}</div>
            </div>
          }
        </div>
      }

      {item.pdf_description !== null &&
        <React.Fragment>
          <div className={`companyCompletedToggleBtns ${companyGreenStep ? companyGreenStepIsIrrelevant ? 'irrelevant' : 'notIrrelevant' : ''}`}>
            {item.irrelevant &&
              <div className="companyCompleted irrelevant">
                <Switch
                  id={'card_carousel_' + item.id + "irrelevant-list"}
                  checked={companyGreenStepIsIrrelevant}
                  onChange={() => companyGreenStep ? handleUncheck(companyGreenStep.id) : handleCheck(item.id, true)}
                  activeBoxShadow="0px 0px 1px 2px #f3f3f3"
                  checkedHandleIcon={
                    <div style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%"
                    }}>
                      <Check/>
                    </div>
                  }
                  className={`react-custom-switch ${companyGreenStepIsIrrelevant && 'active'}`}
                />
                <div className="companyCompletedTitle">{t('pluses_page.irrelevant')}</div>
              </div>
            }
          </div>
          <div className="confirm_container">
            <div className="confirm_title categoryTextColor">
              {currentLang === 'en' && item.pdf_description_en ? item.pdf_description_en : item.pdf_description}
            </div>
            <div className="confirm_title ">
              {currentLang === 'en' && item.pdf_description2_en ? item.pdf_description2_en : item.pdf_description2}
            </div>

            <RenderPdfFileContainer
              categoryItem={item}
              mainCategoryIndex={item.main_category-1}
              key={"renderBigCategory_mainItem_" + item.main_category-1 + "RenderPdfFileContainer"}
              requiredFrom={false}
            />
          </div>
        </React.Fragment>
      }

      {item.slider === true &&
        <div className="slider col-sm-12 col-md-8 col-lg-6 col-xl-4 col-xxl-4">
          <div className="title">{currentLang === 'en' && item.slider_description_en ? item.slider_description_en : item.slider_description}</div>
          <Slider
            value={sliderItem ? sliderItem.scorePercentage : 0}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => `${value}%`}
            step={1}
            min={0}
            max={100}
            onChange={(e, newValue) => sliderHandleChange(newValue, item)}
            onChangeCommitted={(e, newValue) => sliderHandleChangeCommitted(newValue, item)}
          />
          <p className="sliderFinish categoryTextColor">100%</p>
        </div>
      }

      {companyGreenStep && item.slider === false && <CommentContainer item={companyGreenStep}/>}

    </React.Fragment>
  )
};

export default GreenStepDefault