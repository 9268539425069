import React, { useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import errorImage from "../assets/images/error-img.png"


const Pages500 = () => {

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">

                  <CardBody>

                    <div className="text-center p-3">

                      <div className="img">
                        <img src={errorImage} className="img-fluid" alt="" />
                      </div>

                      <h1 className="error-page mt-5"><span>403</span></h1>
                      <h4 className="mb-4 mt-5">Access denied</h4>
                      <a href="/">
                        <div className="btn btn-primary mb-4 waves-effect waves-light">
                          <i className="mdi mdi-home"></i> Back to Main Page
                        </div>
                      </a>
                    </div>

                  </CardBody>
                </Card>

              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Pages500
