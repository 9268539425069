import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Label} from "reactstrap";
import Select from "react-select";
import i18n from "../../../../i18n";

import ScoreAndFavorite from "../scoreAndFavorite";
import RenderPdfFileContainer from "../renderPdfFileContainer";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {customDropdownStyles} from "../../../../helpers/dropdown";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const yearOptions = () => {
  let years = [];
  for(let i=0; i<=60; i++) {
    years.push({label: 1990+i, value: 1990+i})
  }
  return years;
};

const yearOptionsTo = () => {
  let years = [];
  for(let i=0; i<=60; i++) {
    years.push({label: 2024+i, value: 2024+i})
  }
  return years;
};

const GreenStep204 = (props) => {
  const { t } = useTranslation();
  const {item, descHasLink, desc, companyGreenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const currentLang = i18n.language || 'is';

  const [yearFrom, setYearFrom] = useState('');
  const [yearTo, setYearTo] = useState('');
  const [error, setError] = useState(false);

  return (
    <React.Fragment>
      <ScoreAndFavorite item={item} companyGreenStep={companyGreenStep}/>

      <div className="title col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 categoryTextColor">
        {item["title_" + currentLang] ? item["title_" + currentLang] : item.title}
      </div>

      <div className="cardDesc col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8">
        {
          descHasLink ?
            <div dangerouslySetInnerHTML={{
              __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`)
            }}/>
          :
            desc
        }
      </div>

      <p className="categoryTextColor"> Til hvaða árs nær stefnan? </p>

      <div className={`customDropDownYear`}>
          <Label className="col-form-label">Frá árinu</Label>
          <Select
            placeholder={t('select')}
            isSearchable={false}
            onChange={(event) => setYearFrom(event.value)}
            styles={customDropdownStyles('#4433CD')}
            options={yearOptions()}
            className={`react-select ${error && yearFrom.length === 0 && 'has-error'}`}
          />

          <Label className="col-form-label">Til ársins</Label>
          <Select
            placeholder={t('select')}
            isSearchable={false}
            onChange={(event) => setYearTo(event.value)}
            styles={customDropdownStyles('#4433CD')}
            options={yearOptionsTo()}
            className={`react-select ${error && yearTo.length === 0 && 'has-error'}`}
          />

      </div>


      <div className="confirm_container">
        <div className="confirm_title categoryTextColor">
          {currentLang === 'en' && item.pdf_description_en ? item.pdf_description_en : item.pdf_description}
        </div>
        <div className="confirm_title ">
          {currentLang === 'en' && item.pdf_description2_en ? item.pdf_description2_en : item.pdf_description2}
        </div>

        <RenderPdfFileContainer
          categoryItem={item}
          mainCategoryIndex={item.main_category-1}
          key={"renderBigCategory_mainItem_" + item.main_category-1 + "RenderPdfFileContainer"}
          requiredFrom={true}
          formValid={yearFrom !== '' && yearTo !== ''}
          changeErrorStatus={(status) => setError(status)}
          comment={[{
            "comment": `Stefnan gildir frá árinu ${yearFrom} til ársins ${yearTo}.`,
            "user_id": userData.user.id,
            "name": userData.user.name,
            "yearFrom": yearFrom,
            "yearTo": yearTo,
            "date": HelperGetCurrentDate(),
            "customComment": true
          }]}
        />
      </div>


    </React.Fragment>
  )
};

export default GreenStep204