import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Edit} from '@mui/icons-material';
import AddRole from "./addRole";

import {Table,  Card, CardBody, Button} from "reactstrap"
import DeleteButton from "../../components/Common/CustomButtons/DeleteButton";
import {LoaderSpinner} from "../../components/Common/Loader";
import {createUpdateRole, deleteRole, getRoles} from "../../store/actions/acl";
import "./index.scss"

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const [alert, setAlert] = useState(null);
  const {loadingRole, roles} = useSelector( state => state.acl );
  const store = JSON.parse(localStorage.getItem('p_user'));
  const permission = store ? store.permission : [];
  const canCreate = permission.includes('admin_roles_create');
  const canEdit = permission.includes('admin_roles_edit');
  const canDelete = permission.includes('admin_roles_delete');

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  if (loadingRole) return (<LoaderSpinner/>);

  const onSubmit = (data, update) => {
    dispatch(createUpdateRole(data, update));
  };

  const htmlAlert = (data) => {
    setAlert(
      <AddRole data={data} onCancel={() => setAlert(null)} onSubmit={(data, update) => onSubmit(data, update)}/>
    );
  };

  const tableData = !roles.results ? '' :
    roles.results.map((value, index) => {
      return (<tr key={value.id+'role'}>
        <td>{index+1}</td>
        <td>{value.name}</td>
        <td>
          {value.permissions.map((item) => <Button color='success' className="role_btn" size="sm">{item.name}</Button>)}
        </td>
        <td width="120px">
          {canEdit && <Button key={value.id+'type'} color="success" size="sm" style={{marginRight: '10px'}} onClick={() => htmlAlert(value)} ><Edit style={{fontSize: '12px'}}/></Button>}
          {canDelete && <DeleteButton key={value.id+'_delete'} action={() => dispatch(deleteRole({...value}))} />}
        </td>
      </tr>)
    });

  return (
    <div className="page-content">
      {alert}
      <Card>
        <CardBody>
          <div className="row">
            <div className="col col-12">
              {canCreate && <Button color="success" className="mb-4 float-end" size="sm" style={{float: 'right'}} onClick={htmlAlert}>{t('acl_page.add_new')}</Button>}
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table table-striped table-hover">
              <thead className="table-light">
                <tr>
                  <th>#</th>
                  <th>{t('acl_page.name')}</th>
                  <th>{t('permissions')}</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {tableData}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  )
};

export default Index
