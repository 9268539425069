import React, {useState} from 'react';
import {Check, Close, Edit} from "@mui/icons-material";
import pdfIcon from "../../../../assets/images/pdf1.png";
import FileUploadPdf2 from "../../../../components/Common/CustomUpload/FileUploadPdf2";
import {Input, Label} from "reactstrap";
import {useTranslation} from "react-i18next";
import {editComment} from "../../../../store/actions/greenStep";
import {HelperGetCurrentDate} from "../../../../helpers/main";
import {useDispatch} from "react-redux";

const GreenStep219Show = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {comment, commentIndex, commentItem, greenStep} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const [editPdfId, setEditPdfId] = useState(-1);
  const [pdfLink, setPdfLink] = useState(commentItem.link ? commentItem.link : '');

  const uploadAndSaveGreenStep = (file, fileName, link = '') => {
    if(file.length > 0 || link.length > 0) {
      let newComment = comment;
      newComment.splice(commentIndex, 1);
      newComment.unshift({
        "comment": `Tegund vottunar eða umhverfisstjórnunarkerfis - ${commentItem.certificate.label}.`,
        'certificate': commentItem.certificate,
        'pdf': file,
        'pdfName': fileName,
        'link': link,
        "user_id": userData.user.id,
        "name": userData.user.name,
        "date": HelperGetCurrentDate(),
        "customComment": true
      });

      dispatch(editComment(greenStep.new_company_green_steps[0].id, newComment, greenStep.main_category-1, 'comment'));
      setEditPdfId(-1);
    } else {
      return false;
    }
  };

  const deleteGreenStepItem = (data) => {
    let newComment = comment;
    newComment.splice(commentIndex, 1);
    dispatch(editComment(greenStep.new_company_green_steps[0].id, newComment, greenStep.main_category-1, 'comment'));
    setEditPdfId(-1);
  };

  return (
    <React.Fragment>
      {(commentItem.pdf !== "" || commentItem.link !== "") && editPdfId !== commentIndex &&
        <div className="pdfContainer">
          {commentItem.link ?
            <div className="pdfLink categoryTextColor">
              { t('pluses_page.link_to_data') }: <a href={commentItem.link} className="categoryTextColor" target="_blank" rel="noopener noreferrer">{commentItem.link}</a>
            </div>
            :
            <a href={commentItem.pdf} target="_blank" rel="noopener noreferrer">
              <img className='pdf_icon' src={pdfIcon} alt="PDF file"/>
              <span className="pdf_name categoryTextColor"> {commentItem.pdfName}</span>
            </a>
          }

          <p
              className="companyCompletedIcon categoryBorderColor true edit hover-effect"
              onClick={() => {setEditPdfId(commentIndex)}}
            >
              <Edit/>
            </p>
           <p className="companyCompletedIcon categoryBorderColor true hover-effect"
               onClick={() => {deleteGreenStepItem(commentIndex)}}
            >
              <Close/>
            </p>
        </div>
      }

      {(editPdfId === commentIndex) &&
        <React.Fragment>
          <FileUploadPdf2
            fileUploadKey={"renderBigCategory_219_" + "uploadNewPdf"}
            onUploadPDF={(file, fileName) => uploadAndSaveGreenStep(file, fileName)}
            msg={t('pluses_page.pdf')}
            mainCategoryIndex={0}
          />

          <div className="pdfContainerfromLink">
            <Label className="form_label categoryTextColor">{ t('pluses_page.link_to_data') }</Label>
            <div className="row">
              <div className="col-sm-10 col-md-10 col-lg-8 col-xl-8 col-xxl-7 inputCol">
                <Input
                  name="action_input"
                  type="text"
                  className="form-control"
                  value={pdfLink}
                  onChange={(e) => {setPdfLink(e.target.value)}}
                />
              </div>
              <div className="col-2">
                <div className="companyCompleted">
                  <div className={`companyCompletedIcon categoryBorderColor checkbox true`} onClick={() => uploadAndSaveGreenStep( '', '', pdfLink)}>
                    <Check/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      }

      {/*<p className="completedCommentText">*/}
      {/*  <span>{commentItem.comment}</span>*/}
      {/*</p>*/}
    </React.Fragment>
  )
};

export default GreenStep219Show