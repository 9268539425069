import i18n from 'i18next';

export function validateRequired(value) {
  if (!value) return i18n.t('required');
  if (value.length < 2) return i18n.t('required');
}

export function validatePhone(value) {
  if (!value) return i18n.t('required');
  if (!/^[0-9]+$/i.test(value)) return "Símanúmerið er ekki á réttu sniði"
  if (value.length < 7 || value.length > 7) return "Athugaðu símanúmerið"
}

export function validateKennitala(value) {
  if (!value) return i18n.t('required');
  if (!/^[0-9]+$/i.test(value)) return "Kennitala er ekki í réttu formi"
  if (value.length !== 10) return "Þessi reitur er ógildur"
}

export function validateEmail(value) {
  if (!value) return i18n.t('required');
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) return "ógilt netfang"
}