import React from 'react';
import {useTranslation} from 'react-i18next';

import {Close} from '@mui/icons-material';
import Swal from 'sweetalert';
import {Button} from "reactstrap"
import "./index.scss"

const CustomButton = (props) => {
  const { t } = useTranslation();

  const warningWithConfirmMessage = () => {
    Swal({
      title:t('are_you_sure'),
      icon: "warning",
      dangerMode: true,
      buttons: {confirm: t('yes'), cancel: t('no')},
    }).then((confirm) => {
      if (confirm) {
        props.action();
      } else {
        Swal.close();
      }
    });
  };


  return <React.Fragment>
    <Button color={props.color || 'success'} id={props.id || ''} size={props.size || 'sm'} style={props.styleBtn} className="actionButton" onClick={() => warningWithConfirmMessage()}>
      {props.msg ? props.msg : props.icon ? props.icon : <Close className="icon" />}
    </Button>
  </React.Fragment>
};

export default CustomButton;