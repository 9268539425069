import * as actionTypes from '../actionTypes';

const initialState = {
  actionPlanGoals: [],
  loadingActionPlanGoals: true,
  actionPlansByCompany: [],
  loadingActionPlansByCompany: true,
  error: false,
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function companyReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_ACTION_PLAN_GOAL_START:
      return  {
        ...state,
        loadingActionPlanGoals: true,
      };
    case actionTypes.GET_ACTION_PLAN_GOAL_SUCCESS:
      return {
        ...state,
        actionPlanGoals: action.data,
        loadingActionPlanGoals: false
      };
    case actionTypes.GET_ACTION_PLAN_GOAL_FAIL:
      return  {
        ...state,
        loadingActionPlanGoals: false
      };

    case actionTypes.GET_ACTION_PLAN_BY_COMPANY_START:
      return  {
        ...state,
        loadingActionPlansByCompany: true,
      };
    case actionTypes.GET_ACTION_PLAN_BY_COMPANY_SUCCESS:
      return {
        ...state,
        actionPlansByCompany: action.data,
        loadingActionPlansByCompany: false
      };
    case actionTypes.GET_ACTION_PLAN_BY_COMPANY_FAIL:
      return  {
        ...state,
        loadingActionPlansByCompany: false
      };


    case actionTypes.CREATE_ACTION_PLAN_GOAL_START:
      return  {
        ...state,
        loadingActionPlanGoals: true
      };
    case actionTypes.CREATE_ACTION_PLAN_GOAL_SUCCESS:
      let goals = Object.assign([], state.actionPlanGoals);
      goals.results.push(action.data.results);
      return {
        ...state,
        actionPlanGoals: goals,
        loadingActionPlanGoals: false
      };
    case actionTypes.CREATE_ACTION_PLAN_GOAL_FAIL:
      return  {
        ...state,
        loadingActionPlanGoals: false
      };


    case actionTypes.UPDATE_ACTION_PLAN_GOAL_START:
      return  {
        ...state,
      };
    case actionTypes.UPDATE_ACTION_PLAN_GOAL_SUCCESS:
      let copyStateGoal = Object.assign([], state.actionPlanGoals);
      const indexC = copyStateGoal.results.findIndex(item => item.id === action.data.results.id);
      if(indexC >= 0) {
        copyStateGoal.results[indexC].goal = action.data.results.goal;
      }
      return {
        ...state,
        actionPlanGoals: copyStateGoal
      };
    case actionTypes.UPDATE_ACTION_PLAN_GOAL_FAIL:
      return  {
        ...state,
      };


    case actionTypes.DELETE_ACTION_PLAN_GOAL_START:
      return  {
        ...state,
      };
    case actionTypes.DELETE_ACTION_PLAN_GOAL_SUCCESS:
      let newStateD = Object.assign([], state.actionPlans.results);
      if(action.action === 'all') {
        const indexD = newStateD.plan_goals.findIndex(item => item.id === action.data.results.id);
        newStateD.plan_goals.splice(indexD, 1);
        state.actionPlans.results = newStateD;
      }
      return  {
        ...state
      };
    case actionTypes.DELETE_ACTION_PLAN_GOAL_FAIL:
      return  {
        ...state,
      };



    default:
      return state;
  }
}
