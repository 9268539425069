import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import { useTranslation } from 'react-i18next';
import {Check} from "@mui/icons-material";
import {editComment,} from "../../../../store/actions/greenStep";
import {Input} from "reactstrap";
import {HelperGetCurrentDate} from "../../../../helpers/main";


const GreenStepDefault = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {comment, item, greenStep, commentIndex} = props;
  const userData = JSON.parse(localStorage.getItem('p_user'));

  const [commentInput, setCommentInput] = useState("");


  useEffect(() => {
    if(props.item) {
      setCommentInput(item.comment);
    }
  }, [props.item]);


  const saveEditComment = () => {
    let newComment = comment;
    newComment.splice(commentIndex, 1);
    newComment.unshift({
      "comment": commentInput,
      "user_id": userData.user.id,
      "name": userData.user.name,
      "date": HelperGetCurrentDate(),
    });

    dispatch(editComment(greenStep.new_company_green_steps[0].id, newComment, greenStep.main_category-1, 'comment'));
    setCommentInput("");
    props.close();
  };

  return (
    <React.Fragment>
      <Input
        id="title-completed"
        type="text"
        className="form-control companyDoneInput"
        placeholder={t('pluses_page.add_explanation')}
        value={commentInput}
        onKeyDown={evt => evt.key === "Enter" && saveEditComment()}
        onChange={(e) => {
          setCommentInput(e.target.value)
        }}
      />
      <p className="companyCompletedIcon categoryBorderColor save true hover-effect"
         onClick={() => {saveEditComment()}}>
        <Check/>
      </p>
    </React.Fragment>
  )
};

export default GreenStepDefault