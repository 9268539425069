import React, {useEffect, useState} from "react"
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";

import {CarouselItem, CarouselControl, Carousel} from "reactstrap"
import {Edit, Close, Add} from '@mui/icons-material';

import {deleteActionPlanGoal} from "../../store/actions/actionPlan";
import CreateGoalModal from "./components/createGoalModal";
import DeleteGoalModal from "./components/deleteGoalModal";
import i18n from "../../i18n";

const Goals = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLang = i18n.language || 'is';
  const {goals} = props;

  const companyId = JSON.parse(localStorage.getItem('p_user'))?.companyId;
  const [alert, setAlert] = useState(null);
  const [slideAnimating, setSlideAnimating] = useState(false);
  const [slideActiveIndex, setSlideActiveIndex] = useState(0);
  const [seeAll, setSeeAll] = useState(false);
  const [slideCount, setSlideCount] = useState(false);

  const {actionPlanGoals} = useSelector( state => state.actionPlan);

  useEffect(() => {
    if(actionPlanGoals && actionPlanGoals.results) {
      setSlideCount(Math.ceil( actionPlanGoals.results.length / 3))
    }
  },[actionPlanGoals])


  const createGoal = (data = null) => {
    setAlert(<CreateGoalModal data={data} onCancel={() => setAlert(null)}/>);
  };

  const deleteGoal = (data) => {
    let goalActions = goals.find(f => f.id === data.id);
    if(goalActions) {
      setAlert(<DeleteGoalModal data={goalActions} onCancel={() => setAlert(null)}/>);
    } else {
      dispatch(deleteActionPlanGoal(data.id, 'all', companyId))
    }
  };

  const slideNext = () => {
    if (slideAnimating || slideActiveIndex === slideCount) return;
    setSlideActiveIndex(slideActiveIndex + 1);
  };

  const slidePrevious = () => {
    if (slideAnimating || slideActiveIndex === 0) return;
    setSlideActiveIndex(slideActiveIndex-1);
  };

  return (
    <div className="goalsContent">
      {alert}

      <div className="header">
        <div className="title">{t('plan_page.my_goals')}</div>
        <div className="all hover-effect" onClick={() => setSeeAll(!seeAll)}>{seeAll ? t('plan_page.see_less') : t('plan_page.see_all')} </div>
      </div>

      <div className="goals">
        {actionPlanGoals.results && actionPlanGoals.results.length > 0 ?
          !seeAll ?
            <Carousel
              activeIndex={slideActiveIndex}
              previous={slidePrevious}
              next={slideNext}
              interval={null}
            >
              <CarouselControl direction="prev" onClickHandler={slidePrevious} className={`carousel-control left hover-effect ${slideActiveIndex > 0}`}/>

              {actionPlanGoals.results.reduce((r, e, i) => (i % 3 ? r[r.length - 1].push(e) : r.push([e])) && r, []).map((items, indexItems) => {
                return (
                  <CarouselItem
                    className="custom-tag"
                    tag="div"
                    key={'card_carousel_' + indexItems}
                    onExiting={() => setSlideAnimating(true)}
                    onExited={() => setSlideAnimating(false)}
                  >
                    {items.map((value, index) => {
                      return (
                        <p className="item" key={"goal_group_"+indexItems+'_'+index}>
                          <span className="item-icon"/>
                          <span className="item-copy">
                            {value["goal_" + currentLang] ? value["goal_" + currentLang] : value.goal}
                          </span>
                        </p>
                      )
                    })}

                  </CarouselItem>
                )}
              )}

              <CarouselControl direction="next" onClickHandler={slideNext} className={`carousel-control right hover-effect ${slideActiveIndex >= 0 && slideActiveIndex < slideCount-1}`}/>
            </Carousel>
          :
            <div className="row item_all">
              {actionPlanGoals.results.map((item, indexItems) => {
                return (
                  <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 item" key={'all_goal'+indexItems}>
                    <span className="item-icon"/>
                    <p className="item-copy">
                      <span>{item["goal_" + currentLang] ? item["goal_" + currentLang] : item.goal}</span>
                      <span className="btn_circle edit" onClick={() => createGoal(item)}> <Edit/> </span>
                      <span className="btn_circle delete" onClick={() => {deleteGoal(item)}}> <Close/> </span>
                    </p>
                  </div>
                )
              })}
            </div>
          :
          <div className="goalsNotExist">
            <p className="item">
              <span className="item-icon"/>
              <span className="item-copy">{t('plan_page.no_goals')}</span>
            </p>
          </div>
        }
      </div>

      <div className="addNewGoal hover-effect" onClick={()=>createGoal()}>
        <span>{t('plan_page.add_goals')}</span>
        <span className='addNewIcon'> <Add/> </span>
      </div>

    </div>
  )

};

export default Goals
