import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {Input} from "reactstrap";
import Select from "react-select";
import ReactApexChart from "react-apexcharts";
import { PieChart, Pie, Label, Tooltip, ResponsiveContainer, Cell } from 'recharts';

import {customDropdownCO2Styles} from "../../helpers/dropdown";
import {createCompanyCarbonEmission, updateCompanyCarbonEmission} from "../../store/actions/carbon";
import {AddCommas, AddCommasToFloat, RemoveNonNumeric} from "./components/helpers";
import {AddCircle} from "@mui/icons-material";
import i18n from "../../i18n";
import { t } from 'i18next';


const renderChartByYear = (data, option) => {
  if(!data) return;
  let labels = [];
  let series = [{name: t('carbon_page.total_emissions'), data: []}];
  let series2 = [
    {name: t('plan_page.all'), data: []},
    {name: t('carbon_page.scope') + ' 1', data: []},
    {name: t('carbon_page.scope') + ' 2', data: []},
    {name: t('carbon_page.scope') + ' 3', data: []}
  ];

  let series1Colors = ['#4FC878', '#007338'];
  let series2Colors = ['#006330', '#83DEA2', '#4FC878', '#2F9C54'];

  Object.keys(data).forEach(function(key, index) {
    labels.push(key);
    if(option.value === '1') {
      series[0].data.push(data[key] ? parseFloat(data[key].total_sum) : 0);
    } else {
      series2[0].data.push(data[key] ? parseFloat(data[key].total_sum) : 0);
      Object.keys(data[key]).forEach(item => {
        if(item !== 'total_sum') {
          series2[item].data.push(data[key][item] ? parseFloat(data[key][item].sum) : 0);
        }
      });
    }
  });

  let chartOptions = {
    colors : option.value === '1' ? series1Colors : series2Colors,
    chart: {
      height: 300,
      type: 'line',
      zoom: {enabled: false},
      toolbar: {show: false,}
    },
    dataLabels: {enabled: false},
    stroke: {width: [6,6,6,6,6,6], curve: 'straight'},
    legend: {offsetY: 8, offsetX: 60, itemMargin: {
        horizontal: 20,
        vertical: 10
      }, horizontalAlign: 'left',  showForSingleSeries: true,},
    markers: {size: 0, hover: {sizeOffset: 6}},
    xaxis: {
      categories: labels,
      offsetY: 8,
    },
    yaxis: {
      labels: {
        formatter: (val) => {return val+ t('carbon_page.kg_co2_eq') },
        style: {fontSize: '12px', color: '#4FC878'},
      },
    }
  };

  return <ReactApexChart options={chartOptions} series={option.value === '1' ? series : series2} type="line" height={300}/>
};

const checkEmptyCategory = (data) => {
  let resStep = 1
  if(!data[1]) {
    resStep = 2;
  }
  if(!data[2]) {
    resStep = 3;
  }
  if(!data[3]) {
    resStep = 1;
  }
  return resStep;
};

const checkEmptyStep = (data) => {
  if(!data[2] || !data[3]) {return true}

  if(
    data[2] &&
    Object.values(data[2]) < 3 &&
    Object.values(data[2]).filter(f => typeof f !== 'string').some(e => e.value === null)
  ){
    return true;
  }

  if(
    data[3] &&
    Object.values(data[3]).filter(f => typeof f !== 'string').some(e => e.value === null)
  ){
    return true;
  }

  return false;
};

const emptyValues = {
  'year': '',
  'yearError': false,

  'car': '',
  'gas': '',
  'diesel': '',
  'fuelService': '',

  'waterService': '',
  'water': '',

  'electService': '',
  'electric': '',

  'wasteService': '',
  'wastes': [
    {'type': '', 'treatment': '', 'value': ''}
  ],
  'newWastes': [],
  'wastesError': false

};

const ResultPage = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData?.companyId;
  const currentLang = i18n.language || 'is';
  const {onChangeShowResultPage, onChangeMainStep, locationState} = props;
  const {companyEmissions, emissions, services} = useSelector( state => state.carbon );

  const [state, setState] = useState(emptyValues);

  const [singleResultData, setSingleResultData] = useState(false);
  const [multiResultData, setMultiResultData] = useState(false);
  const [resultStep, setResultStep] = useState(1);
  const [headerMenu, setHeaderMenu] = useState('all');

  const [editScope, setEditScope] = useState(0);
  const [mainChartOption, setMainChartOption] = useState( {label: t('carbon_page.total_emissions'), value: '1'});
  const [continueBtn, setContinueBtn] = useState('');
  const [scope3WasteOptions, setScope3WasteOptions] = useState([]);


  useEffect(() => {
    if(companyEmissions && companyEmissions.results) {
      let itemKeys = Object.keys(companyEmissions.results);
      let itemValues = Object.values(companyEmissions.results);

      if(itemKeys.length === 1) {
        if(checkEmptyStep(itemValues[0])) {
          setContinueBtn(itemKeys[0])
        }
        setResultStep(checkEmptyCategory(itemValues[0]));
        setSingleResultData(itemValues[0]);
      } else if(itemKeys.length > 1) {
        itemKeys.forEach(item => {
          let yearData = companyEmissions.results[item];
          if(!yearData[2] || !yearData[3] || (yearData[2] && Object.values(yearData[2]) < 3 )) {
            setContinueBtn(item);
          }
        })
        setMultiResultData(itemValues)
      }
    }
  }, [companyEmissions]);

  useEffect(() => {
    if(locationState && locationState.showResult && companyEmissions && companyEmissions.results) {
      setActiveMenu(locationState.showResult)
    }
  }, [locationState]);


  const renderCircleChart = (item, dataIndex, type = '') => {

    if(!item || (type === 'second' && !item[dataIndex])) return;
    let data01 = [];

    if(type === 'second') {
      let currentScope = item[dataIndex];
      let colors = ['#FFFFFF', '#BCEACC', '#ACEABA', '#FFFFFF', '#BCEACC', '#ACEABA', '#FFFFFF', '#BCEACC', '#ACEABA'];

      Object.keys(item[dataIndex]).forEach(function(key, index) {
        if(key !== 'sum') {
          data01.push({ name: t(key), value: currentScope[key] ? parseFloat(currentScope[key].result) : 0, color: colors[index] })
        }
      });
    } else {
      let colors = ['#83DEA2', '#4FC878', '#009448'];
      Object.keys(item).forEach(function(key, index) {
        if(key !== 'sum' && item[key].sum > 0) {
          data01.push({ name: t('carbon_page.scope')+" " +key, value: item[key] ? parseFloat(item[key].sum) : 0, color: colors[index] });
        }
      });
    }

    let sum = data01.reduce((a, {value}) => a + value, 0).toFixed(2);
    let RADIAN = Math.PI / 180;

    let renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, payload, percent, value, index, fill, name }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 1.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      let newPercent = (percent * 100).toFixed(2);

      if(dataIndex === 2 ) {
        return (
          <>
            <text x={x} y={y-5} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
              {name}
            </text>
            <text x={x} y={y+10} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
              {newPercent.replace(".", ',')}%
            </text>
          </>
        );
      } else {
        return (
          <text x={x > cx ? x+5 : x-5} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" >
            {name.length>10 ? name.substring(0,10)+'...' : name} - {newPercent.replace(".", ',')}%{' '}
          </text>
        );
      }
    };

    let CustomLabel = ({viewBox, value1, value2}) => {
      const {cx, cy} = viewBox;
      return (
        <>
          <text x={cx} y={cy-5} fill={type === 'second' ? '#fff' : '#009448'} textAnchor={'middle'} dominantBaseline="central">
            {AddCommas(value1)}
          </text>
          <text x={cx} y={cy+10} fill={type === 'second' ? '#fff' : '#009448'} textAnchor={'middle'} dominantBaseline="central">
            {value2}
          </text>
        </>
      )
    };

    return <ResponsiveContainer width="100%" height="100%">
      <PieChart height={170} margin={{top: 15, right: 15, left: 15, bottom: 15}}>
        <Pie
          dataKey="value"
          data={data01}
          cx="50%"
          cy="50%"
          innerRadius={40}
          fill="#8884d8"
          paddingAngle={Object.values(item).filter(f => typeof f !== 'string').length === 1 ? 0 : 5}
          minAngle={2}
          blendStroke
          label={renderCustomizedLabel}
        >
          {data01.map((entry, index) => ( <Cell key={`cell-${index}`} fill={entry.color} /> ))}
          <Label width={30} position="center" content={<CustomLabel value1={sum} value2={t('carbon_page.kg_co2_eq')}/>} />
        </Pie>
        <Tooltip formatter={value => `${AddCommasToFloat(value)}`}  />
      </PieChart>
    </ResponsiveContainer>
  };

  const onChangeNumberState = (item, value) => {
    let states = {...state};
    states[item] = AddCommas(RemoveNonNumeric(value));
    setState(states);
  };

  const onChangeNumberWasteState = (item, value, index) => {
    let states = {...state};
    states.wastes[index][item] = AddCommas(RemoveNonNumeric(value));
    setState(states);
  };

  const addNewCarbon = () => {
    setState(emptyValues);
    onChangeShowResultPage(false);
    onChangeMainStep('0');
  };

  const handleContinueBtn = () => {
    props.onChangeMainStep('1.0');
    props.handleContinue(continueBtn);
    onChangeShowResultPage(false);
  };

  const setActiveMenu = (data) => {
    let states = {...state};
    states['newWastes'] = [];
    setState(states);
    setHeaderMenu(data);
    setEditScope(0);
    setScope3WasteOptions([]);

    if(data === 'all') {
      setResultStep(1);
      setSingleResultData(false);
    } else {
      let currentYear = companyEmissions.results[data]
      setSingleResultData(currentYear);
      setResultStep(checkEmptyCategory(currentYear));
    }
  };

  const handleEditScope = (currentScope) => {
    let states = {...state};
    let data = singleResultData[currentScope];

    if(currentScope === 1) {
      if(data['gas'] && data['gas'].value) {
        states['gas'] = data['gas'].value;
        states['gasId'] = data['gas'].id;
        states['gasEmission'] = data['gas'].carbon_emission.value;
      }
      if(data['diesel'] && data['diesel'].value) {
        states['diesel'] = data['diesel'].value;
        states['dieselId'] = data['diesel'].id;
        states['dieselEmission'] = data['diesel'].carbon_emission.value;
      }
    }

    if(currentScope === 2) {
      if(data['water'] && data['water'].value) {
        states['water'] = data['water'].value;
        states['waterId'] = data['water'].id;
        states['waterEmission'] = data['water'].carbon_emission.value;
      }
      if(data['electricity'] && data['electricity'].value) {
        states['electric'] = data['electricity'].value;
        states['electricId'] = data['electricity'].id;
        states['electricEmission'] = data['electricity'].carbon_emission.value;
      }
    }

    if(currentScope === 3) {
      let data3 = Object.values(data).filter(f => typeof f !== 'string').sort((a,b) => b.result - a.result);
      let wastes = [];
      data3.forEach(item => {
        wastes.push({
          'value': item.value,
          'id': item.id,
          "emission": item.carbon_emission.value,
          'parentName': item.carbon_emission.type+' - '+item.carbon_emission.treatment
        })
      });
      states['wastes'] = wastes;

    }

    setEditScope(currentScope);
    setState(states);

  };

  const updateCompanyCarbon = (id, value, result) => {
    dispatch(updateCompanyCarbonEmission(id, value, result));
  };

  const saveCompanyCarbon = (emission, providerId, value, btn = '') => {
    dispatch(createCompanyCarbonEmission({
      "company_id": companyId,
      "carbon_emission_id": emission.id,
      "service_provider_id": providerId,
      "value": value,
      "result": value === null ? null : value * emission.value,
      "currentStep": '3.1',
      "type": btn
    }));
  };

  const handleSaveScope = (currentScope) => {
    let copyResData = {...singleResultData};

    if(currentScope === 1) {
      let forceUpdate = false;
      if(state['gas'] !== "") {
        let value = RemoveNonNumeric(state['gas']);
        if(state['gasId'] && state['gasId'] !== "") {
          updateCompanyCarbon(state['gasId'], value, value * state['gasEmission']);
          copyResData[currentScope]['gas'].value = value;
          copyResData[currentScope]['gas'].result = value * state['gasEmission'];
        } else {
          let year = headerMenu === 'all' ? Object.keys(companyEmissions.results)[0] : headerMenu;
          let service = services.results.find(f => f.name === 'Annað' && f.type === 'fuel');
          let emissionGas = emissions.results.find(f => f.year === year && f.scope === 1 && f.type === 'gas');saveCompanyCarbon(emissionGas, 44, value);
          saveCompanyCarbon(emissionGas, service.id, value);
          forceUpdate = true;
        }
      }

      if(state['diesel'] !== "") {
        let value = RemoveNonNumeric(state['diesel']);
        if(state['dieselId'] && state['dieselId'] !== "") {
          updateCompanyCarbon(state['dieselId'], value, value * state['dieselEmission']);
          copyResData[currentScope]['diesel'].value = value;
          copyResData[currentScope]['diesel'].result = value * state['dieselEmission'];
        } else {
          let year = headerMenu === 'all' ? Object.keys(companyEmissions.results)[0] : headerMenu;
          let service = services.results.find(f => f.name === 'Annað' && f.type === 'fuel');
          let emissionDiesel = emissions.results.find(f => f.year === year && f.scope === 1 && f.type === 'diesel');
          saveCompanyCarbon(emissionDiesel, service.id, value)
          forceUpdate = true;
        }
      }

      if(forceUpdate) {
        setTimeout(function(){
          window.location.reload();
        }, 1500);
      } else {
        copyResData[currentScope]['sum'] = copyResData[currentScope]['gas'].result + copyResData[currentScope]['diesel'].result;
      }
    }

    if(currentScope === 2) {
      let sum = "0";
      let forceUpdate = false;

      if(state['water'] !== "") {
        let value = RemoveNonNumeric(state['water']);
        if (state['waterId'] && state['waterId'] !== "") {
          updateCompanyCarbon(state['waterId'], value, value * state['waterEmission']);
          copyResData[currentScope]['water'].value = value;
          copyResData[currentScope]['water'].result = value * state['waterEmission'];
          sum += value * state['waterEmission'];
        } else {
          let year = headerMenu === 'all' ? Object.keys(companyEmissions.results)[0] : headerMenu;
          let service = services.results.find(f => f.name === 'Annað' && f.type === 'water');
          let emissionWater = emissions.results.find(f => f.year === year && f.scope === 2 && f.type === 'water');
          saveCompanyCarbon(emissionWater, service.id, value)
          forceUpdate = true;
        }
      }

      if(state['electric'] !== "") {
        let value = RemoveNonNumeric(state['electric']);
        if (state['electricId'] && state['electricId'] !== "") {
          updateCompanyCarbon(state['electricId'], value, value * state['electricEmission']);
          copyResData[currentScope]['electricity'].value = value;
          copyResData[currentScope]['electricity'].result = value * state['electricEmission'];
          sum += value * state['electricEmission'];
        } else {
          let year = headerMenu === 'all' ? Object.keys(companyEmissions.results)[0] : headerMenu;
          let service = services.results.find(f => f.name === 'Annað' && f.type === 'electricity');
          let emissionElec = emissions.results.find(f => f.year === year && f.scope === 2 && f.type === 'electricity');
          saveCompanyCarbon(emissionElec, service.id, value)
          forceUpdate = true;
        }
      }
      if(forceUpdate) {
        setTimeout(function(){
          window.location.reload();
        }, 1500);
      } else {
        copyResData[currentScope]['sum'] = sum;
      }
    }

    if(currentScope === 3) {
      let scopeKeys = Object.keys(copyResData[currentScope]).filter(f => f !== 'sum');
      if(state.wastes) {
        let sum = 0;
        state.wastes.forEach((item, wIndex) => {
          let value = RemoveNonNumeric(item.value);
          sum += value * item.emission;
          updateCompanyCarbon(item.id, value, value * item.emission);
          copyResData[currentScope][item.parentName].value = value;
          copyResData[currentScope][item.parentName].result = value * item.emission;
        });
        copyResData[currentScope].sum = (parseFloat(sum).toFixed(2)).toString();
      }

      if(state.newWastes.length>0) {
        let serviceId = copyResData[currentScope][scopeKeys[0]].service_provider_id;
        state.newWastes.forEach((item, wIndex) => {
          if(item.type && item.treatment && item.value.length>0) {
            saveCompanyCarbon(item.treatment, serviceId, RemoveNonNumeric(item.value))
          }
        });

        setTimeout(function(){
          window.location.reload();
        }, 1500);
      }
    }

    setSingleResultData(copyResData);
    setEditScope(0);
  };

  const handleEditEmptyFirstScope = (currentScope) => {
    let states = {...state};

    states['year'] = 0;

    states['gas'] = 0;
    states['gasId'] = "";
    states['gasEmission'] = "";

    states['diesel'] = 0;
    states['dieselId'] = "";
    states['dieselEmission'] = "";

    setEditScope(currentScope);
    setState(states);

  };

  const handleSaveFirstScope = (currentScope) => {
    let year = headerMenu === 'all' ? Object.keys(companyEmissions.results)[0] : headerMenu;
    let emissionDiesel = emissions.results.find(f => f.year === year && f.scope === 1 && f.type === 'diesel');
    let emissionGas = emissions.results.find(f => f.year === year && f.scope === 1 && f.type === 'gas');
    let service = services.results.find(f => f.name === 'Annað' && f.type === 'fuel');

    if(state.gas !== "" && emissionGas) {
      saveCompanyCarbon(emissionGas, service.id, RemoveNonNumeric(state.gas))
    }

    if(state.diesel !== "" && emissionDiesel) {
      saveCompanyCarbon(emissionDiesel, service.id, RemoveNonNumeric(state.diesel))
    }

    setTimeout(function(){
      window.location.reload();
    }, 1500);
  };

  const slider = (result, sum) => {
    let value = parseFloat(result*100/sum).toFixed(2);
    if (value>100) {
      value = 100;
    }
    return (
      <div className="sliderContainer">
        <div className="slider">
          <div className="sliderComplete" style={{width: `${value}%`}}/>
        </div>
        <div className="sliderSum">
          {AddCommas(value)}%
        </div>
      </div>
    )
  };

  const addNewWaste = () => {
    if(emissions && emissions.results && singleResultData && singleResultData[3] && scope3WasteOptions.length===0) {
      let year = companyEmissions ? multiResultData ? headerMenu : Object.keys(companyEmissions.results)[0] : '';
      let scopeKeys = Object.keys(singleResultData[3]).filter(f => f !== 'sum').join(',');
      let ems = [];

      emissions.results.forEach(item => {
        if (item.scope === 3 && item.year === year) {
          if (ems.findIndex(f => f.value === item.type) === -1 && !scopeKeys.includes(item.type)) {
            let findTreatments = emissions.results.filter(f => f.scope === 3 && f.year === year && f.type === item.type);
            ems.push({
              label: item.type,
              label_is: item.type,
              label_en: item.type_en,
              value: item.type,
              treatments: findTreatments.map(item => {
                return {
                  label: item.treatment,
                  label_en: item.treatment_en,
                  label_is: item.treatment,
                  value: item.value,
                  id: item.id
                }
              })
            })
          }
        }
      });
      setScope3WasteOptions(ems);
    }

    let states = {...state};
    states.newWastes.push({'type': '', 'treatment': '', 'value': '', 'id': 0});
    setState(states);
  };

  const onChangeWasteType = (value, index) => {
    let states = {...state};
    states.newWastes[index]['type'] = value;

    if(value.treatments.length === 1) {
      states.newWastes[index]['treatment'] = {...value.treatments[0], disabled: true};

      let options = [...scope3WasteOptions];
      states.newWastes.forEach(m => {
        options = options.filter(f => f.value !== m.type.value)
      });
      setScope3WasteOptions(options);
    } else {
      states.newWastes[index]['treatment'] = '';
    }

    setState(states);
  };

  const onChangeWasteTreatment = (type, value, index) => {
    let states = {...state};
    states.newWastes[index]['treatment'] = value;
    states.newWastes[index]['type']['treatments'] = type.treatments.filter(f => f.id !== value.id);

    if(states.newWastes[index]['type']['treatments'].length === 0) {
      let options = [...scope3WasteOptions];
      states.newWastes.forEach(m => {
        options = options.filter(f => f.value !== m.type.value)
      });
      setScope3WasteOptions(options);
    }

    setState(states);
  };

  const onChangeNumberNewWasteState = (item, value, index) => {
    let states = {...state};
    states.newWastes[index][item] = AddCommas(RemoveNonNumeric(value));
    setState(states);
  };

  return (
    <div className="resultPageContainer">

      {multiResultData &&
        <div className="headerMenu">
          <div key={'headerMenu_all'}
            className={`item ${headerMenu === 'all' && 'active'}`}
            onClick={() => setActiveMenu('all')}
          >
            {t('carbon_page.summary')}
          </div>

          {Object.keys(companyEmissions.results).map(item =>
            <div
              key={'headerMenu_'+item}
              className={`item ${headerMenu === item && 'active'}`}
              onClick={() => setActiveMenu(item)}
            >
              {item}
            </div>
          )}
        </div>
      }

      {multiResultData && !singleResultData && companyEmissions &&
        <div className="mainChartBox">
          <div className="title">
            {t('carbon_page.operational_emissions')} {Object.keys(companyEmissions.results)[0]} - {Object.keys(companyEmissions.results)[Object.keys(companyEmissions.results).length-1]}
          </div>
          <div className="dropdown">
            <Select
              isSearchable={false}
              placeholder={t('select')}
              value={mainChartOption}
              onChange={(event) => setMainChartOption( event)}
              styles={customDropdownCO2Styles}
              options={[{label: t('carbon_page.total_emissions'), value: '1'}, {label: t('carbon_page.emissions_by_scope'), value: '2'}]}
              className={`react-select`}
            />
          </div>
          <div className="chart">
            {renderChartByYear(companyEmissions.results, mainChartOption)}
          </div>
        </div>
      }



      {singleResultData && <React.Fragment>
        <div className="row totalBox">
          <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 sumColumn">
            <div className="title">
              {t('carbon_page.operational_emissions_for')} {' '}
              {companyEmissions ? multiResultData ? headerMenu : Object.keys(companyEmissions.results)[0] : ''}
            </div>
            <div className="sum">
              {t('carbon_page.total_emissions')}:
              {' '} {singleResultData && AddCommas(singleResultData.total_sum)}
              {' '} {t('carbon_page.kg_co2_eq')}
            </div>
            <div className="scopeSum">
              <span>{t('carbon_page.scope')} 1: </span>
              {singleResultData && singleResultData[1] ? AddCommasToFloat(singleResultData[1].sum) : 0} {t('carbon_page.kg_co2_eq')}
            </div>
            <div className="scopeSum">
              <span>{t('carbon_page.scope')} 2: </span>
              {singleResultData && singleResultData[2] ? AddCommasToFloat(singleResultData[2].sum) : 0} {t('carbon_page.kg_co2_eq')}
            </div>
            <div className="scopeSum">
              <span>{t('carbon_page.scope')} 3: </span>
              {singleResultData && singleResultData[3] ? AddCommasToFloat(singleResultData[3].sum) : 0} {t('carbon_page.kg_co2_eq')}
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 chart" id="chart">
            <div className="chartContainer">
              {singleResultData && renderCircleChart(singleResultData)}
            </div>

          </div>
        </div>

        <div className={`row scopeBox activeStep_${resultStep}`}>
          <div className="col-11">

            <div className="menu">
              <div className={`scope scope1 ${resultStep === 1 && 'active'} `} onClick={() => setResultStep(1)}>
                {t('carbon_page.scope')} 1
              </div>
              <div className={`scope scope2 ${resultStep === 2 && 'active'} ${singleResultData && !singleResultData[2] && 'disabled'} `} onClick={() => setResultStep(2)}>
                {t('carbon_page.scope')} 2
              </div>
              <div className={`scope scope3 ${resultStep === 3 && 'active'} ${singleResultData && !singleResultData[3] && 'disabled'} `} onClick={() => setResultStep(3)}>
                {t('carbon_page.scope')} 3
              </div>
            </div>

            <div className={`content ${'currentItem_'+resultStep}`}>
              <div className="row">
                <div className="col-12 sumColumn">
                  {resultStep === 1 && singleResultData && singleResultData[1] && <React.Fragment>
                    <div className="title">{t('carbon_page.fuel_consumption')}</div>

                    <div className={`scopeSum first ${editScope === resultStep && 'editCon'}`}>
                      <span className="value firstScope">
                        {editScope === resultStep ?
                          <Input
                            name="other"
                            type="text"
                            value={state.gas}
                            className="form-control"
                            onChange={event => onChangeNumberState("gas", event.target.value)}
                          />
                          :
                          singleResultData[1]['gas'] ? AddCommasToFloat(singleResultData[1]['gas'].value) : 0
                        }

                       {' '} {t('carbon_page.liters_petrol')}
                      </span>

                      <div className="result">
                        {singleResultData[1]['gas'] ? AddCommasToFloat(singleResultData[1]['gas'].result) : 0} {t('carbon_page.kg_co2_eq')}
                      </div>

                      {slider(singleResultData[1]['gas'] ? singleResultData[1]['gas'].result : 0, singleResultData[1]['sum'])}
                    </div>

                    <div className={`scopeSum first ${editScope === resultStep && 'editCon'}`}>
                      <span className="value firstScope">
                        {editScope === resultStep ?
                          <Input
                            name="other"
                            type="text"
                            value={state.diesel}
                            className="form-control"
                            onChange={event => onChangeNumberState("diesel", event.target.value)}
                          />
                          :
                          singleResultData[1]['diesel'] ? AddCommasToFloat(singleResultData[1]['diesel'].value)  : 0
                        }

                        {' '} {t('carbon_page.liters_diesel')}
                      </span>

                      <div className="result">
                        {singleResultData[1]['diesel'] ? AddCommasToFloat(singleResultData[1]['diesel'].result) : 0} {t('carbon_page.kg_co2_eq')}
                      </div>

                      {slider(singleResultData[1]['diesel'] ? singleResultData[1]['diesel'].result : 0, singleResultData[1]['sum'])}
                    </div>
                    <div className="sum">{singleResultData && singleResultData[1] ? AddCommasToFloat(singleResultData[1].sum) : 0} {t('carbon_page.kg_co2_eq')}</div>

                  </React.Fragment>}

                  {resultStep === 1 && singleResultData && !singleResultData[1] && <React.Fragment>
                    <div className="title">{t('carbon_page.fuel_consumption')}</div>

                    <div className={`scopeSum first ${editScope === resultStep && 'editCon'}`}>
                      <span className="value firstScope">
                        {editScope === resultStep ?
                          <Input
                            name="other"
                            type="text"
                            value={state.gas}
                            className="form-control"
                            onChange={event => onChangeNumberState("gas", event.target.value)}
                          />
                          : 0
                        }
                        {' '} {t('carbon_page.liters_petrol')}
                      </span>

                      <div className="result"> 0 {t('carbon_page.kg_co2_eq')} </div>
                      {slider(0, 1)}
                    </div>

                    <div className={`scopeSum first ${editScope === resultStep && 'editCon'}`}>
                      <span className="value firstScope">
                        {editScope === resultStep ?
                          <Input
                            name="other"
                            type="text"
                            value={state.diesel}
                            className="form-control"
                            onChange={event => onChangeNumberState("diesel", event.target.value)}
                          />
                          : 0
                        }
                        {' '} {t('carbon_page.liters_diesel')}
                      </span>

                      <div className="result"> 0 {t('carbon_page.kg_co2_eq')} </div>
                      {slider(0, 1)}
                    </div>

                    <div className="sum">0 {t('carbon_page.kg_co2_eq')}</div>

                  </React.Fragment>}

                  {resultStep === 2 && singleResultData && singleResultData[2] && <div>
                    <div className="title">{t('carbon_page.hot_water')}</div>
                    <div className={`scopeSum second ${editScope === resultStep && 'editCon'}`}>
                      <span className="value">
                        {editScope === resultStep ?
                          <Input
                            name="other"
                            type="text"
                            value={state.water}
                            className="form-control"
                            onChange={event => onChangeNumberState("water", event.target.value)}
                          />
                          :
                          singleResultData[2]['water'] ? AddCommasToFloat(singleResultData[2]['water'].value) : 0
                        }
                        {' '} m3
                      </span>

                      <div className="result">
                        {singleResultData[2]['water'] ? AddCommasToFloat(singleResultData[2]['water'].result) : 0} {t('carbon_page.kg_co2_eq')}
                      </div>

                      {slider(singleResultData[2]['water'] ? singleResultData[2]['water'].result : 0, singleResultData[2]['sum'])}
                    </div>

                    <div className="title" style={{marginTop: '10px'}}>{t('carbon_page.electricity')}:</div>
                    <div className={`scopeSum second ${editScope === resultStep && 'editCon'}`}>
                      <span className="value">
                        {editScope === resultStep ?
                          <Input
                            name="other"
                            type="text"
                            value={state.electric}
                            className="form-control"
                            onChange={event => onChangeNumberState("electric", event.target.value)}
                          />
                          :
                          singleResultData[2]['electricity'] ? AddCommasToFloat(singleResultData[2]['electricity'].value) : 0
                        }
                        {' '} kwst
                      </span>

                      <div className="result">
                        {singleResultData[2]['electricity'] ? AddCommasToFloat(singleResultData[2]['electricity'].result) : 0} {t('carbon_page.kg_co2_eq')}
                      </div>

                      {slider(singleResultData[2]['electricity'] ? singleResultData[2]['electricity'].result : 0, singleResultData[2]['sum'])}
                    </div>

                  </div>}

                  {resultStep === 3 && singleResultData && singleResultData[3] && <React.Fragment>
                    {Object.values(singleResultData[3])
                      .filter(f => typeof f !== 'string')
                      .sort((a,b) => b.result - a.result)
                      .map((item, index) =>
                        <div className={`scopeSum last ${editScope === resultStep && 'editCon'}`} key={"scope3Result_"+index}>
                          <p className="key">
                            {item.carbon_emission["type_" + currentLang] ? item.carbon_emission["type_" + currentLang] : item.carbon_emission.type}
                          </p>
                          <div className="scopeSumLastItems">
                           <span className="value">
                              {editScope === resultStep ?
                                <Input
                                  id={'value'+index}
                                  name="other"
                                  type="text"
                                  value={state.wastes[index].value}
                                  className="form-control"
                                  onChange={event => onChangeNumberWasteState("value", event.target.value, index)}
                                />
                                :
                                AddCommas(item.value)
                              }
                             {' kg '}
                           </span>

                            <span className="result">{AddCommasToFloat(item.result)} {t('carbon_page.kg_co2_eq')}</span>
                            {slider(item.result, singleResultData[3]['sum'])}
                          </div>
                        </div>
                      )
                    }

                    {state.newWastes.length>0 &&
                      <div className="addNewWastes">
                        {state.newWastes.map((item, index) =>
                          <div className="row" key={"scope3_1_waste_" + index}>
                            <div className="col-4">
                              <label htmlFor={'type'+index}>{t('carbon_page.waste_category')}</label>
                              <Select
                                id={'type'+index}
                                isSearchable={false}
                                placeholder={t('select')}
                                // value={state.newWastes[index].type || {}}
                                value={state.newWastes[index].type ? {...state.newWastes[index].type, label: state.newWastes[index].type["label_"+currentLang]} : {}}
                                onChange={(event) => onChangeWasteType(event, index)}
                                styles={customDropdownCO2Styles}
                                // options={scope3WasteOptions}
                                options={
                                 scope3WasteOptions.map((item) => {
                                    return {...item, label: item["label_"+currentLang]}
                                  })
                                }
                                className={`react-select ${state.wastesError && state.newWastes[index].type === '' && 'has-error'}`}
                              />
                            </div>

                            <div className="col-3">
                              <label htmlFor={'treatment'+index}>{t('carbon_page.treatment')}</label>
                              <Select
                                id={'treatment'+index}
                                isSearchable={false}
                                placeholder={t('select')}
                                isDisabled={!!(state.newWastes[index].treatment && state.newWastes[index].treatment.disabled === true) }
                                onChange={(event) => onChangeWasteTreatment(state.newWastes[index].type, event, index)}
                                // value={state.newWastes[index].treatment || {}}
                                value={state.newWastes[index].treatment ? {...state.newWastes[index].treatment, label: state.newWastes[index].treatment["label_"+currentLang]} : {}}
                                styles={customDropdownCO2Styles}
                                // options={state.newWastes[index].type ? state.newWastes[index].type.treatments : []}
                                options={
                                  state.newWastes[index].type ? state.newWastes[index].type.treatments.map((item) => {
                                    return {...item, label: item["label_"+currentLang]}
                                  }) : []
                                }
                                className={`react-select ${state.wastesError && state.newWastes[index].treatment === '' && 'has-error'}`}
                              />
                            </div>

                            <div className="col-3">
                              <label htmlFor={'value'+index}>{t('carbon_page.amount')}</label>
                              <Input
                                id={'value'+index}
                                name="other"
                                type="text"
                                value={state.newWastes[index].value}
                                onChange={event => onChangeNumberNewWasteState("value", event.target.value, index)}
                                className={`form-control ${state.wastesError && state.newWastes[index].value === '' && 'has-error'}`}
                              />
                            </div>
                            <div className="col-1" style={{paddingTop: '30px'}}>kg</div>
                          </div>
                        )}
                      </div>
                    }

                    {editScope === 3 && <div className="addNewWaste" onClick={() => addNewWaste()}> {t('carbon_page.add_category')} <AddCircle/> </div>}
                  </React.Fragment>}
                </div>
              </div>
              <div className="row edit">

                {singleResultData[resultStep] &&
                  <div className="edit" onClick={() => editScope > 0 && editScope === resultStep ? handleSaveScope(resultStep) : handleEditScope(resultStep)}>
                    {editScope > 0 && editScope === resultStep ? t('save') : t('edit')}
                  </div>
                }

                {
                  resultStep === 1 && !singleResultData[1] &&
                    <div className="edit" onClick={() => editScope > 0 && editScope === resultStep ? handleSaveFirstScope(resultStep) : handleEditEmptyFirstScope(resultStep)}>
                      {editScope > 0 && editScope === resultStep ? t('save') : t('carbon_page.add_information')}
                    </div>
                }

              </div>
            </div>
          </div>
        </div>
      </React.Fragment>}


        <div className="row footer">
          {continueBtn !== '' && <div className="btn continueBtn hover-effect" onClick={() => handleContinueBtn()}>{t('carbon_page.complete_calculations')} {continueBtn}</div>}
          <div className="btn hover-effect" onClick={() => addNewCarbon()}>{t('carbon_page.calculate_more_years')}</div>
        </div>
    </div>
  )
};

export default ResultPage