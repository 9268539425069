import React, {useState} from "react"
import {Modal} from "reactstrap"
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import PictureUpload2 from "../../../components/Common/CustomUpload/PictureUpload2";
import {createUpdateCompany} from "../../../store/actions/company";

const ChangeLogo = (props) => {
  const { t } = useTranslation();
  const {currentLogo, companyId} = props;
  const dispatch = useDispatch();
  const [logo, setLogo] = useState(currentLogo);

  const onConfirm = () => {
    let data = {
      "id": companyId,
      "logo": logo
    };
    dispatch(createUpdateCompany(true, data));
    props.onCancel();
  };

  const onCancel = () => {
    props.onCancel();
  };

  const onUpload = (data) => {
    setLogo(data);
  };

  return (
    <Modal isOpen={true} centered toggle={() => {onCancel()}} backdrop={"static"} className="changeLogoModal">
      <div className="modal-body">
        <div className="currentLogo">
          <div className="logoImage" style={{backgroundImage: "url(" + logo + ")"}}/>
        </div>

        <PictureUpload2  msg={t('settings_page.upload_logo')} onUpload={(data) => onUpload(data)}/>
      </div>

      <div className="modal-footer">
        <button type="button" className="btn hover-effect cancelBtn" onClick={() => onCancel()}>
          {t('cancel')}
        </button>
        <button type="button" className="btn hover-effect saveBtn" onClick={() => onConfirm()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  );

};

export default ChangeLogo