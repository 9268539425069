import React, { useEffect, useState } from "react"
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
import {useTranslation} from "react-i18next"
import {Language} from '@mui/icons-material';

import isFlag from "../../../assets/images/flags/is.png"
import enFlag from "../../../assets/images/flags/en.png"
import i18n from "../../../i18n"

const LanguageDropdown = () => {
  const { t } = useTranslation();
  const [selectedLang, setSelectedLang] = useState("")
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    const currentLanguage = localStorage.getItem("lang");
    setSelectedLang(currentLanguage || 'is')
  }, [])

  const changeLang = lang => {
    i18n.changeLanguage(lang);
    setSelectedLang(lang);
    localStorage.setItem('lang', lang);
  };

  return (
    <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="languageDropdown" title={t('switch_language')}>
      <DropdownToggle className="btn header-toggle waves-effect" tag="button">
        <Language/>
        <span> {selectedLang} </span>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-end">
        <DropdownItem onClick={() => changeLang('is')} className={`item ${selectedLang === 'is' ? "active" : "none"}`}>
          <img src={isFlag} alt="Íslenska" className="me-1" height="15"/>
          <span className="align-middle">Íslenska</span>
        </DropdownItem>
        <DropdownItem onClick={() => changeLang('en')} className={`item ${selectedLang === 'en' ? "active" : "none"}`}>
            <img src={enFlag} alt="English" className="me-1" height="15"/>
            <span className="align-middle">English</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default LanguageDropdown
