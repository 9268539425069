import React from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const CreateCarbonInitialPage = (props) => {
  const { t } = useTranslation();
  const {initStep} = props;
  const navigate = useNavigate();

  const nextInitStep = () => {
    if(initStep === 1) {
      props.onChangeInitStep(2);
    } else {
      props.onChangeInitStep(0);
      props.onChangeMainStep('0');
    }
  };

  const redirectToGreenStep = () => {
    navigate('/adgerdir', { state: {"addOwnCarbon": 154}});
  };

  return (
    <div className="initialPageContainer">
      {initStep === 1 &&
        <div className="step step1">
          <div className="title greenText">{t('carbon_page.about_calculator')}</div>
          <div className="copy">
            {t('carbon_page.about_calculator2')} <br/>
            {t('carbon_page.about_calculator3')} <br/>
            {t('carbon_page.about_calculator4')}
          </div>
          <div className="legal">
            <span className="greenText">{t('carbon_page.note1')}</span> {t('carbon_page.note2')} <br/>
              {t('carbon_page.note3')}
            <span className="link" onClick={() => redirectToGreenStep()}> {t('carbon_page.note4')}</span>
          </div>
        </div>
      }
      {initStep === 2 &&
        <div className="step step2">
          <div className="title greenText">{t('carbon_page.before_we_begin')}</div>
          <div className="copy">
            {t('carbon_page.before_we_begin2')} <br/>
            <p className="scope greenText">{t('carbon_page.scope')} 1</p>
            <p className="scopeDesc">{t('carbon_page.before_we_begin3')}</p>
            <p className="scope greenText">{t('carbon_page.scope')} 2</p>
            <p className="scopeDesc">{t('carbon_page.before_we_begin4')}</p>
            <p className="scopeDesc">{t('carbon_page.before_we_begin5')}</p>
            <p className="scope greenText">{t('carbon_page.scope')} 3</p>
            <p className="scopeDesc">{t('carbon_page.before_we_begin6')}</p>
          </div>
        </div>
      }
      <div className="btn hover-effect" onClick={() => nextInitStep()}>
        {initStep === 1 ? t('carbon_page.next') : t('carbon_page.begin')}
      </div>
    </div>
  )
};

export default CreateCarbonInitialPage