import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const createNotification = (data) => async dispatch => {
  dispatch({type: actionTypes.CREATE_NOTIFICATIONS_START});
  try {
    const res = await axios({
      url: '/admin_notification/create_notification',
      method:'POST',
      data: {...data}
    });
    dispatch({type: actionTypes.CREATE_NOTIFICATIONS_SUCCESS, data: res.data});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Tilkynning send', style:'success'});
    dispatch(getNotifications(70));
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: actionTypes.CREATE_NOTIFICATIONS_FAIL, data: errorMessage});
    dispatch({type: actionTypes.TOGGLE_SNACKBAR_OPEN, message: 'Something went wrong', style:'error'});
  }
};


export const getNotifications = (companyId) => async dispatch => {
  dispatch({type: actionTypes.GET_NOTIFICATIONS_START});
  try {
    let res = await axios.get('/admin_notification/get_notifications?company_id='+companyId);
    dispatch({type: actionTypes.GET_NOTIFICATIONS_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_NOTIFICATIONS_FAIL});
  }
};

export const notificationMarkAsRead = (id) => async dispatch => {
  dispatch({type: actionTypes.GET_NOTIFICATIONS_READ_START});
  try {
    let res = await axios.get('/admin_notification/mark_as_read/'+id);
    dispatch({type: actionTypes.GET_NOTIFICATIONS_READ_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_NOTIFICATIONS_READ_FAIL});
  }
};

export const getNotificationsMenu = (companyId, roles) => async dispatch => {
  dispatch({type: actionTypes.GET_NOTIFICATIONS_MENU_START});
  try {
    let res;
    if(roles === 'super_admin' || roles === 'manager') {
      res = await axios.get('/admin_notification?role=manager&read_at=true');
    } else {
      res = await axios.get('/admin_notification?role=company_user&read_at=true&company_id='+companyId);
    }
    dispatch({type: actionTypes.GET_NOTIFICATIONS_MENU_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_NOTIFICATIONS_MENU_FAIL});
  }
};

export const notificationMenuMarkAsRead = (id) => async dispatch => {
  dispatch({type: actionTypes.GET_NOTIFICATIONS_MENU_READ_START});
  try {
    let res = await axios.get('/admin_notification/mark_as_read/'+id);
    dispatch({type: actionTypes.GET_NOTIFICATIONS_MENU_READ_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_NOTIFICATIONS_MENU_READ_FAIL});
  }
};
