import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getCompanyUsers = (companyId) => async dispatch => {
  dispatch({type: actionTypes.GET_COMPANY_USER_START});
  try {
    const res = await axios.get('/company_setting/get_users/'+companyId);
    dispatch({type: actionTypes.GET_COMPANY_USER_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_COMPANY_USER_FAIL});
  }
};

export const createUpdateCompanyUser = (update, data) => async dispatch => {
  dispatch({type: update ? actionTypes.UPDATE_COMPANY_USER_START : actionTypes.CREATE_COMPANY_USER_START});
  try {
    const res = await axios({
      url: update ? '/company_setting/update_user' : '/company_setting/create_user',
      method: 'POST',
      data: { ...data }
    });
    dispatch({type: update ? actionTypes.UPDATE_COMPANY_USER_SUCCESS : actionTypes.CREATE_COMPANY_USER_SUCCESS, data: res.data});
    dispatch(getCompanyUsers(data.company_id));
  } catch (error) {
    const res = error.response.data;
    const message = (res.results) ? res.results[Object.keys(res.results)[0]] : '';
    const errorMessage = res.message + ". " + message;
    dispatch({type: update ? actionTypes.UPDATE_COMPANY_USER_FAIL : actionTypes.CREATE_COMPANY_USER_FAIL, data: errorMessage});
  }
};


export const deleteCompanyUser = (id, company_id) => async dispatch => {
  dispatch({type: actionTypes.DELETE_COMPANY_USER_START});
  try {
    const res = await axios.delete('/company_setting/delete_user/' + id);
    dispatch({type: actionTypes.DELETE_COMPANY_USER_SUCCESS, data: res.data});
    dispatch(getCompanyUsers(company_id));
  } catch (error) {
    dispatch({type: actionTypes.DELETE_COMPANY_USER_FAIL});
  }
};
