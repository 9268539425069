import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import Select from "react-select";
import {customDropdownStyles} from "../../../helpers/dropdown";
import {Check} from "@mui/icons-material";
import {HelperGetCurrentDate} from "../../../helpers/main";
import {createCompanyGreenStep} from "../../../store/actions/greenStep";
import {Label} from "reactstrap";
import {useTranslation} from "react-i18next";

const yearOptions = () => {
  let years = [];
  for(let i=0; i<=60; i++) {
    years.push({label: 1990+i, value: 1990+i})
  }
  return years;
};

const percentOptions = () => {
  let percent = [];
  for(let i=1; i<=100; i++) {
    percent.push({label: i, value: i})
  }
  return percent;
};
const yearOptionsTo = () => {
  let years = [];
  for(let i=0; i<=60; i++) {
    years.push({label: 2024+i, value: 2024+i})
  }
  return years;
};

const RenderDropdownContainer = (props) => {
  const {mainCategoryIndex, itemId} = props;
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const companyId = userData ? userData.companyId : 0;

  const [yearFrom, setYearFrom] = useState(0);
  const [yearTo, setYearTo] = useState(0);
  const [percent, setPercent] = useState(0);

  const addCustomCommentByYear = (id) => {
    if(yearFrom === 0 || yearTo === 0 || percent === 0) {
      return false;
    }

    let data = {
      "new_green_step_id": id,
      "comment": [{
        "comment": `Frá ${yearFrom}-${yearTo} mun fyrirtækið draga úr losun gróðurhúsalofttegunda um ${percent}%.`,
        "user_id": userData.user.id,
        "name": "Töluleg áætlun",
        "date": HelperGetCurrentDate(),
        "customComment": true
      }],
      "irrelevant": false,
      "reason": [],
      "pdf": null
    };

    dispatch(createCompanyGreenStep(data, companyId, mainCategoryIndex));
    return true

  };

  return (
    <div className={`dropDownBox row`}>
      <div className="col-3">
        <Label className="col-form-label categoryTextColor">{t('pluses_page.beginning_year')}</Label>
        <Select
          placeholder={t('select')}
          isSearchable={false}
          onChange={(event) => setYearFrom(event.value)}
          styles={customDropdownStyles()}
          options={yearOptions()}
        />
      </div>
      <div className="col-3">
        <Label className="col-form-label categoryTextColor">{t('pluses_page.end_year')}</Label>
        <Select
          placeholder={t('select')}
          isSearchable={false}
          onChange={(event) => setYearTo(event.value)}
          styles={customDropdownStyles()}
          options={yearOptionsTo()}
        />
      </div>
      <div className="col-3">
        <Label className="col-form-label categoryTextColor">{t('pluses_page.emission_reductions')}</Label>
        <Select
          placeholder={t('select')}
          isSearchable={false}
          onChange={(event) => setPercent(event.value)}
          styles={customDropdownStyles()}
          options={percentOptions()}
        />
      </div>
      <div className="col-2">
        <div className="companyCompletedIcon categoryBorderColor true" onClick={()=>addCustomCommentByYear(itemId)}><Check/></div>
      </div>
    </div>
  )
};

export default RenderDropdownContainer