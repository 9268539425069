import * as actionTypes from '../actionTypes';
import axios from "../middlewares/axios";

export const getDashboardReport = (companyId) => async dispatch => {
  dispatch({type: actionTypes.GET_DASHBOARD_REPORT_START});
  try {
    const res = await axios.get('/dashboard/total_count/'+companyId);
    dispatch({type: actionTypes.GET_DASHBOARD_REPORT_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_DASHBOARD_REPORT_FAIL});
  }
};

export const getDashboardMunicipality = (municipalityId) => async dispatch => {
  dispatch({type: actionTypes.GET_DASHBOARD_MUNICIPALITY_START});
  try {
    const res = await axios.get('/dashboard/municipality/'+municipalityId);
    dispatch({type: actionTypes.GET_DASHBOARD_MUNICIPALITY_SUCCESS, data: res.data});
  } catch (error) {
    dispatch({type: actionTypes.GET_DASHBOARD_MUNICIPALITY_FAIL});
  }
};

