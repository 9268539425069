import * as actionTypes from '../actionTypes';

const initialState = {
  municipalities: [],
  institutions: [],
  loadingMunicipality: false,
  loadingInstitution: false,

  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function registrationReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_MUNICIPALITIES_START:
      return  {
        ...state,
        loadingMunicipality: true
      };
    case actionTypes.GET_MUNICIPALITIES_SUCCESS:
      return {
        ...state,
        municipalities: action.data,
        loadingMunicipality: false
      };
    case actionTypes.GET_MUNICIPALITIES_PLAN_FAIL:
      return  {
        ...state,
        loadingMunicipality: false
      };


    case actionTypes.GET_INSTITUTIONS_START:
      return  {
        ...state,
        loadingInstitution: true
      };
    case actionTypes.GET_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        institutions: action.data,
        loadingInstitution: false
      };
    case actionTypes.GET_INSTITUTIONS_FAIL:
      return  {
        ...state,
        loadingInstitution: false
      };



    default:
      return state;
  }
}
