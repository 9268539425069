import React, {useEffect} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {useNavigate} from "react-router-dom";
import {Card, CardBody, Button, Container} from "reactstrap"
import {Notifications} from "@mui/icons-material";

import AddAnnouncement from "./addAnnouncement";
import {LoaderSpinner} from "../../components/Common/Loader";
import {getNotifications} from "../../store/actions/notification";
import {helperFormatDateWithMonthName} from "../../helpers/main";

import "./index.scss"

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [alert, setAlert] = React.useState(null);
  const {notifications, loading} = useSelector(state => state.notification);

  useEffect(() => {
    dispatch(getNotifications(70));
  }, []);

  if (loading) return (<LoaderSpinner/>);

  const htmlAlert = (data) => {
    setAlert(<AddAnnouncement data={data} onCancel={() => setAlert(null)}/>);
  };

  const redirectToGreenStep = (id, index) => {
    navigate('/adgerdir', { state: {"showCategory": true, categoryId: id, categoryIndex: index}});
  };


  return (
    <div className="page-content announcementListPage">
      {alert}
      <Card>
        <CardBody>
          <div className="row">
            <div className="col col-12">
              <Button color="success" className="mb-4 float-end" size="sm" style={{float: 'right'}} onClick={() => htmlAlert(null)}>
                Bæta við tilkynningu
              </Button>
            </div>
          </div>

            {notifications && notifications.results.map((notification, index) => {
              let logData = notification.data ? notification.data : false;
              return (
                <div className="containerRow" key={"activityLog_"+index}>
                  <div className="logRow logDate">{helperFormatDateWithMonthName(notification.created_at)}</div>
                  <div className="logRow">
                    <span className="logType">
                      <Notifications/>
                    </span>
                    <span className="logCategory">{logData.type.label}</span>
                  </div>
                  <div className={`logRow desc ${logData.greenStepMainCategory ? "mainIndex_"+logData.greenStepMainCategory.value : '0'}`}>
                    <span className="logDesc">{
                      logData.description ?
                        logData.greenStepMainCategory ? <div onClick={() => redirectToGreenStep(logData.greenStepId, logData.greenStepMainCategory.index)}  dangerouslySetInnerHTML={{__html: logData.description}}/> : logData.description
                        : ''
                    }</span>
                  </div>
                </div>
              )
            })}


        </CardBody>
      </Card>
    </div>
  )
};

export default Index
