import * as actionTypes from '../actionTypes';

const initialState = {
  loadingPermission: true,
  permissions: [],
  loadingRole: true,
  roles: [],
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function tutorialVideoReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_PERMISSION_START:
      return  {
        ...state,
        loadingPermission: true
      };
    case actionTypes.GET_PERMISSION_SUCCESS:
      return {
        ...state,
        permissions: action.data,
        loadingPermission: false
      };
    case actionTypes.GET_PERMISSION_FAIL:
      return  {
        ...state,
        loadingPermission: false
      };

    case actionTypes.GET_ROLE_START:
      return  {
        ...state,
        loadingRole: true
      };
    case actionTypes.GET_ROLE_SUCCESS:
      return {
        ...state,
        roles: action.data,
        loadingRole: false
      };
    case actionTypes.GET_ROLE_FAIL:
      return  {
        ...state,
        loadingRole: false
      };


    default:
      return state;
  }
}
