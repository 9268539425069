import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {Input} from "reactstrap";
import {Check} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {HelperGetCurrentDate} from "../../../helpers/main";
import {editComment} from "../../../store/actions/greenStep";

const CommentContainer = (props) => {
  const {item} = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userData = JSON.parse(localStorage.getItem('p_user'));
  const [commentInput, setCommentInput] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);

  const [isIrrelevant, setIsIrrelevant] = useState(false);

  useEffect(() => {
    if(props.item && props.item.hasOwnProperty('irrelevant') && props.item.irrelevant === true) {
      setIsIrrelevant(true);
    }
  }, props.item);

  const saveComment = (id, isIrrelevant) => {
    if(isIrrelevant && commentInput.length<3) {
      setErrorStatus(true);
      return false;
    }

    if(commentInput.length>3) {
      let comment = [{
        "comment": commentInput,
        "user_id": userData.user.id,
        "name": userData.user.name,
        "date": HelperGetCurrentDate(),
      }];
      dispatch(editComment(id, comment, item.main_category-1, isIrrelevant ? 'reason' : 'comment'));
      setCommentInput("");
      setErrorStatus(false);
    }

  };

  return (
    <div className="row justify-content-start align-items-center userCommentRow">
      <div className="col-8">
        <Input
          id="title-list"
          type="text"
          className="form-control companyCompletedInput"
          placeholder={isIrrelevant ? t('pluses_page.why_not_applicable') : t('pluses_page.add_explanation')}
          value={commentInput}
          onKeyDown={evt => evt.key === "Enter" && saveComment(item.id, isIrrelevant)}
          onChange={(e) => {setCommentInput(e.target.value)}}
          invalid={isIrrelevant && errorStatus && commentInput.length === 0}
        />
      </div>
      <div className="col-2">
        <p className="companyCompletedIcon categoryBorderColor save true" onClick={()=> {saveComment(item.id, isIrrelevant)}}><Check/></p>
      </div>
    </div>
  )
};

export default CommentContainer