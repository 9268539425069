import * as actionTypes from '../actionTypes';

const initialState = {
  loadingCertificate: true,
  certificates: [],
  loading: true,
  companyCertificates: [],

  error: false,
  notification: {
    status: false,
    type: '',
    message: ''
  }
};

export default function companyReducer(state = initialState, action = {}) {
  switch (action.type) {

    case actionTypes.GET_CERTIFICATES_START:
      return  {
        ...state,
        loadingCertificate: true
      };
    case actionTypes.GET_CERTIFICATES_SUCCESS:
      return {
        ...state,
        certificates: action.data,
        loadingCertificate: false
      };
    case actionTypes.GET_CERTIFICATES_FAIL:
      return  {
        ...state,
        loadingCertificate: false
      };


    case actionTypes.GET_COMPANY_CERTIFICATES_START:
      return  {
        ...state,
        loading: true
      };
    case actionTypes.GET_COMPANY_CERTIFICATES_SUCCESS:
      return {
        ...state,
        companyCertificates: action.data,
        loading: false
      };
    case actionTypes.GET_COMPANY_CERTIFICATES_FAIL:
      return  {
        ...state,
        loading: false
      };


    case actionTypes.DELETE_COMPANY_CERTIFICATE_START:
      return  {
        ...state
      };
    case actionTypes.DELETE_COMPANY_CERTIFICATE_SUCCESS:
      const id = action.data.results.id;
      const storyData = state.companyCertificates.results.filter((item) => item.id !== id);
      state.companyCertificates.results = storyData;
      return {
        ...state
      };
    case actionTypes.DELETE_COMPANY_CERTIFICATE_FAIL:
      return  {
        ...state
      };

    default:
      return state;
  }
}
