import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import Switch from "react-switch";
import i18n from "../../../i18n";
import {Check, Add, Remove} from '@mui/icons-material';
import {Input} from "reactstrap";
import {HelperGetCurrentDate} from "../../../helpers/main";
import {createCompanyGreenStep, deleteCompanyGreenStep, editComment} from "../../../store/actions/greenStep";
import RenderDropdownContainer from "./renderDropdownContainer";
import RenderPdfFileContainer from "./renderPdfFileContainer";
import avatar2 from "../../../assets/images/company_placeholder.png";

import GreenStepDefault from "./customGreenStepsCompleted/greenStepDefault";
import GreenStepDefaultShow from "./customGreenStepsCompleted/greenStepDefaultShow";
import GreenStep62 from "./customGreenStepsCompleted/greenStep62";
import GreenStep142 from "./customGreenStepsCompleted/greenStep142";
import GreenStep144 from "./customGreenStepsCompleted/greenStep144";
import GreenStep168 from "./customGreenStepsCompleted/greenStep168";
import GreenStep173 from "./customGreenStepsCompleted/greenStep173";
import GreenStep177 from "./customGreenStepsCompleted/greenStep177";
import GreenStep179 from "./customGreenStepsCompleted/greenStep179";
import GreenStep191 from "./customGreenStepsCompleted/greenStep191";
import GreenStep196 from "./customGreenStepsCompleted/greenStep196";
import GreenStep197 from "./customGreenStepsCompleted/greenStep197";
import GreenStep201 from "./customGreenStepsCompleted/greenStep201";
import GreenStep202 from "./customGreenStepsCompleted/greenStep202";
import GreenStep203 from "./customGreenStepsCompleted/greenStep203";
import GreenStep204 from "./customGreenStepsCompleted/greenStep204";
import GreenStep207 from "./customGreenStepsCompleted/greenStep207";
import GreenStep210 from "./customGreenStepsCompleted/greenStep210";
import GreenStep211 from "./customGreenStepsCompleted/greenStep211";
import GreenStep212 from "./customGreenStepsCompleted/greenStep212";
import GreenStep213 from "./customGreenStepsCompleted/greenStep213";
import GreenStep214 from "./customGreenStepsCompleted/greenStep214";
import GreenStep215 from "./customGreenStepsCompleted/greenStep215";
import GreenStep216 from "./customGreenStepsCompleted/greenStep216";
import GreenStep217 from "./customGreenStepsCompleted/greenStep217";

import GreenStep203Show from "./customGreenStepsCompleted/greenStep203Show";
import GreenStep215Show from "./customGreenStepsCompleted/greenStep215Show";
import GreenStep216Show from "./customGreenStepsCompleted/greenStep216Show";
import GreenStep217Show from "./customGreenStepsCompleted/greenStep217Show";
import GreenStep219Show from "./customGreenStepsCompleted/greenStep219Show";

const linkRegExp = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim; // eslint-disable-line

const RenderCompletedList = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentLang = i18n.language || 'is';
  const userData = JSON.parse(localStorage.getItem('p_user'));
  const firstCompany = userData && userData.companies.length>0 ? userData.companies[0] : false;
  const isInstitution = firstCompany && firstCompany.municipality_id>0 && firstCompany.type === null;
  const {data, dataType, mainCategoryIndex} = props;

  const [commentInput, setCommentInput] = useState("");
  const [commentId, setCommentId] = useState(0);
  const [commentItemIndex, setCommentItemIndex] = useState(999);
  const [newCommentId, setNewCommentId] = useState(0);

  const editCommentAction = (id, comment, type) => {
    dispatch(editComment(id, comment, mainCategoryIndex, type));
  };

  const toggleGreenStepByCompany = (greenStep, irrelevant) => {
    let item = greenStep.new_company_green_steps && greenStep.new_company_green_steps.length>0 ? greenStep.new_company_green_steps[0] : false;
    if(greenStep.new_company_green_steps[0].deletedItem) {
      let data = {
        "new_green_step_id": greenStep.id,
        "comment": item && item.comment && item.comment.length > 0 ? JSON.parse(item.comment) : [],
        "irrelevant": irrelevant,
        "reason": item && item.reason && item.reason.length > 0 ? JSON.parse(item.reason) : [],
      };
      dispatch(createCompanyGreenStep(data, userData?.companyId, mainCategoryIndex));
    } else {
      dispatch(deleteCompanyGreenStep(greenStep.new_company_green_steps[0].id, mainCategoryIndex));
    }
  };

  const setCommentState = (comment = "", commentId = 0, commentIndex = 999, commentNewId = 0) => {
    setCommentInput(comment);
    setCommentId(commentId);
    setCommentItemIndex(commentIndex);
    setNewCommentId(commentNewId);
  };

  const removeComment = (commentId, commentIndex, commentData, subCategory, type) => {
    commentData.splice(commentIndex, 1);
    editCommentAction(commentId, commentData, type);
    setCommentState();
  };

  const saveNewComment = (greenStep, subCategory, type) => {
    let newCom = greenStep.new_company_green_steps[0][type] ? JSON.parse(greenStep.new_company_green_steps[0][type]) : [];
    let data = {"comment": commentInput, "user_id": userData.user.id, "name": userData.user.name, "date": HelperGetCurrentDate()};
    if(newCom && newCom.length>0) {
      newCom.unshift(data);
    } else {
      newCom = [data];
    }

    editCommentAction(newCommentId, commentInput.length === 0 ? "" : newCom, type);
    setCommentState();
  };

  return data.map((greenStep, greenStepIndex) => {
    let isDeleted = greenStep.new_company_green_steps.length>0 && greenStep.new_company_green_steps[0].deletedItem;
    let currentActive = greenStep.new_company_green_steps[0].id === newCommentId;
    let comment = greenStep.new_company_green_steps && greenStep.new_company_green_steps.length>0 && greenStep.new_company_green_steps[0][dataType] ? JSON.parse(greenStep.new_company_green_steps[0][dataType]) : null;
    let desc = greenStep["description_" + currentLang] ? greenStep["description_" + currentLang] : greenStep.description;
    let descIsContainHttp = desc && desc.includes('http');
    let companyLogo = isInstitution && greenStep.type === "municipality" ? firstCompany && firstCompany.logo ? firstCompany.logo : avatar2  : '';
    return (
      <div
        className={`col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-8 item ${'completedGreenStep_'+greenStep.id}  ${dataType === 'reason' ? 'irrelevant' : ''} ${isDeleted ? 'deletedItem' : ''}`}
        key={'cat_'+mainCategoryIndex+'_step_'+greenStep.id}
      >

        {!isInstitution &&
          <React.Fragment>
            {dataType === 'comment' &&
              <p
                className={`${isDeleted ? 'companyCompletedIcon categoryBorderColor' : 'completedDoneIcon categoryBgColor hover-effect'}`}
                onClick={() => toggleGreenStepByCompany(greenStep, false)}
              >
                <Check/>
              </p>
            }

            {dataType === 'reason' &&
              <div className="completedDoneSwitch">
                <Switch
                  id={'irrelevant-completed-edit_'+mainCategoryIndex+'_step_'+greenStepIndex}
                  checked={!isDeleted}
                  onChange={() => toggleGreenStepByCompany(greenStep, true)}
                  activeBoxShadow="0px 0px 1px 2px #f3f3f3"
                  checkedHandleIcon={
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                      <Check/>
                    </div>
                  }
                  className={`react-custom-switch ${!isDeleted ? 'active' : ''}`}
                />
              </div>
            }

            {greenStep.id !== 62 && greenStep.id !== 211 &&
              <p className={`completedDoneTitle ${!isDeleted && currentActive ? 'activeAddComment' : ''} `}>
                <span className="completedDoneTitleCopy">
                  {greenStep["title_"+currentLang] ? greenStep["title_"+currentLang] : greenStep.title}
                </span>

                {!isDeleted &&
                  <span
                    className='completedAddNewIcon categoryBgColor addNew hover-effect'
                    onClick={() => currentActive ? setCommentState() : setCommentState("", 0, 999, greenStep.new_company_green_steps[0].id)}
                  >
                    {currentActive ? <Remove/> : <Add/>}
                  </span>
                }
              </p>
            }

            {!isDeleted && currentActive &&
              <div className="completedDoneDesc">
                {
                  descIsContainHttp ?
                    <div dangerouslySetInnerHTML={{ __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`) }} />
                  :
                    desc
                }
              </div>
            }

            {!isDeleted && currentActive &&
              <p className="completedCommentInputRow">
                <Input
                  id="title-completed-edit"
                  type="text"
                  className="form-control companyDoneInput"
                  placeholder={t('pluses_page.add_explanation')}
                  value={commentInput}
                  onKeyDown={evt => evt.key === "Enter" && saveNewComment(greenStep, greenStep.new_green_step_category_id, dataType)}
                  onChange={(e) => {setCommentInput(e.target.value)}}
                />
                <p className="companyCompletedIcon categoryBorderColor save true hover-effect" onClick={()=> {saveNewComment(greenStep, greenStep.new_green_step_category_id, dataType)}}><Check/></p>
              </p>
            }

            {(greenStep.pdf_description !== null && greenStep.id !== 157 && greenStep.id !== 155 && greenStep.id !== 219 && (greenStep.new_company_green_steps[0].pdf !== "" || greenStep.new_company_green_steps[0].link !== "")) &&
              <div className="completedDoneDesc">
                  <RenderPdfFileContainer
                    categoryItem={greenStep}
                    mainCategoryIndex={mainCategoryIndex}
                    key={'cat_'+mainCategoryIndex+'_step_'+greenStep.id+'keyFileUploadPdf'}
                    requiredFrom={false}
                  />
              </div>
            }

            {comment && comment.length>0 && comment.map((commentItem, commentIndex) => {
              return (
                <div className="completedComment completedDoneComment" key={'cat_step'+mainCategoryIndex+"_"+greenStepIndex+"_comm_"+commentIndex}>
                  {greenStep.id !== 203 && greenStep.id !== 62 && greenStep.id !== 211 && <React.Fragment>
                    <p className="completedCommentName categoryTextColor">{commentItem.name}</p>
                    <p className="completedCommentDate categoryTextColor">{commentItem.date}</p>
                  </React.Fragment>}

                  {commentId === greenStep.new_company_green_steps[0].id && commentIndex === commentItemIndex ?
                    <React.Fragment>

                      {!Object.hasOwn(commentItem, 'customComment') &&
                          <GreenStepDefault comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />
                      }

                      {greenStep.id === 62 && Object.hasOwn(commentItem, 'customComment') && <GreenStep62 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 142 && Object.hasOwn(commentItem, 'customComment') && <GreenStep142 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 144 && Object.hasOwn(commentItem, 'customComment') && <GreenStep144 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 155 && Object.hasOwn(commentItem, 'customComment') && <RenderDropdownContainer mainCategoryIndex={greenStep.main_category-1} itemId={greenStep.id}/>}
                      {greenStep.id === 157 && Object.hasOwn(commentItem, 'customComment') && <RenderDropdownContainer mainCategoryIndex={greenStep.main_category-1} itemId={greenStep.id}/>}
                      {greenStep.id === 168 && Object.hasOwn(commentItem, 'customComment') && <GreenStep168 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 173 && Object.hasOwn(commentItem, 'customComment') && <GreenStep173 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 177 && Object.hasOwn(commentItem, 'customComment') && <GreenStep177 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 179 && Object.hasOwn(commentItem, 'customComment') && <GreenStep179 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 191 && Object.hasOwn(commentItem, 'customComment') && <GreenStep191 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 196 && Object.hasOwn(commentItem, 'customComment') && <GreenStep196 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 197 && Object.hasOwn(commentItem, 'customComment') && <GreenStep197 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 201 && Object.hasOwn(commentItem, 'customComment') && <GreenStep201 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 202 && Object.hasOwn(commentItem, 'customComment') && <GreenStep202 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 203 && Object.hasOwn(commentItem, 'customComment') && <GreenStep203 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 204 && Object.hasOwn(commentItem, 'customComment') && <GreenStep204 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 207 && Object.hasOwn(commentItem, 'customComment') && <GreenStep207 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 210 && Object.hasOwn(commentItem, 'customComment') && <GreenStep210 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 211 && Object.hasOwn(commentItem, 'customComment') && <GreenStep211 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 213 && Object.hasOwn(commentItem, 'customComment') && <GreenStep213 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 212 && Object.hasOwn(commentItem, 'customComment') && <GreenStep212 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 214 && Object.hasOwn(commentItem, 'customComment') && <GreenStep214 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 215 && Object.hasOwn(commentItem, 'customComment') && <GreenStep215 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 216 && Object.hasOwn(commentItem, 'customComment') && <GreenStep216 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}
                      {greenStep.id === 217 && Object.hasOwn(commentItem, 'customComment') && <GreenStep217 comment={comment} item={commentItem} commentIndex={commentIndex} greenStep={greenStep} close={() => setCommentState()} />}


                    </React.Fragment>
                    :
                    <React.Fragment>
                      {greenStep.id !== 203 && greenStep.id !== 215 && greenStep.id !== 216 && greenStep.id !== 217 && greenStep.id !== 219 &&
                         <GreenStepDefaultShow
                           comment={comment}
                           commentIndex={commentIndex}
                           commentItem={commentItem}
                           isDeleted={isDeleted}
                           greenStep={greenStep}
                           dataType={dataType}
                           changeCommentState={(comment, id, index) => setCommentState(comment, id, index)}
                           toggleItem={() => toggleGreenStepByCompany(greenStep, false)}
                           removeComment={(commentId, commentIndex, commentData, subCategory, type) => removeComment(commentId, commentIndex, commentData, subCategory, type)}
                         />
                      }

                      {greenStep.id === 203 &&
                        <GreenStep203Show
                          commentIndex={commentIndex}
                          commentItem={commentItem}
                          greenStep={greenStep}
                          changeCommentState={(comment, id, index) => setCommentState(comment, id, index)}
                       />
                      }

                      {greenStep.id === 215 &&
                        <GreenStep215Show
                          comment={comment}
                          commentIndex={commentIndex}
                          commentItem={commentItem}
                          isDeleted={isDeleted}
                          greenStep={greenStep}
                          dataType={dataType}
                          changeCommentState={(comment, id, index) => setCommentState(comment, id, index)}
                          removeComment={(commentId, commentIndex, commentData, subCategory, type) => removeComment(commentId, commentIndex, commentData, subCategory, type)}
                       />
                      }

                      {greenStep.id === 216 &&
                        <GreenStep216Show
                          comment={comment}
                          commentIndex={commentIndex}
                          commentItem={commentItem}
                          isDeleted={isDeleted}
                          greenStep={greenStep}
                          dataType={dataType}
                          changeCommentState={(comment, id, index) => setCommentState(comment, id, index)}
                          removeComment={(commentId, commentIndex, commentData, subCategory, type) => removeComment(commentId, commentIndex, commentData, subCategory, type)}
                       />
                      }

                      {greenStep.id === 217 &&
                        <GreenStep217Show
                          comment={comment}
                          commentIndex={commentIndex}
                          commentItem={commentItem}
                          isDeleted={isDeleted}
                          greenStep={greenStep}
                          dataType={dataType}
                          changeCommentState={(comment, id, index) => setCommentState(comment, id, index)}
                          removeComment={(commentId, commentIndex, commentData, subCategory, type) => removeComment(commentId, commentIndex, commentData, subCategory, type)}
                       />
                      }

                      {greenStep.id === 219 &&
                        <GreenStep219Show
                          comment={comment}
                          commentIndex={commentIndex}
                          commentItem={commentItem}
                          isDeleted={isDeleted}
                          greenStep={greenStep}
                          dataType={dataType}
                          changeCommentState={(comment, id, index) => setCommentState(comment, id, index)}
                          removeComment={(commentId, commentIndex, commentData, subCategory, type) => removeComment(commentId, commentIndex, commentData, subCategory, type)}
                       />
                      }
                    </React.Fragment>
                  }

                </div>
              )
            })}
          </React.Fragment>
        }

        {isInstitution && greenStep.type === "municipality" &&
        <React.Fragment>

          <div className="companyLogo" style={{backgroundImage: "url(" + companyLogo + ")"}}/>


          <p className={`completedDoneTitle`}>
            <span className="completedDoneTitleCopy">
              {greenStep["title_"+currentLang] ? greenStep["title_"+currentLang] : greenStep.title}
            </span>
          </p>

          <div className="completedDoneDesc">
            {descIsContainHttp ?
              <div dangerouslySetInnerHTML={{ __html: desc.replace(linkRegExp, `<a href="$1" target="_blank" rel="noopener noreferrer" class="categoryTextColor">${t("here")}</a>`) }} />
            :
              desc
            }
          </div>

          {comment && comment.length>0 && comment.map((commentItem, commentIndex) => {
            return (
              <div className="completedComment completedDoneComment" key={'cat_step'+mainCategoryIndex+"_"+greenStepIndex+"_comm_"+commentIndex}>
                <p className="completedCommentName categoryTextColor">{commentItem.name}</p>
                <p className="completedCommentDate categoryTextColor">{commentItem.date}</p>
                <p className="completedCommentText">{commentItem.comment}</p>
              </div>
            )
          })}
        </React.Fragment>
        }
      </div>
    )
  })

};

export default RenderCompletedList