import React, {useEffect} from "react"
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {Table,  Card, CardBody, Button} from "reactstrap"
import {Done, RemoveDone} from '@mui/icons-material';

import {getCompanyEnvironmentals, deleteCompanyEnvironmental, updateCompanyEnvironmentalStatus} from "../../store/actions/companyEnvironmental";
import DeleteButton from "../../components/Common/CustomButtons/DeleteButton";
import pdfIcon from "../../assets/images/pdf_icon.png";
import {LoaderSpinner} from "../../components/Common/Loader";
import {HelperGetCurrentDate} from "../../helpers/main";
import UserAvatar from "../../components/Common/UserAvatar";
import "./index.scss"

const formateDate = (date) => {
  const today = new Date(date);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  return dd + '-' + mm + '-' + yyyy;
};

const Index = () => {
  const dispatch = useDispatch ();
  const { t } = useTranslation();
  const {companyEnvs, loading} = useSelector( state => state.companyEnvironmental);
  const store = JSON.parse(localStorage.getItem('p_user'));
  const permission = store ? store.permission : [];
  const storageUser = store.user;

  const canApprove = permission.includes('admin_company_environmental_approve');
  const canDelete = permission.includes('admin_company_environmental_delete');

  useEffect(() => {
    dispatch(getCompanyEnvironmentals());
  }, []);

  const handleChangeStatus = (value, status) => {
    let user = status ? {
      "user_id": storageUser.id,
      "name": storageUser.name,
      "profile": storageUser.profile_picture,
      "date": HelperGetCurrentDate()
    } : {};
    dispatch(updateCompanyEnvironmentalStatus(value.id, status, user))
  };

  if (loading) return (<LoaderSpinner/>);

  const tableData = !companyEnvs.results ? '' :
    companyEnvs.results.map((value, index) => {
      let approvedBy = value.approved && value.approved_by ? JSON.parse(value.approved_by) : false;
      return (<tr key={index}>
        <td>{index+1}</td>
        <td><img className="img-thumbnail rounded category_img" src={value.company?.logo} alt=""/> {value.company?.name} </td>
        <td>
          {value.approved ?
            <React.Fragment>
              <Button color='success' size="sm">Approved</Button> &nbsp;
              {approvedBy && <UserAvatar name={approvedBy.name} profile={approvedBy.profile} className="leaves_user_img_name" key={'user_avatar_env_'+index} />}
            </React.Fragment>
            :
            <Button color='warning' size="sm">Not Approved</Button>
          }
        </td>
        <td><a href={value.pdf} target="_blank" rel="noopener noreferrer"><img style={{width: '20px'}} src={pdfIcon} alt=""/> </a></td>
        <td>{formateDate(value.updated_at)}</td>
        <td>
          {
            canApprove ?
              value.approved ?
                <Button key={value.id+'unApprove'} color="success" size="sm" onClick={() => handleChangeStatus(value, false)}><RemoveDone style={{fontSize: '12px'}}/></Button>
              :
                <Button key={value.id+'approve'} color="success" size="sm" onClick={() => handleChangeStatus(value, true)}><Done style={{fontSize: '12px'}}/></Button>
              : ''
          }
          {canDelete && value.company && <DeleteButton action={() => dispatch(deleteCompanyEnvironmental(value.company?.id, 'main'))}/>}
        </td>
      </tr>)
    });

  return (
    <div className="page-content">
      <Card>
        <CardBody>
          <div className="table-responsive">
            <Table className="table table-striped table-hover">
              <thead className="table-light">
                <tr>
                  <th>#</th>
                  <th>{t('company_environmental_page.company')}</th>
                  <th>{t('company_environmental_page.status')}</th>
                  <th>{t('company_environmental_page.pdf')}</th>
                  <th>{t('company_environmental_page.date')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {tableData}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    </div>
  )
};

export default Index
