import React, {useState} from "react"
import { useTranslation } from 'react-i18next';
import {Row, Modal} from "reactstrap"
import TextField from '@mui/material/TextField';
import "./index.scss"

const AddPermission = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    id: props.data ? props.data.id : null,
    name: props.data ? props.data.name : '',
    nameState: false
  });

  const onConfirm = () => {
    if (state.nameState === false) {
      props.onSubmit(state, !!state.id);
      props.onCancel();
    }
  };

  const changeName = (event) => {
    setState({
      ...state,
      "name": event.target.value,
      "nameState": event.target.value.length < 3,
    });
  };

  const onCancel = () => {
    props.onCancel();
  };

  return (
    <Modal isOpen={true} toggle={() => {onCancel()}} backdrop={"static"}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">{state.id ? t('acl_page.update') : t('acl_page.add_new')}</h5>
      </div>
      <div className="modal-body">
        <Row>
          <div className="col-md-12 mb-3">
            <TextField
              className="textField"
              label={ <span> {t('acl_page.name')} <small>({t('required')})</small> </span> }
              variant="standard"
              fullWidth
              error={state.nameState}
              value={state.name}
              onChange={event => changeName(event)}
            />
          </div>
        </Row>
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => onCancel()} className="btn btn-danger waves-effect" data-dismiss="modal">
          {t('close')}
        </button>
        <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => onConfirm()}>
          {t('save')}
        </button>
      </div>
    </Modal>
  );

};

export default AddPermission